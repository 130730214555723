import assignmentsAction from 'modules/assignments/assignments.action';
import groupsAction from 'modules/groups/groups.action';
import { templateModule } from 'modules/template/template.reducer';
import templatesAction from 'modules/templates/templates.action';
import uploadAction from 'modules/upload/upload.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'store';
import { TAddTrainingBody } from 'types/assignments';
import { TOptionsQuery } from 'types/common';
import AssignmentsAdmAdd from './AssignmentsAdmAdd';

const AssignmentsAdmAddContainer = () => {
  const dispatch = useDispatch();
  const { training } = useParams<{ training: string }>();

  const groupsState = useSelector((state: AppState) => state.pages.groups);
  const uiState = useSelector((state: AppState) => state.pages.ui);
  const templateState = useSelector((state: AppState) => state.pages.template);
  const assignmentsState = useSelector((state: AppState) => state.pages.assignments);
  const templatesState = useSelector((state: AppState) => state.pages.templates);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);

  const getTraining = (trainingId: string) => {
    if (currentGroup.id) dispatch(assignmentsAction.getTraining({ id: trainingId, groupId: currentGroup.id }));
  };
  const getAllTemplatesFinished = (groupId: number) => {
    dispatch(templatesAction.getAllTemplates({ isCompleted: true, groupId }));
  };

  const getStudents = (params: TOptionsQuery) => {
    dispatch(
      groupsAction.getUsers({
        ...params,
        role: 'student',
      }),
    );
  };

  const updateImage = (data: FormData) => {
    dispatch(uploadAction.uploadImage({ data }));
  };

  const clearStateUpload = () => {
    dispatch(uploadAction.clearState());
  };

  const createTraining = (t: TAddTrainingBody) => dispatch(assignmentsAction.createTraining(t));
  const updateTraining = (t: TAddTrainingBody) => dispatch(assignmentsAction.updateTraining(t));
  const updateNameImage = (name: string) => dispatch(templateModule.actions.updateImage(name));
  const clearState = () => dispatch(templateModule.actions.clearState());
  return (
    <AssignmentsAdmAdd
      groupsState={groupsState}
      uiState={uiState}
      templateState={templateState}
      assignmentsState={assignmentsState}
      getStudents={getStudents}
      updateImage={updateImage}
      clearStateUpload={clearStateUpload}
      createTraining={createTraining}
      updateNameImage={updateNameImage}
      trainingId={training}
      getTraining={getTraining}
      updateTraining={updateTraining}
      getAllTemplatesFinished={getAllTemplatesFinished}
      templatesState={templatesState}
      clearState={clearState}
      currentGroup={currentGroup}
    />
  );
};

export default AssignmentsAdmAddContainer;
