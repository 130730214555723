/* eslint-disable complexity */
import React from 'react';
import { isEqual } from 'lodash';
import { TTrainingResult } from 'types/trainingResult';
import { Table } from 'antd';
import { TQuestions, TQuestion, TAnswerRadio } from 'types/questions';
import { TTrainingStep } from 'libs/calculators';
import { columns, DataType } from './Questions.state';

const Questions: React.FC<{ trainingResult: TTrainingResult }> = ({ trainingResult }) => {
  const getValueOfQuestionId = (questionId: string, step: TTrainingStep): number => {
    if (trainingResult[step]) {
      const value = trainingResult[step]?.answer.find((a) => isEqual(a.questionId, questionId))?.answerValue ?? 0;
      return Number(value);
    }
    return 0;
  };

  const getAnwserValue = (question: TQuestion, valueAnswer: number): number | string => {
    switch (question.fieldType) {
      case 'radio': {
        const answer = question.answer as TAnswerRadio[];
        if (answer.length > 0) {
          const answerValue = answer.find((answer) => Number(answer.value) === valueAnswer);
          return answerValue?.label || '';
        }
        return valueAnswer;
      }
      case 'text':
      case 'number':
      case 'select':
        return valueAnswer;
      default:
        return '';
    }
  };

  const renderQuestion = (question: TQuestions) => {
    const step = `step${question?.step}` as TTrainingStep;

    const data: DataType[] = question.questions.map((question, index) => {
      let anwserValue;
      if (step) {
        anwserValue = getAnwserValue(question, getValueOfQuestionId(question.questionId, step));
      }

      return { index: index + 1, question: question?.fieldName ?? '', anwser: anwserValue ?? '' };
    });
    return (
      <>
        {question.groupName}
        <Table rowKey="questionId" columns={columns} dataSource={data} pagination={false} />
      </>
    );
  };

  return (
    <div>
      {trainingResult?.training?.template?.step2 &&
        trainingResult.training.template.step2.map((question) => renderQuestion(question))}
      {trainingResult?.training?.template?.step3 &&
        trainingResult.training.template.step3.map((question) => renderQuestion(question))}
    </div>
  );
};

export default Questions;
