import React from 'react';
import { Table } from 'antd';
import { TWorkingTime } from 'types/workingTime';

type EditableTableProps = Parameters<typeof Table>[0];

export type DataType = {
  key: React.Key;
  name: string;
  age: string;
  address: string;
};

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const defaultColumns = [
  {
    title: '作業工程名',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    render(_: any, record: TWorkingTime) {
      return {
        props: {
          style: { background: '#E9ECEF' },
        },
        children: <div>{record.name}</div>,
      };
    },
  },
  {
    title: '必要労働時間の単位',
    dataIndex: 'unit',
    key: 'unit',
    fixed: 'left',
    render(_: any, record: TWorkingTime) {
      return {
        props: {
          style: { background: '#E9ECEF' },
        },
        children: <div>{record.unit}</div>,
      };
    },
  },
  {
    title: '①年間の必要労働時間[h]',
    dataIndex: 'laborTime',
    key: 'laborTime',
    editable: true,
    render(_: any, record: TWorkingTime) {
      return {
        children: <div>{record.laborTime}</div>,
      };
    },
  },
  {
    title: '②設備投資時の年間削減量[h]',
    dataIndex: 'optimalTime',
    key: 'optimalTime',
    editable: true,
    render(_: any, record: TWorkingTime) {
      let background = '#E9ECEF';
      let pointerEvents = 'none';
      if (record.name === '換気' || record.name === '灌水') {
        pointerEvents = 'auto';
      }
      if (record.optimalTime > 0) {
        background = 'none';
      }
      return {
        props: {
          style: { background, pointerEvents },
        },
        children: <div>{record.optimalTime}</div>,
      };
    },
  },
  {
    title: '③年間に対する月別の必要労働時間の比率[%]',
    key: '3',
    editable: true,
    children: [
      {
        title: '1月',
        dataIndex: 'month1',
        editable: true,
        align: 'right',
      },
      {
        title: '2月',
        dataIndex: 'month2',
        editable: true,
        align: 'right',
      },
      {
        title: '3月',
        dataIndex: 'month3',
        editable: true,
        align: 'right',
      },
      {
        title: '4月',
        dataIndex: 'month4',
        editable: true,
        align: 'right',
      },
      {
        title: '5月',
        dataIndex: 'month5',
        editable: true,
        align: 'right',
      },
      {
        title: '6月',
        dataIndex: 'month6',
        editable: true,
        align: 'right',
      },
      {
        title: '7月',
        dataIndex: 'month7',
        editable: true,
        align: 'right',
      },
      {
        title: '8月',
        dataIndex: 'month8',
        editable: true,
        align: 'right',
      },
      {
        title: '9月',
        dataIndex: 'month9',
        editable: true,
        align: 'right',
      },
      {
        title: '10月',
        dataIndex: 'month10',
        editable: true,
        align: 'right',
      },
      {
        title: '11月',
        dataIndex: 'month11',
        editable: true,
        align: 'right',
      },
      {
        title: '12月',
        dataIndex: 'month12',
        editable: true,
        align: 'right',
      },
    ],
  },
  {
    title: '比率合計[%]',
    dataIndex: 'totalMonth',
    key: '8',
    render(_: any, record: TWorkingTime) {
      let style = { background: '#A6E9D5', textAlign: 'center' };
      if (record.totalMonth !== 100) {
        style = { background: '#F1AEB5', textAlign: 'center' };
      }
      return {
        props: {
          style,
        },
        children: <div>{record.totalMonth}</div>,
      };
    },
  },
];

export type TStep6 = {
  step6: TWorkingTime[];
};
