import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { AxiosResponse } from 'libs/axios';
import { uiModule } from 'modules/ui/ui.reducer';
import { messageModule } from 'modules/message/message.reducer';
import { forgotPasswordServices } from 'services/forgotPassword';
import { push } from 'react-router-redux';
import { routers } from 'constants/router';
import { messages } from 'constants/validate/message';
import actions from './forgotPassword.action';

export const resetPassword: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.resetPassword),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(forgotPasswordServices.resetPassword(payload.newPassword, payload.token)),
        complete: uiModule.actions.hideLoading(),
        next: () => [messageModule.actions.setSuccess(messages.changePasswordSuc), push(routers.U1.path)],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const forgotPassword: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$, state$) =>
  action$.pipe(
    ofAction(actions.forgotPassword),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFns: from(forgotPasswordServices.forgotPassword(payload)),
        next: () => [messageModule.actions.setSuccess(messages.forgotPassword)],
        previous: uiModule.actions.showLoading(),
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
