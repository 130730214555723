import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { ENavType } from 'constants/nav';
import { TQuestion, TQuestionRadio, TQuestions, TQuestionSelect } from 'types/questions';
import { useForm } from 'react-hook-form';
import ButtonMoveA3 from 'components/ButtonMoveA3';
import SelectTable from 'components/SelectTable/SelectTable';
import { TTemplateState } from 'modules/template/template.reducer';
import { TBaseLibState } from 'modules/baseLibrary/baseLibrary.reducer';
import EditTemplate from 'libs/hook/EditTemplate';
import { TGroupsClassDetail } from 'types/groupsClass';
import { columnsState, TStep2 } from './AdmAddQuestion1.state';
import AdmQuestionForm1 from './AdmQuestionForm1';
import AdmQuestionFormSelect1 from './AdmQuestionFormSelect1';
import './style.css';
import styles from './style.module.scss';

const AdmAddQuestion1: React.FC<{
  getQuestions: (groupId: number) => void;
  template: TTemplateState;
  updateTemplate: (data: TStep2, id: string, buttonFooter: boolean, link?: string) => void;
  currentGroup: TGroupsClassDetail;
  baseLibState: TBaseLibState;
}> = ({ getQuestions, template, updateTemplate, currentGroup, baseLibState }) => {
  const [questions, setQuestions] = useState<TQuestions[] | undefined>(template.step2);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedItem, setSelectedItem] = useState<TQuestionRadio[]>([]);
  const [selectedItemSelect, setSelectedItemSelect] = useState<TQuestionSelect>();
  const { control, reset, register, handleSubmit } = useForm({
    defaultValues: selectedItem[0],
  });
  const {
    reset: reset2,
    register: register2,
    handleSubmit: handleSubmit2,
  } = useForm({
    defaultValues: selectedItemSelect,
  });

  EditTemplate();

  useEffect(() => {
    if (template.step2 && template.step2.length > 0) {
      setQuestions([...template.step2]);
      return;
    }
    if (currentGroup.id) {
      getQuestions(currentGroup.id);
    }
  }, [template, baseLibState]);

  const onChangeState = (data: TQuestion) => {
    if (questions) {
      const newState = questions.map((question) => {
        const newQuestion = question.questions.map((item) => {
          if (item.questionId === data.questionId) {
            return { ...data };
          }
          return item;
        });
        return { ...question, questions: newQuestion };
      });
      setQuestions(newState);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: TQuestion[]) => {
      setSelectedRowKeys(selectedRowKeys);
      if (selectedItem[0]) {
        handleSubmit((data) => {
          onChangeState(data);
        })();
      } else {
        handleSubmit2((data) => {
          onChangeState(data);
        })();
      }
      if (selectedRows[0].fieldType === 'radio') {
        setSelectedItemSelect(undefined);
        setSelectedItem(selectedRows as TQuestionRadio[]);
      } else {
        setSelectedItem([]);
        setSelectedItemSelect(selectedRows[0] as TQuestionSelect);
      }
    },
    getCheckboxProps: (record: TQuestion) => ({
      name: record.fieldName,
    }),
  };

  const onSubmit = (data: TQuestion, buttonFooter: boolean, link?: string) => {
    if (questions) {
      const newState: TQuestions[] = questions.map((question) => {
        const newQuestion = question.questions.map((item) => {
          if (item.questionId === data.questionId) {
            return { ...data };
          }
          return item;
        });
        return { ...question, questions: newQuestion };
      });
      const newData: TStep2 = { step2: newState };
      updateTemplate(newData, template.id, buttonFooter, link);
      setQuestions(newState);
    }
  };

  const onFinish = (buttonFooter: boolean = false, link?: string) => {
    if (selectedItem[0]) {
      handleSubmit((data) => {
        onSubmit(data, buttonFooter, link);
      })();
    } else {
      handleSubmit2((data) => {
        onSubmit(data, buttonFooter, link);
      })();
    }
  };

  const onClickChangeRouter = (link: string) => {
    const buttonFooter = false;
    onFinish(buttonFooter, link);
  };

  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <Row>
          <Col span={12}>
            <Row>
              <p>研修テンプレート登録</p>
              <p className={styles.title}>{template.name}</p>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <ButtonMoveA3 />
            </Row>
          </Col>
        </Row>
        <NavStep type={ENavType.teacher} onClick={onClickChangeRouter} />
        <div className={styles.contentTitle}>設問設定①</div>
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div className={styles.contentWrap__left}>
              <div className={styles.form}>
                <p>方針、 初期投資、 年間費用の設問設定を行います。</p>
                {questions &&
                  questions.length > 0 &&
                  questions.map((question) => (
                    <div key={question.groupName}>
                      <p>{question.groupName}</p>
                      <SelectTable
                        data={question.questions}
                        rowSelection={rowSelection}
                        columnsState={columnsState}
                        key={question.groupName}
                      />
                    </div>
                  ))}
              </div>
            </div>
            {selectedItem.length > 0 && (
              <AdmQuestionForm1 reset={reset} control={control} selectedItem={selectedItem} register={register} />
            )}

            {selectedItemSelect && (
              <AdmQuestionFormSelect1 reset={reset2} selectedItem={selectedItemSelect} register={register2} />
            )}
          </div>
        </div>
      </div>
      <ButtonFooter handelClickNext={onFinish} handelClickPre={onFinish} />
    </>
  );
};

export default AdmAddQuestion1;
