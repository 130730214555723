import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveCurrentUserToLS, setRefreshToken, setToken } from 'config/server';
import { AxiosResponse } from 'libs/axios';
import { TUserDetail } from 'types/groups';

const initialState: TAuth = {
  isAuthenticated: false,
  currentUser: {},
};

export type TAuth = {
  isAuthenticated: boolean;
  currentUser: TUserDetail;
};
export type TAuthWithToken = TAuth & {
  token: string;
  refreshToken: string;
};

const _module = createSlice({
  name: '[auths/authentications]',
  initialState,
  reducers: {
    save(state: TAuth, action: PayloadAction<TAuthWithToken>) {
      const { token, refreshToken, ...auth } = action.payload;
      saveCurrentUserToLS(auth.currentUser);
      setRefreshToken(refreshToken);
      setToken(token);
      return {
        ...state,
        ...auth,
      };
    },
    saveUser(state: TAuth, action: PayloadAction<TAuth>) {
      saveCurrentUserToLS(action.payload.currentUser);
      return {
        ...state,
        ...action.payload,
      };
    },
    logout() {
      setToken('');
      setRefreshToken('');
      saveCurrentUserToLS({});
      return initialState;
    },
  },
});

export const authModule = _module;
