import React from 'react';
import { Modal, Table } from 'antd';
import MainButton from 'components/MainButton/MainButton';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import styles from './style.module.scss';

const ModalGroups: React.FC<{
  title: string;
  open: boolean;
  onClose: () => void;
  afterClose?: () => void;
}> = ({ open, onClose, title, afterClose }) => {
  const columnGroupTable = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: 'グループ名',
      dataIndex: 'name',
      width: '30%',
    },
    {
      title: '',
      width: '5%',
      render: () => (
        <div className={styles.actionBtn}>
          <MainButton text="選択" />
        </div>
      ),
    },
  ];

  const data = Array(10).fill({
    id: 1,
    name: 'Duy thanh',
  });
  return (
    <Modal title={title} centered open={open} onCancel={onClose} afterClose={afterClose} footer={null} width="65%">
      <div className={styles.contentModal}>
        <Table bordered columns={columnGroupTable} dataSource={data} pagination={false} loading={false} rowKey="id" />
        <div className={styles.paginationWrapper}>
          <PaginationCustom current={1} total={20} pageSize={10} disabled={false} onChange={() => {}} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalGroups;
