import React from 'react';
import { Col, Row } from 'antd';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import PieChart from 'components/PieChart/PieChart';
import styles from '../style.module.scss';

const Row3: React.FC<{ configTable: any }> = ({ configTable }) => (
  <Row
    gutter={[
      { xs: 0, sm: 20 },
      { xs: 20, sm: 0 },
    ]}
  >
    <ChartTitle
      title="生産計画表"
      subTitle="生産計画表では、設問の回答結果から算出した収量の推移や等級割合のシミュレーション結果を確認できます。"
    />
    <Col xs={24} sm={10}>
      <Row className={`${styles.subTitle} ${styles.borderSubTitleB}`}>
        <div className="left">等級割合グラフ</div>
      </Row>
      {configTable?.dataPieChartTab4 && <PieChart configChart={configTable?.dataPieChartTab4} />}
    </Col>
    <Col xs={24} sm={14}>
      <Row className={`${styles.subTitle} ${styles.borderSubTitleB}`}>
        <div className="left">年別収量推移グラフ</div>
      </Row>
      {configTable?.dataColumnChartTab4Two && <ColumnChart configChart={configTable?.dataColumnChartTab4Two} />}
    </Col>
  </Row>
);

export default Row3;
