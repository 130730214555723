import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const createTrainingResult: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/training-results',
};

const updateTrainingResult: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'training-results/getByTraining/:trainingId',
};

const getTrainingResultByTraining: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'training-results/getByTraining/:trainingId',
};

const getTrainingResultByTrainingAndStudent: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'training-results/getByTrainingAndUser/:trainingId/:studentId',
};

export const trainingResultRepositories = {
  createTrainingResult: apiClient.postFn(createTrainingResult),
  updateTrainingResult: apiClient.patchFn(updateTrainingResult),
  getTrainingResultByTraining: apiClient.getFn(getTrainingResultByTraining),
  getTrainingResultByTrainingAndStudent: apiClient.getFn(getTrainingResultByTrainingAndStudent),
};
