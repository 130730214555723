import { DEFAULT_LIMIT_PAGINATION } from 'constants/pagination';
import { groupsRepositories } from 'repositories/groups';
import { TOptionsQuery } from 'types/common';
import { TUserDetail } from 'types/groups';

const DEFAULT_GROUPS_LIMIT = DEFAULT_LIMIT_PAGINATION;
const DEFAULT_GROUPS_PAGE = 1;

const getUsers = async (params: TOptionsQuery<TUserDetail>) => {
  const res = await groupsRepositories.getUsers({
    condition: {
      ...params,
      limit: params.limit ?? DEFAULT_GROUPS_LIMIT,
      page: params.page ?? DEFAULT_GROUPS_PAGE,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const createUser = async (data: TUserDetail & { groupId: number }) => {
  const { groupId, ...body } = data;
  const res = await groupsRepositories.createUser({
    body,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateUser = async (data: TUserDetail & { groupId: number }) => {
  const { id, groupId, ...body } = data;
  const res = await groupsRepositories.updateUser({
    body,
    pathParameters: {
      id,
    },
    condition: { groupId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const deleteUser = async (data: { id: string; groupId: number }) => {
  const { id, groupId } = data;
  const res = await groupsRepositories.deleteUser({
    pathParameters: {
      id,
    },
    condition: { groupId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

export const groupsServices = {
  getUsers,
  updateUser,
  createUser,
  deleteUser,
};
