import { S2DataConfig } from '@antv/s2';
import { IndexedObject } from 'constants/types';
import { Calculator } from 'libs/calculators';
import { numberWithCommas, removeCommas, round } from 'libs/helpers/functions';
import { TTemplate } from 'types/template';
import { TTrainingResult } from 'types/trainingResult';

export type TConfigTab3Fn = {
  CONFIG_TABLE: S2DataConfig;
  DATA_COLUMN_CHART_SLIDER: IndexedObject;
};

const months = ['10', '11', '12', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
export const configResultsTable3 = (template: TTemplate, trainingResult: TTrainingResult): TConfigTab3Fn => {
  const cal = new Calculator(template, trainingResult);
  cal.M3();
  const CONFIG_TABLE = {
    fields: {
      rows: ['title'],
      values: months,
    },
    meta: [
      {
        field: 'title',
        name: '資金繰計画表',
      },
      {
        field: '10',
        name: '10月',
      },
      {
        field: '11',
        name: '11月',
      },
      {
        field: '12',
        name: '12月',
      },
      {
        field: '1',
        name: '1月',
      },
      {
        field: '2',
        name: '2月',
      },
      {
        field: '3',
        name: '3月',
      },
      {
        field: '4',
        name: '4月',
      },
      {
        field: '5',
        name: '5月',
      },
      {
        field: '6',
        name: '6月',
      },
      {
        field: '7',
        name: '7月',
      },
      {
        field: '8',
        name: '8月',
      },
      {
        field: '9',
        name: '9月',
      },
    ],
    data: [
      {
        title: '1年目残額',
        10: numberWithCommas(cal.M301(1, 1)),
        11: numberWithCommas(cal.M301(2, 1)),
        12: numberWithCommas(cal.M301(3, 1)),
        1: numberWithCommas(cal.M301(4, 1)),
        2: numberWithCommas(cal.M301(5, 1)),
        3: numberWithCommas(cal.M301(6, 1)),
        4: numberWithCommas(cal.M301(7, 1)),
        5: numberWithCommas(cal.M301(8, 1)),
        6: numberWithCommas(cal.M301(9, 1)),
        7: numberWithCommas(cal.M301(10, 1)),
        8: numberWithCommas(cal.M301(11, 1)),
        9: numberWithCommas(cal.M301(12, 1)),
      },
      {
        title: '2年目残額',
        10: numberWithCommas(cal.M301(1, 2)),
        11: numberWithCommas(cal.M301(2, 2)),
        12: numberWithCommas(cal.M301(3, 2)),
        1: numberWithCommas(cal.M301(4, 2)),
        2: numberWithCommas(cal.M301(5, 2)),
        3: numberWithCommas(cal.M301(6, 2)),
        4: numberWithCommas(cal.M301(7, 2)),
        5: numberWithCommas(cal.M301(8, 2)),
        6: numberWithCommas(cal.M301(9, 2)),
        7: numberWithCommas(cal.M301(10, 2)),
        8: numberWithCommas(cal.M301(11, 2)),
        9: numberWithCommas(cal.M301(12, 2)),
      },
      {
        title: '3年目残額',
        10: numberWithCommas(cal.M301(1, 3)),
        11: numberWithCommas(cal.M301(2, 3)),
        12: numberWithCommas(cal.M301(3, 3)),
        1: numberWithCommas(cal.M301(4, 3)),
        2: numberWithCommas(cal.M301(5, 3)),
        3: numberWithCommas(cal.M301(6, 3)),
        4: numberWithCommas(cal.M301(7, 3)),
        5: numberWithCommas(cal.M301(8, 3)),
        6: numberWithCommas(cal.M301(9, 3)),
        7: numberWithCommas(cal.M301(10, 3)),
        8: numberWithCommas(cal.M301(11, 3)),
        9: numberWithCommas(cal.M301(12, 3)),
      },
      {
        title: '4年目残額',
        10: numberWithCommas(cal.M301(1, 4)),
        11: numberWithCommas(cal.M301(2, 4)),
        12: numberWithCommas(cal.M301(3, 4)),
        1: numberWithCommas(cal.M301(4, 4)),
        2: numberWithCommas(cal.M301(5, 4)),
        3: numberWithCommas(cal.M301(6, 4)),
        4: numberWithCommas(cal.M301(7, 4)),
        5: numberWithCommas(cal.M301(8, 4)),
        6: numberWithCommas(cal.M301(9, 4)),
        7: numberWithCommas(cal.M301(10, 4)),
        8: numberWithCommas(cal.M301(11, 4)),
        9: numberWithCommas(cal.M301(12, 4)),
      },
      {
        title: '5年目残額',
        10: numberWithCommas(cal.M301(1, 5)),
        11: numberWithCommas(cal.M301(2, 5)),
        12: numberWithCommas(cal.M301(3, 5)),
        1: numberWithCommas(cal.M301(4, 5)),
        2: numberWithCommas(cal.M301(5, 5)),
        3: numberWithCommas(cal.M301(6, 5)),
        4: numberWithCommas(cal.M301(7, 5)),
        5: numberWithCommas(cal.M301(8, 5)),
        6: numberWithCommas(cal.M301(9, 5)),
        7: numberWithCommas(cal.M301(10, 5)),
        8: numberWithCommas(cal.M301(11, 5)),
        9: numberWithCommas(cal.M301(12, 5)),
      },
    ],
  };

  const DATA_COLUMN_CHART_SLIDER = {
    data: [
      { 月: '1年目10月', 現金残高: round(cal.M301(1, 1)) },
      { 月: '1年目11月', 現金残高: round(cal.M301(2, 1)) },
      { 月: '1年目12月', 現金残高: round(cal.M301(3, 1)) },
      { 月: '1年目1月', 現金残高: round(cal.M301(4, 1)) },
      { 月: '1年目2月', 現金残高: round(cal.M301(5, 1)) },
      { 月: '1年目3月', 現金残高: round(cal.M301(6, 1)) },
      { 月: '1年目4月', 現金残高: round(cal.M301(7, 1)) },
      { 月: '1年目5月', 現金残高: round(cal.M301(8, 1)) },
      { 月: '1年目6月', 現金残高: round(cal.M301(9, 1)) },
      { 月: '1年目7月', 現金残高: round(cal.M301(10, 1)) },
      { 月: '1年目8月', 現金残高: round(cal.M301(11, 1)) },
      { 月: '1年目9月', 現金残高: round(cal.M301(12, 1)) },

      { 月: '2年目10月', 現金残高: round(cal.M301(1, 2)) },
      { 月: '2年目11月', 現金残高: round(cal.M301(2, 2)) },
      { 月: '2年目12月', 現金残高: round(cal.M301(3, 2)) },
      { 月: '2年目1月', 現金残高: round(cal.M301(4, 2)) },
      { 月: '2年目2月', 現金残高: round(cal.M301(5, 2)) },
      { 月: '2年目3月', 現金残高: round(cal.M301(6, 2)) },
      { 月: '2年目4月', 現金残高: round(cal.M301(7, 2)) },
      { 月: '2年目5月', 現金残高: round(cal.M301(8, 2)) },
      { 月: '2年目6月', 現金残高: round(cal.M301(9, 2)) },
      { 月: '2年目7月', 現金残高: round(cal.M301(10, 2)) },
      { 月: '2年目8月', 現金残高: round(cal.M301(11, 2)) },
      { 月: '2年目9月', 現金残高: round(cal.M301(12, 2)) },

      { 月: '3年目10月', 現金残高: round(cal.M301(1, 3)) },
      { 月: '3年目11月', 現金残高: round(cal.M301(2, 3)) },
      { 月: '3年目12月', 現金残高: round(cal.M301(3, 3)) },
      { 月: '3年目1月', 現金残高: round(cal.M301(4, 3)) },
      { 月: '3年目2月', 現金残高: round(cal.M301(5, 3)) },
      { 月: '3年目3月', 現金残高: round(cal.M301(6, 3)) },
      { 月: '3年目4月', 現金残高: round(cal.M301(7, 3)) },
      { 月: '3年目5月', 現金残高: round(cal.M301(8, 3)) },
      { 月: '3年目6月', 現金残高: round(cal.M301(9, 3)) },
      { 月: '3年目7月', 現金残高: round(cal.M301(10, 3)) },
      { 月: '3年目8月', 現金残高: round(cal.M301(11, 3)) },
      { 月: '3年目9月', 現金残高: round(cal.M301(12, 3)) },

      { 月: '4年目10月', 現金残高: round(cal.M301(1, 4)) },
      { 月: '4年目11月', 現金残高: round(cal.M301(2, 4)) },
      { 月: '4年目12月', 現金残高: round(cal.M301(3, 4)) },
      { 月: '4年目1月', 現金残高: round(cal.M301(4, 4)) },
      { 月: '4年目2月', 現金残高: round(cal.M301(5, 4)) },
      { 月: '4年目3月', 現金残高: round(cal.M301(6, 4)) },
      { 月: '4年目4月', 現金残高: round(cal.M301(7, 4)) },
      { 月: '4年目5月', 現金残高: round(cal.M301(8, 4)) },
      { 月: '4年目6月', 現金残高: round(cal.M301(9, 4)) },
      { 月: '4年目7月', 現金残高: round(cal.M301(10, 4)) },
      { 月: '4年目8月', 現金残高: round(cal.M301(11, 4)) },
      { 月: '4年目9月', 現金残高: round(cal.M301(12, 4)) },

      { 月: '5年目10月', 現金残高: round(cal.M301(1, 5)) },
      { 月: '5年目11月', 現金残高: round(cal.M301(2, 5)) },
      { 月: '5年目12月', 現金残高: round(cal.M301(3, 5)) },
      { 月: '5年目1月', 現金残高: round(cal.M301(4, 5)) },
      { 月: '5年目2月', 現金残高: round(cal.M301(5, 5)) },
      { 月: '5年目3月', 現金残高: round(cal.M301(6, 5)) },
      { 月: '5年目4月', 現金残高: round(cal.M301(7, 5)) },
      { 月: '5年目5月', 現金残高: round(cal.M301(8, 5)) },
      { 月: '5年目6月', 現金残高: round(cal.M301(9, 5)) },
      { 月: '5年目7月', 現金残高: round(cal.M301(10, 5)) },
      { 月: '5年目8月', 現金残高: round(cal.M301(11, 5)) },
      { 月: '5年目9月', 現金残高: round(cal.M301(12, 5)) },
    ],
    xField: '月',
    yField: '現金残高',
    xAxis: {
      label: {
        autoRotate: true,
      },
    },
    slider: {
      start: 0,
      end: 1,
    },
    tooltip: {
      formatter: (datum: IndexedObject) => ({ name: datum.月, value: numberWithCommas(datum.現金残高) }),
    },
    yAxis: {
      label: {
        formatter: (datum: string) => numberWithCommas(Number(datum)),
      },
    },
  };

  return { CONFIG_TABLE, DATA_COLUMN_CHART_SLIDER };
};

const getOptions = (type?: string) =>
  months.map((month) => ({
    field: month,
    mapping(field: string | number) {
      if (Number(removeCommas(field)) < 0) {
        return type === 'text' ? { fill: '#ffffff' } : { fill: '#FF4D4F' };
      }
      return {
        fill: type === 'text' ? '#000000' : '',
      };
    },
  }));

export const OPTIONS_TABLE = {
  height: 250,
  conditions: {
    background: getOptions(),
    text: getOptions('text'),
  },
  style: {
    colCfg: {
      widthByFieldValue: {
        1: 125,
        2: 125,
        3: 125,
        4: 125,
        5: 125,
        6: 125,
        7: 125,
        8: 125,
        9: 125,
        10: 125,
        11: 125,
        12: 125,
      },
    },
  },
};
