import forgotPasswordAction from 'modules/forgotPassword/forgotPassword.action';
import React from 'react';
import { useDispatch } from 'react-redux';
import ForgotPassword from './ForgotPassword';

const ForgotPasswordContainer = () => {
  const dispatch = useDispatch();

  const forgotPassword = (email: string) => {
    dispatch(forgotPasswordAction.forgotPassword(email));
  };
  return <ForgotPassword forgotPassword={forgotPassword} />;
};

export default ForgotPasswordContainer;
