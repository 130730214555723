import React from 'react';
import { Form, InputNumber } from 'antd';
import { Rule } from 'antd/lib/form';

const CommonInputNumber: React.FC<{
  label: string;
  placeholder: string;
  onChange?: () => void;
  name?: string;
  rule?: Rule[] | undefined;
  extra?: string | undefined;
}> = ({ label, placeholder, onChange, name, rule, extra }) => (
  <Form.Item label={label} name={name} rules={rule} extra={extra}>
    <InputNumber style={{ width: '100%' }} onChange={onChange} placeholder={placeholder} />
  </Form.Item>
);

export default CommonInputNumber;
