import React from 'react';
import { Col, RadioChangeEvent, Row } from 'antd';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import SheetTable from 'components/SheetTable/SheetTable';
import { RADIO_DATA } from 'pages/Client/AssignmentsResults/Tabs/tab1/assignmentsResults';
import CommonRadio from 'components/CommonRadio/CommonRadio';
import { TTargetYear } from 'libs/calculators';
import styles from '../style.module.scss';

const RowFour: React.FC<{
  configTable: any;
  onChange?: (e: RadioChangeEvent) => void;
  statementExpenseEachYear: TTargetYear;
}> = ({ configTable, onChange, statementExpenseEachYear }) => (
  <Row
    style={{ marginTop: '100px' }}
    gutter={[
      { xs: 0, sm: 30 },
      { xs: 20, sm: 0 },
    ]}
  >
    <Col xs={24} sm={12}>
      <CommonRadio radioOptions={RADIO_DATA} onChange={onChange} value={String(statementExpenseEachYear)} />
      <div className={styles.subTitle}>
        <div className="left">{statementExpenseEachYear}年目コスト</div>
      </div>
      {configTable?.dataColumn && <ColumnChart configChart={configTable?.dataColumn} />}
    </Col>
    <Col xs={24} sm={12}>
      {configTable?.dataTableDiagram2 && (
        <SheetTable dataCfg={configTable?.dataTableDiagram2} options={configTable?.OPTIONS_TABLE} />
      )}
    </Col>
  </Row>
);
export default RowFour;
