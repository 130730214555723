import React from 'react';
import assignmentsAction from 'modules/assignments/assignments.action';
import trainingResultAction from 'modules/trainingResult/trainingResult.action';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { TAddTrainingBody } from 'types/assignments';
import { TOptionsQuery } from 'types/common';
import Top from './Top';

const TopContainer = () => {
  const dispatch = useDispatch();
  const assignmentsState = useSelector((state: AppState) => state.pages.assignments);
  const uiState = useSelector((state: AppState) => state.pages.ui);
  const userId = useSelector((state: AppState) => state.pages.auth.currentUser.id);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);

  const getTrainingsWorking = (params: TOptionsQuery<TAddTrainingBody>) =>
    dispatch(
      assignmentsAction.getTrainingsWorking({
        ...params,
        isCompleted: false,
      }),
    );
  const getTrainingsFinish = (params: TOptionsQuery<TAddTrainingBody>) =>
    dispatch(
      assignmentsAction.getTrainingsFinish({
        ...params,
        isCompleted: true,
      }),
    );
  const createTrainingsResult = (trainingId: string) => {
    if (currentGroup.id)
      dispatch(trainingResultAction.createTrainingResult({ data: { trainingId, groupId: currentGroup.id } }));
  };

  return (
    <Top
      assignmentsState={assignmentsState}
      uiState={uiState}
      userId={userId}
      getTrainingsWorking={getTrainingsWorking}
      getTrainingsFinish={getTrainingsFinish}
      createTrainingsResult={createTrainingsResult}
      currentGroup={currentGroup}
    />
  );
};

export default TopContainer;
