import { TStep5 } from 'pages/Admin/AdmAddCalculationSettingsGrade/AdmAddCalculationSettingsGrade.state';
import { TStep4 } from 'pages/Admin/AdmAddCalculationSettingsYields/AdmAddCalculationSettingsYields.state';
import { TStep2 } from 'pages/Admin/AdmAddQuestion1/AdmAddQuestion1.state';
import { TStep3 } from 'pages/Admin/AdmAddQuestion2/AdmAddQuestion2.state';
import { TStep7 } from 'pages/Admin/TemplateKpi/TemplateKpi.state';
import { TTemplate } from 'pages/Admin/TemplateRfp/TemplateRfp.state';
import { TStep6 } from 'pages/Admin/WorkingTime/WorkingTime.state';
import { templateRepositories } from 'repositories/template';

const createTemplate = async (data: TTemplate & { groupId: number }) => {
  const { groupId, ...body } = data;
  const res = await templateRepositories.createTemplate({
    body,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate = async (groupId: number, data: TTemplate, id: string) => {
  const res = await templateRepositories.updateTemplateStep1({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate2 = async (groupId: number, data: TStep2, id: string) => {
  const res = await templateRepositories.updateTemplateStep1({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate3 = async (groupId: number, data: TStep3, id: string) => {
  const res = await templateRepositories.updateTemplateStep1({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate4 = async (groupId: number, data: TStep4, id: string) => {
  const res = await templateRepositories.updateTemplateStep1({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate5 = async (groupId: number, data: TStep5, id: string) => {
  const res = await templateRepositories.updateTemplateStep1({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate6 = async (groupId: number, data: TStep6, id: string) => {
  const res = await templateRepositories.updateTemplateStep1({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTemplate7 = async (groupId: number, data: TStep7, id: string) => {
  const res = await templateRepositories.updateTemplateStep1({
    pathParameters: {
      id,
    },
    body: data,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const getTemplate = async (data: { id: string; groupId: number }) => {
  const { id, groupId } = data;
  const res = await templateRepositories.getTemplate({
    pathParameters: {
      id,
    },
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

export const templateServices = {
  createTemplate,
  updateTemplate,
  updateTemplate2,
  updateTemplate3,
  updateTemplate4,
  updateTemplate5,
  updateTemplate6,
  updateTemplate7,
  getTemplate,
};
