import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'store';
import trainingResultAction from 'modules/trainingResult/trainingResult.action';

const EditTraining = () => {
  const { trainingId } = useParams<{ trainingId: string }>();
  const trainingResult = useSelector((state: AppState) => state.pages.trainingResult);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);

  const dispatch = useDispatch();

  useEffect(() => {
    if (trainingId !== trainingResult?.training?.id && currentGroup.id) {
      dispatch(trainingResultAction.getTrainingResultByTraining({ trainingId, groupId: currentGroup.id }));
    }
  }, []);
};

export default EditTraining;
