import baseLibraryAction from 'modules/baseLibrary/baseLibrary.action';
import templateAction from 'modules/template/template.action';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'store';

const EditTemplate = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const template = useSelector((state: AppState) => state.pages.template);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);

  const dispatch = useDispatch();

  useEffect(() => {
    if (templateId && currentGroup.id) {
      dispatch(templateAction.getTemplateId({ id: templateId, groupId: currentGroup.id }));
    }
  }, [templateId, currentGroup.id]);

  useEffect(() => {
    if (template.baseLibrary) {
      dispatch(baseLibraryAction.getBaseLibrarySample(template.baseLibrary));
    }
  }, [template.baseLibrary]);
};

export default EditTemplate;
