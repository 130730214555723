/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { configTableFnTab4 } from 'pages/Client/AssignmentsResults/Tabs/Tab4/assignmentResults4';
import { RadioChangeEvent, Tabs } from 'antd';
import { TTrainingResult } from 'types/trainingResult';
import { configResultsTable3 } from 'pages/Client/AssignmentsResults/Tabs/Tab3/assignmentsResultsTab3';
import { configTab2Fn } from 'pages/Client/AssignmentsResults/Tabs/Tab2/assignmentResult2';
import { configTableFnTab1 } from 'pages/Client/AssignmentsResults/Tabs/tab1/assignmentsResults';
import { TTargetYear } from 'libs/calculators';
import { useLocation, useParams } from 'react-router-dom';
import { TGroupsClassDetail } from 'types/groupsClass';
import styles from './style.module.scss';
import './style.css';
import Tab1 from './tab1/Tab1';
import Tab2 from './Tab2/Tab2';
import Tab3 from './Tab3/Tab3';
import Tab4 from './Tab4/Tab4';
import Questions from '../Questions/Questions';

type LocationState = {
  tab: string;
};

const AssignmentsResultsTab: React.FC<{
  trainingResult: TTrainingResult;
  admPage?: boolean;
  getTrainingResult: (data: { trainingId: string; groupId: number }) => void;
  currentGroup: TGroupsClassDetail;
}> = ({ trainingResult, admPage, getTrainingResult, currentGroup }) => {
  const [profitAndLossEachYear, setProfitAndLossEachYear] = useState<TTargetYear>(1);
  const [statementExpenseEachYear, setStatementExpenseEachYear] = useState<TTargetYear>(1);
  const { tab } = (useLocation().state as LocationState) ?? '';

  const [configDataTab1, setConfigDataTab1] = useState<any>();

  const [configDataTab2, setConfigDataTab2] = useState<any>();

  const [configTable3, setConfigTable3] = useState<any>();

  const [configDataTab4, setConfigDataTab4] = useState<any>();

  const { trainingId } = useParams<{ trainingId: string }>();

  const getDataTabActive = (tabActive?: string) => {
    const showTab = tabActive ?? tab;
    if ((showTab === '1' && !configDataTab1) || !showTab) {
      setConfigDataTab1(
        configTableFnTab1(
          trainingResult.training?.template ?? {},
          trainingResult,
          profitAndLossEachYear,
          statementExpenseEachYear,
        ),
      );
    }
    if (showTab === '2' && !configDataTab2)
      setConfigDataTab2(configTab2Fn(trainingResult.training?.template ?? {}, trainingResult));
    if (showTab === '3' && !configTable3)
      setConfigTable3(configResultsTable3(trainingResult.training?.template ?? {}, trainingResult));
    if (showTab === '4' && !configDataTab4)
      setConfigDataTab4(configTableFnTab4(trainingResult.training?.template ?? {}, trainingResult));
  };

  useEffect(() => {
    if (trainingResult.id && trainingResult) {
      getDataTabActive();
      return;
    }
    if (currentGroup.id) getTrainingResult({ trainingId, groupId: currentGroup.id });
  }, [trainingResult, currentGroup.id]);

  const handleProfitAndLossEachYear = (e: RadioChangeEvent) => {
    setProfitAndLossEachYear(e.target.value);
    setConfigDataTab1(
      configTableFnTab1(
        trainingResult.training?.template ?? {},
        trainingResult,
        e.target.value,
        statementExpenseEachYear,
      ),
    );
  };

  const handleStatementExpenseEachYear = (e: RadioChangeEvent) => {
    setStatementExpenseEachYear(e.target.value);
    setConfigDataTab1(
      configTableFnTab1(trainingResult.training?.template ?? {}, trainingResult, profitAndLossEachYear, e.target.value),
    );
  };

  const getDefaultActiveKey = (): string => {
    if (admPage) {
      return '0';
    }
    return tab ?? '1';
  };

  const changeTab = (tabActive: string) => {
    getDataTabActive(tabActive);
  };

  return (
    <Tabs defaultActiveKey={getDefaultActiveKey()} type="card" className={styles.tabsWrap} onChange={changeTab}>
      {admPage && (
        <Tabs.TabPane tab="回答結果" key="0">
          <Questions trainingResult={trainingResult} />
        </Tabs.TabPane>
      )}
      <Tabs.TabPane tab="損益計算書（PL）" key="1">
        {configDataTab1 && (
          <Tab1
            configTable={configDataTab1}
            handleProfitAndLossEachYear={handleProfitAndLossEachYear}
            handleStatementExpenseEachYear={handleStatementExpenseEachYear}
            profitAndLossEachYear={profitAndLossEachYear}
            statementExpenseEachYear={statementExpenseEachYear}
          />
        )}
      </Tabs.TabPane>
      <Tabs.TabPane tab="投資計画" key="2">
        {configDataTab2 && <Tab2 configTable={configDataTab2} />}
      </Tabs.TabPane>
      <Tabs.TabPane tab="資金繰計画" key="3">
        {configTable3 && <Tab3 configTable={configTable3} />}
      </Tabs.TabPane>
      <Tabs.TabPane tab="生産＆販売計画" key="4">
        {configDataTab4 && <Tab4 configTable={configDataTab4} />}
      </Tabs.TabPane>
    </Tabs>
  );
};

export default AssignmentsResultsTab;
