import { IndexedObject } from 'constants/types';
import { Location } from 'history';
import queryString from 'query-string';
import { TRoleUser } from 'types/auth';
import { TGroupsClassDetail } from 'types/groupsClass';

export const replacePathParams = (path: string, params: IndexedObject<string>): string =>
  path.replace(/:([^/]+)/g, (_, p1) => encodeURIComponent(params[p1] ? params[p1] : ''));

export const isEmptyObject = (obj: IndexedObject) => {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

export const parseFloatNum = (str: string | (string | null)[] | null | number) => {
  const trimmed = str && typeof str === 'string' ? str.trim() : null;
  if (!trimmed) {
    return null;
  }
  const num = parseFloat(trimmed);
  const isFullyParsedNum = num.toString() === trimmed;
  return isFullyParsedNum ? num : null;
};
export const parseSearchParams = (search: string) => {
  const params = queryString.parse(search);
  return Object.keys(params).reduce((result: IndexedObject, key: string) => {
    const newResult = { ...result };
    const val = params[key];
    if (val === 'true') {
      newResult[key] = true;
    } else if (val === 'false') {
      newResult[key] = false;
    } else {
      const num = parseFloatNum(val);
      newResult[key] = num === null ? val : num;
    }
    return newResult;
  }, {});
};

export const createQueryUrl = (location: Location, params: IndexedObject) => {
  const { pathname } = location;
  if (isEmptyObject(params)) return pathname;
  const query = queryString.stringify(params);
  return `${pathname}?${query}`;
};

export const getTitleRoleUser = (role?: TRoleUser) => {
  switch (role) {
    case 'student':
      return '生徒';
    case 'teacher':
      return '講師';
    default:
      return '管理者';
  }
};

export const numberWithCommas = (x: number, unit?: string) =>
  x
    ? `${unit ?? '¥'}${x
        .toFixed()
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    : 0;

export const removeCommas = (x: string | number) => (x ? x.toString().replace(/[,¥,%]/g, '') : 0);
export const getValueOrDefault = (value: number): number => (Number.isFinite(value) ? value : 0);

export const round = (num: number): number => Math.round(num);

export const fixedNumber = (num: number, fixed: number): number => parseFloat(num.toFixed(fixed));

export const addCommas = (x: number, y?: string, fixed?: number) =>
  x
    ? `${
        x
          .toFixed(fixed ?? 2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + y ?? ''
      }`
    : '0';

export const getNumberOnly = (number: string | number): number => {
  if (typeof number === 'number') return number;
  const numb = number.toString().split('.')[0].match(/\d/g)?.join('');
  return Number(numb || 0);
};

export const addOnlyCommas = (x: string) => x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const saveGroupToLS = (group: TGroupsClassDetail) => localStorage.setItem('group', JSON.stringify(group));
export const getGroupFromLS = () => JSON.parse(localStorage.getItem('group') ?? '{}');
