import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map, mergeMap } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { AxiosResponse } from 'libs/axios';
import { push } from 'react-router-redux';
import { uiModule } from 'modules/ui/ui.reducer';
import { templateServices } from 'services/template';
import { messageModule } from 'modules/message/message.reducer';
import { routers } from 'constants/router';
import { baseLibraryModule } from 'modules/baseLibrary/baseLibrary.reducer';
import { templateModule, TTemplateState } from './template.reducer';
import actions from './template.action';

export const getTemplateEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getTemplateId),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.getTemplate(payload)),
        next: (v: TTemplateState) => [templateModule.actions.getTemplate(v)],
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const createTemplateEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.createTemplate),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.createTemplate({ ...payload.data, groupId: payload.groupId })),
        next: (v: TTemplateState) => {
          if (payload.link) {
            return [push(payload.link.replace('templateId', v.id)), templateModule.actions.saveTemplate(v)];
          }
          if (v.id) {
            return [push(routers.A5_2.path.replace(':templateId', v.id)), templateModule.actions.saveTemplate(v)];
          }
          return [templateModule.actions.saveTemplate(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStep1: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep1),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate(payload.groupId, payload.data, payload.id)),
        next: (v: TTemplateState) => {
          if (payload.link) {
            return [push(payload.link.replace('templateId', v.id)), templateModule.actions.saveTemplate(v)];
          }
          if (payload.buttonFooter) {
            return [push(routers.A5_2.path.replace(':templateId', v.id)), templateModule.actions.saveTemplate(v)];
          }
          return [templateModule.actions.saveTemplate(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStep2: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep2),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate2(payload.groupId, payload.data, payload.id)),
        next: (v: TTemplateState) => {
          if (payload.link) {
            return [push(payload.link.replace('templateId', v.id)), templateModule.actions.updateStep2(v)];
          }
          if (payload.buttonFooter) {
            return [push(routers.A5_3.path.replace(':templateId', v.id)), templateModule.actions.updateStep2(v)];
          }
          return [push(routers.A5.path.replace(':templateId', v.id)), templateModule.actions.updateStep2(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStep3: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep3),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate3(payload.groupId, payload.data, payload.id)),
        next: (v: TTemplateState) => {
          if (payload.link) {
            return [push(payload.link.replace('templateId', v.id)), templateModule.actions.updateStep3(v)];
          }
          if (payload.buttonFooter) {
            return [push(routers.A5_4.path.replace(':templateId', v.id)), templateModule.actions.updateStep3(v)];
          }
          return [push(routers.A5_2.path.replace(':templateId', v.id)), templateModule.actions.updateStep3(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStep4: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep4),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate4(payload.groupId, payload.data, payload.id)),
        next: (v: TTemplateState) => {
          if (payload.link) {
            return [push(payload.link.replace('templateId', v.id)), templateModule.actions.updateStep4(v)];
          }
          if (payload.buttonFooter) {
            return [push(routers.A5_5.path.replace(':templateId', v.id)), templateModule.actions.updateStep4(v)];
          }
          return [push(routers.A5_3.path.replace(':templateId', v.id)), templateModule.actions.updateStep4(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStep5: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep5),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate5(payload.groupId, payload.data, payload.id)),
        next: (v: TTemplateState) => {
          if (payload.link) {
            return [push(payload.link.replace('templateId', v.id)), templateModule.actions.updateStep5(v)];
          }
          if (payload.buttonFooter) {
            return [push(routers.A5_6.path.replace(':templateId', v.id)), templateModule.actions.updateStep5(v)];
          }
          return [push(routers.A5_4.path.replace(':templateId', v.id)), templateModule.actions.updateStep5(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStep6: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep6),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate6(payload.groupId, payload.data, payload.id)),
        next: (v: TTemplateState) => {
          if (payload.link) {
            return [push(payload.link.replace('templateId', v.id)), templateModule.actions.updateStep6(v)];
          }
          if (payload.buttonFooter) {
            return [push(routers.A5_7.path.replace(':templateId', v.id)), templateModule.actions.updateStep6(v)];
          }
          return [push(routers.A5_5.path.replace(':templateId', v.id)), templateModule.actions.updateStep6(v)];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTemplateStep7: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTemplateStep7),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(templateServices.updateTemplate7(payload.groupId, payload.data, payload.id)),
        next: (v: TTemplateState) => {
          if (payload.link) {
            return [
              push(payload.link.replace('templateId', v.id)),
              templateModule.actions.updateStep7(v),
              baseLibraryModule.actions.saveDataSample({}),
            ];
          }
          if (payload.buttonFooter) {
            return [
              push(routers.A3.path),
              templateModule.actions.updateStep7(v),
              baseLibraryModule.actions.saveDataSample({}),
            ];
          }
          return [
            push(routers.A5_6.path.replace(':templateId', v.id)),
            templateModule.actions.updateStep7(v),
            baseLibraryModule.actions.saveDataSample({}),
          ];
        },
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const clearTemplate: Epic<AnyAction, WrapAction<any>> = (action$) =>
  action$.pipe(
    ofAction(actions.clearTemplate),
    mergeMap(() => [templateModule.actions.clearState()]),
  );
