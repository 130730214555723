import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paginationDefault } from 'constants/pagination';
import { TResponsePagination } from 'libs/axios';
import { TBaseLibary } from 'types/baseLibrary';
import { TOptionsQuery, TPagination } from 'types/common';

export type TDataFileUpload = { fname?: string; originalName?: string };
export type TBaseLibState = {
  params: TOptionsQuery<TBaseLibary>;
  baseLibs: TBaseLibary[];
  baseLibDetail: TBaseLibary;
  pagination: TPagination;
  dataFileUpload: TDataFileUpload;
  loading: boolean;
  dataSample: any;
};

const initialState: TBaseLibState = {
  loading: false,
  params: {},
  baseLibs: [],
  baseLibDetail: {},
  pagination: paginationDefault(),
  dataFileUpload: {},
  dataSample: {},
};

const _module = createSlice({
  name: 'baseLibrary',
  initialState,
  reducers: {
    setParams(state: TBaseLibState, action: PayloadAction<TOptionsQuery<TBaseLibary>>) {
      return {
        ...state,
        params: action.payload,
      };
    },
    saveBaseLibs(state: TBaseLibState, action: PayloadAction<TResponsePagination<TBaseLibary>>) {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        baseLibs: results,
        pagination,
      };
    },
    setDataFileUpload(state: TBaseLibState, action: PayloadAction<TDataFileUpload>) {
      return {
        ...state,
        dataFileUpload: action.payload,
      };
    },
    deleteBaseLib(state: TBaseLibState, action: PayloadAction<number>) {
      const remainBaseLibList = state.baseLibs.filter((b) => b.id !== action.payload);
      return {
        ...state,
        baseLibs: remainBaseLibList,
      };
    },
    saveBaseLibDetail(state: TBaseLibState, action: PayloadAction<TBaseLibary>) {
      return {
        ...state,
        baseLibDetail: action.payload,
      };
    },
    saveDataSample(state: TBaseLibState, action: PayloadAction<any>) {
      return {
        ...state,
        dataSample: action.payload,
      };
    },
  },
});

export const baseLibraryModule = _module;
