import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const getQuestions: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'questions',
};

export const questionsRepositories = {
  getQuestions: apiClient.getFn(getQuestions),
};
