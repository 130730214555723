import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { ENavType } from 'constants/nav';
import ButtonMoveA3 from 'components/ButtonMoveA3';
import TemplateKpiTable, { TNameGoalSetting } from 'components/TemplateKpiTable/TemplateKpiTable';
import { TTemplateState } from 'modules/template/template.reducer';
import { TBaseLibState } from 'modules/baseLibrary/baseLibrary.reducer';
import { TGoalTrainingSetting } from 'types/assignments';
import EditTemplate from 'libs/hook/EditTemplate';
import { getDefaultsStep7, getStep7, TFormStep7, TStep7 } from './TemplateKpi.state';
import styles from './style.module.scss';

const TemplateKpi: React.FC<{
  template: TTemplateState;
  baseLibState: TBaseLibState;
  updateTemplate: (data: TStep7, id: string, buttonFooter: boolean, link?: string) => void;
}> = ({ template, updateTemplate, baseLibState }) => {
  const [defaultState, setDeafultState] = useState<TFormStep7 | null>();
  const [checkboxKpi, setCheckboxKpi] = useState<TNameGoalSetting[]>([]);
  const [form] = Form.useForm();

  EditTemplate();

  useEffect(() => {
    if (template.step7) {
      const newCheckboxKpi = Object.keys(template.step7 ?? {}).filter(
        (key) => template.step7 && template.step7[key as keyof TGoalTrainingSetting].checked,
      ) as TNameGoalSetting[];
      setCheckboxKpi(newCheckboxKpi);
      setDeafultState(getDefaultsStep7(template.step7));
      form.setFieldsValue(getDefaultsStep7(template.step7));
    } else {
      setDeafultState(getDefaultsStep7());
      form.setFieldsValue(getDefaultsStep7());
    }
  }, [template, baseLibState]);

  const getCheckedKpi = (checkbox: TNameGoalSetting[]) =>
    checkbox.length !== checkboxKpi.length &&
    checkbox.toString() !== checkboxKpi.toString() &&
    setCheckboxKpi(checkbox);

  const onChangeValue = (changeValue: TFormStep7) => {
    setDeafultState({ ...defaultState, ...changeValue });
  };

  const onFinish = (buttonFooter: boolean = false, link?: string) => {
    if (defaultState) {
      const newData = {
        step7: getStep7(defaultState, checkboxKpi),
      };
      updateTemplate(newData, template.id, buttonFooter, link);
    }
  };

  const onClickChangeRouter = (link: string) => {
    const buttonFooter = false;
    onFinish(buttonFooter, link);
  };

  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <Row>
          <Col span={12}>
            <Row>
              <p>研修テンプレート登録</p>
              <p className={styles.title}>{template.name}</p>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <ButtonMoveA3 />
            </Row>
          </Col>
        </Row>
        <NavStep type={ENavType.teacher} onClick={onClickChangeRouter} />
        <div className={styles.content}>
          <Card title="達成目標の設定" className={styles.contentWrap}>
            <p className={styles.titleContent}>達成目標の設定を行います。</p>
            <p className={styles.titleContent}>目標に含める項目は、チェックボックスをチェックします。</p>
            <Form autoComplete="off" layout="vertical" form={form} onValuesChange={onChangeValue}>
              <TemplateKpiTable defaultChecked={checkboxKpi} getChecked={getCheckedKpi} />
            </Form>
          </Card>
        </div>
      </div>
      <ButtonFooter handelClickPre={onFinish} handelClickFinish={onFinish} />
    </>
  );
};

export default TemplateKpi;
