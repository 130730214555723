import React from 'react';
import { Modal, Space } from 'antd';
import MainButton from 'components/MainButton/MainButton';

const DeleteMembersModal: React.FC<{
  open: boolean;
  onClose: () => void;
  handleDelete: () => void;
}> = ({ open, onClose, handleDelete }) => (
  <Modal
    title="確認"
    centered
    open={open}
    onCancel={onClose}
    footer={[
      <Space align="center" style={{ marginRight: '10px' }} key="いいえ">
        <MainButton subButton text="いいえ" onClick={onClose} />
      </Space>,
      <Space align="center" key="はい">
        <MainButton
          text="はい"
          onClick={() => {
            handleDelete();
            onClose();
          }}
        />
      </Space>,
    ]}
  >
    <p>ユーザーを削除してもよろしいでしょうか？ 削除するとユーザーの解答結果も削除されます。</p>
  </Modal>
);

export default DeleteMembersModal;
