import { DEFAULT_LIMIT_PAGINATION } from 'constants/pagination';
import { assignmentsRepositories } from 'repositories/assignments';
import { TOptionsQuery } from 'types/common';
import { TAddTrainingBody } from 'types/assignments';

const DEFAULT_ASSIGNMENTS_LIMIT = DEFAULT_LIMIT_PAGINATION;
const DEFAULT_ASSIGNMENTS_STUDENT_LIMIT = 20;
const DEFAULT_ASSIGNMENTS_PAGE = 1;

const getTrainings = async (params: TOptionsQuery<TAddTrainingBody>) => {
  const res = await assignmentsRepositories.getTrainings({
    condition: {
      ...params,
      limit: params.limit ?? DEFAULT_ASSIGNMENTS_LIMIT,
      page: params.page ?? DEFAULT_ASSIGNMENTS_PAGE,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const getTrainingsWorking = async (params: TOptionsQuery<TAddTrainingBody>) => {
  const res = await assignmentsRepositories.getTrainings({
    condition: {
      ...params,
      limit: params.limit ?? DEFAULT_ASSIGNMENTS_STUDENT_LIMIT,
      page: params.page ?? DEFAULT_ASSIGNMENTS_PAGE,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const getTrainingsFinish = async (params: TOptionsQuery<TAddTrainingBody>) => {
  const res = await assignmentsRepositories.getTrainings({
    condition: {
      ...params,
      limit: params.limit ?? DEFAULT_ASSIGNMENTS_STUDENT_LIMIT,
      page: params.page ?? DEFAULT_ASSIGNMENTS_PAGE,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const getTraining = async (id: string, groupId: number) => {
  const res = await assignmentsRepositories.getTraining({
    pathParameters: {
      id,
    },
    condition: { groupId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const createTraining = async (data: TAddTrainingBody) => {
  const { groupId, ...body } = data;
  const res = await assignmentsRepositories.createTraining({
    body,
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateTraining = async (data: TAddTrainingBody) => {
  const { id, groupId, ...body } = data;
  const res = await assignmentsRepositories.updateTraining({
    body,
    pathParameters: {
      id,
    },
    condition: { groupId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const deleteTraining = async (data: { trainingId: string; groupId: number }) => {
  const { trainingId, groupId } = data;
  const res = await assignmentsRepositories.deleteTraining({
    pathParameters: {
      id: trainingId,
    },
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

export const assignmentsServices = {
  getTrainings,
  getTraining,
  updateTraining,
  createTraining,
  deleteTraining,
  getTrainingsWorking,
  getTrainingsFinish,
};
