import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paginationDefault } from 'constants/pagination';
import { AxiosResponse, TResponsePagination } from 'libs/axios';
import { TOptionsQuery, TPagination } from 'types/common';
import { TUserDetail } from 'types/groups';

export type TGroupsState = {
  params: TOptionsQuery<TUserDetail>;
  users: TUserDetail[];
  pagination: TPagination;
};

const initialState: TGroupsState = {
  params: {},
  users: [],
  pagination: paginationDefault(),
};

const _module = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setParams(state: TGroupsState, action: PayloadAction<TOptionsQuery<TUserDetail>>) {
      return {
        ...state,
        params: action.payload,
      };
    },
    saveUsers(state: TGroupsState, action: PayloadAction<TResponsePagination<TUserDetail>>) {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        users: results,
        pagination,
      };
    },
    saveUser(state: TGroupsState, action: PayloadAction<TUserDetail>) {
      return {
        ...state,
        users: state.users.map((u) => (u.id === action.payload.id ? action.payload : u)),
      };
    },
    deleteUser(state: TGroupsState, action: PayloadAction<TUserDetail>) {
      const pagination = { ...state.pagination };
      return {
        ...state,
        users: state.users.filter((u) => u.id !== action.payload.id),
        pagination: { ...pagination, totalResults: pagination.totalResults - 1 },
      };
    },
  },
});

export const groupsModule = _module;
