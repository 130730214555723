import React, { useEffect } from 'react';
import { TQuestionSelect } from 'types/questions';
import { UseFormRegister, UseFormReset } from 'react-hook-form';
import CommonCustomInput from 'components/CommonCustomInput/CommonCustomInput';
import styles from './style.module.scss';
import './style.css';

const AdmQuestionFormSelect1: React.FC<{
  selectedItem: TQuestionSelect | undefined;
  reset: UseFormReset<TQuestionSelect>;
  register: UseFormRegister<TQuestionSelect>;
}> = ({ selectedItem, reset, register }) => {
  useEffect(() => {
    reset(selectedItem);
  }, [selectedItem]);

  return (
    <div className={styles.contentWrap__right}>
      <div className={styles.form}>
        <p>フィールドの詳細設定</p>
        {selectedItem && (
          <form className={styles.form}>
            <CommonCustomInput label="フィールド名（必須）" register={register('fieldName')} disabled={false} />
            <CommonCustomInput label="フィールドコード" register={register('fieldCd')} disabled />
            <CommonCustomInput label="フィールドタイプ" register={register('fieldType')} disabled />
            {selectedItem.fieldType === 'select' && (
              <>
                <div className={styles.form__row}>
                  <div className={styles.item}>
                    <CommonCustomInput label="下限" register={register('answer.from')} disabled={false} />
                  </div>
                  <div className={styles.item}>
                    <CommonCustomInput label="上限" register={register('answer.to')} disabled={false} />
                  </div>
                  <div className={styles.item}>
                    <CommonCustomInput label="刻み" register={register('answer.increments')} disabled={false} />
                  </div>
                </div>
                <CommonCustomInput label="ラベル" register={register('answer.unit')} disabled={false} />
              </>
            )}
            <CommonCustomInput label="補足説明" register={register('extra')} disabled={false} />
          </form>
        )}
      </div>
    </div>
  );
};

export default AdmQuestionFormSelect1;
