import trainingResultAction from 'modules/trainingResult/trainingResult.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { TAnswerTrainingResults } from 'types/trainingResult';
import AssignmentCalculationSettingsYields from './AssignmentCalculationYields';

const AssignmentCalculationSettingsYieldsContainer = () => {
  const dispatch = useDispatch();
  const { trainingResult } = useSelector((state: AppState) => state.pages);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);

  const updateTrainingResult = (data: TAnswerTrainingResults, id: string) => {
    if (currentGroup.id)
      dispatch(trainingResultAction.updateTrainingResult({ groupId: currentGroup.id, data, id, step: 'step4' }));
  };

  const resetTrainingResult = () => {
    dispatch(trainingResultAction.resetStateTraining());
  };

  return (
    <AssignmentCalculationSettingsYields
      trainingResult={trainingResult}
      updateTrainingResult={updateTrainingResult}
      resetTrainingResult={resetTrainingResult}
    />
  );
};

export default AssignmentCalculationSettingsYieldsContainer;
