import { TStep5 } from 'pages/Admin/AdmAddCalculationSettingsGrade/AdmAddCalculationSettingsGrade.state';
import { TStep4 } from 'pages/Admin/AdmAddCalculationSettingsYields/AdmAddCalculationSettingsYields.state';
import { TStep2 } from 'pages/Admin/AdmAddQuestion1/AdmAddQuestion1.state';
import { TStep3 } from 'pages/Admin/AdmAddQuestion2/AdmAddQuestion2.state';
import { TStep7 } from 'pages/Admin/TemplateKpi/TemplateKpi.state';
import { TTemplate } from 'pages/Admin/TemplateRfp/TemplateRfp.state';
import { TStep6 } from 'pages/Admin/WorkingTime/WorkingTime.state';
import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory();

export default {
  getTemplateId: ac<{ id: string; groupId: number }>('getTrainingId'),
  createTemplate: ac<{ data: TTemplate; link?: string; groupId: number }>('createTemplate'),
  updateTemplateStep1: ac<{ groupId: number; data: TTemplate; id: string; buttonFooter: boolean; link?: string }>(
    'updateTemplateStep1',
  ),
  updateTemplateStep2: ac<{ groupId: number; data: TStep2; id: string; buttonFooter: boolean; link?: string }>(
    'updateTemplateStep2',
  ),
  updateTemplateStep3: ac<{ groupId: number; data: TStep3; id: string; buttonFooter: boolean; link?: string }>(
    'updateTemplateStep3',
  ),
  updateTemplateStep4: ac<{ groupId: number; data: TStep4; id: string; buttonFooter: boolean; link?: string }>(
    'updateTemplateStep4',
  ),
  updateTemplateStep5: ac<{ groupId: number; data: TStep5; id: string; buttonFooter: boolean; link?: string }>(
    'updateTemplateStep5',
  ),
  updateTemplateStep6: ac<{ groupId: number; data: TStep6; id: string; buttonFooter: boolean; link?: string }>(
    'updateTemplateStep6',
  ),
  updateTemplateStep7: ac<{ groupId: number; data: TStep7; id: string; buttonFooter: boolean; link?: string }>(
    'updateTemplateStep7',
  ),
  clearTemplate: ac('clearTemplate'),
};
