import React from 'react';
import styles from './style.module.scss';
import SheetTable from '../../../components/SheetTable/SheetTable';
import {
  OPTIONS_TABLE,
  OPTIONS_TABLE2,
  OPTIONS_TABLE3,
  OPTIONS_TABLE7,
  THEME_TABLE,
} from './AssignmentQuestion7.state';

// eslint-disable-next-line complexity
const AssignmentQuestionForm: React.FC<{ configTable: any }> = ({ configTable }) => (
  <div>
    <div>
      {/* row 2 */}
      <p>①まず、下図の資金繰り計画表を確認してください。</p>
      {configTable?.configTable2 && (
        <SheetTable dataCfg={configTable?.configTable2} options={OPTIONS_TABLE2} themeCfg={THEME_TABLE} />
      )}
      <p>
        ②
        赤色のセルは、現金が不足している月を表しています。その月に現金がそれだけ不足し、給与の支払い、水道光熱費の支払い等ができずに倒産します。
      </p>
      <p className={styles.mb_50}>
        ③
        赤色のセルが表示されないように補助金を利用するか、資本金をいくら用意するのか、銀行からいくら借り入れるのかを設定してみましょう。
      </p>
    </div>
    {/* row 3 */}

    <div>
      <p className={styles.p_2}>初期投資費用合計</p>
      {configTable.configTable3 && (
        <SheetTable dataCfg={configTable?.configTable3} options={OPTIONS_TABLE3} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 4 */}
    <div>
      <p className={styles.p_2}>資金調達</p>
      {configTable.configTable4 && (
        <SheetTable dataCfg={configTable?.configTable4} options={OPTIONS_TABLE} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 5 */}
    <div className={styles.w60}>
      <p className={styles.p_2}>銀行からの借入情報</p>
      {configTable.configTable5 && (
        <SheetTable dataCfg={configTable?.configTable5} options={OPTIONS_TABLE3} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 6 */}
    <div className={styles.w60}>
      <p className={styles.p_2}>銀行からの新規借入額</p>
      {configTable.configTable6 && (
        <SheetTable dataCfg={configTable?.configTable6} options={OPTIONS_TABLE} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 7 */}
    <div className={styles.w60}>
      <p className={styles.p_2}>銀行返済予定表</p>
      {configTable.configTable7 && (
        <SheetTable dataCfg={configTable?.configTable7} options={OPTIONS_TABLE7} themeCfg={THEME_TABLE} />
      )}
    </div>
  </div>
);

export default AssignmentQuestionForm;
