import { Form, Table } from 'antd';
import React from 'react';
import { TUserDetail } from 'types/groups';
import { columnsTableStudents } from '../AssignmentsAdmAdd.state';
import styles from '../style.module.scss';

type Props = {
  selectedStudents: TUserDetail[];
};

const PreviewStudentsTraining = ({ selectedStudents }: Props) => {
  if (!selectedStudents.length) return null;

  return (
    <Form.Item>
      <div className={styles.noteWrap}>
        <p>※選択中の受講者一覧（上で選択したユーザーの一覧です）</p>
      </div>
      <Table
        dataSource={selectedStudents}
        columns={columnsTableStudents}
        rowKey="id"
        pagination={false}
        scroll={{ y: 160 }}
      />
    </Form.Item>
  );
};

export default PreviewStudentsTraining;
