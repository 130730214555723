import { ThemeName, generatePalette, getPalette } from '@antv/s2';
import { addCommas, numberWithCommas } from 'libs/helpers/functions';
import { TTemplate } from 'types/template';
import { TTrainingResult } from 'types/trainingResult';
import { Calculator } from 'libs/calculators';

export const configTableData = (template: TTemplate, trainingResult: TTrainingResult) => {
  const cal = new Calculator(template, trainingResult);
  const targetYear = 1;

  const configTable1 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2', 'value3'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: 'A品',
      },
      {
        field: 'value2',
        name: 'その他等級',
      },
      {
        field: 'value3',
        name: '合計',
      },
    ],
    data: [
      {
        title: '年間出荷量',
        value1: addCommas(cal.Y413(targetYear), 't'),
        value2: addCommas(cal.Y414(targetYear), 't'),
        value3: addCommas(cal.Y413(targetYear) + cal.Y414(targetYear), 't'),
      },
      {
        title: '販売単価[円/kg]',
        value1: numberWithCommas(cal.Y401()),
        value2: numberWithCommas(cal.Y401()),
        value3: '-',
      },
      {
        title: '年間売上',
        value1: numberWithCommas(cal.Y413(targetYear) * cal.Y401() * 1000),
        value2: numberWithCommas(cal.Y414(targetYear) * cal.Y401() * 1000),
        value3: numberWithCommas(cal.annualSales(targetYear)),
      },
    ],
  };

  const configTable2 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2', 'value3'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: 'A品',
      },
      {
        field: 'value2',
        name: 'その他等級',
      },
      {
        field: 'value3',
        name: '合計',
      },
    ],
    data: [
      {
        title: '販促活動費',
        value1: numberWithCommas(cal.Y703()),
        value2: numberWithCommas(cal.Y704()),
        value3: numberWithCommas(cal.Y702()),
      },
      {
        title: '売上比率',
        value1: '-',
        value2: '-',
        value3: addCommas(cal.Y707(targetYear), '%', 2),
      },
    ],
  };

  const configTable3 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2', 'value3'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: 'A品',
      },
      {
        field: 'value2',
        name: 'その他等級',
      },
      {
        field: 'value3',
        name: '合計',
      },
    ],
    data: [
      {
        title: '梱包材料費',
        value1: numberWithCommas(cal.Y709(targetYear)),
        value2: numberWithCommas(cal.Y710(targetYear)),
        value3: numberWithCommas(cal.Y708(targetYear)),
      },
      {
        title: '売上比率',
        value1: '-',
        value2: '-',
        value3: addCommas(cal.Y711(targetYear), '%', 2),
      },
    ],
  };

  const configTable4 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2', 'value3'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: 'A品',
      },
      {
        field: 'value2',
        name: 'その他等級',
      },
      {
        field: 'value3',
        name: '合計',
      },
    ],
    data: [
      {
        title: '物流・輸送費',
        value1: numberWithCommas(cal.Y713(targetYear)),
        value2: numberWithCommas(cal.Y714(targetYear)),
        value3: numberWithCommas(cal.Y712(targetYear)),
      },
      {
        title: '売上比率',
        value1: '-',
        value2: '-',
        value3: addCommas((cal.Y712(targetYear) / cal.Y101(targetYear)) * 100, '%', 2),
      },
    ],
  };

  const configTable5 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2', 'value3'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: 'A品',
      },
      {
        field: 'value2',
        name: 'その他等級',
      },
      {
        field: 'value3',
        name: '合計',
      },
    ],
    data: [
      {
        title: 'その他経費',
        value1: numberWithCommas(cal.Y716(targetYear)),
        value2: numberWithCommas(cal.Y717(targetYear)),
        value3: numberWithCommas(cal.Y715(targetYear)),
      },
      {
        title: '売上比率',
        value1: '-',
        value2: '-',
        value3: addCommas(cal.Y718(targetYear), '%', 2),
      },
    ],
  };

  const configTable6 = {
    fields: {
      rows: ['title'],
      values: ['value1'],
    },
    meta: [
      {
        field: 'title',
        name: '販売費合計',
      },
      {
        field: 'value1',
        name: '売上に対する販売費の割合',
      },
    ],
    data: [
      {
        title: numberWithCommas(cal.totalSellingExpenses(targetYear)),
        value1: addCommas((cal.totalSellingExpenses(targetYear) / cal.annualSales(targetYear)) * 100, '%', 2),
      },
    ],
  };

  return {
    configTable1,
    configTable2,
    configTable3,
    configTable4,
    configTable5,
    configTable6,
  };
};

export const OPTIONS_TABLE1 = {
  height: 250,
};

export const THEME_TABLE = {
  name: 'colorful' as ThemeName,
  palette: generatePalette({
    ...getPalette('colorful'),
    brandColor: '#1D81BA',
  }),
};

export const OPTIONS_TABLE = {
  height: 150,
};

export const OPTIONS_TABLE_ROW_3 = {
  height: 120,
};
