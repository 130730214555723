/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Row, Select, DatePicker, Table } from 'antd';
import { disabledDateBeforeToday, DEFAULT_FORMAT_DATE } from 'constants/date';
import { Link } from 'react-router-dom';
import { EPath } from 'constants/routes';
import TemplateKpiTable, { TNameGoalSetting } from 'components/TemplateKpiTable/TemplateKpiTable';
import { defaultValidateMessages } from 'constants/validate/message';
import { TOptionsQuery } from 'types/common';
import { TGroupsState } from 'modules/groups/groups.reducer';
import { TUiState } from 'modules/ui/ui.reducer';
import { TTemplateState } from 'modules/template/template.reducer';
import { TUserDetail } from 'types/groups';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import { TAddTrainingBody, TFormUpdateTraining, TGoalTrainingSetting } from 'types/assignments';
import { TAssignmentsState } from 'modules/assignments/assignments.reducer';
import { TTemplatesState } from 'modules/templates/templates.reducer';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { TGroupsClassDetail } from 'types/groupsClass';
import styles from './style.module.scss';
import { columnsTableStudents, getDefaultTrainingData, getNewTrainingData } from './AssignmentsAdmAdd.state';
import PreviewImageTraining from './components/PreviewImageTraining';
import PreviewStudentsTraining from './components/PreviewStudentsTraining';
import PreviewSummaryTraining from './components/PreviewSummaryTraining';
import 'moment/locale/ja';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

type Props = {
  getStudents: (params: TOptionsQuery) => void;
  groupsState: TGroupsState;
  uiState: TUiState;
  templateState: TTemplateState;
  updateImage: (data: FormData) => void;
  clearStateUpload: () => void;
  createTraining: (t: TAddTrainingBody) => void;
  updateNameImage: (name: string) => void;
  trainingId: string;
  assignmentsState: TAssignmentsState;
  getTraining: (trainingId: string) => void;
  updateTraining: (t: TAddTrainingBody) => void;
  getAllTemplatesFinished: (groupId: number) => void;
  templatesState: TTemplatesState;
  clearState: () => void;
  currentGroup: TGroupsClassDetail;
};

const AssignmentsAdmAdd = ({
  getStudents,
  groupsState,
  uiState,
  updateImage,
  clearStateUpload,
  templateState,
  createTraining,
  updateNameImage,
  trainingId,
  assignmentsState,
  updateTraining,
  getTraining,
  getAllTemplatesFinished,
  templatesState,
  clearState,
  currentGroup,
}: Props) => {
  const [form] = Form.useForm();
  const [summary, setSummary] = useState<string | undefined>('');
  const [checkboxKpi, setCheckboxKpi] = useState<TNameGoalSetting[]>([]);
  const [selectedStudentKeys, setSelectedStudentKeys] = useState<React.Key[] | undefined>([]);
  const [selectedStudents, setSelectedStudents] = useState<TUserDetail[]>([]);

  const { users, pagination } = groupsState;
  const { isShowLoading } = uiState;
  const { picture } = templateState;
  const { trainingDetail } = assignmentsState;
  const { templatesAll } = templatesState;

  useEffect(() => {
    if (getStudents && currentGroup.id) {
      getStudents({ groupId: currentGroup.id });
    }
    if (trainingId && getTraining && currentGroup.id) {
      getTraining(trainingId);
    }
    if (currentGroup.id) {
      getAllTemplatesFinished(currentGroup.id);
    }
  }, [currentGroup.id]);

  useEffect(() => {
    if (trainingId && trainingDetail.id) {
      const defaultTraining = getDefaultTrainingData(trainingDetail);
      const newCheckboxKpi = Object.keys(trainingDetail.goalSetting ?? {}).filter(
        (key) => trainingDetail.goalSetting && trainingDetail.goalSetting[key as keyof TGoalTrainingSetting].checked,
      ) as TNameGoalSetting[];
      setSummary(trainingDetail.summary);
      setCheckboxKpi(newCheckboxKpi);
      updateNameImage(trainingDetail.picture ?? '');
      setSelectedStudentKeys(trainingDetail.students?.map((s) => s.id ?? ''));
      const selectedStudent = trainingDetail.students;
      setSelectedStudents(selectedStudent ?? []);
      form.setFieldsValue(defaultTraining);
    }
  }, [trainingDetail]);

  const onSelectStudent = (newSelectedRowKeys: React.Key[]) => setSelectedStudentKeys(newSelectedRowKeys);

  const checkStudentExit = (object: TUserDetail) => {
    const data = [...selectedStudents];
    const index = selectedStudents.findIndex((o) => o.id === object.id);
    if (index === -1) data.push(object);
    else data.splice(index, 1);
    return data;
  };

  const onSelectedStudent = (newSelectedRowKeys: TUserDetail) => {
    setSelectedStudents(checkStudentExit(newSelectedRowKeys));
  };
  const onChangePageStudent = (page: number, limit: number) => {
    if (currentGroup.id) getStudents({ page, limit, groupId: currentGroup.id });
  };

  const onFinish = (values: TFormUpdateTraining) => {
    const trainingData = getNewTrainingData(values, picture, checkboxKpi, selectedStudentKeys);
    if (createTraining && !trainingId) {
      createTraining({ ...trainingData, groupId: currentGroup.id });
    }
    if (updateTraining && trainingId) {
      updateTraining({
        ...trainingData,
        id: trainingDetail.id,
        groupId: currentGroup.id,
      });
    }
    clearState();
  };

  const onChangeValue = (changeValue: TFormUpdateTraining) => {
    if (changeValue.summary) {
      setSummary(changeValue.summary);
    }
    if (changeValue.template) {
      const selectedTemplate = templatesAll.find((t) => t.id === changeValue.template);
      const step7 = selectedTemplate?.step7;
      const newValues = {
        summary: selectedTemplate?.description,
        goal1: step7?.goal1.defaultVal[0],
        goal2: step7?.goal2.defaultVal[0],
        goal31: step7?.goal3.defaultVal[0],
        goal32: step7?.goal3.defaultVal[1],
        goal4: step7?.goal4.defaultVal[0],
        goal5: step7?.goal5.defaultVal[0],
      };
      const newCheckboxKpi = Object.keys(step7 ?? {}).filter(
        (key) => step7 && step7[key as keyof TGoalTrainingSetting].checked,
      ) as TNameGoalSetting[];
      setSummary(selectedTemplate?.description);
      setCheckboxKpi(newCheckboxKpi);
      updateNameImage(selectedTemplate?.picture ?? '');
      form.setFieldsValue(newValues);
    }
  };

  const getCheckedKpi = (checkbox: TNameGoalSetting[]) =>
    checkbox.length !== checkboxKpi.length &&
    checkbox.toString() !== checkboxKpi.toString() &&
    setCheckboxKpi(checkbox);

  return (
    <div className={styles.groupsAdm}>
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>{trainingId ? '研修編集' : '研修登録'}</p>
            </div>
          }
        >
          <Form
            autoComplete="off"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onValuesChange={onChangeValue}
            validateMessages={defaultValidateMessages}
            disabled={isShowLoading}
          >
            <div className={styles.content}>
              <div className={styles.top}>
                <Button>
                  <Link to={EPath.assignments}>研修一覧に戻る</Link>
                </Button>
              </div>
              <Row>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="研修名"
                    name="name"
                    rules={[{ message: defaultValidateMessages.required, required: true, whitespace: true }]}
                  >
                    <Input placeholder="exemple" />
                  </Form.Item>
                  <Form.Item
                    label="実施期間"
                    name="date"
                    rules={[{ message: defaultValidateMessages.required, required: true }]}
                  >
                    <RangePicker locale={locale} format={DEFAULT_FORMAT_DATE} disabledDate={disabledDateBeforeToday} />
                  </Form.Item>
                  <Form.Item label="研修テンプレート">
                    <Form.Item
                      label="※研修の設問や試算の係数の設定テンプレートです。"
                      name="template"
                      rules={[{ message: defaultValidateMessages.required, required: true }]}
                    >
                      <Select placeholder="Please select">
                        {templatesAll.map((t) => (
                          <Select.Option key={t.id} value={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
              <div>
                <div className={styles.noteWrap}>
                  <p>業務命令書の文章 (2000文字まで)</p>
                  <p>※研修の冒頭説明の文章を設定します。 2000文字まで設定できます。</p>
                  <p>※研修テンプレートを変更すると、テンプレートの設定文章が自動入力されます。</p>
                </div>
                <Form.Item
                  name="summary"
                  rules={[{ message: defaultValidateMessages.required, required: true, whitespace: true }]}
                >
                  <TextArea />
                </Form.Item>
              </div>
              <PreviewImageTraining clearStateUpload={clearStateUpload} picture={picture} updateImage={updateImage} />
              <PreviewSummaryTraining summary={summary} picture={picture} />
              <Form.Item>
                <div className={styles.noteWrap}>
                  <p>達成目標の設定</p>
                  <p>※目標に含める項目は、チェックボックスをチェックします。</p>
                </div>
                <TemplateKpiTable defaultChecked={checkboxKpi} getChecked={getCheckedKpi} />
              </Form.Item>
              <Form.Item>
                <div className={styles.noteWrap}>
                  <p>研修受講者の設定</p>
                  <p>※研修を受講するユーザーを選択してください。</p>
                </div>
                <Table
                  rowSelection={{
                    selectedRowKeys: selectedStudentKeys,
                    preserveSelectedRowKeys: true,
                    onChange: onSelectStudent,
                    onSelect: onSelectedStudent,
                  }}
                  dataSource={users}
                  columns={columnsTableStudents}
                  rowKey="id"
                  pagination={false}
                  scroll={{ y: 200 }}
                />
                <div className={styles.pagination}>
                  <PaginationCustom
                    current={pagination.page}
                    total={pagination.totalResults}
                    pageSize={pagination.limit}
                    disabled={isShowLoading}
                    onChange={onChangePageStudent}
                    showQuickJumper={false}
                    showSizeChanger={false}
                    showLessItems={false}
                    showTotal={false}
                    hideOnSinglePage
                  />
                </div>
              </Form.Item>
              <PreviewStudentsTraining selectedStudents={selectedStudents} />
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                    !selectedStudentKeys?.length
                  }
                >
                  保存する
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default AssignmentsAdmAdd;
