import trainingResultAction from 'modules/trainingResult/trainingResult.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import AssignmentsResultsAdm from './AssignmentsResultsAdm';

const AssignmentsResultsAdmContainer = () => {
  const dispatch = useDispatch();

  const { trainingResult } = useSelector((state: AppState) => state.pages);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);

  const getTrainingResult = (trainingId: string, studentId: string, groupId: number) => {
    dispatch(trainingResultAction.getTrainingResultByTrainingAndStudent({ trainingId, studentId, groupId }));
  };

  return (
    <AssignmentsResultsAdm
      getTrainingResult={getTrainingResult}
      currentGroup={currentGroup}
      trainingResult={trainingResult}
    />
  );
};

export default AssignmentsResultsAdmContainer;
