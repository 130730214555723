import { trainingResultRepositories } from 'repositories/trainingResult';
import { TAnswerTrainingResults, TTrainingResult } from 'types/trainingResult';

const createTrainingResult = async (data: TTrainingResult & { groupId: number }) => {
  const { groupId, ...body } = data;
  const res = await trainingResultRepositories.createTrainingResult({
    body,
    condition: { groupId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const editTrainingResult = async (groupId: number, data: TAnswerTrainingResults, id: string, step: string) => {
  const res = await trainingResultRepositories.updateTrainingResult({
    pathParameters: {
      trainingId: id,
    },
    body: { [step]: data },
    condition: { groupId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return { ...res.data, updated: true };
};

const getTrainingResultByTraining = async (data: { trainingId: string; groupId: number }) => {
  const { trainingId, groupId } = data;
  const res = await trainingResultRepositories.getTrainingResultByTraining({
    pathParameters: {
      trainingId,
    },
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const getTrainingResultByTrainingAndStudent = async (trainingId: string, studentId: string, groupId: number) => {
  const res = await trainingResultRepositories.getTrainingResultByTrainingAndStudent({
    pathParameters: {
      trainingId,
      studentId,
    },
    condition: { groupId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

export const trainingResultServices = {
  createTrainingResult,
  editTrainingResult,
  getTrainingResultByTraining,
  getTrainingResultByTrainingAndStudent,
};
