import { TWorkingTime } from 'types/workingTime';

export const dataWorkingTime: TWorkingTime[] = [
  {
    name: '本圃準備',
    unit: 'h/10a',
    laborTime: 100,
    optimalTime: 0,
    month1: 0,
    month2: 0,
    month3: 0,
    month4: 0,
    month5: 0,
    month6: 0,
    month7: 25,
    month8: 4,
    month9: 42,
    month10: 29,
    month11: 0,
    month12: 0,
    totalMonth: 100,
  },
  {
    name: '育苗',
    unit: 'h/10a',
    laborTime: 0.7,
    optimalTime: 0,
    month1: 0,
    month2: 0,
    month3: 0,
    month4: 0,
    month5: 0,
    month6: 0,
    month7: 0,
    month8: 0,
    month9: 100,
    month10: 0,
    month11: 0,
    month12: 0,
    totalMonth: 100,
  },
  {
    name: '定植',
    unit: 'h/10a',
    laborTime: 15,
    optimalTime: 0,
    month1: 0,
    month2: 0,
    month3: 0,
    month4: 0,
    month5: 0,
    month6: 0,
    month7: 0,
    month8: 0,
    month9: 51,
    month10: 49,
    month11: 0,
    month12: 0,
    totalMonth: 100,
  },
  {
    name: '整枝・摘葉',
    unit: 'h/10a',
    laborTime: 833.7,
    optimalTime: 0,
    month1: 11,
    month2: 11,
    month3: 12,
    month4: 11,
    month5: 12,
    month6: 10,
    month7: 0,
    month8: 0,
    month9: 2,
    month10: 12,
    month11: 10,
    month12: 9,
    totalMonth: 100,
  },
  {
    name: '換気',
    unit: 'h/10a',
    laborTime: 41.3,
    optimalTime: 10,
    month1: 7,
    month2: 7,
    month3: 11,
    month4: 17,
    month5: 18,
    month6: 7,
    month7: 0,
    month8: 0,
    month9: 4,
    month10: 11,
    month11: 14,
    month12: 4,
    totalMonth: 100,
  },
  {
    name: '灌水',
    unit: 'h/10a',
    laborTime: 48,
    optimalTime: 10,
    month1: 8,
    month2: 9,
    month3: 9,
    month4: 10,
    month5: 10,
    month6: 10,
    month7: 0,
    month8: 0,
    month9: 16,
    month10: 16,
    month11: 5,
    month12: 7,
    totalMonth: 100,
  },
  {
    name: 'その他管理',
    unit: 'h/10a',
    laborTime: 59,
    optimalTime: 0,
    month1: 5,
    month2: 4,
    month3: 3,
    month4: 5,
    month5: 2,
    month6: 2,
    month7: 0,
    month8: 25,
    month9: 33,
    month10: 8,
    month11: 5,
    month12: 2,
    totalMonth: 100,
  },
  {
    name: '収穫･調整',
    unit: 'h/t',
    laborTime: 40.7,
    optimalTime: 0,
    month1: 12,
    month2: 12,
    month3: 13,
    month4: 13,
    month5: 13,
    month6: 12,
    month7: 0,
    month8: 0,
    month9: 0,
    month10: 2,
    month11: 10,
    month12: 13,
    totalMonth: 100,
  },
  {
    name: '出荷',
    unit: 'h/t',
    laborTime: 3.4,
    optimalTime: 0,
    month1: 13,
    month2: 13,
    month3: 13,
    month4: 12,
    month5: 13,
    month6: 13,
    month7: 0,
    month8: 0,
    month9: 0,
    month10: 2,
    month11: 9,
    month12: 12,
    totalMonth: 100,
  },
  {
    name: '後片づけ',
    unit: 'h/10a',
    laborTime: 51.7,
    optimalTime: 0,
    month1: 0,
    month2: 0,
    month3: 0,
    month4: 0,
    month5: 0,
    month6: 37,
    month7: 56,
    month8: 7,
    month9: 0,
    month10: 0,
    month11: 0,
    month12: 0,
    totalMonth: 100,
  },
];
