import { IndexedObject } from './types';

export const customValidate: IndexedObject = {
  allowance_name01: 'allowance_cost01',
  allowance_cost01: 'allowance_name01',
  allowance_name02: 'allowance_cost02',
  allowance_cost02: 'allowance_name02',
  allowance_name03: 'allowance_cost03',
  allowance_cost03: 'allowance_name03',
  supplies_name01: 'supplies_cost01',
  supplies_cost01: 'supplies_name01',
  supplies_name02: 'supplies_cost02',
  supplies_cost02: 'supplies_name02',
  supplies_name03: 'supplies_cost03',
  supplies_cost03: 'supplies_name03',
  supplies_name04: 'supplies_cost04',
  supplies_cost04: 'supplies_name04',
  supplies_name05: 'supplies_cost05',
  supplies_cost05: 'supplies_name05',
  supplies_name11: 'supplies_cost11',
  supplies_cost11: 'supplies_name11',
  supplies_name12: 'supplies_cost12',
  supplies_cost12: 'supplies_name12',
  supplies_name13: 'supplies_cost13',
  supplies_cost13: 'supplies_name13',
  supplies_name14: 'supplies_cost14',
  supplies_cost14: 'supplies_name14',
  supplies_name15: 'supplies_cost15',
  supplies_cost15: 'supplies_name15',
  system_name01: 'system_cost01',
  system_cost01: 'system_name01',
  system_name02: 'system_cost02',
  system_cost02: 'system_name02',
  system_name03: 'system_cost03',
  system_cost03: 'system_name03',
  system_name04: 'system_cost04',
  system_cost04: 'system_name04',
  system_name05: 'system_cost05',
  system_cost05: 'system_name05',
  consumable_name01: 'consumable_cost01',
  consumable_cost01: 'consumable_name01',
  consumable_name02: 'consumable_cost02',
  consumable_cost02: 'consumable_name02',
  consumable_name03: 'consumable_cost03',
  consumable_cost03: 'consumable_name03',
  consumable_name04: 'consumable_cost04',
  consumable_cost04: 'consumable_name04',
  consumable_name05: 'consumable_cost05',
  consumable_cost05: 'consumable_name05',
};

export const disableField = {
  checkField: 'consultants_numbers',
  value: '0',
  disabledField: ['Q0402002', 'Q0402003', 'Q0402004', 'Q0402005', 'Q0402006', 'Q0402007', 'Q0402008'],
};
