import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paginationDefault } from 'constants/pagination';
import { TResponsePagination } from 'libs/axios';
import { TOptionsQuery, TPagination } from 'types/common';
import { TAddTrainingBody, TTrainingDetail } from 'types/assignments';

export type TAssignmentsState = {
  params: TOptionsQuery<TAddTrainingBody>;
  trainings: TTrainingDetail[];
  pagination: TPagination;
  trainingDetail: TTrainingDetail;
  trainingsWorking: TTrainingDetail[];
  paginationWorking: TPagination;
  trainingsFinish: TTrainingDetail[];
  paginationFinish: TPagination;
};

const initialState: TAssignmentsState = {
  params: {},
  trainings: [],
  trainingDetail: {},
  pagination: paginationDefault(),
  trainingsWorking: [],
  paginationWorking: paginationDefault(),
  trainingsFinish: [],
  paginationFinish: paginationDefault(),
};

const _module = createSlice({
  name: 'assignments',
  initialState,
  reducers: {
    setParams(state: TAssignmentsState, action: PayloadAction<TOptionsQuery<TAddTrainingBody>>) {
      return {
        ...state,
        params: action.payload,
      };
    },
    saveTrainings(state: TAssignmentsState, action: PayloadAction<TResponsePagination<TTrainingDetail>>) {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        trainings: results,
        pagination,
      };
    },
    saveTrainingsWorking(state: TAssignmentsState, action: PayloadAction<TResponsePagination<TTrainingDetail>>) {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        trainingsWorking: results,
        paginationWorking: pagination,
      };
    },
    saveTrainingsFinish(state: TAssignmentsState, action: PayloadAction<TResponsePagination<TTrainingDetail>>) {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        trainingsFinish: results,
        paginationFinish: pagination,
      };
    },
    saveTrainingDetail(state: TAssignmentsState, action: PayloadAction<TTrainingDetail>) {
      return {
        ...state,
        trainingDetail: action.payload,
      };
    },
    saveTraining(state: TAssignmentsState, action: PayloadAction<TTrainingDetail>) {
      return {
        ...state,
        trainings: state.trainings.map((u) => (u.id === action.payload.id ? action.payload : u)),
      };
    },
    deleteTraining(state: TAssignmentsState, action: PayloadAction<string>) {
      return {
        ...state,
        trainings: state.trainings.filter((u) => u.id !== action.payload),
      };
    },
  },
});

export const assignmentsModule = _module;
