import React, { useEffect, useState } from 'react';
import { Button, Card, MenuProps, Space, Table } from 'antd';
import MainButton from 'components/MainButton/MainButton';
import PullDown from 'components/Pulldown/PullDown';
import { TUiState } from 'modules/ui/ui.reducer';
import './style.css';
import { TTemplatesState } from 'modules/templates/templates.reducer';
import { TOptionsQuery } from 'types/common';
import { TTemplateDetail } from 'types/templates';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import { createQueryUrl, parseSearchParams, replacePathParams } from 'libs/helpers/functions';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getDateFormat } from 'libs/helpers/date';
import { DEFAULT_FORMAT_DATE } from 'constants/date';
import { TGroupsClassDetail } from 'types/groupsClass';
import { EPath } from 'constants/routes';
import ModalDelete from 'components/ModalDelete/ModalDelete';
import styles from './style.module.scss';

type Props = {
  getTemplatesFinished: (params: TOptionsQuery<TTemplateDetail>) => void;
  getAllTemplatesUnFinished: (groupId: number) => void;
  deleteTemplate: (data: { templateId: string; groupId: number }) => void;
  templatesState: TTemplatesState;
  uiState: TUiState;
  resetTemplate: () => void;
  currentGroup: TGroupsClassDetail;
};

const GroupsAdm = ({
  getTemplatesFinished,
  getAllTemplatesUnFinished,
  templatesState,
  uiState,
  deleteTemplate,
  resetTemplate,
  currentGroup,
}: Props) => {
  const history = useHistory();
  const { templates, pagination, templatesAll } = templatesState;
  const { isShowLoading } = uiState;
  const [modalDelete, setModalDelete] = useState<string>('');

  const location = useLocation();
  const urlQueryParams: TOptionsQuery<TTemplateDetail> = parseSearchParams(location.search);

  useEffect(() => {
    if (getAllTemplatesUnFinished && currentGroup.id) {
      getAllTemplatesUnFinished(currentGroup.id);
    }
  }, [currentGroup.id]);

  useEffect(() => {
    if (getTemplatesFinished && currentGroup.id) {
      getTemplatesFinished({ ...urlQueryParams, groupId: currentGroup.id });
    }
    if (getAllTemplatesUnFinished && currentGroup.id) {
      getAllTemplatesUnFinished(currentGroup.id);
    }
  }, [location, currentGroup]);

  const onChangePage = (page: number, limit: number) => {
    history.push(createQueryUrl(location, { ...urlQueryParams, page, limit }));
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: 'テンプレート名',
      dataIndex: 'name',
      width: '40%',
    },
    {
      title: '最終更新日',
      width: '30%',
      render: (_: any, record: TTemplateDetail) => getDateFormat(record.updatedAt, DEFAULT_FORMAT_DATE),
    },
    {
      title: '',
      width: '20%',
      render: (_: any, record: TTemplateDetail) => (
        <Space align="center">
          <Link to={replacePathParams(EPath.A5, { templateId: record.id ?? '' })}>
            <MainButton text="閲覧・編集" />
          </Link>
          <MainButton subButton text="削除" onClick={() => setModalDelete(record.id ?? '')} />
        </Space>
      ),
    },
  ];

  const itemsDropdown: MenuProps['items'] = templatesAll.map((t) => ({
    key: t.id ?? '',
    label: <Link to={replacePathParams(EPath.A5, { templateId: t.id ?? '' })}>{t.name}</Link>,
  }));

  const handleDelete = () => {
    if (currentGroup.id) deleteTemplate({ templateId: modalDelete, groupId: currentGroup.id });
  };

  const createTemplate = () => {
    resetTemplate();
  };

  return (
    <div className="groupsAdm">
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>研修テンプレート一覧</p>
            </div>
          }
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space>
                <Button onClick={createTemplate}>
                  <Link to={EPath.A4}>新しいテンプレートを作成する</Link>
                </Button>
                {itemsDropdown && !!itemsDropdown.length && (
                  <PullDown menuProps={{ items: itemsDropdown }} title="未完成のテンプレートを続きから作成する" />
                )}
              </Space>
              <div className={styles.table}>
                <Table
                  bordered
                  columns={columns}
                  dataSource={templates}
                  pagination={false}
                  loading={isShowLoading}
                  rowKey="id"
                />
              </div>
            </div>
            <div className={styles.bottom}>
              <PaginationCustom
                current={pagination.page}
                total={pagination.totalResults}
                pageSize={pagination.limit}
                disabled={isShowLoading}
                onChange={onChangePage}
              />
            </div>
          </div>
        </Card>
        <ModalDelete
          title="確認"
          subTitle="テンプレートを削除してもよろしいですか? タスクがこのテンプレートを使用している場合は削除できません。"
          open={!!modalDelete}
          onClose={() => setModalDelete('')}
          handleDelete={handleDelete}
        />
      </div>
    </div>
  );
};

export default GroupsAdm;
