import { ThemeName, generatePalette, getPalette } from '@antv/s2';
import { Calculator, ETrainingStep } from 'libs/calculators';
import { TTrainingResult } from 'types/trainingResult';
import { TTemplate } from 'types/template';
import { addCommas, fixedNumber, getNumberOnly, numberWithCommas } from 'libs/helpers/functions';

export const configTable1 = (template: TTemplate, trainingResult: TTrainingResult) => {
  const cal = new Calculator(template, trainingResult);

  const dataTable1 = [
    {
      title: '栽培に必要な労働時間',
      value1: addCommas(cal.Y900(), ' 時間'),
    },
    {
      title: '雇用している栽培作業者の労働時間',
      value1: addCommas(cal.Y1006(), ' 時間'),
    },
  ];
  const configTable1 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'type'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '時間',
      },
    ],
    data: dataTable1,
  };
  const LABOR_HOURS = [
    {
      month: '10月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(10), 1),
    },
    {
      month: '10月',
      title: '育苗',
      value1: fixedNumber(cal.M902(10), 1),
    },
    {
      month: '10月',
      title: '定植',
      value1: fixedNumber(cal.M903(10), 1),
    },
    {
      month: '10月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(10), 1),
    },
    {
      month: '10月',
      title: '換気',
      value1: fixedNumber(cal.M905(10), 1),
    },
    {
      month: '10月',
      title: '灌水',
      value1: fixedNumber(cal.M906(10), 1),
    },
    {
      month: '10月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(10), 1),
    },
    {
      month: '10月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(10), 1),
    },
    {
      month: '10月',
      title: '出荷',
      value1: fixedNumber(cal.M909(10), 1),
    },
    {
      month: '10月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(10), 1),
    },
    {
      month: '10月',
      title: '合計',
      value1: fixedNumber(cal.M900(10), 1),
    },

    {
      month: '11月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(11), 2),
    },
    {
      month: '11月',
      title: '育苗',
      value1: fixedNumber(cal.M902(11), 2),
    },
    {
      month: '11月',
      title: '定植',
      value1: fixedNumber(cal.M903(11), 1),
    },
    {
      month: '11月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(11), 1),
    },
    {
      month: '11月',
      title: '換気',
      value1: fixedNumber(cal.M905(11), 1),
    },
    {
      month: '11月',
      title: '灌水',
      value1: fixedNumber(cal.M906(11), 1),
    },
    {
      month: '11月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(11), 1),
    },
    {
      month: '11月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(11), 1),
    },
    {
      month: '11月',
      title: '出荷',
      value1: fixedNumber(cal.M909(11), 1),
    },
    {
      month: '11月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(11), 1),
    },
    {
      month: '11月',
      title: '合計',
      value1: fixedNumber(cal.M900(11), 1),
    },

    {
      month: '12月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(12), 1),
    },
    {
      month: '12月',
      title: '育苗',
      value1: fixedNumber(cal.M902(12), 1),
    },
    {
      month: '12月',
      title: '定植',
      value1: fixedNumber(cal.M903(12), 1),
    },
    {
      month: '12月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(12), 1),
    },
    {
      month: '12月',
      title: '換気',
      value1: fixedNumber(cal.M905(12), 1),
    },
    {
      month: '12月',
      title: '灌水',
      value1: fixedNumber(cal.M906(12), 1),
    },
    {
      month: '12月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(12), 1),
    },
    {
      month: '12月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(12), 1),
    },
    {
      month: '12月',
      title: '出荷',
      value1: fixedNumber(cal.M909(12), 1),
    },
    {
      month: '12月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(12), 1),
    },
    {
      month: '12月',
      title: '合計',
      value1: fixedNumber(cal.M900(12), 1),
    },
    //
    {
      month: '1月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(1), 1),
    },
    {
      month: '1月',
      title: '育苗',
      value1: fixedNumber(cal.M902(1), 1),
    },
    {
      month: '1月',
      title: '定植',
      value1: fixedNumber(cal.M903(1), 1),
    },
    {
      month: '1月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(1), 1),
    },
    {
      month: '1月',
      title: '換気',
      value1: fixedNumber(cal.M905(1), 1),
    },
    {
      month: '1月',
      title: '灌水',
      value1: fixedNumber(cal.M906(1), 1),
    },
    {
      month: '1月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(1), 1),
    },
    {
      month: '1月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(1), 1),
    },
    {
      month: '1月',
      title: '出荷',
      value1: fixedNumber(cal.M909(1), 1),
    },
    {
      month: '1月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(1), 1),
    },
    {
      month: '1月',
      title: '合計',
      value1: fixedNumber(cal.M900(1), 1),
    },
    //
    {
      month: '2月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(2), 1),
    },
    {
      month: '2月',
      title: '育苗',
      value1: fixedNumber(cal.M902(2), 1),
    },
    {
      month: '2月',
      title: '定植',
      value1: fixedNumber(cal.M903(2), 1),
    },
    {
      month: '2月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(2), 1),
    },
    {
      month: '2月',
      title: '換気',
      value1: fixedNumber(cal.M905(2), 1),
    },
    {
      month: '2月',
      title: '灌水',
      value1: fixedNumber(cal.M906(2), 1),
    },
    {
      month: '2月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(2), 1),
    },
    {
      month: '2月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(2), 1),
    },
    {
      month: '2月',
      title: '出荷',
      value1: fixedNumber(cal.M909(2), 1),
    },
    {
      month: '2月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(2), 1),
    },
    {
      month: '2月',
      title: '合計',
      value1: fixedNumber(cal.M900(2), 1),
    },
    //
    {
      month: '3月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(3), 1),
    },
    {
      month: '3月',
      title: '育苗',
      value1: fixedNumber(cal.M902(3), 1),
    },
    {
      month: '3月',
      title: '定植',
      value1: fixedNumber(cal.M903(3), 1),
    },
    {
      month: '3月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(3), 1),
    },
    {
      month: '3月',
      title: '換気',
      value1: fixedNumber(cal.M905(3), 1),
    },
    {
      month: '3月',
      title: '灌水',
      value1: fixedNumber(cal.M906(3), 1),
    },
    {
      month: '3月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(3), 1),
    },
    {
      month: '3月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(3), 1),
    },
    {
      month: '3月',
      title: '出荷',
      value1: fixedNumber(cal.M909(3), 1),
    },
    {
      month: '3月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(3), 1),
    },
    {
      month: '3月',
      title: '合計',
      value1: fixedNumber(cal.M900(3), 1),
    },
    //
    {
      month: '4月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(4), 1),
    },
    {
      month: '4月',
      title: '育苗',
      value1: fixedNumber(cal.M902(4), 1),
    },
    {
      month: '4月',
      title: '定植',
      value1: fixedNumber(cal.M903(4), 1),
    },
    {
      month: '4月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(4), 1),
    },
    {
      month: '4月',
      title: '換気',
      value1: fixedNumber(cal.M905(4), 1),
    },
    {
      month: '4月',
      title: '灌水',
      value1: fixedNumber(cal.M906(4), 1),
    },
    {
      month: '4月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(4), 1),
    },
    {
      month: '4月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(4), 1),
    },
    {
      month: '4月',
      title: '出荷',
      value1: fixedNumber(cal.M909(4), 1),
    },
    {
      month: '4月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(4), 1),
    },
    {
      month: '4月',
      title: '合計',
      value1: fixedNumber(cal.M900(4), 1),
    },
    //
    {
      month: '5月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(5), 1),
    },
    {
      month: '5月',
      title: '育苗',
      value1: fixedNumber(cal.M902(5), 1),
    },
    {
      month: '5月',
      title: '定植',
      value1: fixedNumber(cal.M903(5), 2),
    },
    {
      month: '5月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(5), 1),
    },
    {
      month: '5月',
      title: '換気',
      value1: fixedNumber(cal.M905(5), 1),
    },
    {
      month: '5月',
      title: '灌水',
      value1: fixedNumber(cal.M906(5), 1),
    },
    {
      month: '5月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(5), 1),
    },
    {
      month: '5月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(5), 1),
    },
    {
      month: '5月',
      title: '出荷',
      value1: fixedNumber(cal.M909(5), 1),
    },
    {
      month: '5月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(5), 1),
    },
    {
      month: '5月',
      title: '合計',
      value1: fixedNumber(cal.M900(5), 1),
    },
    //
    {
      month: '6月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(6), 1),
    },
    {
      month: '6月',
      title: '育苗',
      value1: fixedNumber(cal.M902(6), 1),
    },
    {
      month: '6月',
      title: '定植',
      value1: fixedNumber(cal.M903(6), 1),
    },
    {
      month: '6月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(6), 1),
    },
    {
      month: '6月',
      title: '換気',
      value1: fixedNumber(cal.M905(6), 1),
    },
    {
      month: '6月',
      title: '灌水',
      value1: fixedNumber(cal.M906(6), 1),
    },
    {
      month: '6月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(6), 1),
    },
    {
      month: '6月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(6), 1),
    },
    {
      month: '6月',
      title: '出荷',
      value1: fixedNumber(cal.M909(6), 1),
    },
    {
      month: '6月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(6), 1),
    },
    {
      month: '6月',
      title: '合計',
      value1: fixedNumber(cal.M900(6), 1),
    },
    //
    {
      month: '7月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(7), 1),
    },
    {
      month: '7月',
      title: '育苗',
      value1: fixedNumber(cal.M902(7), 1),
    },
    {
      month: '7月',
      title: '定植',
      value1: fixedNumber(cal.M903(7), 1),
    },
    {
      month: '7月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(7), 1),
    },
    {
      month: '7月',
      title: '換気',
      value1: fixedNumber(cal.M905(7), 1),
    },
    {
      month: '7月',
      title: '灌水',
      value1: fixedNumber(cal.M906(7), 1),
    },
    {
      month: '7月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(7), 1),
    },
    {
      month: '7月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(7), 1),
    },
    {
      month: '7月',
      title: '出荷',
      value1: fixedNumber(cal.M909(7), 1),
    },
    {
      month: '7月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(7), 1),
    },
    {
      month: '7月',
      title: '合計',
      value1: fixedNumber(cal.M900(7), 1),
    },
    {
      month: '8月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(8), 1),
    },
    {
      month: '8月',
      title: '育苗',
      value1: fixedNumber(cal.M902(8), 1),
    },
    {
      month: '8月',
      title: '定植',
      value1: fixedNumber(cal.M903(8), 1),
    },
    {
      month: '8月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(8), 1),
    },
    {
      month: '8月',
      title: '換気',
      value1: fixedNumber(cal.M905(8), 1),
    },
    {
      month: '8月',
      title: '灌水',
      value1: fixedNumber(cal.M906(8), 1),
    },
    {
      month: '8月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(8), 1),
    },
    {
      month: '8月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(8), 1),
    },
    {
      month: '8月',
      title: '出荷',
      value1: fixedNumber(cal.M909(8), 1),
    },
    {
      month: '8月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(8), 1),
    },
    {
      month: '8月',
      title: '合計',
      value1: fixedNumber(cal.M900(8), 1),
    },

    //
    {
      month: '9月',
      title: '本圃準備',
      value1: fixedNumber(cal.M901(9), 1),
    },
    {
      month: '9月',
      title: '育苗',
      value1: fixedNumber(cal.M902(9), 1),
    },
    {
      month: '9月',
      title: '定植',
      value1: fixedNumber(cal.M903(9), 1),
    },
    {
      month: '9月',
      title: '整枝・摘葉',
      value1: fixedNumber(cal.M904(9), 1),
    },
    {
      month: '9月',
      title: '換気',
      value1: fixedNumber(cal.M905(9), 1),
    },
    {
      month: '9月',
      title: '灌水',
      value1: fixedNumber(cal.M906(9), 1),
    },
    {
      month: '9月',
      title: 'その他管理',
      value1: fixedNumber(cal.M907(9), 1),
    },
    {
      month: '9月',
      title: '収穫･調整',
      value1: fixedNumber(cal.M908(9), 1),
    },
    {
      month: '9月',
      title: '出荷',
      value1: fixedNumber(cal.M909(9), 1),
    },
    {
      month: '9月',
      title: '後片づけ',
      value1: fixedNumber(cal.M910(9), 1),
    },
    {
      month: '9月',
      title: '合計',
      value1: fixedNumber(cal.M900(9), 1),
    },

    {
      month: '年間',
      title: '本圃準備',
      value1: fixedNumber(
        fixedNumber(cal.M901(10), 1) +
          fixedNumber(cal.M901(11), 2) +
          fixedNumber(cal.M901(12), 1) +
          fixedNumber(cal.M901(1), 1) +
          fixedNumber(cal.M901(2), 1) +
          fixedNumber(cal.M901(3), 1) +
          fixedNumber(cal.M901(4), 1) +
          fixedNumber(cal.M901(5), 1) +
          fixedNumber(cal.M901(6), 1) +
          fixedNumber(cal.M901(7), 1) +
          fixedNumber(cal.M901(8), 1) +
          fixedNumber(cal.M901(9), 1),
        1,
      ),
    },
    {
      month: '年間',
      title: '育苗',
      value1: fixedNumber(
        fixedNumber(cal.M902(10), 1) +
          fixedNumber(cal.M902(11), 2) +
          fixedNumber(cal.M902(12), 1) +
          fixedNumber(cal.M902(1), 1) +
          fixedNumber(cal.M902(2), 1) +
          fixedNumber(cal.M902(3), 1) +
          fixedNumber(cal.M902(4), 1) +
          fixedNumber(cal.M902(5), 1) +
          fixedNumber(cal.M902(6), 1) +
          fixedNumber(cal.M902(7), 1) +
          fixedNumber(cal.M902(8), 1) +
          fixedNumber(cal.M902(9), 1),
        1,
      ),
    },
    {
      month: '年間',
      title: '定植',
      value1: fixedNumber(
        fixedNumber(cal.M903(10), 1) +
          fixedNumber(cal.M903(11), 1) +
          fixedNumber(cal.M903(12), 1) +
          fixedNumber(cal.M903(1), 1) +
          fixedNumber(cal.M903(2), 1) +
          fixedNumber(cal.M903(3), 1) +
          fixedNumber(cal.M903(4), 1) +
          fixedNumber(cal.M903(5), 1) +
          fixedNumber(cal.M903(6), 1) +
          fixedNumber(cal.M903(7), 1) +
          fixedNumber(cal.M903(8), 1) +
          fixedNumber(cal.M903(9), 1),
        1,
      ),
    },
    {
      month: '年間',
      title: '整枝・摘葉',
      value1: fixedNumber(
        fixedNumber(cal.M904(10), 1) +
          fixedNumber(cal.M904(11), 1) +
          fixedNumber(cal.M904(12), 1) +
          fixedNumber(cal.M904(1), 1) +
          fixedNumber(cal.M904(2), 1) +
          fixedNumber(cal.M904(3), 1) +
          fixedNumber(cal.M904(4), 1) +
          fixedNumber(cal.M904(5), 1) +
          fixedNumber(cal.M904(6), 1) +
          fixedNumber(cal.M904(7), 1) +
          fixedNumber(cal.M904(8), 1) +
          fixedNumber(cal.M904(9), 1),
        1,
      ),
    },
    {
      month: '年間',
      title: '換気',
      value1: fixedNumber(
        fixedNumber(cal.M905(10), 1) +
          fixedNumber(cal.M905(11), 1) +
          fixedNumber(cal.M905(12), 1) +
          fixedNumber(cal.M905(1), 1) +
          fixedNumber(cal.M905(2), 1) +
          fixedNumber(cal.M905(3), 1) +
          fixedNumber(cal.M905(4), 1) +
          fixedNumber(cal.M905(5), 1) +
          fixedNumber(cal.M905(6), 1) +
          fixedNumber(cal.M905(7), 1) +
          fixedNumber(cal.M905(8), 1) +
          fixedNumber(cal.M905(9), 1),
        1,
      ),
    },
    {
      month: '年間',
      title: '灌水',
      value1: fixedNumber(
        fixedNumber(cal.M906(10), 1) +
          fixedNumber(cal.M906(11), 1) +
          fixedNumber(cal.M906(12), 1) +
          fixedNumber(cal.M906(1), 1) +
          fixedNumber(cal.M906(2), 1) +
          fixedNumber(cal.M906(3), 1) +
          fixedNumber(cal.M906(4), 1) +
          fixedNumber(cal.M906(5), 1) +
          fixedNumber(cal.M906(6), 1) +
          fixedNumber(cal.M906(7), 1) +
          fixedNumber(cal.M906(8), 1) +
          fixedNumber(cal.M906(9), 1),
        1,
      ),
    },
    {
      month: '年間',
      title: 'その他管理',
      value1: fixedNumber(
        fixedNumber(cal.M907(10), 1) +
          fixedNumber(cal.M907(11), 1) +
          fixedNumber(cal.M907(12), 1) +
          fixedNumber(cal.M907(1), 1) +
          fixedNumber(cal.M907(2), 1) +
          fixedNumber(cal.M907(3), 1) +
          fixedNumber(cal.M907(4), 1) +
          fixedNumber(cal.M907(5), 1) +
          fixedNumber(cal.M907(6), 1) +
          fixedNumber(cal.M907(7), 1) +
          fixedNumber(cal.M907(8), 1) +
          fixedNumber(cal.M907(9), 1),
        1,
      ),
    },
    {
      month: '年間',
      title: '収穫･調整',
      value1: fixedNumber(
        fixedNumber(cal.M908(10), 1) +
          fixedNumber(cal.M908(11), 1) +
          fixedNumber(cal.M908(12), 1) +
          fixedNumber(cal.M908(1), 1) +
          fixedNumber(cal.M908(2), 1) +
          fixedNumber(cal.M908(3), 1) +
          fixedNumber(cal.M908(4), 1) +
          fixedNumber(cal.M908(5), 1) +
          fixedNumber(cal.M908(6), 1) +
          fixedNumber(cal.M908(7), 1) +
          fixedNumber(cal.M908(8), 1) +
          fixedNumber(cal.M908(9), 1),
        1,
      ),
    },
    {
      month: '年間',
      title: '出荷',
      value1: fixedNumber(
        fixedNumber(cal.M909(10), 1) +
          fixedNumber(cal.M909(11), 1) +
          fixedNumber(cal.M909(12), 1) +
          fixedNumber(cal.M909(1), 1) +
          fixedNumber(cal.M909(2), 1) +
          fixedNumber(cal.M909(3), 1) +
          fixedNumber(cal.M909(4), 1) +
          fixedNumber(cal.M909(5), 1) +
          fixedNumber(cal.M909(6), 1) +
          fixedNumber(cal.M909(7), 1) +
          fixedNumber(cal.M909(8), 1) +
          fixedNumber(cal.M909(9), 1),
        1,
      ),
    },
    {
      month: '年間',
      title: '後片づけ',
      value1: fixedNumber(
        fixedNumber(cal.M910(10), 1) +
          fixedNumber(cal.M910(11), 1) +
          fixedNumber(cal.M910(12), 1) +
          fixedNumber(cal.M910(1), 1) +
          fixedNumber(cal.M910(2), 1) +
          fixedNumber(cal.M910(3), 1) +
          fixedNumber(cal.M910(4), 1) +
          fixedNumber(cal.M910(5), 1) +
          fixedNumber(cal.M910(6), 1) +
          fixedNumber(cal.M910(7), 1) +
          fixedNumber(cal.M910(8), 1) +
          fixedNumber(cal.M910(9), 1),
        1,
      ),
    },
    {
      month: '年間',
      title: '合計',
      value1: fixedNumber(
        fixedNumber(cal.M900(10), 1) +
          fixedNumber(cal.M900(11), 1) +
          fixedNumber(cal.M900(12), 1) +
          fixedNumber(cal.M900(1), 1) +
          fixedNumber(cal.M900(2), 1) +
          fixedNumber(cal.M900(3), 1) +
          fixedNumber(cal.M900(4), 1) +
          fixedNumber(cal.M900(5), 1) +
          fixedNumber(cal.M900(6), 1) +
          fixedNumber(cal.M900(7), 1) +
          fixedNumber(cal.M900(8), 1) +
          fixedNumber(cal.M900(9), 1),
        1,
      ),
    },
  ];

  const LABOR_HOUR_TABLE_2 = [
    {
      title: '本圃準備',
      month10: fixedNumber(cal.M901(10), 1),
      month11: fixedNumber(cal.M901(11), 2),
      month12: fixedNumber(cal.M901(12), 1),
      month1: fixedNumber(cal.M901(1), 1),
      month2: fixedNumber(cal.M901(2), 1),
      month3: fixedNumber(cal.M901(3), 1),
      month4: fixedNumber(cal.M901(4), 1),
      month5: fixedNumber(cal.M901(5), 1),
      month6: fixedNumber(cal.M901(6), 1),
      month7: fixedNumber(cal.M901(7), 1),
      month8: fixedNumber(cal.M901(8), 1),
      month9: fixedNumber(cal.M901(9), 1),
      total: fixedNumber(
        fixedNumber(cal.M901(10), 1) +
          fixedNumber(cal.M901(11), 2) +
          fixedNumber(cal.M901(12), 1) +
          fixedNumber(cal.M901(1), 1) +
          fixedNumber(cal.M901(2), 1) +
          fixedNumber(cal.M901(3), 1) +
          fixedNumber(cal.M901(4), 1) +
          fixedNumber(cal.M901(5), 1) +
          fixedNumber(cal.M901(6), 1) +
          fixedNumber(cal.M901(7), 1) +
          fixedNumber(cal.M901(8), 1) +
          fixedNumber(cal.M901(9), 1),
        1,
      ),
    },
    {
      title: '育苗',
      month10: fixedNumber(cal.M902(10), 1),
      month11: fixedNumber(cal.M902(11), 2),
      month12: fixedNumber(cal.M902(12), 1),
      month1: fixedNumber(cal.M902(1), 1),
      month2: fixedNumber(cal.M902(2), 1),
      month3: fixedNumber(cal.M902(3), 1),
      month4: fixedNumber(cal.M902(4), 1),
      month5: fixedNumber(cal.M902(5), 1),
      month6: fixedNumber(cal.M902(6), 1),
      month7: fixedNumber(cal.M902(7), 1),
      month8: fixedNumber(cal.M902(8), 1),
      month9: fixedNumber(cal.M902(9), 1),
      total: fixedNumber(
        fixedNumber(cal.M902(10), 1) +
          fixedNumber(cal.M902(11), 2) +
          fixedNumber(cal.M902(12), 1) +
          fixedNumber(cal.M902(1), 1) +
          fixedNumber(cal.M902(2), 1) +
          fixedNumber(cal.M902(3), 1) +
          fixedNumber(cal.M902(4), 1) +
          fixedNumber(cal.M902(5), 1) +
          fixedNumber(cal.M902(6), 1) +
          fixedNumber(cal.M902(7), 1) +
          fixedNumber(cal.M902(8), 1) +
          fixedNumber(cal.M902(9), 1),
        1,
      ),
    },
    {
      title: '定植',
      month10: fixedNumber(cal.M903(10), 1),
      month11: fixedNumber(cal.M903(11), 2),
      month12: fixedNumber(cal.M903(12), 1),
      month1: fixedNumber(cal.M903(1), 1),
      month2: fixedNumber(cal.M903(2), 1),
      month3: fixedNumber(cal.M903(3), 1),
      month4: fixedNumber(cal.M903(4), 1),
      month5: fixedNumber(cal.M903(5), 1),
      month6: fixedNumber(cal.M903(6), 1),
      month7: fixedNumber(cal.M903(7), 1),
      month8: fixedNumber(cal.M903(8), 1),
      month9: fixedNumber(cal.M903(9), 1),
      total: fixedNumber(
        fixedNumber(cal.M903(10), 1) +
          fixedNumber(cal.M903(11), 2) +
          fixedNumber(cal.M903(12), 1) +
          fixedNumber(cal.M903(1), 1) +
          fixedNumber(cal.M903(2), 1) +
          fixedNumber(cal.M903(3), 1) +
          fixedNumber(cal.M903(4), 1) +
          fixedNumber(cal.M903(5), 1) +
          fixedNumber(cal.M903(6), 1) +
          fixedNumber(cal.M903(7), 1) +
          fixedNumber(cal.M903(8), 1) +
          fixedNumber(cal.M903(9), 1),
        1,
      ),
    },
    {
      title: '整枝・摘葉',
      month10: fixedNumber(cal.M904(10), 1),
      month11: fixedNumber(cal.M904(11), 1),
      month12: fixedNumber(cal.M904(12), 1),
      month1: fixedNumber(cal.M904(1), 1),
      month2: fixedNumber(cal.M904(2), 1),
      month3: fixedNumber(cal.M904(3), 1),
      month4: fixedNumber(cal.M904(4), 1),
      month5: fixedNumber(cal.M904(5), 1),
      month6: fixedNumber(cal.M904(6), 1),
      month7: fixedNumber(cal.M904(7), 1),
      month8: fixedNumber(cal.M904(8), 1),
      month9: fixedNumber(cal.M904(9), 1),
      total: fixedNumber(
        fixedNumber(cal.M904(10), 1) +
          fixedNumber(cal.M904(11), 1) +
          fixedNumber(cal.M904(12), 1) +
          fixedNumber(cal.M904(1), 1) +
          fixedNumber(cal.M904(2), 1) +
          fixedNumber(cal.M904(3), 1) +
          fixedNumber(cal.M904(4), 1) +
          fixedNumber(cal.M904(5), 1) +
          fixedNumber(cal.M904(6), 1) +
          fixedNumber(cal.M904(7), 1) +
          fixedNumber(cal.M904(8), 1) +
          fixedNumber(cal.M904(9), 1),
        1,
      ),
    },
    {
      title: '換気',
      month10: fixedNumber(cal.M905(10), 1),
      month11: fixedNumber(cal.M905(11), 1),
      month12: fixedNumber(cal.M905(12), 1),
      month1: fixedNumber(cal.M905(1), 1),
      month2: fixedNumber(cal.M905(2), 1),
      month3: fixedNumber(cal.M905(3), 1),
      month4: fixedNumber(cal.M905(4), 1),
      month5: fixedNumber(cal.M905(5), 1),
      month6: fixedNumber(cal.M905(6), 1),
      month7: fixedNumber(cal.M905(7), 1),
      month8: fixedNumber(cal.M905(8), 1),
      month9: fixedNumber(cal.M905(9), 1),
      total: fixedNumber(
        fixedNumber(cal.M905(10), 1) +
          fixedNumber(cal.M905(11), 1) +
          fixedNumber(cal.M905(12), 1) +
          fixedNumber(cal.M905(1), 1) +
          fixedNumber(cal.M905(2), 1) +
          fixedNumber(cal.M905(3), 1) +
          fixedNumber(cal.M905(4), 1) +
          fixedNumber(cal.M905(5), 1) +
          fixedNumber(cal.M905(6), 1) +
          fixedNumber(cal.M905(7), 1) +
          fixedNumber(cal.M905(8), 1) +
          fixedNumber(cal.M905(9), 1),
        1,
      ),
    },
    {
      title: '灌水',
      month10: fixedNumber(cal.M906(10), 1),
      month11: fixedNumber(cal.M906(11), 1),
      month12: fixedNumber(cal.M906(12), 1),
      month1: fixedNumber(cal.M906(1), 1),
      month2: fixedNumber(cal.M906(2), 1),
      month3: fixedNumber(cal.M906(3), 1),
      month4: fixedNumber(cal.M906(4), 1),
      month5: fixedNumber(cal.M906(5), 1),
      month6: fixedNumber(cal.M906(6), 1),
      month7: fixedNumber(cal.M906(7), 1),
      month8: fixedNumber(cal.M906(8), 1),
      month9: fixedNumber(cal.M906(9), 1),
      total: fixedNumber(
        fixedNumber(cal.M906(10), 1) +
          fixedNumber(cal.M906(11), 1) +
          fixedNumber(cal.M906(12), 1) +
          fixedNumber(cal.M906(1), 1) +
          fixedNumber(cal.M906(2), 1) +
          fixedNumber(cal.M906(3), 1) +
          fixedNumber(cal.M906(4), 1) +
          fixedNumber(cal.M906(5), 1) +
          fixedNumber(cal.M906(6), 1) +
          fixedNumber(cal.M906(7), 1) +
          fixedNumber(cal.M906(8), 1) +
          fixedNumber(cal.M906(9), 1),
        1,
      ),
    },
    {
      title: 'その他管理',
      month10: fixedNumber(cal.M907(10), 1),
      month11: fixedNumber(cal.M907(11), 1),
      month12: fixedNumber(cal.M907(12), 1),
      month1: fixedNumber(cal.M907(1), 1),
      month2: fixedNumber(cal.M907(2), 1),
      month3: fixedNumber(cal.M907(3), 1),
      month4: fixedNumber(cal.M907(4), 1),
      month5: fixedNumber(cal.M907(5), 1),
      month6: fixedNumber(cal.M907(6), 1),
      month7: fixedNumber(cal.M907(7), 1),
      month8: fixedNumber(cal.M907(8), 1),
      month9: fixedNumber(cal.M907(9), 1),
      total: fixedNumber(
        fixedNumber(cal.M907(10), 1) +
          fixedNumber(cal.M907(11), 1) +
          fixedNumber(cal.M907(12), 1) +
          fixedNumber(cal.M907(1), 1) +
          fixedNumber(cal.M907(2), 1) +
          fixedNumber(cal.M907(3), 1) +
          fixedNumber(cal.M907(4), 1) +
          fixedNumber(cal.M907(5), 1) +
          fixedNumber(cal.M907(6), 1) +
          fixedNumber(cal.M907(7), 1) +
          fixedNumber(cal.M907(8), 1) +
          fixedNumber(cal.M907(9), 1),
        1,
      ),
    },
    {
      title: '収穫･調整',
      month10: fixedNumber(cal.M908(10), 1),
      month11: fixedNumber(cal.M908(11), 1),
      month12: fixedNumber(cal.M908(12), 1),
      month1: fixedNumber(cal.M908(1), 1),
      month2: fixedNumber(cal.M908(2), 1),
      month3: fixedNumber(cal.M908(3), 1),
      month4: fixedNumber(cal.M908(4), 1),
      month5: fixedNumber(cal.M908(5), 1),
      month6: fixedNumber(cal.M908(6), 1),
      month7: fixedNumber(cal.M908(7), 1),
      month8: fixedNumber(cal.M908(8), 1),
      month9: fixedNumber(cal.M908(9), 1),
      total: fixedNumber(
        fixedNumber(cal.M908(10), 1) +
          fixedNumber(cal.M908(11), 1) +
          fixedNumber(cal.M908(12), 1) +
          fixedNumber(cal.M908(1), 1) +
          fixedNumber(cal.M908(2), 1) +
          fixedNumber(cal.M908(3), 1) +
          fixedNumber(cal.M908(4), 1) +
          fixedNumber(cal.M908(5), 1) +
          fixedNumber(cal.M908(6), 1) +
          fixedNumber(cal.M908(7), 1) +
          fixedNumber(cal.M908(8), 1) +
          fixedNumber(cal.M908(9), 1),
        1,
      ),
    },
    {
      title: '出荷',
      month10: fixedNumber(cal.M909(10), 1),
      month11: fixedNumber(cal.M909(11), 1),
      month12: fixedNumber(cal.M909(12), 1),
      month1: fixedNumber(cal.M909(1), 1),
      month2: fixedNumber(cal.M909(2), 1),
      month3: fixedNumber(cal.M909(3), 1),
      month4: fixedNumber(cal.M909(4), 1),
      month5: fixedNumber(cal.M909(5), 1),
      month6: fixedNumber(cal.M909(6), 1),
      month7: fixedNumber(cal.M909(7), 1),
      month8: fixedNumber(cal.M909(8), 1),
      month9: fixedNumber(cal.M909(9), 1),
      total: fixedNumber(
        fixedNumber(cal.M909(10), 1) +
          fixedNumber(cal.M909(11), 1) +
          fixedNumber(cal.M909(12), 1) +
          fixedNumber(cal.M909(1), 1) +
          fixedNumber(cal.M909(2), 1) +
          fixedNumber(cal.M909(3), 1) +
          fixedNumber(cal.M909(4), 1) +
          fixedNumber(cal.M909(5), 1) +
          fixedNumber(cal.M909(6), 1) +
          fixedNumber(cal.M909(7), 1) +
          fixedNumber(cal.M909(8), 1) +
          fixedNumber(cal.M909(9), 1),
        1,
      ),
    },
    {
      title: '後片づけ',
      month10: fixedNumber(cal.M910(10), 1),
      month11: fixedNumber(cal.M910(11), 2),
      month12: fixedNumber(cal.M910(12), 1),
      month1: fixedNumber(cal.M910(1), 1),
      month2: fixedNumber(cal.M910(2), 1),
      month3: fixedNumber(cal.M910(3), 1),
      month4: fixedNumber(cal.M910(4), 1),
      month5: fixedNumber(cal.M910(5), 1),
      month6: fixedNumber(cal.M910(6), 1),
      month7: fixedNumber(cal.M910(7), 1),
      month8: fixedNumber(cal.M910(8), 1),
      month9: fixedNumber(cal.M910(9), 1),
      total: fixedNumber(
        fixedNumber(cal.M910(10), 1) +
          fixedNumber(cal.M910(11), 2) +
          fixedNumber(cal.M910(12), 1) +
          fixedNumber(cal.M910(1), 1) +
          fixedNumber(cal.M910(2), 1) +
          fixedNumber(cal.M910(3), 1) +
          fixedNumber(cal.M910(4), 1) +
          fixedNumber(cal.M910(5), 1) +
          fixedNumber(cal.M910(6), 1) +
          fixedNumber(cal.M910(7), 1) +
          fixedNumber(cal.M910(8), 1) +
          fixedNumber(cal.M910(9), 1),
        1,
      ),
    },
    {
      title: '合計',
      month10: fixedNumber(cal.M900(10), 1),
      month11: fixedNumber(cal.M900(11), 2),
      month12: fixedNumber(cal.M900(12), 1),
      month1: fixedNumber(cal.M900(1), 1),
      month2: fixedNumber(cal.M900(2), 1),
      month3: fixedNumber(cal.M900(3), 1),
      month4: fixedNumber(cal.M900(4), 1),
      month5: fixedNumber(cal.M900(5), 1),
      month6: fixedNumber(cal.M900(6), 1),
      month7: fixedNumber(cal.M900(7), 1),
      month8: fixedNumber(cal.M900(8), 1),
      month9: fixedNumber(cal.M900(9), 1),
      total: fixedNumber(
        fixedNumber(cal.M900(10), 1) +
          fixedNumber(cal.M900(11), 2) +
          fixedNumber(cal.M900(12), 1) +
          fixedNumber(cal.M900(1), 1) +
          fixedNumber(cal.M900(2), 1) +
          fixedNumber(cal.M900(3), 1) +
          fixedNumber(cal.M900(4), 1) +
          fixedNumber(cal.M900(5), 1) +
          fixedNumber(cal.M900(6), 1) +
          fixedNumber(cal.M900(7), 1) +
          fixedNumber(cal.M900(8), 1) +
          fixedNumber(cal.M900(9), 1),
        1,
      ),
    },
  ];
  const configTable2 = {
    fields: {
      rows: ['title'],
      values: [
        'month10',
        'month11',
        'month12',
        'month1',
        'month2',
        'month3',
        'month4',
        'month5',
        'month6',
        'month7',
        'month8',
        'month9',
        'total',
      ],
    },
    meta: [
      {
        field: 'title',
        name: ' ',
      },
      {
        field: 'month10',
        name: '10月',
      },
      {
        field: 'month11',
        name: '11月',
      },
      {
        field: 'month12',
        name: '12月',
      },
      {
        field: 'month1',
        name: '1月',
      },
      {
        field: 'month2',
        name: '2月',
      },
      {
        field: 'month3',
        name: '3月',
      },
      {
        field: 'month4',
        name: '4月',
      },
      {
        field: 'month5',
        name: '5月',
      },
      {
        field: 'month6',
        name: '6月',
      },
      {
        field: 'month6',
        name: '6月',
      },
      {
        field: 'month7',
        name: '7月',
      },
      {
        field: 'month8',
        name: '8月',
      },
      {
        field: 'month9',
        name: '9月',
      },
      {
        field: 'total',
        name: '年間',
      },
    ],
    data: LABOR_HOUR_TABLE_2,
  };

  const dataChart = {
    data: LABOR_HOURS.filter((item) => item.title !== '合計').filter((e) => e.month !== '年間'),
    xField: 'month',
    yField: 'value1',
    isStack: true,
    seriesField: 'title',
  };
  const configTable3 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '人数',
      },
      {
        field: 'value2',
        name: '費用合計',
      },
    ],
    data: [
      {
        title: '社員・家族労働者（非栽培作業者）',
        value1: cal.getValueOfQuestionId('Q0401001', ETrainingStep.step4),
        value2: numberWithCommas(cal.Y610()),
      },
      {
        title: '社長・代表',
        value1: 1,
        value2: numberWithCommas(cal.Y611()),
      },
      {
        title: 'パート（栽培作業者）',
        value1: cal.getValueOfQuestionId('Q0403001', ETrainingStep.step4),
        value2: numberWithCommas(cal.Y1009()),
      },
      {
        title: '栽培技術コンサル',
        value1: cal.getValueOfQuestionId('Q0402001', ETrainingStep.step4),
        value2: numberWithCommas(cal.Y1200()),
      },
      {
        title: '合計',
        value1: cal.Q0401001() + cal.getValueOfQuestionId('Q0403001', ETrainingStep.step4) + cal.Q0402001() + 1,
        value2: numberWithCommas(cal.Y610() + cal.Y611() + cal.Y1009() + cal.Y1200()),
      },
    ],
  };
  const optionTable1 = {
    height: 100,
    conditions: {
      background: [
        {
          field: 'value1',
          mapping(fieldValue: any, fieldName: any) {
            if (fieldName.title === '雇用している栽培作業者の労働時間') {
              if (getNumberOnly(fieldValue) < getNumberOnly(cal.Y900())) {
                return {
                  fill: '#FB6A6D',
                };
              }
              return {};
            }
            return {};
          },
        },
      ],
    },
  };

  const optionTable2 = {
    height: 500,
  };

  const optionTable3 = {
    height: 350,
  };

  return {
    configTable1,
    configTable2,
    configTable3,
    dataChart,
    LABOR_HOURS,
    optionTable1,
    optionTable2,
    optionTable3,
    dataTable1,
  };
};

export const THEME_TABLE = {
  name: 'colorful' as ThemeName,
  palette: generatePalette({
    ...getPalette('colorful'),
    brandColor: '#1D81BA',
  }),
};

export type Anotation = {
  type: string;
  position: [string, number];
  content: string;
  style: {};
  offsetY: number;
};
