import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import trainingResultAction from 'modules/trainingResult/trainingResult.action';
import { TAnswerTrainingResults } from 'types/trainingResult';
import AssignmentsQuestion from './AssignmentsQuestion';

const AssignmentsQuestionContainer = () => {
  const dispatch = useDispatch();
  const { trainingResult } = useSelector((state: AppState) => state.pages);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);

  const updateTrainingResult = (data: TAnswerTrainingResults, id: string) => {
    if (currentGroup.id) {
      dispatch(trainingResultAction.updateTrainingResult({ groupId: currentGroup.id, data, id, step: 'step1' }));
    }
  };

  const resetTrainingResult = () => {
    dispatch(trainingResultAction.resetStateTraining());
  };

  return (
    <AssignmentsQuestion
      trainingResult={trainingResult}
      updateTrainingResult={updateTrainingResult}
      resetTrainingResult={resetTrainingResult}
    />
  );
};

export default AssignmentsQuestionContainer;
