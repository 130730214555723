import React from 'react';
import AssignmentsMenuContainer from 'pages/Client/AssignmentsMenu/AssignmentsMenu.container';
import AssignmentsQuestionContainer from 'pages/Client/AssignmentsQuestion/AssignmentsQuestion.container';
import AssignmentsRfpContainer from 'pages/Client/AssignmentsRfp/AssignmentsRfp.container';
import LoginContainer from 'pages/Login/Login.container';
import ResetPasswordContainer from 'pages/ResetPassword/ResetPassword.container';
import TopContainer from 'pages/Client/Top/Top.container';
import TopAdmContainer from 'pages/Admin/TopAdm/TopAdm.container';
import AssignmentsResultsContainer from 'pages/Client/AssignmentsResults/AssignmentsResults.container';
import AssignmentsStartContainer from 'pages/Client/AssignmentsStart/AssignmentsStart.container';
import GroupsAdmContainer from 'pages/Admin/GroupsAdm/GroupsAdm.container';
import TemplatesAdmContainer from 'pages/Admin/TemplatesAdm/TemplatesAdm.container';
import WorkingTimeContainer from 'pages/Admin/WorkingTime/WorkingTime.container';
import AssignmentsAdmContainer from 'pages/Admin/AssignmentsAdm/AssignmentsAdm.container';
import TemplateRfpContainer from 'pages/Admin/TemplateRfp/TemplateRfp.container';
import TemplateKpiContainer from 'pages/Admin/TemplateKpi/TemplateKpi.container';
import AdmAddQuestion1Container from 'pages/Admin/AdmAddQuestion1/AdmAddQuestion1.container';
import AdmAddCalculationSettingsGradeContainer from 'pages/Admin/AdmAddCalculationSettingsGrade/AdmAddCalculationSettingsGrade.container';
import AdmAddCalculationSettingsYieldsContainer from 'pages/Admin/AdmAddCalculationSettingsYields/AdmAddCalculationSettingsYields.container';
import AssignmentsResultsAdmContainer from 'pages/Admin/AssignmentsResultsAdm/AssignmentsResultsAdm.container';
import AssignmentsAdmAddContainer from 'pages/Admin/AssignmentAdmAdd/AssignmentsAdmAdd.container';
import AssignmentsAdmDetailContainer from 'pages/Admin/AssignmentAdmDetail/AssignmentsAdmDetail.container';
import ChangePasswordContainer from 'pages/ChangePassword/ChangePassword.container';
import AssignmentQuestion2Container from 'pages/Client/AssignmentQuestion2/AssignmentQuestion2.container';
import AssignmentCalculatorGradeContainer from 'pages/Client/AssignmentCaculationSettingsGrade/AssignmentCalculatorGrade.container';
import AssignmentCalculationWorkTimeContainer from 'pages/Client/AssignmentCalculationWorkTime/AssignmentCalculationWorkTime.container';
import AssignmentScaleUpContainer from 'pages/Client/AssignmentScaleUp/AssignmentScaleUp.container';
import AdmAddQuestion2Container from 'pages/Admin/AdmAddQuestion2/AdmAddQuestion2.container';
import { TRoleUser } from 'types/auth';
import AssignmentQuestion3Container from 'pages/Client/AssignmentQuestion3/AssignmentQuestion3.container';
import AssignmentQuestion7Container from 'pages/Client/AssignmentQuestion7/AssignmentQuestion7.container';
import ForgotPasswordContainer from 'pages/ForgotPassword/ForgotPassword.container';
import GroupsManageContainer from 'pages/Master/GroupsManage/GroupsManage.container';
import GroupsDetailsContainer from 'pages/Master/GroupsDetails/GroupsDetails.container';
import LibManageContainer from 'pages/Master/LibManage/LibManage.container';
import LibDetailsContainer from 'pages/Master/LibDetails/LibDetails.container';
import CalculationYieldsContainer from '../pages/Client/AssignmentCalculationSettingsYields/AssignmentCalculationSettingsYields.container';
import { EPath } from './routes/index';
import { IndexedObject } from './types';

type TRouterConfig = {
  name: string;
  component: JSX.Element;
  path: string;
  exact: boolean;
  public: boolean;
  role: TRoleUser[];
  isShowFooter: boolean;
  isShowLeftMenu: boolean;
};

enum role {
  teacher = 'teacher',
  student = 'student',
  admin = 'admin',
}

export const routers: IndexedObject<TRouterConfig> = {
  U1: {
    name: 'U1',
    component: <LoginContainer />,
    path: EPath.login,
    exact: true,
    public: true,
    role: [],
    isShowFooter: true,
    isShowLeftMenu: false,
  },
  U1_2: {
    name: 'U1_2',
    component: <ResetPasswordContainer />,
    path: EPath.resetPassword,
    exact: true,
    public: true,
    role: [],
    isShowFooter: true,
    isShowLeftMenu: false,
  },
  U2: {
    name: 'U2',
    component: <ForgotPasswordContainer />,
    path: EPath.forgotPassword,
    exact: true,
    public: true,
    role: [],
    isShowFooter: true,
    isShowLeftMenu: false,
  },
  U2_1: {
    name: 'U2_1',
    component: <ChangePasswordContainer />,
    path: '/password/change',
    exact: true,
    public: false,
    role: [role.student, role.teacher, role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  U3: {
    name: 'U3',
    component: <TopContainer />,
    path: EPath.U3_Top,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  U4: {
    name: 'U4',
    component: <AssignmentsMenuContainer />,
    path: EPath.U4_Menu,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  U5: {
    name: 'U5',
    component: <AssignmentsRfpContainer />,
    path: EPath.U5,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U6: {
    name: 'U6',
    component: <AssignmentsStartContainer />,
    path: EPath.U6,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U7: {
    name: 'U7',
    component: <AssignmentsQuestionContainer />,
    path: EPath.U7,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U7_2: {
    name: 'U7_2',
    component: <AssignmentQuestion2Container />,
    path: EPath.U7_2,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U7_3: {
    name: 'U7_3',
    component: <AssignmentQuestion3Container />,
    path: EPath.U7_3,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U7_4: {
    name: 'U7_4',
    component: <CalculationYieldsContainer />,
    path: EPath.U7_4,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U7_5: {
    name: 'U7_5',
    component: <AssignmentCalculatorGradeContainer />,
    path: EPath.U7_5,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U7_6: {
    name: 'U7_6',
    component: <AssignmentCalculationWorkTimeContainer />,
    path: EPath.U7_6,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U7_7: {
    name: 'U7_7',
    component: <AssignmentScaleUpContainer />,
    path: EPath.U7_7,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U7_8: {
    name: 'U7_8',
    component: <AssignmentQuestion7Container />,
    path: EPath.U7_8,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  U8: {
    name: 'U8',
    component: <AssignmentsResultsContainer />,
    path: EPath.U8,
    exact: true,
    public: false,
    role: [role.student],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A1: {
    name: 'A1',
    component: <TopAdmContainer />,
    path: EPath.A1,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A2: {
    name: 'A2',
    component: <GroupsAdmContainer />,
    path: EPath.A2,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A3: {
    name: 'A3',
    component: <TemplatesAdmContainer />,
    path: EPath.A3,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A4: {
    name: 'A4',
    component: <TemplateRfpContainer />,
    path: EPath.A4,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_2: {
    name: 'A4_2',
    component: <AdmAddQuestion1Container />,
    path: EPath.A4_2,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_3: {
    name: 'A4_3',
    component: <AdmAddQuestion2Container />,
    path: EPath.A4_3,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_4: {
    name: 'A4_4',
    component: <AdmAddCalculationSettingsYieldsContainer />,
    path: EPath.A4_4,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_5: {
    name: 'A4_5',
    component: <AdmAddCalculationSettingsGradeContainer />,
    path: EPath.A4_5,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_6: {
    name: 'A4_6',
    component: <WorkingTimeContainer />,
    path: EPath.A4_6,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A4_7: {
    name: 'A4_7',
    component: <TemplateKpiContainer />,
    path: EPath.A4_7,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A5: {
    name: 'A5',
    component: <TemplateRfpContainer />,
    path: EPath.A5,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A5_2: {
    name: 'A5_2',
    component: <AdmAddQuestion1Container />,
    path: '/adm/templates/:templateId/edit/questions/1',
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A5_3: {
    name: 'A5_3',
    component: <AdmAddQuestion2Container />,
    path: '/adm/templates/:templateId/edit/questions/2',
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A5_4: {
    name: 'A5_4',
    component: <AdmAddCalculationSettingsYieldsContainer />,
    path: '/adm/templates/:templateId/edit/calculation_settings/yields',
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A5_5: {
    name: 'A5_5',
    component: <AdmAddCalculationSettingsGradeContainer />,
    path: '/adm/templates/:templateId/edit/calculation_settings/grade',
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A5_6: {
    name: 'A5_6',
    component: <WorkingTimeContainer />,
    path: '/adm/templates/:templateId/edit/calculation_settings/working_time',
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A5_7: {
    name: 'A5_7',
    component: <TemplateKpiContainer />,
    path: '/adm/templates/:templateId/edit/kpi',
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: false,
    isShowLeftMenu: true,
  },
  A6: {
    name: 'A6',
    component: <AssignmentsAdmContainer />,
    path: EPath.assignments,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A7: {
    name: 'A7',
    component: <AssignmentsAdmDetailContainer />,
    path: EPath.assignmentDetail,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A8: {
    name: 'A8',
    component: <AssignmentsAdmAddContainer />,
    path: EPath.assignmentAdd,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A9: {
    name: 'A9',
    component: <AssignmentsAdmAddContainer />,
    path: EPath.assignmentEdit,
    exact: true,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  A10: {
    name: 'A10',
    component: <AssignmentsResultsAdmContainer />,
    path: EPath.A10,
    exact: false,
    public: false,
    role: [role.teacher, role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  // Adminstrator Master
  S1_TOP: {
    name: 'S1_TOP',
    component: <TopAdmContainer />,
    path: EPath.S1_TOP,
    exact: true,
    public: false,
    role: [role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  S3_1: {
    name: 'S3_1',
    component: <GroupsManageContainer />,
    path: EPath.S3_1,
    exact: true,
    public: false,
    role: [role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  S3_2_CREATE: {
    name: 'S3_2_CREATE',
    component: <GroupsDetailsContainer />,
    path: EPath.S3_2_CREATE,
    exact: true,
    public: false,
    role: [role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  S3_2: {
    name: 'S3_2',
    component: <GroupsDetailsContainer />,
    path: EPath.S3_2,
    exact: true,
    public: false,
    role: [role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  S4_1: {
    name: 'S4_1',
    component: <LibManageContainer />,
    path: EPath.S4_1,
    exact: true,
    public: false,
    role: [role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  S4_2_CREATE: {
    name: 'S4_2_CREATE',
    component: <LibDetailsContainer />,
    path: EPath.S4_2_CREATE,
    exact: true,
    public: false,
    role: [role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
  S4_2: {
    name: 'S4_2',
    component: <LibDetailsContainer />,
    path: EPath.S4_2,
    exact: true,
    public: false,
    role: [role.admin],
    isShowFooter: true,
    isShowLeftMenu: true,
  },
};
