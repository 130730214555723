import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import CommonSelect from 'components/CommonSelect/CommonSelect';
import { TSelectOptions } from 'types/common';
import AssignmentsResultsContainer from 'pages/Client/AssignmentsResults/AssignmentsResults.container';
import { useHistory, useParams } from 'react-router-dom';
import { routers } from 'constants/router';
import { replacePathParams } from 'libs/helpers/functions';
import { TTrainingResult } from 'types/trainingResult';
import { TGroupsClassDetail } from 'types/groupsClass';
import styles from './style.module.scss';

const AssignmentsResults: React.FC<{
  getTrainingResult: (trainingId: string, studentId: string, groupId: number) => void;
  trainingResult: TTrainingResult;
  currentGroup: TGroupsClassDetail;
}> = ({ getTrainingResult, trainingResult, currentGroup }) => {
  const [listStudents, setListStudent] = useState<TSelectOptions>([]);

  const { trainingId, studentId } = useParams<{ trainingId: string; studentId: string }>();
  const [form] = Form.useForm();

  const history = useHistory();

  useEffect(() => {
    if (trainingId && studentId && currentGroup.id) {
      getTrainingResult(trainingId, studentId, currentGroup.id);
    }
  }, [trainingId, studentId, currentGroup]);

  useEffect(() => {
    if (trainingResult?.training?.isCompleted) {
      const listStudent = trainingResult.training.isCompleted.map((student) => ({
        name: student.name || '',
        value: student.id || '',
      }));
      setListStudent(listStudent || []);
      form.setFieldsValue({ studentId });
    }
  }, [trainingResult]);

  const onChangeSelect = (value: string): void => {
    const studentId = value;
    history.push({
      pathname: replacePathParams(routers.A10.path, { trainingId, studentId }),
    });
  };

  const onClickChangeUrl = () => {
    history.push({
      pathname: replacePathParams(routers.A6.path, { trainingId, studentId }),
    });
  };

  return (
    <>
      <div className={styles.assignmentsResultsWrap}>
        <div className={styles.assignmentsResultsWrap__content}>
          <div className={styles.title}>
            <p>研修閲覧</p>
          </div>
          <div className={styles.content}>
            <Row>
              <Col sm={12} xs={24}>
                <Button onClick={onClickChangeUrl} onKeyDown={onClickChangeUrl}>
                  研修一覧に戻る
                </Button>
                <Form autoComplete="off" layout="vertical" form={form}>
                  <CommonSelect
                    name="studentId"
                    placeholder=""
                    label="どのユーザーの研修結果を閲覧しますか"
                    selectOptions={listStudents}
                    onChange={onChangeSelect}
                  />
                </Form>
              </Col>
              <Col sm={24}>
                <AssignmentsResultsContainer admPage />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentsResults;
