import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TTrainingResult } from 'types/trainingResult';

const initialState: TTrainingResult = {};

const _module = createSlice({
  name: 'template',
  initialState,
  reducers: {
    saveTraining(state: TTrainingResult, action: PayloadAction<TTrainingResult>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearStateUpdate(state: TTrainingResult) {
      return {
        ...state,
        updated: false,
      };
    },
  },
});

export const trainingResultModule = _module;
