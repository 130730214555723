import { TChangePasswordBody, TUser } from 'types/auth';
import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory();

export default {
  login: ac<TUser>('login'),
  logout: ac('logout'),
  changePassword: ac<TChangePasswordBody>('changePassword'),
  getAuth: ac<string>('getAuth'),
};
