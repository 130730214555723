import React, { useEffect, useState } from 'react';
import { Button, Card, Space, Table } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import MainButton from 'components/MainButton/MainButton';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import ModalDelete from 'components/ModalDelete/ModalDelete';
import { Location } from 'history';
import { TOptionsQuery } from 'types/common';
import { EPath } from 'constants/routes';
import { TBaseLibary } from 'types/baseLibrary';
import { TBaseLibState } from 'modules/baseLibrary/baseLibrary.reducer';
import { createQueryUrl, replacePathParams } from 'libs/helpers/functions';
import styles from './style.module.scss';

type TModal = '' | 'delete';
type Props = {
  getBaseLibList: (params: TOptionsQuery<any>) => void;
  urlQueryParams: TOptionsQuery<any>;
  location: Location;
  baseLibState: TBaseLibState;
  deleteBaseLib: (id: number) => void;
  downloadFileBaseLib: (baseLib: TBaseLibary) => void;
};

const LibManage = ({
  getBaseLibList,
  urlQueryParams,
  location,
  baseLibState,
  deleteBaseLib,
  downloadFileBaseLib,
}: Props) => {
  const [openModal, setOpenModal] = useState<TModal>('');
  const [baseLib, setBaseLib] = useState<number>(0);
  const history = useHistory();
  const { baseLibs, pagination, loading } = baseLibState;
  const pushToDetailBaseLibPage = (id: number) => {
    history.push(replacePathParams(EPath.S4_2, { id: String(id) }));
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: 'ベースライブラリ名',
      dataIndex: 'name',
      width: '40%',
    },
    {
      title: '登録日',
      dataIndex: 'createdAt',
      width: '30%',
    },
    {
      title: '',
      width: '20%',
      render: (_: any, record: TBaseLibary) => (
        <Space align="center" className={styles.groupButton}>
          <MainButton text="エクスポート" onClick={() => downloadFileBaseLib(record)} />
          <MainButton text="更新" onClick={() => pushToDetailBaseLibPage(record.id ?? 0)} />
          <MainButton
            subButton
            text="削除"
            onClick={() => {
              setOpenModal('delete');
              setBaseLib(record.id ?? 0);
            }}
          />
        </Space>
      ),
    },
  ];
  const onChangePage = (page: number, limit: number) => {
    history.push(createQueryUrl(location, { ...urlQueryParams, page, limit }));
  };
  useEffect(() => {
    getBaseLibList({ ...urlQueryParams, sortBy: '_id:desc' });
  }, [location]);

  return (
    <>
      <ModalDelete
        title="確認"
        subTitle="ベースライブラリを削除してもよろしいでしょうか？"
        open={openModal === 'delete'}
        onClose={() => setOpenModal('')}
        handleDelete={() => deleteBaseLib(baseLib)}
      />
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.menuTitle}>
              <p>ベースライブラリ管理</p>
            </div>
          }
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space className={styles.topButtonGroup}>
                <Button>
                  <Link to={EPath.A1}>TOPに戻る</Link>
                </Button>
                <Button>
                  <Link to={EPath.S4_2_CREATE}>ベースライブラリを追加する</Link>
                </Button>
              </Space>
              <Table
                bordered
                columns={columns}
                dataSource={baseLibs}
                pagination={false}
                loading={loading}
                rowKey="id"
              />
            </div>
            <div className={styles.bottom}>
              <PaginationCustom
                current={pagination.page}
                total={pagination.totalResults}
                pageSize={pagination.limit}
                disabled={false}
                onChange={onChangePage}
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default LibManage;
