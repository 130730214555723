import React from 'react';
import styles from './style.module.scss';
import SheetTable from '../../../components/SheetTable/SheetTable';
import { OPTIONS_TABLE1, THEME_TABLE, OPTIONS_TABLE2, OPTIONS_TABLE3, OPTIONS_TABLE4 } from './AssignmentScaleUp.state';

const AssignmentScaleUpForm: React.FC<{ configTable: any; tableDataForScaleUp: any }> = ({
  configTable,
  tableDataForScaleUp,
}) => (
  <div>
    {/* row 1 */}
    <div>
      <p className={styles.p_2}>ここまでで初年度の計画が決定しました。ここで一度PLを確認してみましょう！</p>
      {configTable.configTable1 && (
        <SheetTable dataCfg={configTable?.configTable1} options={OPTIONS_TABLE1} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 2 */}
    <div className={styles.littleTab}>
      <p>年度ごとの規模拡大</p>
      {tableDataForScaleUp.configTable2 && (
        <SheetTable dataCfg={tableDataForScaleUp?.configTable2} options={OPTIONS_TABLE2} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 3 */}
    <div className={styles.littleTab}>
      <p>１圃場あたりの規模拡大に必要とする投資額です。</p>
      {tableDataForScaleUp.configTable3 && (
        <SheetTable dataCfg={tableDataForScaleUp?.configTable3} options={OPTIONS_TABLE3} themeCfg={THEME_TABLE} />
      )}
      <p>※規模拡大を行う場合には、１年目に建設した施設同様の費用が発生します。</p>
      <p>※規模拡大を行うと選択した年度から栽培、収穫、販売を行えるものとします。</p>
      <p className={styles.mb_50}> （前年度からハウス建設、準備を始めるものとします）</p>
    </div>
    {/* row 4 */}
    <div>
      <p>年度ごとの収量増加割合</p>
      {tableDataForScaleUp.configTable4 && (
        <SheetTable dataCfg={tableDataForScaleUp?.configTable4} options={OPTIONS_TABLE4} themeCfg={THEME_TABLE} />
      )}
    </div>
  </div>
);

export default AssignmentScaleUpForm;
