/* eslint-disable complexity */
import React, { useMemo, useState } from 'react';
import { Row, Col, Carousel } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import EditTraining from 'libs/hook/EditTraining';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import { TTrainingDetail } from 'types/assignments';
import { routers } from 'constants/router';
import { map, difference } from 'lodash';
import HeaderTraining from 'components/HeaderTraining';
import { data } from './AssignmentsStart.state';
import styles from './style.module.scss';
import './style.css';

const AssignmentsStart: React.FC<{ training: TTrainingDetail | undefined }> = ({ training }) => {
  const { trainingId } = useParams<{ trainingId: string }>();
  const [seenStep, setSeenStep] = useState<number[]>([]);
  const [disable, setDisable] = useState<boolean>(true);
  const history = useHistory();

  EditTraining();

  const handelClickNext = () => {
    history.push(routers.U7.path.replace(':trainingId', trainingId));
  };

  const getIndexGold = useMemo(() => {
    const arr: number[] = [1];

    map(training?.goalSetting, (val) =>
      val.checked ? arr.push(arr[arr.length - 1] + 1) : arr.push(arr[arr.length - 1]),
    );
    return arr;
  }, [training]);

  const onClickPreStep = () => {
    history.push(routers.U5.path.replace(':trainingId', trainingId));
  };

  return (
    <>
      <div className={styles.assignmentsRfpWrap}>
        <HeaderTraining />
        <Row>
          <p style={{ marginTop: '52px' }}>
            シミュレーションの流れを最後まで読み、画面下の「進む」ボタンを押しましょう。
          </p>
        </Row>
        <div className={styles.contentWrap}>
          <Carousel
            arrows
            className={styles.slider}
            autoplay
            afterChange={(current: number) => {
              setSeenStep([...seenStep, current]);
              if (difference([1, 2], [...seenStep, current]).length === 0) setDisable(false);
            }}
          >
            <div className={styles.sliderItem}>
              <span className={styles.textSlider}>
                シミュレーションの流れ <br />
              </span>
              <span className={styles.textSlider}>
                1.Stepごとの設問に回答し、
                <p className={styles.sliderItem_button}>進む</p>
                ボタンを押しましょう。
              </span>
              <div className={styles.imgWrap}>
                <img src="/images/image_U6.jpg" alt="" className={styles.img} />
              </div>
            </div>
            <div className={styles.sliderItem}>
              <div className={styles.itemsTitle}>
                <p>シミュレーションの流れ</p> <p>2.全ステップの回答が終わると計画が自動作成されます。</p>
              </div>
              <div className={styles.itemTable}>
                {data.map((items) => (
                  <div className={styles.table} key={items.id}>
                    <div className={styles.colsFirst}>
                      <p>{items.name}</p>
                      <img src={items.img} alt={items.name} />
                    </div>
                    <div className={styles.colsSecond}>{items.title}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.sliderItem}>
              <div className={styles.itemsTitle}>
                <p>シミュレーションの流れ</p>
                <p>3. 損益計算書を確認し、以下の項目を達成したらゴール達成です！</p>
              </div>
              <div className={styles.table3}>
                {training?.goalSetting?.goal1.checked && (
                  <Row className={styles.rowtable}>
                    <Col sm={8} className={styles.col1}>
                      {getIndexGold[0]}
                    </Col>
                    {training?.goalSetting?.goal1.checked && <Col sm={16}>途中で倒産しない</Col>}
                  </Row>
                )}
                {training?.goalSetting?.goal2.checked && (
                  <Row className={styles.rowtable}>
                    <Col sm={8} className={styles.col1}>
                      {getIndexGold[1]}
                    </Col>
                    <Col sm={16}> {training?.goalSetting?.goal2.defaultVal[0]} 年目に単年黒字化</Col>
                  </Row>
                )}
                {training?.goalSetting?.goal3.checked && (
                  <Row className={styles.rowtable}>
                    <Col sm={8} className={styles.col1}>
                      {getIndexGold[2]}
                    </Col>
                    <Col sm={16}>
                      {training?.goalSetting?.goal3.defaultVal[0]} 年目に営業利益{' '}
                      {training?.goalSetting?.goal3.defaultVal[1]} ％以上
                    </Col>
                  </Row>
                )}
                {training?.goalSetting?.goal4.checked && (
                  <Row className={styles.rowtable}>
                    <Col sm={8} className={styles.col1}>
                      {getIndexGold[3]}
                    </Col>
                    <Col sm={16}>
                      5年目に累計利益
                      {training?.goalSetting?.goal4.defaultVal[0]} 万円以上
                    </Col>
                  </Row>
                )}
                {training?.goalSetting?.goal5.checked && (
                  <Row className={styles.rowtable}>
                    <Col sm={8} className={styles.col1}>
                      {getIndexGold[4]}
                    </Col>
                    <Col sm={16}>
                      5年目に累計利益率
                      {training?.goalSetting?.goal5.defaultVal[0]} ％以上
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      <ButtonFooter handelClickNext={handelClickNext} handelClickPre={onClickPreStep} active={disable} />
    </>
  );
};

export default AssignmentsStart;
