import { parseSearchParams } from 'libs/helpers/functions';
import { useLocation } from 'react-router-dom';
import groupsAction from 'modules/groups/groups.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { TUserDetail } from 'types/groups';
import { TOptionsQuery } from 'types/common';
import GroupsAdm from './GroupsAdm';

const GroupsAdmContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const urlQueryParams: TOptionsQuery<TUserDetail> = parseSearchParams(location.search);
  const groupsState = useSelector((state: AppState) => state.pages.groups);
  const groupsClassState = useSelector((state: AppState) => state.pages.groupClass);
  const uiState = useSelector((state: AppState) => state.pages.ui);

  const getUsers = (params: TOptionsQuery<TUserDetail>) => dispatch(groupsAction.getUsers(params));
  const createUser = (u: TUserDetail & { groupId: number }) => dispatch(groupsAction.createUser(u));
  const updateUser = (u: TUserDetail & { groupId: number }) => dispatch(groupsAction.updateUser(u));
  const deleteUser = (data: { id: string; groupId: number }) => dispatch(groupsAction.deleteUser(data));

  return (
    <GroupsAdm
      getUsers={getUsers}
      groupsState={groupsState}
      groupsClassState={groupsClassState}
      uiState={uiState}
      createUser={createUser}
      updateUser={updateUser}
      deleteUser={deleteUser}
      location={location}
      urlQueryParams={urlQueryParams}
    />
  );
};

export default GroupsAdmContainer;
