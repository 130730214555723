import React from 'react';
import { Form, FormInstance, Table } from 'antd';
import { IndexedObject } from 'constants/types';

const EditTable: React.FC<{
  form?: FormInstance<any>;
  editableCell?: any;
  data?: any[];
  mergedColumns?: any;
  loading?: boolean;
  rowKey?: string;
  validateMessages?: IndexedObject;
}> = ({ form, editableCell, data, mergedColumns, loading, rowKey = 'id', validateMessages }) => (
  <Form form={form} component={false} validateMessages={validateMessages}>
    <Table
      components={{
        body: {
          cell: editableCell,
        },
      }}
      bordered
      dataSource={data}
      columns={mergedColumns}
      rowClassName="editable-row"
      pagination={false}
      loading={loading}
      rowKey={(record) => record[rowKey]}
    />
  </Form>
);

export default EditTable;
