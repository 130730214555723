import { Button, Form, Image } from 'antd';
import { BASE_UPLOAD_URL } from 'constants/index';
import ModalUpload from 'pages/Admin/TemplateRfp/Modal/ModalUpload';
import React, { useState } from 'react';
import styles from '../style.module.scss';

type Props = {
  picture: string;
  updateImage: (data: FormData) => void;
  clearStateUpload: () => void;
};

const PreviewImageTraining = ({ picture, updateImage, clearStateUpload }: Props) => {
  const [isShowModalUpload, setShowModalUpload] = useState(false);
  return (
    <>
      <Form.Item>
        <div className={styles.noteWrap}>
          <p>業務命令書の画像 (2000文字まで)</p>
          <p>※冒頭説明で画像を載せたい場合は、 画像のアップロードをしてください。 </p>
          <p>(JPEG/GIF/PNG形式。 添付可能なファイルサイズは最大で5MBです。)</p>
          <p>※研修テンプレートを変更すると、テンプレートの設定画像が自動入力されます。</p>
        </div>
        <Button onClick={() => setShowModalUpload(true)}>ファイルを選択</Button>
        <div className={styles.previewImage}>
          {picture && <Image width={300} height={300} src={BASE_UPLOAD_URL + picture} />}
        </div>
      </Form.Item>
      <ModalUpload
        open={isShowModalUpload}
        setModal={(active) => setShowModalUpload(active)}
        updateImage={updateImage}
        clearStateUpload={clearStateUpload}
      />
    </>
  );
};

export default PreviewImageTraining;
