import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map, mergeMap } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { AxiosResponse } from 'libs/axios';
import { uiModule } from 'modules/ui/ui.reducer';
import { messageModule } from 'modules/message/message.reducer';
import { uploadServices } from 'services/upload';
import { templateModule } from 'modules/template/template.reducer';
import actions from './upload.action';
import { uploadModule } from './upload.reducer';

export const uploadImage: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.uploadImage),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(uploadServices.uploadImage(payload.data)),
        next: (v: any) => [uploadModule.actions.uploaded(true), templateModule.actions.updateImage(v.name)],
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const clearState: Epic<AnyAction, WrapAction<any>> = (action$) =>
  action$.pipe(
    ofAction(actions.clearState),
    mergeMap(() => [uploadModule.actions.clearState()]),
  );
