import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { messageModule } from 'modules/message/message.reducer';
import baseLibraryAction from 'modules/baseLibrary/baseLibrary.action';
import { AppState } from 'store';
import { messages } from 'constants/validate/message';
import LibDetails from './LibDetails';

const LibDetailsContainer = () => {
  const dispatch = useDispatch();
  const baseLibState = useSelector((state: AppState) => state.pages.baseLibrary);
  const { id } = useParams<{ id: string }>();

  const handleUploadFile = (data: { file: FormData; fileName: string }) => {
    dispatch(baseLibraryAction.uploadBaseLibraryFile(data));
  };
  const createBaseLib = (data: { name?: string; fname?: string }) => {
    dispatch(baseLibraryAction.createBaseLibrary(data));
  };
  const updateBaseLib = (data: { libId: number; name?: string; fname?: string }) => {
    dispatch(baseLibraryAction.updateBaseLibrary(data));
  };
  const getDetailBaseLib = (libId: number) => {
    dispatch(baseLibraryAction.getBaseLibraryDetail(libId));
  };
  const showErrorMissingFile = () => {
    dispatch(messageModule.actions.setError(messages.missingBaseLib));
  };
  return (
    <LibDetails
      libId={Number(id)}
      handleCreateBaseLib={createBaseLib}
      handleUpdateBaseLib={updateBaseLib}
      handleUploadFile={handleUploadFile}
      getDetailBaseLib={getDetailBaseLib}
      showErrorMissingFile={showErrorMissingFile}
      baseLibState={baseLibState}
    />
  );
};

export default LibDetailsContainer;
