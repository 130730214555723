import { Form, Image } from 'antd';
import { BASE_UPLOAD_URL } from 'constants/index';
import React from 'react';
import styles from '../style.module.scss';

type Props = {
  picture?: string;
  summary?: string;
};

const PreviewSummaryTraining = ({ summary, picture }: Props) => {
  if (!summary || !picture) return null;
  return (
    <Form.Item>
      <div className={styles.noteWrap}>
        <p>業務命令書のプレビュー </p>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.left}>
          <p>{summary}</p>
        </div>
        {picture && (
          <div className={styles.right}>
            <Image src={BASE_UPLOAD_URL + picture} />
          </div>
        )}
      </div>
    </Form.Item>
  );
};

export default PreviewSummaryTraining;
