import React from 'react';
import styles from './style.module.scss';
import SheetTable from '../../../components/SheetTable/SheetTable';
import {
  OPTIONS_TABLE1,
  THEME_TABLE,
  OPTIONS_TABLE2,
  OPTIONS_TABLE3,
  OPTIONS_TABLE4,
} from './AssignmentCalculationWorkTime.state';

const CalculationWorkTimeForm: React.FC<{ configTable: any }> = ({ configTable }) => (
  <div>
    <div className={styles.littleTab}>
      <p>福利厚生（年間）</p>
      {configTable?.configTable1 && (
        <SheetTable dataCfg={configTable.configTable1} options={OPTIONS_TABLE1} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 2 */}
    <div className={styles.littleTab}>
      <p>教育費（年間）</p>
      {configTable?.configTable2 && (
        <SheetTable dataCfg={configTable.configTable2} options={OPTIONS_TABLE2} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 3 */}
    <div>
      <p>その他販売費</p>
      {configTable?.configTable3 && (
        <SheetTable dataCfg={configTable.configTable3} options={OPTIONS_TABLE3} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 4 */}
    <div>
      {configTable?.configTable4 && (
        <SheetTable dataCfg={configTable.configTable4} options={OPTIONS_TABLE4} themeCfg={THEME_TABLE} />
      )}
    </div>
  </div>
);

export default CalculationWorkTimeForm;
