import { questionsRepositories } from 'repositories/questions';

const getQuestions = async (groupId: number) => {
  const res = await questionsRepositories.getQuestions({
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

export const questionsServices = {
  getQuestions,
};
