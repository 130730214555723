import { DEFAULT_FORMAT_DATE } from 'constants/date';
import { getDateFormat } from 'libs/helpers/date';
import { TTrainingDetail } from 'types/assignments';

export const getImplementTrainingDate = (training: TTrainingDetail) => {
  const startDate = getDateFormat(training.startDate, DEFAULT_FORMAT_DATE);
  const endDate = getDateFormat(training.endDate, DEFAULT_FORMAT_DATE);
  return {
    startDate,
    endDate,
    implementDate: `${startDate} - ${endDate}`,
  };
};
