export const defaultValidateMessages = {
  required: 'この項目は入力が必須です。',
  whitespace: 'この項目は入力が必須です。',
  types: {
    email: 'メールアドレスが正しい形式ではありません。',
  },
};

export const customValidateMessages = {
  validatePassword: 'パスワードは8文字以上で、少なくとも１つの半角英字と１つの数字を含むものを入力してください。',
  rePasswordNotMatch: '新しいパスワードと新しいパスワード（確認用）が一致しません。',
};

export const messages = {
  forgotPassword: 'パスワード変更用のメールを送信しました。メールを確認してください。',
  changePasswordSuc: 'パスワードの変更が完了しました。',
  fileTooLarge: 'アップロードするファイルサイズが大きすぎます。',
  invalidFile: '添付ファイルのファイル形式またはファイル拡張子が正しくありません。',
  missingBaseLib: 'ベースライブラリのファイルを添付してください。',
};
