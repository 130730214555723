import React from 'react';
import { Col, Row, Table } from 'antd';
import { PlusOutlined, PercentageOutlined } from '@ant-design/icons';
import { TSettingSchema } from 'types/setting';
import styles from './style.module.scss';

type EditableTableProps = Parameters<typeof Table>[0];

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const columnBasicYeild = [
  {
    title: '月',
    dataIndex: 'month',
    width: '40%',
    render: (text: string) => <p>{text}月</p>,
  },
  {
    title: '基本収量',
    dataIndex: 'value',
    width: '20%',
    editable: true,
    render: (text: string) => <p className={styles.input}>{text}</p>,
  },
  {
    title: '',
    dataIndex: 'unit',
    width: '40%',
  },
];

export const columnYieldIncrementRatioSetting = [
  {
    title: '投資項目名',
    dataIndex: 'name',
    width: '50%',
  },
  {
    title: '収量の増加量',
    dataIndex: 'value1',
    width: '50%',
    editable: true,
    render: (text: string) => (
      <Row>
        <Col className={styles.colTable} span={4}>
          <p className={styles.titleCol}>
            <PlusOutlined />
          </p>
        </Col>
        <Col span={14}>
          <p className={styles.inputNumber}>{text}</p>
        </Col>
        <Col className={styles.colTable} span={6} style={{ borderRight: 0 }}>
          <p className={styles.titleCol}>
            <PercentageOutlined />
          </p>
        </Col>
      </Row>
    ),
  },
];

export type TStep4 = {
  step4: TSettingSchema;
};
