import React from 'react';
import { Column, DualAxes } from '@ant-design/plots';

const ColumnChart: React.FC<{
  configChart?: any;
}> = ({ configChart }) => <Column {...configChart} />;

export const DualAxesChart: React.FC<{
  configChart?: any;
}> = ({ configChart }) => <DualAxes {...configChart} />;

export default ColumnChart;
