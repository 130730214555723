import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import templateAction from 'modules/template/template.action';
import { AppState } from 'store';
import AdmAddCalculationSettingsYields from './AdmAddCalculationSettingsYields';
import { TStep4 } from './AdmAddCalculationSettingsYields.state';

const AdmAddCalculationSettingsYieldsContainer = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);
  const baseLibState = useSelector((state: AppState) => state.pages.baseLibrary);

  const updateTemplate = (data: TStep4, id: string, buttonFooter: boolean = false, link?: string) => {
    if (currentGroup.id) {
      dispatch(templateAction.updateTemplateStep4({ groupId: currentGroup.id, data, id, buttonFooter, link }));
    }
  };

  return (
    <AdmAddCalculationSettingsYields updateTemplate={updateTemplate} baseLibState={baseLibState} template={template} />
  );
};

export default AdmAddCalculationSettingsYieldsContainer;
