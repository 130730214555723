import React from 'react';
import { Col, Row, Table } from 'antd';
import { PlusOutlined, PercentageOutlined } from '@ant-design/icons';
import { AlignType } from 'rc-table/lib/interface';
import { TSettingSchema } from 'types/setting';
import { IndexedObject } from 'constants/types';
import styles from './style.module.scss';

type EditableTableProps = Parameters<typeof Table>[0];

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const columnBasicYeild = [
  {
    title: '月',
    dataIndex: 'month',
    width: '40%',
    render: (text: string) => <p>{text}月</p>,
  },
  {
    title: 'A品',
    dataIndex: 'value',
    width: '20%',
    editable: true,
    render: (text: string) => <p className={styles.input}>{text}%</p>,
  },
  {
    title: 'その他',
    dataIndex: 'value1',
    width: '40%',
    render: (text: string, object: IndexedObject) => <p>{100 - object.value}%</p>,
  },
];

export const columnYieldIncrementRatioSetting = [
  {
    title: '投資項目名',
    dataIndex: 'name',
    width: '30%',
    align: 'center' as AlignType,
  },
  {
    title: 'A品の増加量',
    dataIndex: 'value1',
    width: '35%',
    editable: true,
    align: 'center' as AlignType,
    render: (text: string) => (
      <Row>
        <Col className={styles.colTable} span={4}>
          <p className={styles.titleCol}>
            <PlusOutlined />
          </p>
        </Col>
        <Col span={14}>
          <p className={styles.inputNumber}>{text}</p>
        </Col>
        <Col className={styles.colTable} span={6} style={{ borderRight: 0 }}>
          <p className={styles.titleCol}>
            <PercentageOutlined />
          </p>
        </Col>
      </Row>
    ),
  },
  {
    title: 'その他の増加量',
    dataIndex: 'value2',
    width: '35%',
    editable: true,
    align: 'center' as AlignType,
    render: (text: string) => (
      <Row>
        <Col className={styles.colTable} span={4}>
          <p className={styles.titleCol}>
            <PlusOutlined />
          </p>
        </Col>
        <Col span={14}>
          <p className={styles.inputNumber}>{text}</p>
        </Col>
        <Col className={styles.colTable} span={6} style={{ borderRight: 0 }}>
          <p className={styles.titleCol}>
            <PercentageOutlined />
          </p>
        </Col>
      </Row>
    ),
  },
];

export type TStep5 = {
  step5: TSettingSchema;
};
