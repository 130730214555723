import { TBasicYield, TYieldIncrementRatio, TYieldIncrementRatioSetting } from 'types/setting';

export const dataBacsicGrade: TBasicYield[] = [
  {
    month: 1,
    value: 75,
    unit: 't/10a',
  },
  {
    month: 2,
    value: 75,
    unit: 't/10a',
  },
  {
    month: 3,
    value: 75,
    unit: 't/10a',
  },
  {
    month: 4,
    value: 75,
    unit: 't/10a',
  },
  {
    month: 5,
    value: 75,
    unit: 't/10a',
  },
  {
    month: 6,
    value: 75,
    unit: 't/10a',
  },
  {
    month: 7,
    value: 75,
    unit: 't/10a',
  },
  {
    month: 8,
    value: 75,
    unit: 't/10a',
  },
  {
    month: 9,
    value: 75,
    unit: 't/10a',
  },
  {
    month: 10,
    value: 75,
    unit: 't/10a',
  },
  {
    month: 11,
    value: 75,
    unit: 't/10a',
  },
  {
    month: 12,
    value: 75,
    unit: 't/10a',
  },
];

export const dataYieldIncrementRatioSetting1: TYieldIncrementRatioSetting[] = [
  {
    name: '暖房機（重油）',
    questionId: 'Q0202002',
    value1: 10,
    value2: 0,
    unit: '%',
  },
  {
    name: 'カーテン',
    questionId: 'Q0202003',
    value1: 10,
    value2: 0,
    unit: '%',
  },
  {
    name: '循環扇',
    questionId: 'Q0202004',
    value1: 10,
    value2: 0,
    unit: '%',
  },
  {
    name: 'かん水装置',
    questionId: 'Q0202005',
    value1: 10,
    value2: 0,
    unit: '%',
  },
  {
    name: '炭酸ガス発生機',
    questionId: 'Q0202006',
    value1: 10,
    value2: 0,
    unit: '%',
  },
  {
    name: '局所施用ダクトファン',
    questionId: 'Q0202007',
    value1: 10,
    value2: 0,
    unit: '%',
  },
  {
    name: 'ミストシステム',
    questionId: 'Q0202008',
    value1: 10,
    value2: 0,
    unit: '%',
  },
  {
    name: '環境制御盤',
    questionId: 'Q0204001',
    value1: 10,
    value2: 0,
    unit: '%',
  },
];

export const dataYieldIncrementRatioSetting2: TYieldIncrementRatioSetting[] = [
  {
    name: 'ハウスタイプ',
    questionId: 'Q0201005',
    value1: 0,
    value2: 0,
    unit: '%',
  },
  {
    name: '環境モニタリング機器',
    questionId: 'Q0203001',
    value1: 0,
    value2: 0,
    unit: '%',
  },
  {
    name: '警報システム',
    questionId: 'Q0203002',
    value1: 0,
    value2: 0,
    unit: '%',
  },
  {
    name: 'トラクター',
    questionId: 'Q0205001',
    value1: 0,
    value2: 0,
    unit: '%',
  },
  {
    name: '管理機',
    questionId: 'Q0205002',
    value1: 0,
    value2: 0,
    unit: '%',
  },
  {
    name: '農薬散布機(動力噴霧器)',
    questionId: 'Q0205003',
    value1: 0,
    value2: 0,
    unit: '%',
  },
];

export const yieldIncrementRatio1: TYieldIncrementRatio = {
  step: 5,
  type: true,
  title: '機器投資',
  settings: dataYieldIncrementRatioSetting1,
};

export const yieldIncrementRatio2: TYieldIncrementRatio = {
  step: 5,
  type: false,
  title: 'モニタリング投資',
  settings: dataYieldIncrementRatioSetting2,
};

export const yieldIncrementRatioGrade: TYieldIncrementRatio[] = [yieldIncrementRatio1, yieldIncrementRatio2];
