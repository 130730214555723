import React from 'react';
import { Button } from 'antd';
import { routers } from 'constants/router';
import { useHistory } from 'react-router-dom';

const ButtonMoveA3 = () => {
  const history = useHistory();

  const moveScreen = () => {
    history.push(routers.A3.path);
  };

  return (
    <Button onClick={moveScreen} onMouseDown={moveScreen} role="button">
      テンプレート一覧に戻る
    </Button>
  );
};

export default ButtonMoveA3;
