import { TNameGoalSetting } from 'components/TemplateKpiTable/TemplateKpiTable';
import moment from 'moment';
import { TAddTrainingBody, TFormUpdateTraining, TTrainingDetail } from 'types/assignments';

export const columnsTableStudents = [
  {
    key: 'name',
    title: '氏名',
    dataIndex: 'name',
    width: '45%',
  },
  {
    key: 'email',
    title: 'メールアドレス',
    dataIndex: 'email',
    width: '45%',
  },
];

export const getDefaultTrainingData = (trainingDetail: TTrainingDetail) => {
  const { name, startDate, endDate, template, summary, goalSetting } = trainingDetail;
  const training: TFormUpdateTraining = {
    name: name ?? '',
    date: [moment(startDate), moment(endDate)],
    template: template?.id ?? '',
    summary: summary ?? '',
    goal1: goalSetting?.goal1.defaultVal[0],
    goal2: goalSetting?.goal2.defaultVal[0],
    goal31: goalSetting?.goal3.defaultVal[0],
    goal32: goalSetting?.goal3.defaultVal[1],
    goal4: goalSetting?.goal4.defaultVal[0],
    goal5: goalSetting?.goal5.defaultVal[0],
  };
  return training;
};

export const getNewTrainingData = (
  values: TFormUpdateTraining,
  picture: string,
  checkboxKpi: TNameGoalSetting[],
  selectedStudentKeys?: React.Key[],
) => {
  const { name, date, template, summary, goal1 = 0, goal2 = 0, goal31 = 0, goal32 = 0, goal4 = 0, goal5 = 0 } = values;
  const newTraining: TAddTrainingBody = {
    name,
    startDate: date[0].toDate(),
    endDate: date[1].toDate(),
    template,
    summary,
    picture,
    students: selectedStudentKeys?.map((s) => s.toString()),
    goalSetting: {
      goal1: {
        checked: checkboxKpi.includes('goal1'),
        defaultVal: [goal1],
      },
      goal2: {
        checked: checkboxKpi.includes('goal2'),
        defaultVal: [goal2],
      },
      goal3: {
        checked: checkboxKpi.includes('goal3'),
        defaultVal: [goal31, goal32],
      },
      goal4: {
        checked: checkboxKpi.includes('goal4'),
        defaultVal: [goal4],
      },
      goal5: {
        checked: checkboxKpi.includes('goal5'),
        defaultVal: [goal5],
      },
    },
  };
  return newTraining;
};
