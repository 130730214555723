import { TOptionsQuery } from 'types/common';
import { TAddTrainingBody } from 'types/assignments';
import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory();

export default {
  getTrainings: ac<TOptionsQuery<TAddTrainingBody>>('getTrainings'),
  getTraining: ac<{ id: string; createTrainingResult?: boolean; groupId: number }>('getTraining'),
  updateTraining: ac<TAddTrainingBody>('updateTraining'),
  createTraining: ac<TAddTrainingBody>('createTraining'),
  deleteTraining: ac<{ trainingId: string; groupId: number }>('deleteTraining'),
  getTrainingsWorking: ac<TOptionsQuery<TAddTrainingBody>>('getTrainingsWorking'),
  getTrainingsFinish: ac<TOptionsQuery<TAddTrainingBody>>('getTrainingsFinish'),
};
