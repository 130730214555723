import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isShowLeftMenu: false,
  isShowLoading: false,
};

export type TUiState = typeof initialState;

const _module = createSlice({
  name: '[ui/uiReducers]',
  initialState,
  reducers: {
    setShowLeftMenu(state: TUiState) {
      return {
        ...state,
        isShowLeftMenu: !state.isShowLeftMenu,
      };
    },
    showLoading(state: TUiState) {
      return {
        ...state,
        isShowLoading: true,
      };
    },
    hideLoading(state: TUiState) {
      return {
        ...state,
        isShowLoading: false,
      };
    },
    clearError() {
      return initialState;
    },
  },
});

export const uiModule = _module;
