import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { baseLibraryServices } from 'services/baseLibrary';
import { AxiosResponse, TResponsePagination } from 'libs/axios';
import { uiModule } from 'modules/ui/ui.reducer';
import { messageModule } from 'modules/message/message.reducer';
import { push } from 'connected-react-router';
import { EPath } from 'constants/routes';
import { TBaseLibary } from 'types/baseLibrary';
import actions from './baseLibrary.action';
import { baseLibraryModule } from './baseLibrary.reducer';

export const getBaseLibraryListEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getBaseLibraryList),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(baseLibraryServices.getBaseLibraryList(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: TResponsePagination<TBaseLibary>) => [baseLibraryModule.actions.saveBaseLibs(u)],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const createBaseLibraryEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.createBaseLibrary),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(baseLibraryServices.createBaseLibrary(payload)),
        complete: uiModule.actions.hideLoading(),
        next: () => [
          baseLibraryModule.actions.setDataFileUpload({}),
          push(EPath.S4_1),
          messageModule.actions.setSuccess(''),
        ],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateBaseLibraryEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateBaseLibrary),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(baseLibraryServices.updateBaseLibrary(payload)),
        complete: uiModule.actions.hideLoading(),
        next: () => [push(EPath.S4_1), messageModule.actions.setSuccess('')],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const deleteBaseLibraryEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.deleteBaseLibrary),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(baseLibraryServices.deleteBaseLibrary(payload)),
        complete: uiModule.actions.hideLoading(),
        next: () => [baseLibraryModule.actions.deleteBaseLib(payload), messageModule.actions.setSuccess('')],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const uploadBaseLibraryFileEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.uploadBaseLibraryFile),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(baseLibraryServices.uploadBaseLibraryFile(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: { fname: string; originalName: string }) => [baseLibraryModule.actions.setDataFileUpload(u)],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getBaseLibraryDetailEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getBaseLibraryDetail),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(baseLibraryServices.getBaseLibraryDetail(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: TBaseLibary) => [baseLibraryModule.actions.saveBaseLibDetail(u)],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const downloadBaseLibraryEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.downloadFileBaseLib),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(baseLibraryServices.downloadBaseLibraryFile(payload.id ?? 0)),
        complete: uiModule.actions.hideLoading(),
        next: (u: any) => {
          const url = window.URL.createObjectURL(new Blob([JSON.stringify(u)], { type: 'application/json' }));
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          const currentTime = moment(Date.now()).format('YYYYMMDD');
          a.download = `${payload.name}_${currentTime}.json`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          return [];
        },
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getBaseLibrarySampleEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getBaseLibrarySample),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(baseLibraryServices.getBaseLibrarySample(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: any) => [baseLibraryModule.actions.saveDataSample(u)],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
