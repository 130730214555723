import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import AssignmentsStart from './AssignmentsStart';

const AssignmentsStartContainer = () => {
  const { training } = useSelector((state: AppState) => state.pages.trainingResult);
  return <AssignmentsStart training={training} />;
};

export default AssignmentsStartContainer;
