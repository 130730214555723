import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Space } from 'antd';
import { EPath } from 'constants/routes';
import { defaultValidateMessages } from 'constants/validate/message';
import { TBaseLibState, baseLibraryModule } from 'modules/baseLibrary/baseLibrary.reducer';
import { Link } from 'react-router-dom';
import ModalUploadBaseLib from './ModalUploadBaseLib';
import styles from './style.module.scss';

type Props = {
  handleUploadFile: (data: { file: FormData; fileName: string }) => void;
  baseLibState: TBaseLibState;
  handleCreateBaseLib: (data: { name: string; fname: string }) => void;
  libId: number;
  getDetailBaseLib: (libId: number) => void;
  handleUpdateBaseLib: (data: { libId: number; name?: string; fname?: string }) => void;
  showErrorMissingFile: () => void;
};
const LibDetails = ({
  handleUploadFile,
  baseLibState,
  handleCreateBaseLib,
  libId,
  getDetailBaseLib,
  handleUpdateBaseLib,
  showErrorMissingFile,
}: Props) => {
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState<boolean>(false);
  useEffect(() => {
    if (libId) {
      getDetailBaseLib(Number(libId));
    }
  }, [libId]);

  useEffect(() => {
    baseLibraryModule.actions.setDataFileUpload({});
  }, []);

  useEffect(() => {
    if (libId) {
      form.setFieldValue('name', baseLibState.baseLibDetail.name);
    }
  }, [baseLibState.baseLibDetail, libId]);

  const create = (values: { name: string }) => {
    const { fname } = baseLibState.dataFileUpload;
    if (!libId && !fname) {
      showErrorMissingFile();
    }
    if (!libId && fname) {
      handleCreateBaseLib({ ...values, fname });
    }
  };
  const update = (values: { name: string }) => {
    const { fname } = baseLibState.dataFileUpload;
    const dataUpdate = fname ? { ...values, fname, libId } : { ...values, libId };
    handleUpdateBaseLib({ ...dataUpdate });
  };
  return (
    <>
      <ModalUploadBaseLib
        updateFile={handleUploadFile}
        open={openModal}
        setModal={setOpenModal}
        clearStateUpload={() => {}}
      />
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>ベースライブラリ登録</p>
            </div>
          }
          loading={false}
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space className={styles.topButtonGroup}>
                <Button className={styles.topButton}>
                  <Link to={EPath.S4_1}>ベースライブラリ管理に戻る</Link>
                </Button>
              </Space>
              <Form
                autoComplete="off"
                layout="vertical"
                form={form}
                onFinish={libId ? update : create}
                validateMessages={defaultValidateMessages}
                disabled={false}
              >
                <Form.Item>
                  <Space direction="vertical" align="start" className={styles.assignmentItem}>
                    <p className={styles.assignmentTitle}>ベースライブラリ名</p>
                    <Form.Item
                      name="name"
                      rules={[{ required: true, message: 'ベースライブラリ名を入力してください。' }]}
                      noStyle
                    >
                      <Input
                        className={styles.assignmentItemWidth}
                        placeholder="ベースライブラリ名を入力してください"
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>

                <div className={styles.body}>
                  <p>
                    ベースライブラリのファイルをアップロードしてください。 <br />
                    (JSON形式。添付可能な最大サイズは500KBです。)
                  </p>
                  <div className={styles.uploadGroup}>
                    <Button className={styles.topButton} onClick={() => setOpenModal(true)}>
                      ファイルを選択
                    </Button>
                    <p>
                      {baseLibState.dataFileUpload.originalName
                        ? baseLibState.dataFileUpload.originalName
                        : '選択されていません。'}
                    </p>
                  </div>
                </div>
                <Form.Item noStyle>
                  <button disabled={false} type="submit" className={styles.confirmButton}>
                    保存する
                  </button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default LibDetails;
