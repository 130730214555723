import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, InputRef, Space, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { TColumns, TQuestion, TQuestionIndex } from 'types/questions';
import { ColumnsType, FilterConfirmProps, TableRowSelection } from 'antd/lib/table/interface';

const SelectTable: React.FC<{
  data: TQuestion[];
  rowKey?: string;
  rowSelection: TableRowSelection<TQuestion>;
  columnsState: TColumns[];
}> = ({ data, rowKey, rowSelection, columnsState }) => {
  const [columns, setColumns] = useState<ColumnsType<TQuestion>>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: TQuestionIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
  };

  const getColumnSearchProps = (dataIndex: TQuestionIndex): ColumnType<TQuestion> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div role="presentation" style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      !!record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    if (columnsState && columnsState.length > 0) {
      const columns = columnsState.map((column) => {
        if (column.columnSearchProps) {
          return {
            title: column.title,
            dataIndex: column.dataIndex,
            ...getColumnSearchProps(column.columnSearchProps as keyof TQuestion),
          };
        }
        return column;
      });
      setColumns(columns);
    }
  }, [columnsState, searchText, searchedColumn]);

  return (
    <Table
      rowSelection={{
        ...rowSelection,
        type: 'radio',
      }}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={rowKey || 'questionId'}
    />
  );
};

export default SelectTable;
