import { DEFAULT_LIMIT_PAGINATION } from 'constants/pagination';
import { baseLibraryRepositories } from 'repositories/baseLibrary';
import { TOptionsQuery } from 'types/common';
import { TBaseLibary } from 'types/baseLibrary';

const DEFAULT_GROUPS_LIMIT = DEFAULT_LIMIT_PAGINATION;
const DEFAULT_GROUPS_PAGE = 1;

const getBaseLibraryList = async (params: TOptionsQuery<TBaseLibary>) => {
  const res = await baseLibraryRepositories.getBaseLibraryList({
    condition: {
      ...params,
      limit: params.limit ?? DEFAULT_GROUPS_LIMIT,
      page: params.page ?? DEFAULT_GROUPS_PAGE,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const getBaseLibraryDetail = async (libId: number) => {
  const res = await baseLibraryRepositories.getBaseLibrary({
    pathParameters: { libId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const getBaseLibrarySample = async (libId: number) => {
  const res = await baseLibraryRepositories.getBaseLibrary({
    pathParameters: { libId },
    condition: { withDetail: true },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const createBaseLibrary = async (data: { name?: string; fname?: string }) => {
  const res = await baseLibraryRepositories.createBaseLibrary({
    body: data,
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateBaseLibrary = async (data: { libId: number; name?: string; fname?: string }) => {
  const { libId, name, fname } = data;
  const res = await baseLibraryRepositories.updateBaseLibrary({
    body: { name, fname },
    pathParameters: { libId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const deleteBaseLibrary = async (libId: number) => {
  const res = await baseLibraryRepositories.deleteBaseLibrary({
    pathParameters: { libId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const uploadBaseLibraryFile = async (data: { file: FormData; fileName: string }) => {
  const { file, fileName } = data;
  const res = await baseLibraryRepositories.uploadBaseLibraryFile({
    body: file,
  });

  if (res instanceof Error) {
    throw res;
  }

  return { ...res.data, originalName: fileName };
};

const downloadBaseLibraryFile = async (libId: number) => {
  const res = await baseLibraryRepositories.downloadBaseLibraryFile({
    pathParameters: { libId },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

export const baseLibraryServices = {
  getBaseLibraryList,
  createBaseLibrary,
  uploadBaseLibraryFile,
  deleteBaseLibrary,
  getBaseLibraryDetail,
  updateBaseLibrary,
  downloadBaseLibraryFile,
  getBaseLibrarySample,
};
