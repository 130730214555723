import React from 'react';
import templateAction from 'modules/template/template.action';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import uploadAction from 'modules/upload/upload.action';
import groupsClassAction from 'modules/groupsClass/groupsClass.action';
import baseLibraryAction from 'modules/baseLibrary/baseLibrary.action';
import TemplateRfp from './TemplateRfp';
import { TTemplate } from './TemplateRfp.state';

const TemplateRfpContainer = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const baseLibState = useSelector((state: AppState) => state.pages.baseLibrary);
  const { currentGroup, groupDetail } = useSelector((state: AppState) => state.pages.groupClass);

  const filterStepsFromBaseLibrary = () => {
    const removeKey = (obj: any, key: string) => {
      const res = { ...obj };
      delete res[key];
      return res;
    };
    const removeIdPropertyFromObject = (obj: any) => {
      const updatedObject: Record<string, any> = {};
      Object.keys(obj).forEach((key: string) => {
        const item = obj[key];
        if (item._id !== undefined) {
          updatedObject[key] = removeKey(item, '_id');
        } else {
          updatedObject[key] = item; // If the "_id" property doesn't exist, copy it as-is
        }
      });
      return updatedObject;
    };
    const step4 = removeKey(baseLibState.dataSample.step4, '_id');
    const step5 = removeKey(baseLibState.dataSample.step5, '_id');
    const step7 = { ...baseLibState.dataSample.step7 }; // Delete _id of step7 because it gets caught in API validation
    const steps = {
      step2: baseLibState.dataSample.step2,
      step3: baseLibState.dataSample.step3,
      step4: removeIdPropertyFromObject(step4),
      step5: removeIdPropertyFromObject(step5),
      step6: baseLibState.dataSample.step6,
      step7: removeIdPropertyFromObject(step7),
    };
    return steps;
  };

  const creteTemplate = (data: TTemplate & { baseLibrary: number }, link?: string) => {
    if (currentGroup.id) {
      const steps = filterStepsFromBaseLibrary();
      const newData = {
        ...data,
        ...steps, // Force overwrite all steps by the selected baseLibrary
      };
      dispatch(templateAction.createTemplate({ data: newData, link, groupId: currentGroup.id }));
    }
  };

  const getDetailGr = () => {
    if (currentGroup.id) dispatch(groupsClassAction.getDetailGroupClass(currentGroup.id));
  };

  const getBaseLibraryDetail = (baseLibrary: number) => {
    if (currentGroup.id) {
      dispatch(baseLibraryAction.getBaseLibrarySample(baseLibrary));
    }
  };

  const updateTemplate = (
    data: TTemplate & { baseLibrary: number },
    id: string,
    buttonFooter: boolean,
    link?: string,
  ) => {
    if (currentGroup.id) {
      let newData = { ...data };
      if (data.baseLibrary !== template.baseLibrary) {
        // If BaseLibrary is changed
        const steps = filterStepsFromBaseLibrary();
        newData = {
          ...data,
          ...steps, // Force overwrite all steps by the selected baseLibrary
        };
      }
      dispatch(templateAction.updateTemplateStep1({ groupId: currentGroup.id, data: newData, id, buttonFooter, link }));
    }
  };

  const updateImage = (data: FormData) => {
    dispatch(uploadAction.uploadImage({ data }));
  };

  const clearStateUpload = () => {
    dispatch(uploadAction.clearState());
  };

  return (
    <TemplateRfp
      creteTemplate={creteTemplate}
      template={template}
      updateTemplate={updateTemplate}
      updateImage={updateImage}
      clearStateUpload={clearStateUpload}
      groupId={currentGroup.id ?? 0}
      getDetailGr={getDetailGr}
      groupDetail={groupDetail}
      getBaseLibraryDetail={getBaseLibraryDetail}
    />
  );
};

export default TemplateRfpContainer;
