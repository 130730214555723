import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const getTemplates: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'templates',
};

const getAllTemplates: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'templates/all',
};

const deleteTemplate: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'templates/:id',
};

export const templatesRepositories = {
  getTemplates: apiClient.getFn(getTemplates),
  getAllTemplates: apiClient.getFn(getAllTemplates),
  deleteTemplate: apiClient.deleteFn(deleteTemplate),
};
