import '@antv/s2-react/dist/style.min.css';
import { Col, Row } from 'antd';
import AchievementGoal from 'components/AchievementGoal/achievementGoal';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import SheetTable from 'components/SheetTable/SheetTable';
import {
  OPTIONS_TABLE_INVEST,
  OPTIONS_TABLE_VALUE,
  TConfigTab2Fn,
} from 'pages/Client/AssignmentsResults/Tabs/Tab2/assignmentResult2';
import React from 'react';

const Tab2: React.FC<{ configTable: TConfigTab2Fn }> = ({ configTable }) => (
  <>
    {/* Row1 */}
    <Row
      gutter={[
        { xs: 0, sm: 20 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle title="サマリー" />
      <Col xs={24} sm={18}>
        <SheetTable dataCfg={configTable.CONFIG_TABLE_VALUE} options={OPTIONS_TABLE_VALUE} />
      </Col>
      <Col xs={24} sm={6}>
        <AchievementGoal />
      </Col>
    </Row>
    {/* Row2 */}
    <Row
      gutter={[
        { xs: 0, sm: 30 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle
        title="１年目投資内容"
        subTitle="年ごとの黒字化や営業利益率、累計利益率に関する達成目標が未達の場合、下のグラフから問題となる年を判断しましょう。 ​"
      />
      <Col xs={24} sm={12}>
        <div>
          <p>投資額グラフ</p>
          <ColumnChart configChart={configTable.DATA_COLUMN_CHART_TAB2} />
        </div>
      </Col>
      <Col xs={24} sm={12}>
        <div style={{ height: '400px' }}>
          <p>投資内容</p>
          <SheetTable dataCfg={configTable.CONFIG_TABLE_INVEST} options={OPTIONS_TABLE_INVEST} />
        </div>
      </Col>
    </Row>
  </>
);

export default Tab2;
