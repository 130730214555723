/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Button, Card, Space, Table } from 'antd';
import MainButton from 'components/MainButton/MainButton';
import './style.css';
import { TOptionsQuery } from 'types/common';
import { TTrainingDetail, TAddTrainingBody } from 'types/assignments';
import { TAssignmentsState } from 'modules/assignments/assignments.reducer';
import { TUiState } from 'modules/ui/ui.reducer';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { createQueryUrl, parseSearchParams, replacePathParams } from 'libs/helpers/functions';
import { EPath } from 'constants/routes';
import ModalNoti from 'components/ModalNoti/ModalNoti';
import ModalDelete from 'components/ModalDelete/ModalDelete';
import { TGroupsClassDetail } from 'types/groupsClass';
import styles from './style.module.scss';
import { getImplementTrainingDate } from './AssignmentsAdm.state';

type Props = {
  getTrainings: (params: TOptionsQuery<TAddTrainingBody>) => void;
  assignmentsState: TAssignmentsState;
  uiState: TUiState;
  resetStateTemplate: () => void;
  deleteTraining: (data: { trainingId: string; groupId: number }) => void;
  currentGroup: TGroupsClassDetail;
};
type TModal = 'delete' | 'noti' | '';
const DELAY_TIME = 1;
const AssignmentsAdm = ({
  getTrainings,
  assignmentsState,
  uiState,
  resetStateTemplate,
  deleteTraining,
  currentGroup,
}: Props) => {
  const history = useHistory();
  const { trainings, pagination } = assignmentsState;
  const { isShowLoading } = uiState;
  const location = useLocation();
  const urlQueryParams: TOptionsQuery<TAddTrainingBody> = parseSearchParams(location.search);
  const [openModal, setOpenModal] = useState<TModal>('');
  const [trainngId, setTrainingId] = useState<string>('');
  useEffect(() => {
    if (getTrainings && currentGroup.id) {
      getTrainings({ ...urlQueryParams, groupId: currentGroup.id });
    }
  }, [location, currentGroup.id]);

  const onChangePage = (page: number, limit: number) => {
    history.push(createQueryUrl(location, { ...urlQueryParams, page, limit }));
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: '研修名',
      dataIndex: 'name',
      width: '25%',
    },
    {
      title: '受講者数',
      width: '10%',
      render: (_: any, record: TTrainingDetail) => record.students?.length,
    },
    {
      title: '実施期間',
      width: '25%',
      render: (_: any, record: TTrainingDetail) => getImplementTrainingDate(record).implementDate,
    },
    {
      title: '',
      width: '30%',
      render: (_: any, record: TTrainingDetail) => (
        <Space align="center">
          <Link to={replacePathParams(EPath.assignmentDetail, { training: record.id ?? '' })}>
            <MainButton subButton text="詳細" />
          </Link>
          <Link to={replacePathParams(EPath.assignmentEdit, { training: record.id ?? '' })}>
            <MainButton text="編集" />
          </Link>
          <Link
            to={replacePathParams(EPath.A10, {
              trainingId: record.id ?? '',
              studentId: record?.isCompleted?.[0]?.id || '',
            })}
            className={record?.isCompleted?.[0]?.id ? '' : styles.disabledLink}
          >
            <MainButton subButton text="結果" />
          </Link>
          <MainButton
            subButton
            text="削除"
            onClick={() => {
              setTrainingId(record.id ?? '');
              setOpenModal('delete');
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="assignmentsAdm">
      <ModalDelete
        title="確認"
        subTitle={
          <span>
            研修を削除してもよろしいでしょうか？
            <br />
            削除するとユーザーの回答結果も削除されます。
          </span>
        }
        open={openModal === 'delete'}
        onClose={() => setOpenModal('')}
        handleDelete={() => {
          setTimeout(() => {
            setOpenModal('noti');
          }, DELAY_TIME);
          if (currentGroup.id) {
            deleteTraining({ trainingId: trainngId, groupId: currentGroup.id });
          }
        }}
      />
      <ModalNoti
        title="確認"
        subTitle="研修を削除しました。"
        open={openModal === 'noti'}
        onClose={() => setOpenModal('')}
        handleOk={() => setOpenModal('')}
      />
      <div className={styles.assignments}>
        <Card
          className={styles.assignments__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>研修一覧</p>
            </div>
          }
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space>
                <Button>
                  <Link to={EPath.A1}>TOPに戻る</Link>
                </Button>
                <Button onClick={() => resetStateTemplate()}>
                  <Link to={EPath.assignmentAdd}>研修を登録する</Link>
                </Button>
              </Space>
              <div className={styles.table}>
                <Table
                  bordered
                  columns={columns}
                  dataSource={trainings}
                  pagination={false}
                  loading={isShowLoading}
                  rowKey="id"
                />
              </div>
            </div>
            <div className={styles.bottom}>
              <PaginationCustom
                current={pagination.page}
                total={pagination.totalResults}
                pageSize={pagination.limit}
                disabled={isShowLoading}
                onChange={onChangePage}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AssignmentsAdm;
