import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { ENavType } from 'constants/nav';
import EditTraining from 'libs/hook/EditTraining';
import { TAnswerTrainingResults, TTrainingResult } from 'types/trainingResult';
import ModalConfirm from 'components/ModalConfirm/ModalConfirm';
import { useHistory, useParams } from 'react-router-dom';
import commonRender from 'hook/commonRender';
import { IndexedObject } from 'constants/types';
import { TQuestions } from 'types/questions';
import { routers } from 'constants/router';
import { convertData, converDataAfterSave } from 'libs/helpers/convertData';
import HeaderTraining from 'components/HeaderTraining';
import CalculatorGradeForm from './CalculatorGradeForm';
import styles from './style.module.scss';
import { configTableData } from './AssignmentCalculatorGrade.state';

const AssignmentCalculatorGrade: React.FC<{
  trainingResult: TTrainingResult;
  updateTrainingResult: (data: TAnswerTrainingResults, id: string) => void;
  resetTrainingResult: () => void;
}> = ({ trainingResult, updateTrainingResult, resetTrainingResult }) => {
  const [nextStep, setNextStep] = useState(false);
  const [urlLink, setUrlLink] = useState<string | undefined>();
  const [configTable, setConfigTable] = useState<any>();
  const [trainingResultState, setTrainingResultState] = useState<TTrainingResult | null>();
  const [data, setData] = useState<TQuestions[]>([]);
  const [failedData, setFailedData] = useState<IndexedObject>({});
  const [showModal, setShowModal] = useState(false);
  const { trainingId } = useParams<{ trainingId: string }>();
  const [renderValue] = commonRender();
  const currentStep = 5;

  const [form] = Form.useForm();
  const history = useHistory();

  EditTraining();

  useEffect(() => {
    if (trainingResultState?.updated) {
      if (urlLink) {
        history.push(urlLink);
      } else if (nextStep) {
        history.push(routers.U7_6.path.replace(':trainingId', trainingId));
      } else {
        history.push(routers.U7_4.path.replace(':trainingId', trainingId));
      }
      resetTrainingResult();
    }
  }, [trainingResultState]);

  useEffect(() => {
    if (trainingResult.training?.template) {
      setTrainingResultState(trainingResult);
      setConfigTable(configTableData(trainingResult.training?.template ?? {}, trainingResult));
    }
  }, [trainingResult]);

  useEffect(() => {
    if (trainingResult?.training?.template?.step3) {
      const step = trainingResult?.training?.template?.step3.filter((assignment) => assignment.step === currentStep);
      setData(step);
      if (trainingResult?.step5?.answer) {
        form.setFieldsValue(convertData(trainingResult?.step5?.answer));
      }
    }
  }, [trainingId, trainingResult]);

  const onFinish = (values: IndexedObject) => {
    const answer = converDataAfterSave(data, values);
    updateTrainingResult({ isCompleted: true, answer }, trainingId);
  };

  const onFinishFailed = (errorInfo: IndexedObject) => {
    setShowModal(true);
    setFailedData(errorInfo.values);
  };

  const onClickPreStep = () => {
    form.submit();
  };

  const onClickNextStep = () => {
    setNextStep(true);
    form.submit();
  };

  const handelConfirm = () => {
    const answer = converDataAfterSave(data, failedData);
    updateTrainingResult({ isCompleted: false, answer }, trainingId);
  };

  const onChangeValue = (changed: IndexedObject, values: IndexedObject) => {
    const answer = converDataAfterSave(data, values);
    if (trainingResultState && trainingResultState.step5) {
      const newData = { ...trainingResultState, step5: { ...trainingResultState.step5, answer } };
      setTrainingResultState(newData);
      setConfigTable(configTableData(trainingResultState.training?.template ?? {}, newData));
    }
  };

  const clickChangeRoute = (link: string) => {
    form.submit();
    setUrlLink(link);
  };
  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <HeaderTraining />
        <NavStep type={ENavType.student} onClick={clickChangeRoute} />
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div className={styles.contentWrap__left}>
              <div className={styles.contentTitle}>設問</div>
              <div className={styles.form}>
                <Form
                  form={form}
                  onValuesChange={onChangeValue}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  {data.map((questions) => questions.questions.map((question) => renderValue(question)))}
                </Form>
              </div>
            </div>
            <div className={styles.contentWrap__right}>
              <div className={styles.contentTitle}>参考情報</div>
              <div className={styles.form}>{configTable && <CalculatorGradeForm configTable={configTable} />}</div>
            </div>
          </div>
        </div>
      </div>
      <ButtonFooter handelClickNext={onClickNextStep} handelClickPre={onClickPreStep} />
      <ModalConfirm
        open={showModal}
        onClose={() => setShowModal(false)}
        handleConfirm={handelConfirm}
        title="確認"
        subTitle="設問回答がすべて終了していません。ここまでの設問回答を保存して他のページに移動しますか？"
      />
    </>
  );
};

export default AssignmentCalculatorGrade;
