import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { ENavType } from 'constants/nav';
import EditableRow from 'components/EditTableColRow/EditableRow';
import ButtonMoveA3 from 'components/ButtonMoveA3';
import { TBasicYield, TYieldIncrementRatio, TYieldIncrementRatioSetting } from 'types/setting';
import EditableCellProps from 'components/EditTableColRow/EditableCellProps';
import { TTemplateState } from 'modules/template/template.reducer';
import { TBaseLibState } from 'modules/baseLibrary/baseLibrary.reducer';
import EditTemplate from 'libs/hook/EditTemplate';
import {
  columnBasicYeild,
  ColumnTypes,
  columnYieldIncrementRatioSetting,
  TStep5,
} from './AdmAddCalculationSettingsGrade.state';
import styles from './style.module.scss';

const AdmAddCalculationSettingsGrade: React.FC<{
  updateTemplate: (data: TStep5, id: string, buttonFooter: boolean, link?: string) => void;
  template: TTemplateState;
  baseLibState: TBaseLibState;
}> = ({ updateTemplate, template, baseLibState }) => {
  const [basicYield, setBasicYield] = useState<TBasicYield[] | []>([]);
  const [yieldIncrementRatioSetting, setYieldIncrementRatioSetting] = useState<TYieldIncrementRatio[] | []>([]);

  EditTemplate();

  useEffect(() => {
    if (template && template.step5) {
      setBasicYield(template.step5.basicYield);
      setYieldIncrementRatioSetting(template.step5.yieldIncrementRatio);
    }
  }, [template, baseLibState]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCellProps,
    },
  };

  const columns = (
    defaultColumns: ColumnTypes,
    handleSave: ((str: TBasicYield) => void) | ((str: TYieldIncrementRatioSetting) => void),
  ): ColumnTypes =>
    defaultColumns.map((col: any) => {
      if (!col.editable) {
        return col;
      }
      const newCol = {
        ...col,
        onCell: (record: any) => ({
          record,
          editable: true,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
      return newCol;
    });

  const handlebacsicYield = (row: TBasicYield) => {
    const newData = [...basicYield];
    const index = newData.findIndex((item) => row.month === item.month);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setBasicYield([...newData]);
  };

  const handleYieldIncrementRatio = (row: TYieldIncrementRatioSetting) => {
    const data = yieldIncrementRatioSetting.map((yieldIncrementRatio) => {
      const newSettings = yieldIncrementRatio.settings.map((item) => {
        if (item.name === row.name) {
          return { ...row };
        }
        return item;
      });
      return { ...yieldIncrementRatio, settings: newSettings };
    });
    setYieldIncrementRatioSetting([...data]);
  };

  const onFinish = (buttonFooter: boolean = false, link?: string) => {
    const data: TStep5 = {
      step5: {
        basicYield,
        yieldIncrementRatio: [...yieldIncrementRatioSetting],
      },
    };
    updateTemplate(data, template.id, buttonFooter, link);
  };

  const onClickChangeRouter = (link: string) => {
    const buttonFooter = false;
    onFinish(buttonFooter, link);
  };
  return (
    <>
      <div className={styles.admAddCalculationSettingsGradeWrap}>
        <Row>
          <Col span={12}>
            <Row>
              <p>研修テンプレート登録</p>
              <p className={styles.title}>{template.name}</p>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <ButtonMoveA3 />
            </Row>
          </Col>
        </Row>
        <NavStep type={ENavType.teacher} onClick={onClickChangeRouter} />
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div className={styles.contentWrap__left}>
              <div className={styles.contentTitle}>月別の等級の配分設定</div>
              <div className={styles.description}>
                <p>月別の等級の試算設定を行います。</p>
                <p>各月の等級の配分[%]を入力してください。</p>
              </div>
              <div className={styles.form}>
                <Table
                  columns={columns(columnBasicYeild, handlebacsicYield)}
                  dataSource={basicYield}
                  pagination={false}
                  rowClassName={() => 'editable-row'}
                  bordered
                  components={components}
                  rowKey="month"
                />
              </div>
            </div>
            <div className={styles.contentWrap__right}>
              <div className={styles.contentTitle}>投資項目別の等級の重み付け設定</div>
              <div className={styles.description}>
                <p>投資項目が導入された場合の等級の増加量を設定します。</p>
                <p>増加量は1~99の整数を入力してください。</p>
              </div>
              {yieldIncrementRatioSetting.length &&
                yieldIncrementRatioSetting.map((item: TYieldIncrementRatio) => (
                  <div className={styles.form} key={item.title}>
                    <p className={styles.titleCategory}>{item.title}</p>
                    <div className={styles.divTable}>
                      <div className={styles.wrapDivTable}>
                        <Table
                          columns={columns(columnYieldIncrementRatioSetting, handleYieldIncrementRatio)}
                          dataSource={item.settings ?? []}
                          pagination={false}
                          rowClassName={() => 'editable-row'}
                          bordered
                          components={components}
                          rowKey="name"
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <ButtonFooter handelClickNext={onFinish} handelClickPre={onFinish} />
    </>
  );
};

export default AdmAddCalculationSettingsGrade;
