import templateAction from 'modules/template/template.action';
import templatesAction from 'modules/templates/templates.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { TOptionsQuery } from 'types/common';
import { TTemplateDetail } from 'types/templates';
import TemplatesAdm from './TemplatesAdm';

const TemplatesContainer = () => {
  const dispatch = useDispatch();
  const templatesState = useSelector((state: AppState) => state.pages.templates);
  const uiState = useSelector((state: AppState) => state.pages.ui);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);

  const getTemplatesFinished = (params: TOptionsQuery<TTemplateDetail>) =>
    dispatch(templatesAction.getTemplates({ ...params, isCompleted: true }));
  const getAllTemplatesUnFinished = (groupId: number) => {
    dispatch(templatesAction.getAllTemplates({ isCompleted: false, groupId }));
  };
  const deleteTemplate = (data: { templateId: string; groupId: number }) =>
    dispatch(templatesAction.deleteTemplate(data));
  const resetTemplate = () => dispatch(templateAction.clearTemplate());

  return (
    <TemplatesAdm
      templatesState={templatesState}
      currentGroup={currentGroup}
      uiState={uiState}
      getTemplatesFinished={getTemplatesFinished}
      getAllTemplatesUnFinished={getAllTemplatesUnFinished}
      deleteTemplate={deleteTemplate}
      resetTemplate={resetTemplate}
    />
  );
};

export default TemplatesContainer;
