import { TAnswerTrainingResults, TTrainingResult } from 'types/trainingResult';
import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory();

export default {
  createTrainingResult: ac<{ data: TTrainingResult & { groupId: number } }>('createTrainingResult'),
  updateTrainingResult: ac<{ groupId: number; data: TAnswerTrainingResults; id: string; step?: string }>(
    'updateTrainingResult',
  ),
  getTrainingResultByTraining: ac<{ trainingId: string; groupId: number }>('getTrainingResultByTraining'),
  resetStateTraining: ac('resetStateTraining'),
  getTrainingResultByTrainingAndStudent: ac<{ trainingId: string; studentId: string; groupId: number }>(
    'getTrainingResultByTrainingAndStudent',
  ),
};
