import React from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import { TSelectOptions } from 'types/common';
import styles from './style.module.scss';

const CommonRadio: React.FC<{
  type?: string;
  radioOptions: TSelectOptions;
  onChange?: (e: RadioChangeEvent) => void;
  value?: string;
  disabled?: boolean;
}> = ({ type, radioOptions, onChange, value, disabled }) => (
  <Radio.Group onChange={onChange} defaultValue={value} disabled={disabled}>
    {radioOptions &&
      radioOptions.map((option) => (
        <Radio className={type === 'vertical' ? styles.vertical : ''} value={option.value} key={option.value}>
          {option.name}
        </Radio>
      ))}
  </Radio.Group>
);

export default CommonRadio;
