import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { assignmentsServices } from 'services/assignments';
import { TTrainingDetail } from 'types/assignments';
import { AxiosResponse, TResponsePagination } from 'libs/axios';
import { uiModule } from 'modules/ui/ui.reducer';
import { messageModule } from 'modules/message/message.reducer';
import { push } from 'react-router-redux';
import { EPath } from 'constants/routes';
import { assignmentsModule } from './assignments.reducer';
import actions from './assignments.action';

export const getTrainingsEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getTrainings),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.getTrainings(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (t: TResponsePagination<TTrainingDetail>) => [
          assignmentsModule.actions.saveTrainings(t),
          assignmentsModule.actions.setParams(payload),
        ],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getTrainingEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getTraining),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.getTraining(payload.id, payload.groupId)),
        complete: uiModule.actions.hideLoading(),
        next: (t: TTrainingDetail) => [assignmentsModule.actions.saveTrainingDetail(t)],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const createTrainingEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.createTraining),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFns: from(assignmentsServices.createTraining(payload)),
        next: () => [push(EPath.assignments), messageModule.actions.setSuccess('')],
        previous: uiModule.actions.showLoading(),
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTrainingEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTraining),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.updateTraining(payload)),
        complete: uiModule.actions.hideLoading(),
        next: () => [push(EPath.assignments), messageModule.actions.setSuccess('')],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const deleteTrainingEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.deleteTraining),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.deleteTraining(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: TTrainingDetail) => [
          assignmentsModule.actions.deleteTraining(payload.trainingId),
          messageModule.actions.setSuccess(''),
        ],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getTrainingsWorkingEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getTrainingsWorking),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.getTrainingsWorking(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (t: TResponsePagination<TTrainingDetail>) => assignmentsModule.actions.saveTrainingsWorking(t),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getTrainingsFinishEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getTrainingsFinish),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(assignmentsServices.getTrainingsFinish(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (t: TResponsePagination<TTrainingDetail>) => assignmentsModule.actions.saveTrainingsFinish(t),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
