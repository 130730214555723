import React from 'react';
import AchievementGoal from 'components/AchievementGoal/achievementGoal';
import { Col, Row } from 'antd';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import SheetTable from 'components/SheetTable/SheetTable';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import { OPTIONS_TABLE, TConfigTab3Fn } from './assignmentsResultsTab3';
import '@antv/s2-react/dist/style.min.css';
import styles from '../style.module.scss';

const Tab3: React.FC<{ configTable: TConfigTab3Fn }> = ({ configTable }) => (
  <>
    {/* Row1 */}
    <Row
      gutter={[
        { xs: 0, sm: 20 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle title="サマリー" />
      <Col xs={24} sm={18}>
        <div>
          <div className={styles.heightText}>
            <p>現金残高の推移です。</p>
            <p>
              下図の資金繰り計画表で赤色の月に現金が足らなくなり倒産します。赤色表示がある場合は、設定を見直しましょう。
            </p>
            <p>対応として以下のことを考えましょう。</p>
          </div>
          <div className={styles.heightText}>
            <p>・銀行からの借り入れを増やす。ただし利息があるため営業外費用が増加します。</p>
            <p>・費用を抑えるため、初期投資内容、人件費を見直す。</p>
            <p>・売上をあげるため、販売価格等を見直す。</p>
          </div>
        </div>
        <div className={styles.subTitle}>
          <div className="left">資金繰計画表</div>
        </div>
        <div style={{ height: '250px' }}>
          {configTable.CONFIG_TABLE && <SheetTable dataCfg={configTable.CONFIG_TABLE} options={OPTIONS_TABLE} />}
        </div>
      </Col>
      <Col xs={24} sm={6}>
        <AchievementGoal />
      </Col>
      <Col span={24}>
        <div className={styles.subTitle}>
          <div className="left">現金残高 (円）</div>
        </div>
        <ColumnChart configChart={configTable.DATA_COLUMN_CHART_SLIDER} />
      </Col>
    </Row>
  </>
);

export default Tab3;
