/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Upload, Modal, Space, UploadFile, UploadProps, message } from 'antd';
import MainButton from 'components/MainButton/MainButton';
import { RcFile, UploadListType } from 'antd/lib/upload/interface';
import { AppState } from 'store';
import { useSelector } from 'react-redux';
import { messages } from 'constants/validate/message';
import { UploadOutlined } from '@ant-design/icons';
import styles from './style.module.scss';

const ModalUpload: React.FC<{
  open: boolean;
  setModal: (active: boolean) => void;
  updateImage?: (data: FormData) => void;
  clearStateUpload: () => void;
}> = ({ open, setModal, updateImage, clearStateUpload }) => {
  const { uploaded } = useSelector((state: AppState) => state.pages.upload);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList);

  useEffect(() => {
    if (uploaded) {
      setModal(false);
      clearStateUpload();
    }
  }, [uploaded]);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
  };

  const handleUpload = (fileList: UploadFile[]) => {
    const isJpgOrPng =
      fileList[0].type === 'image/jpeg' || fileList[0].type === 'image/png' || fileList[0].type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error(messages.fileTooLarge);
    }
    const isLt2M = fileList[0]?.size ? fileList[0]?.size / 1024 / 1024 < 5 : undefined;
    if (!isLt2M) {
      message.error(messages.fileTooLarge);
    }
    if (isJpgOrPng && isLt2M && updateImage) {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append('image', file.originFileObj as RcFile);
      });
      updateImage(formData);
    }
  };

  return (
    <Modal
      title="ファイルのアップロード"
      centered
      open={open}
      onOk={() => setModal(false)}
      onCancel={() => setModal(false)}
      footer={null}
    >
      <Upload.Dragger maxCount={1} {...props} onChange={handleChange} listType={'picture' as UploadListType}>
        <p className="ant-upload-drag-icon">
          <img src="/images/icon-wrapper-h.png" alt="icon-wrapper-h" />
        </p>
        <p className="ant-upload-text">ここにファイルをドラッグ＆ドロップ</p>
        <p className="ant-upload-hint">またはここをクリックし、ファイルを指定してください。</p>
      </Upload.Dragger>
      <div className={styles.buttonUpload}>
        <Space>
          <MainButton
            text={
              <span>
                <UploadOutlined /> このファイルをアップロード
              </span>
            }
            onClick={() => handleUpload(fileList)}
          />
        </Space>
      </div>
    </Modal>
  );
};

export default ModalUpload;
