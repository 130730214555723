import trainingResultAction from 'modules/trainingResult/trainingResult.action';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import AssignmentsResults from './AssignmentsResults';

const AssignmentsResultsContainer: React.FC<{ admPage?: boolean }> = ({ admPage }) => {
  const dispatch = useDispatch();
  const { trainingResult } = useSelector((state: AppState) => state.pages);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);
  const getTrainingResult = (data: { trainingId: string; groupId: number }) => {
    dispatch(trainingResultAction.getTrainingResultByTraining(data));
  };
  return (
    <AssignmentsResults
      admPage={admPage}
      trainingResult={trainingResult}
      getTrainingResult={getTrainingResult}
      currentGroup={currentGroup}
    />
  );
};

export default AssignmentsResultsContainer;
