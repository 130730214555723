import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MESSAGE_ERROR_DEFAULT, MESSAGE_SUCCESS_DEFAULT } from 'constants/notifications/message';

const initialState = {
  success: false,
  error: false,
  message: '',
};

type TMessageState = typeof initialState;

const _module = createSlice({
  name: '[message/messageReducers]',
  initialState,
  reducers: {
    setSuccess(state: TMessageState, action: PayloadAction<string>) {
      return {
        error: false,
        success: true,
        message: action.payload ? action.payload : MESSAGE_SUCCESS_DEFAULT,
      };
    },
    setError(state: TMessageState, action: PayloadAction<string>) {
      return {
        success: false,
        error: true,
        message: action.payload ? action.payload : MESSAGE_ERROR_DEFAULT,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const messageModule = _module;
