/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Row, Card, Form, Input } from 'antd';
import { checkPassword } from 'constants/validate';
import { customValidateMessages, defaultValidateMessages } from 'constants/validate/message';
import MainButton from '../../components/MainButton/MainButton';
import styles from './style.module.scss';

const ResetPassword: React.FC<{ resetPassword: (newPassword: string) => void }> = ({ resetPassword }) => {
  const [form] = Form.useForm();

  const handelSubmit = (values: { password: string; confirmPassword: string }) => {
    resetPassword(values.password);
  };

  return (
    <Row justify="center" align="middle" style={{ height: '100%' }}>
      <Card type="inner" title="パスワードの再設定" className={styles.cardWrap}>
        <p className={styles.text}>新しいパスワードを入力して、パスワードを再設定して下さい。</p>
        <div className={styles.formWarp}>
          <Form layout="vertical" form={form} onFinish={handelSubmit}>
            <Form.Item
              label="新しいパスワード"
              name="password"
              style={{ marginBottom: '16px', marginTop: '25px' }}
              rules={[{ pattern: checkPassword, message: customValidateMessages.validatePassword }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              label="新しいパスワード（確認用）"
              name="confirmPassword"
              style={{ marginBottom: '16px', marginTop: '25px' }}
              rules={[
                {
                  required: true,
                  message: defaultValidateMessages.required,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(customValidateMessages.rePasswordNotMatch));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <MainButton htmlType="submit" text="変更" />
            </Form.Item>
          </Form>
        </div>
      </Card>
    </Row>
  );
};

export default ResetPassword;
