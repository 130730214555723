import React, { useEffect } from 'react';
import { Modal, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { TGroupsClassDetail } from 'types/groupsClass';
import MainButton from 'components/MainButton/MainButton';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import { TOptionsQuery, TPagination } from 'types/common';
import { TUserDetail } from 'types/groups';
import { useLocation } from 'react-router-dom';
import { saveGroupToLS } from 'libs/helpers/functions';
import { groupsClassModule } from 'modules/groupsClass/groupsClass.reducer';
import groupsClassAction from 'modules/groupsClass/groupsClass.action';
import { EPath } from 'constants/routes';
import styles from './style.module.scss';

const ModalGroups: React.FC<{
  title: string;
  open: boolean;
  onClose: () => void;
  loading: boolean;
  afterClose?: () => void;
  data: Array<TGroupsClassDetail>;
  pagination: Partial<TPagination>;
  currentUser: TUserDetail;
}> = ({ open, onClose, title, afterClose, data, pagination, loading, currentUser }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const columnGroupTable = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: 'グループ名',
      dataIndex: 'name',
      width: '30%',
    },
    {
      title: '',
      width: '10%',
      render: (_: any, group: TGroupsClassDetail) => (
        <div className={styles.actionBtn}>
          <MainButton text="選択" onClick={() => onSelectGroup(group)} />
        </div>
      ),
    },
  ];

  const getGroupsClass = (params: TOptionsQuery<TGroupsClassDetail>) => {
    dispatch(groupsClassAction.getGroupsClassModal({ ...params }));
  };
  const onSelectGroup = (group: TGroupsClassDetail) => {
    dispatch(groupsClassModule.actions.saveCurrentGroup(group));
    saveGroupToLS(group);
    dispatch(groupsClassModule.actions.setModal(''));
  };
  const onChangePage = (page: number, limit: number) => {
    dispatch(groupsClassModule.actions.savePagingModal({ page, limit }));
    getGroupsClass({ page, limit });
  };

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0 && location.pathname !== EPath.login) {
      const { page, limit } = pagination;
      getGroupsClass({ page, limit });
    }
  }, [open, currentUser]);
  return (
    <Modal title={title} centered open={open} onCancel={onClose} afterClose={afterClose} footer={null} width="65%">
      <div className={styles.contentModal}>
        <div className={styles.tableWrapper}>
          <Table bordered columns={columnGroupTable} dataSource={data} pagination={false} loading={false} rowKey="id" />
        </div>
        <div className={styles.paginationWrapper}>
          <PaginationCustom
            current={pagination.page ?? 1}
            total={pagination.totalResults ?? 0}
            pageSize={pagination.limit ?? 10}
            disabled={loading}
            onChange={onChangePage}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalGroups;
