/* eslint-disable complexity */
import { IndexedObject } from 'constants/types';
import { Calculator, TTargetYear } from 'libs/calculators';
import { TTemplate } from 'types/template';
import { TTrainingResult } from 'types/trainingResult';
import { addCommas, getValueOrDefault, numberWithCommas, removeCommas } from '../../../../../libs/helpers/functions';

export const configTableFnTab1 = (
  template: TTemplate,
  trainingResult: TTrainingResult,
  year1: TTargetYear,
  year2: TTargetYear,
) => {
  const cal = new Calculator(template, trainingResult);
  cal.calY117All();
  const configTable = {
    fields: {
      rows: ['title'],
      values: ['col1', 'col2', 'col3', 'col4', 'col5'],
    },
    meta: [
      {
        field: 'title',
        name: ' ',
      },
      {
        field: 'col1',
        name: '1年目',
      },
      {
        field: 'col2',
        name: '2年目',
      },
      {
        field: 'col3',
        name: '3年目',
      },
      {
        field: 'col4',
        name: '4年目',
      },
      {
        field: 'col5',
        name: '5年目',
      },
    ],
    data: [
      {
        title: '売上高',
        col1: numberWithCommas(cal.Y101(1)),
        col2: numberWithCommas(cal.Y101(2)),
        col3: numberWithCommas(cal.Y101(3)),
        col4: numberWithCommas(cal.Y101(4)),
        col5: numberWithCommas(cal.Y101(5)),
      },
      {
        title: '売上原価',
        col1: numberWithCommas(cal.Y102(1)),
        col2: numberWithCommas(cal.Y102(2)),
        col3: numberWithCommas(cal.Y102(3)),
        col4: numberWithCommas(cal.Y102(4)),
        col5: numberWithCommas(cal.Y102(5)),
      },
      {
        title: '売上総利益',
        col1: numberWithCommas(cal.Y103(1)),
        col2: numberWithCommas(cal.Y103(2)),
        col3: numberWithCommas(cal.Y103(3)),
        col4: numberWithCommas(cal.Y103(4)),
        col5: numberWithCommas(cal.Y103(5)),
      },
      {
        title: '粗利率',
        col1: addCommas(cal.Y104(1), '%', 0),
        col2: addCommas(cal.Y104(2), '%', 0),
        col3: addCommas(cal.Y104(3), '%', 0),
        col4: addCommas(cal.Y104(4), '%', 0),
        col5: addCommas(cal.Y104(5), '%', 0),
      },
      {
        title: '販売管理費',
        col1: numberWithCommas(cal.Y105(1)),
        col2: numberWithCommas(cal.Y105(2)),
        col3: numberWithCommas(cal.Y105(3)),
        col4: numberWithCommas(cal.Y105(4)),
        col5: numberWithCommas(cal.Y105(5)),
      },
      {
        title: '営業利益',
        col1: numberWithCommas(cal.Y106(1)),
        col2: numberWithCommas(cal.Y106(2)),
        col3: numberWithCommas(cal.Y106(3)),
        col4: numberWithCommas(cal.Y106(4)),
        col5: numberWithCommas(cal.Y106(5)),
      },
      {
        title: '営業利益率',
        col1: addCommas(cal.Y107(1), '%', 0),
        col2: addCommas(cal.Y107(2), '%', 0),
        col3: addCommas(cal.Y107(3), '%', 0),
        col4: addCommas(cal.Y107(4), '%', 0),
        col5: addCommas(cal.Y107(5), '%', 0),
      },
      {
        title: '営業外費用',
        col1: numberWithCommas(cal.Y108(1)),
        col2: numberWithCommas(cal.Y108(2)),
        col3: numberWithCommas(cal.Y108(3)),
        col4: numberWithCommas(cal.Y108(4)),
        col5: numberWithCommas(cal.Y108(5)),
      },
      {
        title: '経常利益',
        col1: numberWithCommas(cal.Y109(1)),
        col2: numberWithCommas(cal.Y109(2)),
        col3: numberWithCommas(cal.Y109(3)),
        col4: numberWithCommas(cal.Y109(4)),
        col5: numberWithCommas(cal.Y109(5)),
      },
      {
        title: '経常利益率',
        col1: addCommas(cal.Y110(1), '%', 0),
        col2: addCommas(cal.Y110(2), '%', 0),
        col3: addCommas(cal.Y110(3), '%', 0),
        col4: addCommas(cal.Y110(4), '%', 0),
        col5: addCommas(cal.Y110(5), '%', 0),
      },
      {
        title: '特別利益',
        1: numberWithCommas(cal.Y111(1)),
        2: numberWithCommas(cal.Y111(2)),
        3: numberWithCommas(cal.Y111(3)),
        4: numberWithCommas(cal.Y111(4)),
        5: numberWithCommas(cal.Y111(5)),
      },
      {
        title: '特別損失',
        col1: numberWithCommas(cal.Y112()),
        col2: numberWithCommas(cal.Y112()),
        col3: numberWithCommas(cal.Y112()),
        col4: numberWithCommas(cal.Y112()),
        col5: numberWithCommas(cal.Y112()),
      },
      {
        title: '税引前当期純利益',
        col1: numberWithCommas(cal.Y113(1)),
        col2: numberWithCommas(cal.Y113(2)),
        col3: numberWithCommas(cal.Y113(3)),
        col4: numberWithCommas(cal.Y113(4)),
        col5: numberWithCommas(cal.Y113(5)),
      },
      {
        title: '法人税等',
        col1: numberWithCommas(cal.Y114(1)),
        col2: numberWithCommas(cal.Y114(2)),
        col3: numberWithCommas(cal.Y114(3)),
        col4: numberWithCommas(cal.Y114(4)),
        col5: numberWithCommas(cal.Y114(5)),
      },
      {
        title: '当期純利益',
        col1: numberWithCommas(cal.Y115(1)),
        col2: numberWithCommas(cal.Y115(2)),
        col3: numberWithCommas(cal.Y115(3)),
        col4: numberWithCommas(cal.Y115(4)),
        col5: numberWithCommas(cal.Y115(5)),
      },
      {
        title: '当期純利益率',
        col1: addCommas(cal.Y116(1), '%', 0),
        col2: addCommas(cal.Y116(2), '%', 0),
        col3: addCommas(cal.Y116(3), '%', 0),
        col4: addCommas(cal.Y116(4), '%', 0),
        col5: addCommas(cal.Y116(5), '%', 0),
      },
      {
        title: '累計利益',
        col1: numberWithCommas(cal.Y117(1)),
        col2: numberWithCommas(cal.Y117(2)),
        col3: numberWithCommas(cal.Y117(3)),
        col4: numberWithCommas(cal.Y117(4)),
        col5: numberWithCommas(cal.Y117(5)),
      },
    ],
  };
  const dataLineChart = {
    data: [
      { year: '1 年目', value: getValueOrDefault(cal.Y106(1)), profit: '営業利益' },
      { year: '2 年目', value: getValueOrDefault(cal.Y106(2)), profit: '営業利益' },
      { year: '3 年目', value: getValueOrDefault(cal.Y106(3)), profit: '営業利益' },
      { year: '4 年目', value: getValueOrDefault(cal.Y106(4)), profit: '営業利益' },
      { year: '5 年目', value: getValueOrDefault(cal.Y106(5)), profit: '営業利益' },

      { year: '1 年目', value: getValueOrDefault(cal.Y109(1)), profit: '経常利益' },
      { year: '2 年目', value: getValueOrDefault(cal.Y109(2)), profit: '経常利益' },
      { year: '3 年目', value: getValueOrDefault(cal.Y109(3)), profit: '経常利益' },
      { year: '4 年目', value: getValueOrDefault(cal.Y109(4)), profit: '経常利益' },
      { year: '5 年目', value: getValueOrDefault(cal.Y109(5)), profit: '経常利益' },

      { year: '1 年目', value: getValueOrDefault(cal.Y115(1)), profit: '当期純利益' },
      { year: '2 年目', value: getValueOrDefault(cal.Y115(2)), profit: '当期純利益' },
      { year: '3 年目', value: getValueOrDefault(cal.Y115(3)), profit: '当期純利益' },
      { year: '4 年目', value: getValueOrDefault(cal.Y115(4)), profit: '当期純利益' },
      { year: '5 年目', value: getValueOrDefault(cal.Y115(5)), profit: '当期純利益' },

      { year: '1 年目', value: getValueOrDefault(cal.Y117(1)), profit: '累計利益' },
      { year: '2 年目', value: getValueOrDefault(cal.Y117(2)), profit: '累計利益' },
      { year: '3 年目', value: getValueOrDefault(cal.Y117(3)), profit: '累計利益' },
      { year: '4 年目', value: getValueOrDefault(cal.Y117(4)), profit: '累計利益' },
      { year: '5 年目', value: getValueOrDefault(cal.Y117(5)), profit: '累計利益' },

      { year: '1 年目', value: getValueOrDefault(cal.Y101(1)) * 0.1, profit: '売上×10%の線' },
      { year: '2 年目', value: getValueOrDefault(cal.Y101(2)) * 0.1, profit: '売上×10%の線' },
      { year: '3 年目', value: getValueOrDefault(cal.Y101(3)) * 0.1, profit: '売上×10%の線' },
      { year: '4 年目', value: getValueOrDefault(cal.Y101(4)) * 0.1, profit: '売上×10%の線' },
      { year: '5 年目', value: getValueOrDefault(cal.Y101(5)) * 0.1, profit: '売上×10%の線' },

      { year: '1 年目', value: getValueOrDefault(cal.Y101(1)) * 0.05, profit: '売上×5%の線' },
      { year: '2 年目', value: getValueOrDefault(cal.Y101(2)) * 0.05, profit: '売上×5%の線' },
      { year: '3 年目', value: getValueOrDefault(cal.Y101(3)) * 0.05, profit: '売上×5%の線' },
      { year: '4 年目', value: getValueOrDefault(cal.Y101(4)) * 0.05, profit: '売上×5%の線' },
      { year: '5 年目', value: getValueOrDefault(cal.Y101(5)) * 0.05, profit: '売上×5%の線' },
    ],
    height: 400,
    xField: 'year',
    yField: 'value',
    point: {
      size: 5,
      shape: 'diamond',
    },
    yAxis: {
      label: {
        formatter: (v: string) => numberWithCommas(Number(v)),
        autoRotate: true,
      },
    },
    tooltip: {
      formatter: (datum: IndexedObject) => ({ name: datum.profit, value: numberWithCommas(datum.value) }),
    },
    seriesField: 'profit',
    color: (profit: any) => {
      if (profit.profit === '営業利益') {
        return '#B8860B';
      }
      if (profit.profit === '経常利益') {
        return '#008000';
      }
      if (profit.profit === '当期純利益') {
        return '#DC143C';
      }
      if (profit.profit === '累計利益') {
        return '#6495ED';
      }

      return '#A9A9A9';
    },
    lineStyle: (profit: any) => {
      if (profit.profit === '売上×10%の線') {
        return { lineDash: [2, 4] };
      }
      if (profit.profit === '売上×5%の線') {
        return { lineDash: [6, 4] };
      }
      return {
        opacity: 1,
      };
    },
  };

  const columnData = [
    {
      year: '1年目',
      value: getValueOrDefault(cal.Y102(1)),
      title: '売上原価',
    },
    {
      year: '2年目',
      value: getValueOrDefault(cal.Y102(2)),
      title: '売上原価',
    },
    {
      year: '3年目',
      value: getValueOrDefault(cal.Y102(3)),
      title: '売上原価',
    },
    {
      year: '4年目',
      value: getValueOrDefault(cal.Y102(4)),
      title: '売上原価',
    },
    {
      year: '5年目',
      value: getValueOrDefault(cal.Y102(5)),
      title: '売上原価',
    },

    {
      year: '1年目',
      value: getValueOrDefault(cal.Y108(1)),
      title: '営業外費用',
    },
    {
      year: '2年目',
      value: getValueOrDefault(cal.Y108(2)),
      title: '営業外費用',
    },
    {
      year: '3年目',
      value: getValueOrDefault(cal.Y108(3)),
      title: '営業外費用',
    },
    {
      year: '4年目',
      value: getValueOrDefault(cal.Y108(4)),
      title: '営業外費用',
    },
    {
      year: '5年目',
      value: getValueOrDefault(cal.Y108(5)),
      title: '営業外費用',
    },
    {
      year: '1年目',
      value: getValueOrDefault(cal.Y105(1)),
      title: '販売管理費',
    },
    {
      year: '2年目',
      value: getValueOrDefault(cal.Y105(2)),
      title: '販売管理費',
    },
    {
      year: '3年目',
      value: getValueOrDefault(cal.Y105(3)),
      title: '販売管理費',
    },
    {
      year: '4年目',
      value: getValueOrDefault(cal.Y105(4)),
      title: '販売管理費',
    },
    {
      year: '5年目',
      value: getValueOrDefault(cal.Y105(5)),
      title: '販売管理費',
    },
  ];
  const lineData = [
    {
      year: '1年目',
      count: getValueOrDefault(cal.Y101(1)),
      name: '売上高',
    },
    {
      year: '2年目',
      count: getValueOrDefault(cal.Y101(2)),
      name: '売上高',
    },
    {
      year: '3年目',
      count: getValueOrDefault(cal.Y101(3)),
      name: '売上高',
    },
    {
      year: '4年目',
      count: getValueOrDefault(cal.Y101(4)),
      name: '売上高',
    },
    {
      year: '5年目',
      count: getValueOrDefault(cal.Y101(5)),
      name: '売上高',
    },
  ];

  const dataColumnChart = {
    data: [columnData, lineData],
    xField: 'year',
    yField: ['value', 'count'],
    meta: {
      value: { sync: 'count' },
      count: { sync: true },
    },
    geometryOptions: [
      {
        geometry: 'column',
        isStack: true,
        seriesField: 'title',
        color: (feild: any) => {
          if (feild.title === '売上原価') {
            return '#7aaddd';
          }
          if (feild.title === '営業外費用') {
            return '#f19759';
          }
          return '#70ad47';
        },
      },
      {
        geometry: 'line',
        seriesField: 'name',
        isStack: true,
        color: (name: any) => {
          if (name.name === '売上高') {
            return '#DC143C';
          }
          return '#726347';
        },
        point: {
          size: 5,
          shape: 'diamond',
        },
      },
    ],
    tooltip: {
      formatter: (datum: IndexedObject) => ({
        name: datum.title ?? datum.name,
        value: numberWithCommas(datum.value ?? datum.count),
      }),
    },
    yAxis: {
      value: {
        label: {
          formatter: (datum: string) => numberWithCommas(Number(datum)),
        },
      },
      count: {
        label: {
          formatter: (datum: string) => numberWithCommas(Number(datum)),
        },
      },
    },
  };

  const dataColumnChart2 = {
    data: [
      {
        year: '売上高',
        value: getValueOrDefault(cal.Y101(year1)),
        type: '収入',
      },
      {
        year: '売上原価',
        value: getValueOrDefault(cal.Y102(year1)),
        type: '支出',
      },

      {
        year: '売上総利益',
        value: getValueOrDefault(cal.Y103(year1)),
        type: '収入',
      },
      {
        year: '販売管理費',
        value: getValueOrDefault(cal.Y105(year1)),
        type: '支出',
      },
      {
        year: '営業利益',
        value: getValueOrDefault(cal.Y106(year1)),
        type: '収入',
      },
      {
        year: '営業外費用',
        value: getValueOrDefault(cal.Y108(year1)),
        type: '支出',
      },
      {
        year: '経常利益',
        value: getValueOrDefault(cal.Y109(year1)),
        type: '収入',
      },
      {
        year: '法人税等',
        value: getValueOrDefault(cal.Y114(year1)),
        type: '支出',
      },
      {
        year: '当期純利益',
        value: getValueOrDefault(cal.Y115(year1)),
        type: '収入',
      },
    ],
    xField: 'year',
    yField: 'value',
    seriesField: 'type',
    color: (type: any) => {
      if (type.type === '収入') {
        return '#7aaddd';
      }
      if (type.type === '支出') {
        return '#f19759';
      }
      return '#70ad47';
    },
    tooltip: {
      formatter: (datum: IndexedObject) => ({ name: datum.year, value: numberWithCommas(datum.value) }),
    },
    label: {
      position: 'top',
      formatter: (datum: IndexedObject) => numberWithCommas(datum.value),
    },
    xAxis: {
      label: {
        offset: 10,
        autoHide: false,
        autoEllipsis: true,
        autoRotate: true,
      },
      verticalLimitLength: 100,
    },
    yAxis: {
      label: {
        formatter: (datum: string) => numberWithCommas(Number(datum)),
      },
    },
  };
  const dataColumn = {
    data: [
      {
        year: '減価償却費',
        value: getValueOrDefault(cal.Y501(year2)),
      },
      {
        year: '燃料費',
        value: getValueOrDefault(cal.Y1100()),
      },
      {
        year: '栽培技術コンサル・パート人件費',
        value: getValueOrDefault(cal.Y1009() + cal.Y1200()),
      },

      {
        year: '販促活動費',
        value: getValueOrDefault(cal.Y702()),
      },
      {
        year: '梱包材料費',
        value: getValueOrDefault(cal.Y708(year2)),
      },
      {
        year: '物流運搬費',
        value: getValueOrDefault(cal.Y712(year2)),
      },
      {
        year: 'その他販売経費',
        value: getValueOrDefault(cal.Y715(year2)),
      },
      {
        year: '人件費（社員・家族労働者・社長）',
        value: getValueOrDefault(cal.Y601() + cal.Y611()),
      },
      {
        year: 'その他管理経費',
        value: getValueOrDefault(cal.Y800()),
      },
      {
        year: '借入利息',
        value: getValueOrDefault(cal.Y108(year2)),
      },
    ],
    xField: 'year',
    yField: 'value',
    color: (type: number) => {
      if (type === 0) {
        return '#f199';
      }
      return '#f19759';
    },
    tooltip: {
      formatter: (datum: IndexedObject) => ({ name: datum.year, value: numberWithCommas(datum.value) }),
    },
    xAxis: {
      label: {
        offset: 10,
        autoHide: false,
        autoEllipsis: false,
        autoRotate: true,
      },
      verticalLimitLength: 60,
    },
    yAxis: {
      label: {
        formatter: (datum: string) => numberWithCommas(Number(datum)),
      },
    },
    label: {
      position: 'top',
      formatter: (datum: IndexedObject) => numberWithCommas(datum.value),
    },
  };

  const dataTableDiagram1 = {
    fields: {
      rows: ['PL'],
      values: ['price'],
    },
    meta: [
      {
        field: 'PL',
        name: 'PL',
      },
      {
        field: 'price',
        name: `${year1}年目`,
      },
    ],
    data: [
      {
        PL: '売上高',
        price: numberWithCommas(cal.Y101(year1)),
      },
      {
        PL: '売上原価',
        price: numberWithCommas(cal.Y102(year1)),
      },
      {
        PL: '売上総利益',
        price: numberWithCommas(cal.Y103(year1)),
      },
      {
        PL: '粗利率',
        price: addCommas(cal.Y104(year1), '%', 0),
      },
      {
        PL: '販売管理費',
        price: numberWithCommas(cal.Y105(year1)),
      },
      {
        PL: '営業利益',
        price: numberWithCommas(cal.Y106(year1)),
      },
      {
        PL: '営業利益率',
        price: addCommas(cal.Y107(year1), '%', 0),
      },
      {
        PL: '営業外費用',
        price: numberWithCommas(cal.Y108(year1)),
      },
      {
        PL: '経常利益',
        price: numberWithCommas(cal.Y109(year1)),
      },
      {
        PL: '経常利益率',
        price: addCommas(cal.Y110(year1), '%', 0),
      },
      {
        PL: '特別利益',
        value: numberWithCommas(cal.Y111(year1)),
      },
      {
        PL: '特別損失',
        price: numberWithCommas(cal.Y112()),
      },
      {
        PL: '税引前当期純利益',
        price: numberWithCommas(cal.Y113(year1)),
      },
      {
        PL: '法人税等',
        price: numberWithCommas(cal.Y114(year1)),
      },
      {
        PL: '当期純利益',
        price: numberWithCommas(cal.Y115(year1)),
      },
      {
        PL: '当期純利益率',
        price: addCommas(cal.Y116(year1), '%', 0),
      },
      {
        PL: '累計利益',
        price: numberWithCommas(cal.Y117(year1)),
      },
    ],
  };
  const dataTableDiagram2 = {
    fields: {
      rows: ['PL'],
      values: ['type'],
    },
    meta: [
      {
        field: 'PL',
        name: 'コスト',
      },
      {
        field: 'type',
        name: `${year2}年目`,
      },
    ],
    data: [
      {
        PL: '減価償却費',
        value: getValueOrDefault(cal.Y501(year2)),
      },
      {
        PL: '燃料費',
        value: getValueOrDefault(cal.Y1100()),
      },
      {
        PL: '栽培技術コンサル・パート人件費',
        type: numberWithCommas(getValueOrDefault(cal.Y1009() + cal.Y1200())),
      },
      {
        PL: '販促活動費',
        type: numberWithCommas(getValueOrDefault(cal.Y702())),
      },
      {
        PL: '梱包材料費',
        type: numberWithCommas(getValueOrDefault(cal.Y708(year2))),
      },
      {
        PL: '物流運搬費',
        type: numberWithCommas(getValueOrDefault(cal.Y712(year2))),
      },
      {
        PL: 'その他販売経費',
        value: getValueOrDefault(cal.Y501(year2)),
      },
      {
        PL: '人件費（社員・家族労働者・社長）',
        type: numberWithCommas(getValueOrDefault(cal.Y601() + cal.Y611())),
      },
      {
        PL: 'その他管理経費',
        type: numberWithCommas(getValueOrDefault(cal.Y800())),
      },
      {
        PL: '借入利息',
        type: numberWithCommas(getValueOrDefault(cal.Y108(year2))),
      },
    ],
  };
  const OPTIONS_TABLE = {
    height: 520,
    conditions: {
      text: [
        {
          field: 'type',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.type)) < 0) {
              return { fill: '#FF4D4F' };
            }
            return { fill: '#00000' };
          },
        },
        {
          field: 'price',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.price)) < 0) {
              return { fill: '#FF4D4F' };
            }
            return { fill: '#00000' };
          },
        },
        {
          field: 'col1',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.col1)) < 0) {
              return { fill: '#FF4D4F' };
            }
            return { fill: '#00000' };
          },
        },
        {
          field: 'col2',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.col2)) < 0) {
              return { fill: '#FF4D4F' };
            }
            return { fill: '#00000' };
          },
        },
        {
          field: 'col3',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.col3)) < 0) {
              return { fill: '#FF4D4F' };
            }
            return { fill: '#00000' };
          },
        },
        {
          field: 'col4',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.col4)) < 0) {
              return { fill: '#FF4D4F' };
            }
            return { fill: '#00000' };
          },
        },
        {
          field: 'col5',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.col5)) < 0) {
              return { fill: '#FF4D4F' };
            }
            return { fill: '#00000' };
          },
        },
      ],
    },
  };
  return {
    configTable,
    dataLineChart,
    dataColumnChart,
    dataColumnChart2,
    dataColumn,
    dataTableDiagram1,
    dataTableDiagram2,
    OPTIONS_TABLE,
  };
};
export const RADIO_DATA = [
  {
    name: '1年目',
    value: '1',
  },
  {
    name: '2年目',
    value: '2',
  },
  {
    name: '3年目',
    value: '3',
  },
  {
    name: '4年目',
    value: '4',
  },
  {
    name: '5年目',
    value: '5',
  },
];
