import React, { ReactNode } from 'react';
import { SheetComponent } from '@antv/s2-react';
import { Pagination, S2DataConfig, S2Options, ThemeCfg } from '@antv/s2';
import { PaginationProps } from 'antd';

const SheetTable: React.FC<{
  dataCfg: S2DataConfig;
  options: S2Options<ReactNode, Pagination & PaginationProps>;
  themeCfg?: ThemeCfg;
}> = ({ dataCfg, options, themeCfg }) => (
  <SheetComponent
    dataCfg={dataCfg}
    adaptive={{
      width: true,
      height: true,
    }}
    options={{
      ...options,
      showDefaultHeaderActionIcon: false,
      tooltip: {
        showTooltip: false,
      },
    }}
    themeCfg={themeCfg}
  />
);

export default SheetTable;
