import React from 'react';
import { Col } from 'antd';
import { useHistory } from 'react-router-dom';
import styles from './style.module.scss';

const Item: React.FC<{
  title: string;
  image: string;
  subTitle: string;
  url?: string;
  resetState?: () => void;
}> = ({ title, image, subTitle, url, resetState }) => {
  const history = useHistory();

  const handelClick = () => {
    if (resetState) resetState();

    if (url) {
      history.push(url);
    }
  };

  return (
    <Col xs={24} sm={24} md={8} className={styles.item}>
      <div className={styles.pointer} onClick={handelClick} onKeyDown={handelClick} role="presentation">
        <div className={styles.item__top}>
          <p>{title}</p>
          <img src={`/images/${image}.svg`} alt={image} />
        </div>
        <div className={styles.borderItem}>
          <p>{subTitle}</p>
        </div>
      </div>
    </Col>
  );
};

export default Item;
