export const EPath = {
  root: '/',
  login: '/login',
  U3_Top: '/',
  U4_Menu: '/assignments/:trainingId/menu',
  U5: '/assignments/:trainingId/rfp',
  U6: '/assignments/:trainingId/start',
  U7: '/assignments/:trainingId/questions/1',
  U7_2: '/assignments/:trainingId/questions/2',
  U7_3: '/assignments/:trainingId/questions/3',
  U7_4: '/assignments/:trainingId/questions/4',
  U7_5: '/assignments/:trainingId/questions/5',
  U7_6: '/assignments/:trainingId/questions/6',
  U7_7: '/assignments/:trainingId/questions/7',
  U7_8: '/assignments/:trainingId/questions/8',
  U8: '/assignments/:trainingId/results',
  resetPassword: '/password/reset/:token',
  forgotPassword: '/password/forgot',
  assignments: '/adm/assignments',
  assignmentAdd: '/adm/assignments/add',
  assignmentDetail: '/adm/assignment/:training',
  assignmentEdit: '/adm/assignment/:training/edit',
  A1: '/adm',
  A2: '/adm/users',
  A3: '/adm/templates',
  A4: '/adm/templates/add/rfp',
  A4_2: '/adm/templates/add/questions/1',
  A4_3: '/adm/templates/add/questions/2',
  A4_4: '/adm/templates/add/calculation_settings/yields',
  A4_5: '/adm/templates/add/calculation_settings/grade',
  A4_6: '/adm/templates/add/calculation_settings/working_time',
  A4_7: '/adm/templates/add/kpi',
  A5: '/adm/templates/:templateId/edit/rfp',
  A10: '/adm/assignments/results/:trainingId/:studentId',
  // Adminstrator master
  S1_TOP: '/adm',
  S3_1: '/adm/groups',
  S3_2_CREATE: '/adm/groups/add',
  S3_2: '/adm/groups/:id/edit',
  S4_1: '/adm/baselibrary',
  S4_2_CREATE: '/adm/baselibrary/add',
  S4_2: '/adm/baselibrary/:id/edit',
};
