import { uploadRepositories } from 'repositories/upload';

const uploadImage = async (data: FormData) => {
  const res = await uploadRepositories.uploadImage({
    body: data,
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

export const uploadServices = {
  uploadImage,
};
