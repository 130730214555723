import React from 'react';
import { Row, Col, Button } from 'antd';
import { TTrainingResult } from 'types/trainingResult';
import { Link, useParams } from 'react-router-dom';
import { replacePathParams } from 'libs/helpers/functions';
import { EPath } from 'constants/routes';
import { TGroupsClassDetail } from 'types/groupsClass';
import styles from './style.module.scss';
import AssignmentsResultsTab from './Tabs/AssignmentsResultsTab';

const QUESTIONNNAIRE_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSeTfGLHfc0EZHrmHQmizaXoPIRUk7pBQoz5zdjlGvCtg5680w/viewform';
const AssignmentsResults: React.FC<{
  admPage?: boolean;
  trainingResult: TTrainingResult;
  getTrainingResult: (data: { trainingId: string; groupId: number }) => void;
  currentGroup: TGroupsClassDetail;
}> = ({ trainingResult, getTrainingResult, admPage, currentGroup }) => {
  const { trainingId } = useParams<{ trainingId: string }>();

  return (
    <>
      <div className={styles.assignmentsResultsWrap}>
        {!admPage && (
          <>
            <Row>
              <p className={styles.mainTitle}>設問回答</p>
              <p className={styles.title}>{trainingResult.training?.name ?? ''}</p>
            </Row>
            <Row justify="space-between">
              <Col>
                <div className={styles.questionWrap}>
                  <p className={styles.questionText}>
                    ※研修に参加いただきありがとうございます。
                    <br />
                    以下のボタンを押し、アンケート回答のご協力をお願いいたします。
                  </p>
                  <Button href={QUESTIONNNAIRE_URL} target="blank">
                    アンケートに回答する
                  </Button>
                </div>
              </Col>
              <Col>
                <Row style={{ marginTop: '20px' }} className={styles.contentRight}>
                  <Link to={replacePathParams(EPath.U7, { trainingId: trainingId ?? '' })}>
                    <Button>設問回答にもどる</Button>
                  </Link>
                  <Link to={replacePathParams(EPath.U4_Menu, { trainingId: trainingId ?? '' })}>
                    <Button>課題の目次へ</Button>
                  </Link>
                </Row>
              </Col>
            </Row>
          </>
        )}
        <div className={styles.tabs}>
          <AssignmentsResultsTab
            admPage={admPage}
            trainingResult={trainingResult}
            getTrainingResult={getTrainingResult}
            currentGroup={currentGroup}
          />
        </div>
      </div>
    </>
  );
};

export default AssignmentsResults;
