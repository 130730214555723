/* eslint-disable complexity */
import { EPath } from 'constants/routes';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { TRoleUser } from 'types/auth';

const RoutePrivate = ({
  component: Component,
  isAuthenticated,
  path,
  exact = false,
  roleUser,
  rolesRouter,
}: {
  component: React.FC<any>;
  isAuthenticated: boolean;
  path: string;
  exact: boolean;
  roleUser?: TRoleUser;
  rolesRouter: TRoleUser[];
}) => (
  <Route
    path={path}
    exact={exact}
    render={(props: any) => {
      if (!isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: EPath.login,
              state: {
                from: props.location.pathname,
                isAuthenticated,
              },
            }}
          />
        );
      }
      if (!roleUser || (!rolesRouter.includes(roleUser) && rolesRouter.length)) {
        return <Redirect to={roleUser === 'teacher' || roleUser === 'admin' ? EPath.A1 : EPath.U3_Top} />;
      }

      return <Component {...props} />;
    }}
  />
);

export default RoutePrivate;
