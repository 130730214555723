import React, { useEffect } from 'react';
import FormDragDrop from 'components/FormDragDrop/FormDragDrop';
import { TQuestionRadio } from 'types/questions';
import { Control, UseFormRegister, UseFormReset } from 'react-hook-form';
import CommonCustomInput from 'components/CommonCustomInput/CommonCustomInput';
import styles from './style.module.scss';
import './style.css';

const AdmQuestionForm2: React.FC<{
  selectedItem: TQuestionRadio[];
  reset: UseFormReset<TQuestionRadio>;
  control: Control<TQuestionRadio, any>;
  register: UseFormRegister<TQuestionRadio>;
}> = ({ selectedItem, reset, control, register }) => {
  useEffect(() => {
    setTimeout(() => {
      // it's important to invoke reset after useFieldArray of 'FormDragDrop'.
      reset(selectedItem[0]);
    }, 100);
  }, [selectedItem]);

  return (
    <div className={styles.contentWrap__right}>
      <div className={styles.form}>
        <p>フィールドの詳細設定</p>
        {selectedItem.length > 0 && (
          <form className={styles.form}>
            <CommonCustomInput label="フィールド名（必須）" register={register('fieldName')} disabled={false} />
            <CommonCustomInput label="フィールドコード" register={register('fieldCd')} disabled />
            <CommonCustomInput label="フィールドタイプ" register={register('fieldType')} disabled />
            <FormDragDrop control={control} register={register} />
            <CommonCustomInput label="補足説明" register={register('extra')} disabled={false} />
          </form>
        )}
      </div>
    </div>
  );
};

export default AdmQuestionForm2;
