import React from 'react';
import baseLibraryAction from 'modules/baseLibrary/baseLibrary.action';
import { useDispatch, useSelector } from 'react-redux';
import { TOptionsQuery } from 'types/common';
import { useLocation } from 'react-router-dom';
import { parseSearchParams } from 'libs/helpers/functions';
import { TBaseLibary } from 'types/baseLibrary';
import { AppState } from 'store';
import LibManage from './LibManage';

const LibManageContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const baseLibState = useSelector((state: AppState) => state.pages.baseLibrary);
  const urlQueryParams: TOptionsQuery<any> = parseSearchParams(location.search);
  const getBaseLibList = (params: TOptionsQuery<any>) => {
    dispatch(baseLibraryAction.getBaseLibraryList(params));
  };
  const deleteBaseLib = (id: number) => dispatch(baseLibraryAction.deleteBaseLibrary(id));

  const downloadFileBaseLib = async (baseLib: TBaseLibary) => {
    dispatch(baseLibraryAction.downloadFileBaseLib(baseLib));
  };
  return (
    <LibManage
      baseLibState={baseLibState}
      getBaseLibList={getBaseLibList}
      location={location}
      urlQueryParams={urlQueryParams}
      deleteBaseLib={deleteBaseLib}
      downloadFileBaseLib={downloadFileBaseLib}
    />
  );
};

export default LibManageContainer;
