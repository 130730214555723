import React from 'react';
import { Col } from 'antd';
import { useHistory } from 'react-router-dom';
import styles from './style.module.scss';

const SubItem: React.FC<{
  title: string;
  subTitle: string;
  url?: string;
}> = ({ title, subTitle, url }) => {
  const history = useHistory();

  const handelClick = () => {
    if (url) {
      history.push(url);
    }
  };

  return (
    <Col xs={24} sm={8}>
      <div className={styles.pointer} onClick={handelClick} onKeyDown={handelClick} role="presentation">
        <div className={styles.subItem}>
          <div className={styles.borderItem}>
            <p>{title}</p>
          </div>
          <p>{subTitle}</p>
        </div>
      </div>
    </Col>
  );
};

export default SubItem;
