import React from 'react';
import { Form, Select } from 'antd';
import { TSelectOptions } from 'types/common';
import { Rule } from 'antd/lib/form';
import { disableField } from 'constants/customValidate';
import styles from './style.module.scss';

const CommonSelect: React.FC<{
  label: string;
  selectOptions: TSelectOptions;
  placeholder: string;
  onChange?: (value: string) => void;
  noMargin?: boolean;
  subLabel?: string;
  name?: string;
  rule?: Rule[] | undefined;
  extra?: string;
  disabled?: boolean;
  customValidationRender?: boolean;
}> = ({
  label,
  selectOptions,
  placeholder,
  onChange,
  noMargin,
  subLabel,
  name,
  rule,
  extra,
  disabled,
  customValidationRender,
}) => (
  <>
    {subLabel && <p style={{ marginBottom: '5px' }}>{subLabel}</p>}
    {customValidationRender ? (
      <Form.Item dependencies={[]} className={styles.noMargin}>
        {({ getFieldValue }) => {
          const valueQuestion = getFieldValue(disableField.checkField);

          return (
            <Form.Item
              label={label}
              className={noMargin ? styles.noMargin : ''}
              name={name}
              rules={[{ required: !(valueQuestion === disableField.value), message: '' }]}
              extra={extra}
            >
              <Select placeholder={placeholder} onChange={onChange} disabled={valueQuestion === disableField.value}>
                {selectOptions &&
                  selectOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          );
        }}
      </Form.Item>
    ) : (
      <Form.Item label={label} className={noMargin ? styles.noMargin : ''} name={name} rules={rule} extra={extra}>
        <Select placeholder={placeholder} onChange={onChange} disabled={disabled}>
          {selectOptions &&
            selectOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    )}
  </>
);

export default CommonSelect;
