/* eslint-disable complexity */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Form, InputNumber, InputRef, Row } from 'antd';
import { logger } from 'libs/logger';
import { PlusOutlined, PercentageOutlined } from '@ant-design/icons';
import { DataType } from '../../pages/Admin/WorkingTime/WorkingTime.state';
import { EditableContext } from './EditableRow';
import styles from './style.module.scss';

const EditableCell: React.FC<{
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof DataType;
  record: DataType;
  title: string;
  handleSave: (record: DataType) => void;
}> = ({ editable, children, dataIndex, record, handleSave, title, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const inputNumberRef = useRef<HTMLInputElement>(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      inputNumberRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (form) form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = form && (await form.validateFields());

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      logger.log('message', errInfo);
    }
  };

  let childNode = children;
  let inputNode = <></>;

  if (title === '収量の増加量' || title === 'A品の増加量' || title === 'その他の増加量') {
    inputNode = (
      <Row key={title}>
        <Col className={styles.colTable} span={4}>
          <p className={styles.titleCol}>
            <PlusOutlined />
          </p>
        </Col>
        <Col span={14} className={styles.colTable}>
          <div className={styles.titleCol}>
            <Form.Item style={{ margin: 0 }} name={dataIndex}>
              <InputNumber style={{ width: '100%' }} ref={inputNumberRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
          </div>
        </Col>
        <Col className={styles.colTable} span={6} style={{ borderRight: 0 }}>
          <p className={styles.titleCol}>
            <PercentageOutlined />
          </p>
        </Col>
      </Row>
    );
  } else {
    inputNode = (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <InputNumber style={{ width: '100%' }} ref={inputNumberRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    );
  }

  if (editable) {
    childNode = editing ? (
      inputNode
    ) : (
      <div
        className={styles.editable_cell_value_wrap}
        onClick={toggleEdit}
        onKeyDown={toggleEdit}
        role="button"
        tabIndex={0}
      >
        {children ?? ''}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
