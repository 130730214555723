import { TOptionsQuery } from 'types/common';
import { TUserDetail } from 'types/groups';
import actionCreatorFactory from 'typescript-fsa';
import { TBaseLibary } from 'types/baseLibrary';

const ac = actionCreatorFactory();

export default {
  getBaseLibraryList: ac<TOptionsQuery<TBaseLibary>>('getBaseLibraryList'),
  getBaseLibraryDetail: ac<number>('getBaseLibraryDetail'),
  uploadBaseLibraryFile: ac<{ file: FormData; fileName: string }>('uploadBaseLibraryFile'),
  createBaseLibrary: ac<{ name?: string; fname?: string }>('createBaseLibrary'),
  deleteBaseLibrary: ac<number>('deleteBaseLibrary'),
  updateBaseLibrary: ac<{ libId: number; name?: string; fname?: string }>('updateBaseLibrary'),
  downloadFileBaseLib: ac<TBaseLibary>('downloadFileBaseLib'),
  getBaseLibrarySample: ac<number>('getBaseLibrarySample'),
};
