import { forgotPasswordRepositories } from 'repositories/forgotPassword';

const forgotPassword = async (email: string) => {
  const res = await forgotPasswordRepositories.forgotPassword({
    body: { email },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const resetPassword = async (password: string, token: string) => {
  const res = await forgotPasswordRepositories.resetPassword({
    body: { password },
    condition: { token },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};
export const forgotPasswordServices = {
  forgotPassword,
  resetPassword,
};
