import React, { useState } from 'react';
import { Button, Card, Space, Table } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import MainButton from 'components/MainButton/MainButton';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import ModalDelete from 'components/ModalDelete/ModalDelete';
import { EPath } from 'constants/routes';
import { TGroupsClassState } from 'modules/groupsClass/groupsClass.reducer';
import { createQueryUrl } from 'libs/helpers/functions';
import { TGroupsClassDetail } from 'types/groupsClass';
import { TOptionsQuery } from 'types/common';
import styles from './style.module.scss';

type TModal = '' | 'delete';

type Props = {
  groupsClassState: TGroupsClassState;
  deleteGroupsClass: (id: number) => void;
  goGroupsDetailPage: (id: number) => void;
  urlQueryParams: TOptionsQuery<TGroupsClassDetail>;
};

const GroupsManage = ({ groupsClassState, deleteGroupsClass, goGroupsDetailPage, urlQueryParams }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [openModal, setOpenModal] = useState<TModal>('');
  const [groupId, setGroupId] = useState<number>(0);
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: 'グループ名',
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: '講師数',
      dataIndex: 'teacherNumber',
      width: '10%',
    },
    {
      title: '生徒数',
      dataIndex: 'studentNumber',
      width: '10%',
    },
    {
      title: '登録日',
      dataIndex: 'createdAt',
      width: '15%',
    },

    {
      title: '',
      width: '15%',
      render: (_: any, groups: TGroupsClassDetail) => (
        <Space align="center" className={styles.groupButton}>
          <MainButton text="詳細・編集" onClick={() => goGroupsDetailPage(groups.id ?? 0)} />
          <MainButton
            subButton
            text="削除"
            onClick={() => {
              setOpenModal('delete');
              setGroupId(groups.id ?? 0);
            }}
          />
        </Space>
      ),
    },
  ];
  const onChangePage = (page: number, limit: number) => {
    history.push(createQueryUrl(location, { ...urlQueryParams, page, limit }));
  };

  return (
    <>
      <ModalDelete
        title="確認"
        subTitle="グループを削除してもよろしいでしょうか？"
        open={openModal === 'delete'}
        onClose={() => setOpenModal('')}
        handleDelete={() => {
          deleteGroupsClass(groupId);
          setOpenModal('');
        }}
      />
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.menuTitle}>
              <p>グループ管理</p>
            </div>
          }
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Space className={styles.topButtonGroup}>
                <Button>
                  <Link to={EPath.S1_TOP}>TOPに戻る</Link>
                </Button>
                <Button>
                  <Link to={EPath.S3_2_CREATE}>グループを追加する</Link>
                </Button>
              </Space>
              <Table
                bordered
                columns={columns}
                dataSource={groupsClassState.groups}
                pagination={false}
                loading={groupsClassState.loading}
                rowKey="id"
              />
            </div>
            <div className={styles.bottom}>
              <PaginationCustom
                current={groupsClassState.pagination.page}
                total={groupsClassState.pagination.totalResults}
                pageSize={groupsClassState.pagination.limit}
                disabled={groupsClassState.loading}
                onChange={onChangePage}
                showQuickJumper
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default GroupsManage;
