import { routers } from 'constants/router';

export type TLeftMenu = {
  text: string;
  link: string;
};

export const studentMenu: TLeftMenu[] = [
  {
    text: 'TOP',
    link: routers.U3.path,
  },
];

export const teacherMenu: TLeftMenu[] = [
  {
    text: 'TOP',
    link: routers.A1.path,
  },
  {
    text: 'グループ管理設定',
    link: routers.A2.path,
  },
  {
    text: '研修テンプレート一覧',
    link: routers.A3.path,
  },
  {
    text: '研修一覧',
    link: routers.A6.path,
  },
];

export const adminMenu: TLeftMenu[] = [
  {
    text: 'TOP',
    link: routers.A1.path,
  },
  {
    text: 'ユーザー管理',
    link: routers.A2.path,
  },
  {
    text: '研修テンプレート一覧',
    link: routers.A3.path,
  },
  {
    text: '研修一覧',
    link: routers.A6.path,
  },
  {
    text: 'グループ管理',
    link: routers.S3_1.path,
  },
  {
    text: 'ベースライブラリ管理',
    link: routers.S4_1.path,
  },
];

export enum TRoles {
  student = 'student',
  teacher = 'teacher',
  admin = 'admin',
}
