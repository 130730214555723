import React, { useState } from 'react';
import { Card, Row } from 'antd';
import { routers } from 'constants/router';
import { useDispatch, useSelector } from 'react-redux';
import templateAction from 'modules/template/template.action';
import { AppState } from 'store';
import { EPath } from 'constants/routes';
import styles from './style.module.scss';
import Item from './Item';
import SubItem from './SubItem';
import ModalGroups from './ModalGroups';

const TopAdm = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: AppState) => state.pages.auth);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const resetState = () => {
    dispatch(templateAction.clearTemplate());
  };

  return (
    <>
      <ModalGroups title="グループを選択する" onClose={() => setOpenModal(false)} open={openModal} />
      <div className={styles.topAdm}>
        <Card
          className={styles.topAdmWarp}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>TOP</p>
            </div>
          }
        >
          <div className={styles.cardContent}>
            <p className={styles.cardContent__title}>研修の準備の流れ</p>
            <hr />
            <div className={styles.rowWrap}>
              <Row gutter={[16, 16]}>
                <Item
                  title="①受講者アカウントを登録します。"
                  image="person-lines-fill"
                  subTitle="受講者登録を行う"
                  url={routers.A2.path}
                />
                <Item
                  title="②研修テンプレートを作成します。"
                  image="terminal-plus"
                  subTitle="テンプレートを作成する"
                  url={routers.A4.path}
                  resetState={resetState}
                />
                <Item
                  title="③開催する研修を作成します。"
                  image="pc-display-horizontal"
                  subTitle="研修を作成する"
                  url={routers.A8.path}
                />
              </Row>
            </div>
          </div>
          <div className={styles.cardContent} style={{ marginTop: '100px' }}>
            <p className={styles.cardContent__title}>各種設定</p>
            <hr />
            <div className={styles.rowWrap}>
              <Row gutter={[16, 16]} className={styles.row}>
                <SubItem title="ユーザー管理" subTitle="受講者・講師の管理を行います。" url={routers.A2.path} />
                <SubItem
                  title="研修テンプレート一覧"
                  subTitle="研修テンプレートの登録・編集・削除を行います。"
                  url={routers.A3.path}
                />
                <SubItem
                  title="研修一覧"
                  subTitle="研修の管理を行います。 受講者の研修実施状況の確認ができます。"
                  url={routers.A6.path}
                />
              </Row>
            </div>
          </div>

          {/* Adminstrator master */}
          {currentUser.role === 'admin' && (
            <div className={styles.cardContent} style={{ marginTop: '100px' }}>
              <p className={styles.cardContent__title}>管理者設定</p>
              <hr />
              <div className={styles.rowWrap}>
                <Row gutter={[16, 16]} className={styles.row}>
                  <SubItem
                    title="グループ管理"
                    subTitle="グループの管理を行います。グループ別に使用するベースライブラリの設定もできます。"
                    url={EPath.S3_1}
                  />
                  <SubItem
                    title="ベースライブラリ管理"
                    subTitle="研修テンプレートの元となるライブラリの管理を行います。"
                    url={EPath.S4_1}
                  />
                </Row>
              </div>
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default TopAdm;
