import React from 'react';
import { Col, RadioChangeEvent, Row } from 'antd';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import CommonRadio from 'components/CommonRadio/CommonRadio';
import SheetTable from 'components/SheetTable/SheetTable';
import { RADIO_DATA } from 'pages/Client/AssignmentsResults/Tabs/tab1/assignmentsResults';
import { TTargetYear } from 'libs/calculators';
import styles from '../style.module.scss';

const RowThree: React.FC<{
  configTable: any;
  onChange?: (e: RadioChangeEvent) => void;
  profitAndLossEachYear?: TTargetYear;
}> = ({ configTable, onChange, profitAndLossEachYear }) => (
  <Row
    style={{ marginTop: '100px' }}
    gutter={[
      { xs: 0, sm: 20 },
      { xs: 20, sm: 0 },
    ]}
  >
    <ChartTitle
      title="各年　PLグラフ"
      subTitle="「５か年　PLグラフ」で判断した問題のある年を下のラジオボタンで選択し、コスト詳細を確認しましょう！
            そしてどこのコストを下げるのか、または売上を向上させるのか決めましょう。​"
    />
    <Col xs={24} sm={12}>
      <CommonRadio radioOptions={RADIO_DATA} onChange={onChange} value={String(profitAndLossEachYear)} />
      <div className={styles.subTitle}>
        <div className="left">{profitAndLossEachYear}年目PL</div>
      </div>
      {configTable?.dataColumnChart2 && <ColumnChart configChart={configTable?.dataColumnChart2} />}
    </Col>
    <Col xs={24} sm={12}>
      {configTable?.dataTableDiagram1 && (
        <SheetTable dataCfg={configTable?.dataTableDiagram1} options={configTable?.OPTIONS_TABLE} />
      )}
    </Col>
  </Row>
);
export default RowThree;
