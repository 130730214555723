import { ColumnsType } from 'antd/lib/table';

export type DataType = {
  index: number;
  question: string;
  anwser: string | number;
};

export const columns: ColumnsType<DataType> = [
  {
    title: 'No',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: '設問',
    dataIndex: 'question',
    key: 'question',
    width: '50%',
  },
  {
    title: '回答',
    dataIndex: 'anwser',
    key: 'anwser',
    width: '40%',
  },
];
