import React, { ReactElement, useEffect, useState } from 'react';
import { InputNumberProps, Form, InputNumber, Checkbox } from 'antd';
import styles from './style.module.scss';

export type TNameGoalSetting = 'goal1' | 'goal2' | 'goal3' | 'goal4' | 'goal5' | 'all';
const TemplateKpiTable: React.FC<{
  disabled?: boolean;
  defaultChecked?: TNameGoalSetting[];
  getChecked?: (checkbox: TNameGoalSetting[]) => void;
}> = ({ disabled, defaultChecked, getChecked }) => {
  const [checkbox, setCheckbox] = useState<TNameGoalSetting[]>([]);

  useEffect(() => {
    if (
      defaultChecked &&
      checkbox.length !== defaultChecked.length &&
      checkbox.toString() !== defaultChecked.toString()
    ) {
      setCheckbox(defaultChecked);
    }
  }, [defaultChecked]);

  useEffect(() => {
    if (getChecked) {
      getChecked(checkbox);
    }
  }, [checkbox]);

  const propsInputDefault: InputNumberProps = {
    min: 0,
    max: 100,
    disabled: !!disabled,
  };

  const propsInputGoal4: InputNumberProps = {
    min: 0,
    max: 100000,
    disabled: !!disabled,
  };

  const input = (name: string, propsInput?: InputNumberProps) => (
    <Form.Item name={name} noStyle>
      <InputNumber {...propsInput} />
    </Form.Item>
  );

  const items: Array<{
    name: TNameGoalSetting;
    childrent: ReactElement;
  }> = [
    {
      name: 'all',
      childrent: <span>達成目標の初期値（研修の作成時に、研修別に後から変更することが可能です。）</span>,
    },
    {
      name: 'goal1',
      childrent: (
        <>
          <span>途中で倒産しない</span>
        </>
      ),
    },
    {
      name: 'goal2',
      childrent: (
        <>
          {input('goal2', propsInputDefault)}
          <span>年目に単年黒字化</span>
        </>
      ),
    },
    {
      name: 'goal3',
      childrent: (
        <>
          {input('goal31', propsInputDefault)}
          <span>年目に営業利益</span>
          {input('goal32', propsInputDefault)}
          <span>％以上</span>
        </>
      ),
    },
    {
      name: 'goal4',
      childrent: (
        <>
          <span>5年目に累計利益</span>
          {input('goal4', propsInputGoal4)}
          <span>万円以上</span>
        </>
      ),
    },
    {
      name: 'goal5',
      childrent: (
        <>
          <span>5年目に累計利益率</span>
          {input('goal5', propsInputDefault)}
          <span>％以上</span>
        </>
      ),
    },
  ];

  const handleSelectItem = (name: TNameGoalSetting, checked: boolean) => {
    if (name === 'all') {
      setCheckbox(checked ? items.map((i) => i.name).filter((i) => i !== 'all') : []);
    } else {
      setCheckbox((prev) => (checked ? [...prev, name] : prev.filter((i) => i !== name)));
    }
  };

  return (
    <ul className={styles.listWrap}>
      {items.map((item, index) => {
        const checked = item.name === 'all' ? checkbox.length === items.length - 1 : checkbox.includes(item.name);
        return (
          <li key={item.name} className={checked ? styles.itemActive : ''}>
            <div>
              <Checkbox
                checked={checked}
                disabled={!!disabled}
                onChange={(e) => handleSelectItem(item.name, e.target.checked)}
              />
            </div>
            <div>
              <span>{index}</span>
            </div>
            <div>{item.childrent}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default TemplateKpiTable;
