import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { authServices } from 'services/auth';
import { uiModule } from 'modules/ui/ui.reducer';
import {
  MESSAGE_CHANGE_PASSWORD_SUCCESS,
  MESSAGE_LOGIN_SUCCESS,
  MESSAGE_LOGOUT_SUCCESS,
} from 'constants/notifications/message';
import { push } from 'react-router-redux';
import { AxiosResponse } from 'libs/axios';
import { messageModule } from 'modules/message/message.reducer';
import { EPath } from 'constants/routes';
import { getCurrentFromLS, saveCurrentUserToLS } from 'config/server';
import _ from 'lodash';
import actions from './user.action';
import { authModule, TAuth, TAuthWithToken } from './user.reducer';

export const userLogin: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.login),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(authServices.login(payload)),
        next: (v: TAuthWithToken) => [
          authModule.actions.save(v),
          messageModule.actions.setSuccess(MESSAGE_LOGIN_SUCCESS),
        ],
        complete: [
          uiModule.actions.hideLoading(),
          push(getCurrentFromLS().role === 'teacher' || getCurrentFromLS().role === 'admin' ? EPath.A1 : EPath.U3_Top),
        ],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const userLogout: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.logout),
    map(() =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(authServices.logout()),
        next: () => messageModule.actions.setSuccess(MESSAGE_LOGOUT_SUCCESS),
        complete: [authModule.actions.logout(), push(EPath.login), uiModule.actions.hideLoading()],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const userChangePassword: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.changePassword),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(authServices.changePassword(payload)),
        next: () => messageModule.actions.setSuccess(MESSAGE_CHANGE_PASSWORD_SUCCESS),
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getAuth: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getAuth),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFns: from(authServices.getAuth(payload)),
        next: (v: TAuth) => {
          const userLS = getCurrentFromLS();
          if (!_.isEqual(v, userLS)) {
            saveCurrentUserToLS(v.currentUser);
          }
          return [];
        },
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
