/* eslint-disable complexity */
import React from 'react';
import { TAnswerRadio, TAnswerSelect, TQuestion } from 'types/questions';
import { Form } from 'antd';
import CommonRadio from 'components/CommonRadio/CommonRadio';
import CommonInput from 'components/CommonInput/CommonInput';
import CommonSelect from 'components/CommonSelect/CommonSelect';
import CommonInputNumber from 'components/CommonInputNumber/CommonInputNumber';
import { Rule } from 'antd/lib/form';
import { TSelectOption } from 'types/common';
import { customValidate, disableField } from 'constants/customValidate';
import { defaultValidateMessages } from 'constants/validate/message';
import { addOnlyCommas } from 'libs/helpers/functions';
import styles from './style.module.scss';

const commonRender = () => {
  const checkValidation = (fieldCd: string): Rule[] => {
    if (customValidate[fieldCd]) {
      return [
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (getFieldValue(customValidate[fieldCd]) && !value) {
              return Promise.reject(new Error(defaultValidateMessages.required));
            }
            return Promise.resolve();
          },
        }),
      ];
    }
    return [{ required: true, message: '' }];
  };

  const renderValue = (data: TQuestion) => {
    const disableFieldName = disableField.disabledField.includes(data.questionId);

    switch (data.fieldType) {
      case 'radio': {
        const answer = data.answer as TAnswerRadio[];
        const dataOption = answer.length
          ? answer.map((ans: TAnswerRadio, index) => {
              const value =
                answer.filter((i) => i.value === ans.value).length > 1 ? `${index}|${ans.value}` : ans.value;
              return {
                name: ans.label,
                value: value ?? 0,
              };
            })
          : [];
        const initialValue = dataOption.length === 1 && !disableFieldName ? dataOption[0].value : undefined;

        return (
          <div key={data.questionId}>
            <Form.Item dependencies={[]} className={styles.noMargin}>
              {({ getFieldValue }) => {
                const valueQuestion = getFieldValue(disableField.checkField);

                return (
                  <Form.Item
                    label={data.fieldName}
                    name={data.fieldCd}
                    rules={[{ required: !(valueQuestion === disableField.value), message: '' }]}
                    initialValue={initialValue}
                    extra={data.extra}
                  >
                    <CommonRadio
                      radioOptions={dataOption}
                      value={initialValue}
                      disabled={disableFieldName && valueQuestion === disableField.value}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>
        );
      }
      case 'select': {
        const options: TSelectOption[] = [];
        const answer = data.answer as TAnswerSelect;
        const { from, to } = answer;
        const increment = Number(answer.increments ?? 0);
        const roundingNum = (num: number): string =>
          num < 2 && !Number.isInteger(num) ? String(num.toFixed(1)) : String(num);

        for (let i = Number(from ?? 0); i <= Number(to ?? 0); i += increment) {
          options.push({
            name: answer.label.replace('#value#', addOnlyCommas(roundingNum(i))),
            value: roundingNum(i),
          });
        }

        return (
          <div key={data.questionId}>
            <CommonSelect
              name={data.fieldCd}
              label={data.fieldName}
              placeholder="Please select"
              selectOptions={options}
              extra={data.extra}
              rule={[{ required: !disableFieldName, message: '' }]}
              customValidationRender={disableFieldName}
            />
          </div>
        );
      }
      case 'number': {
        return (
          <div key={data.questionId}>
            <CommonInputNumber
              rule={checkValidation(data.fieldCd)}
              label={data.fieldName}
              placeholder=""
              extra={data.extra}
              name={data.fieldCd}
            />
          </div>
        );
      }
      case 'text': {
        return (
          <div key={data.questionId}>
            <CommonInput
              rule={checkValidation(data.fieldCd)}
              label={data.fieldName}
              placeholder=""
              extra={data.extra}
              name={data.fieldCd}
            />
          </div>
        );
      }
      default:
        return <></>;
    }
  };

  return [renderValue];
};

export default commonRender;
