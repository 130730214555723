import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

export const getBaseLibraryList: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/base-libraries',
};

export const createBaseLibrary: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/base-libraries',
};

export const getBaseLibrary: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/base-libraries/:libId',
};

export const updateBaseLibrary: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/base-libraries/:libId',
};

export const deleteBaseLibrary: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/base-libraries/:libId',
};

export const uploadBaseLibraryFile: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/base-libraries/upload',
};

export const downloadBaseLibraryFile: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: '/base-libraries/:libId/file',
};

export const baseLibraryRepositories = {
  getBaseLibraryList: apiClient.getFn(getBaseLibraryList),
  createBaseLibrary: apiClient.postFn(createBaseLibrary),
  getBaseLibrary: apiClient.getFn(getBaseLibrary),
  updateBaseLibrary: apiClient.patchFn(updateBaseLibrary),
  deleteBaseLibrary: apiClient.deleteFn(deleteBaseLibrary),
  uploadBaseLibraryFile: apiClient.postFn(uploadBaseLibraryFile),
  downloadBaseLibraryFile: apiClient.getFn(downloadBaseLibraryFile),
};
