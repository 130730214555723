import React from 'react';
import CommonRadio from 'components/CommonRadio/CommonRadio';
import { Form, Input, Modal } from 'antd';
import MainButton from 'components/MainButton/MainButton';
import { TUserDetail } from 'types/groups';
import { checkPassword } from 'constants/validate';
import { customValidateMessages, defaultValidateMessages } from 'constants/validate/message';
import { optionsValue } from '../GroupsAdm.state';
import styles from './style.module.scss';

const AddMembersModal: React.FC<{
  open: boolean;
  openModal: (open: boolean) => void;
  handleCreateUser: (values: TUserDetail) => void;
}> = ({ open, openModal, handleCreateUser }) => {
  const [form] = Form.useForm();

  const onFinish = (values: TUserDetail) => {
    handleCreateUser(values);
    form.resetFields();
  };

  return (
    <Modal
      title="ユーザーを追加する"
      centered
      open={open}
      footer={null}
      onOk={() => openModal(false)}
      onCancel={() => openModal(false)}
    >
      <Form layout="vertical" onFinish={onFinish} form={form} validateMessages={defaultValidateMessages}>
        <Form.Item
          name="email"
          label="メールアドレス"
          rules={[{ required: true, whitespace: true }, { type: 'email' }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item name="name" label="氏名" rules={[{ required: true, whitespace: true }]}>
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="password"
          label="初期パスワード"
          rules={[
            { required: true, whitespace: true },
            { pattern: checkPassword, message: customValidateMessages.validatePassword },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item name="role">
          <CommonRadio radioOptions={optionsValue} />
        </Form.Item>
        <p className={styles.desc}>
          ※ユーザーの追加後、上のメールアドレスあてにIDとパスワードを記載した招待メールが送信されます。
        </p>
        <Form.Item>
          <MainButton htmlType="submit" text="追加する" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddMembersModal;
