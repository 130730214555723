import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOptionsQuery } from 'types/common';
import baseLibraryAction from 'modules/baseLibrary/baseLibrary.action';
import { parseSearchParams } from 'libs/helpers/functions';
import { useLocation, useParams } from 'react-router-dom';
import { AppState } from 'store';
import groupsClassAction from 'modules/groupsClass/groupsClass.action';
import { TGroupsClassDetail } from 'types/groupsClass';
import GroupsDetails from './GroupsDetails';

const GroupsDetailsContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  const urlQueryParams: TOptionsQuery<any> = parseSearchParams(location.search);
  const baseLibState = useSelector((state: AppState) => state.pages.baseLibrary);
  const groupClassState = useSelector((state: AppState) => state.pages.groupClass);

  const getListBaseLib = (params: TOptionsQuery<any>) => {
    dispatch(baseLibraryAction.getBaseLibraryList(params));
  };

  const createGroup = (data: TGroupsClassDetail) => {
    dispatch(groupsClassAction.createGroupsClass(data));
  };

  const getDetailGroup = (id: number) => {
    dispatch(groupsClassAction.getDetailGroupClass(id));
  };

  const updateGroup = (data: TGroupsClassDetail) => {
    dispatch(groupsClassAction.updateGroupsClass(data));
  };
  return (
    <GroupsDetails
      baseLibState={baseLibState}
      groupClassState={groupClassState}
      createGroup={createGroup}
      getListBaseLib={getListBaseLib}
      urlQueryParams={urlQueryParams}
      getDetailGroup={getDetailGroup}
      updateGroup={updateGroup}
      groupId={id}
    />
  );
};

export default GroupsDetailsContainer;
