import { TColumns, TQuestion, TQuestions } from 'types/questions';

export const columnsState: TColumns[] = [
  {
    title: 'フィールド名',
    dataIndex: 'fieldName',
    columnSearchProps: 'fieldName',
    width: '40%',
  },
  {
    title: 'フィールドコード',
    dataIndex: 'fieldCd',
    columnSearchProps: 'fieldCd',
    width: '40%',
  },
  {
    title: 'タイプ',
    dataIndex: 'fieldType',
    width: '20%',
    filters: [
      {
        text: 'select',
        value: 'select',
      },
      {
        text: 'radio',
        value: 'radio',
      },
      {
        text: 'text',
        value: 'text',
      },
      {
        text: 'number',
        value: 'number',
      },
    ],
    onFilter: (value: string | number | boolean, record: TQuestion) => record.fieldType === value,
  },
];

export type TStep2 = {
  step2: TQuestions[];
};
