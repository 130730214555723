// Common
export const MESSAGE_ERROR_DEFAULT = 'データの更新に失敗しました。';
export const MESSAGE_SUCCESS_DEFAULT = 'データの更新が完了しました。';

// Auth
export const MESSAGE_CHANGE_PASSWORD_SUCCESS = 'パスワードの変更が完了しました。';
export const MESSAGE_LOGIN_SUCCESS = 'ログインしました。';
export const MESSAGE_LOGOUT_SUCCESS = 'ログアウトしました。';

// A4 screen
export const MESSAGE_VALIDATE_TEMPLATE_NAME = '研修テンプレート名を入力してください。';
export const MESSAGE_VALIDATE_TEMPLATE_BASE = '研修テンプレートのベースとなる設定を選択してください。';
