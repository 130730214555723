import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import { TNavStep, TNavSteps } from 'types/common';
import { navAdmin, navClient, ENavType, navAdminEdit } from 'constants/nav';
import classnames from 'classnames';
import styles from './style.module.scss';

const NavStep: React.FC<{ type: ENavType; onClick?: (link: string) => void }> = ({ type, onClick }) => {
  const [data, setData] = useState<TNavSteps>();
  const { trainingId, templateId } = useParams<{ trainingId: string; templateId: string }>();
  const converPath = (data: TNavSteps, id: string, key: string) => {
    const newPath = data.map((path: TNavStep) => ({ ...path, link: path.link.replace(`:${key}`, id) }));
    return newPath;
  };

  useEffect(() => {
    if (type === ENavType.teacher) {
      if (templateId) {
        setData(converPath(navAdminEdit, templateId, 'traningId'));
      } else {
        setData(navAdmin);
      }
    } else {
      setData(converPath(navClient, trainingId, 'trainingId'));
    }
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
    e.preventDefault();
    if (onClick) onClick(link);
  };

  return (
    <Row className={styles.stepWrap}>
      {data &&
        data.map((step) => (
          <NavLink
            key={step.link}
            to={step.link}
            className={(isActive) => (isActive ? classnames(styles.linkActive, styles.step) : styles.step)}
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e, step.link)}
          >
            <p>
              {step.name}
              <span>{step.label}</span>
            </p>
          </NavLink>
        ))}
    </Row>
  );
};

export default NavStep;
