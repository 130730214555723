import React from 'react';
import { AppState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import questionsAction from 'modules/questions/questions.action';
import templateAction from 'modules/template/template.action';
import AdmAddQuestion2 from './AdmAddQuestion2';
import { TStep3 } from './AdmAddQuestion2.state';

const AdmAddQuestion2Container = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);
  const baseLibState = useSelector((state: AppState) => state.pages.baseLibrary);

  const getQuestions = () => {
    if (currentGroup.id) {
      dispatch(questionsAction.getQuestions(currentGroup.id));
    }
  };

  const updateTemplate = (data: TStep3, id: string, buttonFooter: boolean, link?: string) => {
    if (currentGroup.id) {
      dispatch(templateAction.updateTemplateStep3({ groupId: currentGroup.id, data, id, buttonFooter, link }));
    }
  };

  return (
    <AdmAddQuestion2
      template={template}
      getQuestions={getQuestions}
      baseLibState={baseLibState}
      updateTemplate={updateTemplate}
    />
  );
};

export default AdmAddQuestion2Container;
