import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QUESTION_TYPE } from 'constants/questions';
import { dataBacsicGrade, yieldIncrementRatioGrade } from 'mock/calculationSettingsGrade';
import { dataBasicYield, yieldIncrementRatio } from 'mock/calculationSettingsYields';
import { dataWorkingTime } from 'mock/workingTime';
import { TTemplateKpiItem } from 'pages/Admin/TemplateKpi/TemplateKpi.state';
import { TQuestions } from 'types/questions';
import { TSettingSchema } from 'types/setting';
import { TWorkingTime } from 'types/workingTime';

export type TTemplateState = {
  isCompleted: boolean;
  name: string;
  picture: string;
  description: string;
  template: string;
  step2?: TQuestions[];
  step3?: TQuestions[];
  step4?: TSettingSchema;
  step5?: TSettingSchema;
  step6?: TWorkingTime[];
  step7?: TTemplateKpiItem;
  id: string;
  baseLibrary?: number;
};

const initialState: TTemplateState = {
  isCompleted: false,
  name: '',
  picture: '',
  description: '',
  template: '',
  step4: {
    basicYield: dataBasicYield,
    yieldIncrementRatio,
  },
  step5: {
    basicYield: dataBacsicGrade,
    yieldIncrementRatio: yieldIncrementRatioGrade,
  },
  step6: dataWorkingTime,
  id: '',
};

const _module = createSlice({
  name: 'template',
  initialState,
  reducers: {
    getTemplate(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    saveTemplate(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        name: action.payload.name,
        picture: action.payload.picture,
        description: action.payload.description,
        template: action.payload.template,
        id: action.payload.id,
      };
    },
    updateStep2(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        step2: action.payload.step2,
      };
    },
    updateStep3(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        step3: action.payload.step3,
      };
    },
    updateStep4(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        step4: action.payload.step4,
      };
    },
    updateStep5(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        step5: action.payload.step5,
      };
    },
    updateStep6(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        step6: action.payload.step6,
      };
    },
    updateStep7(state: TTemplateState, action: PayloadAction<TTemplateState>) {
      return {
        ...state,
        step7: action.payload.step7,
      };
    },
    updateImage(state: TTemplateState, action: PayloadAction<string>) {
      return {
        ...state,
        picture: action.payload,
      };
    },
    updateQuestions(state: TTemplateState, action: PayloadAction<TQuestions[]>) {
      if (action.payload.length) {
        const step2: TQuestions[] = [];
        const step3: TQuestions[] = [];
        action.payload.forEach((question) => {
          if (QUESTION_TYPE.step2.includes(question.step)) {
            step2.push(question);
          } else {
            step3.push(question);
          }
        });
        if (!state.step2?.length || !state.step3?.length)
          return {
            ...state,
            step2,
            step3,
          };
      }
      return state;
    },
    clearState() {
      return { ...initialState };
    },
  },
});

export const templateModule = _module;
