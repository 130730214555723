import React from 'react';
import { Col, Row } from 'antd';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import SheetTable from 'components/SheetTable/SheetTable';
import AchievementGoal from 'components/AchievementGoal/achievementGoal';
import styles from '../style.module.scss';

const FirstRow: React.FC<{ configTable: any }> = ({ configTable }) => (
  <>
    <Row
      gutter={[
        { xs: 0, sm: 20 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle title="販売計画表" />
      <Col xs={24} sm={18}>
        <div className={`${styles.subTitle} ${styles.borderSubTitleB}`}>
          <div className="left">価格と販路の一覧</div>
        </div>
        <div style={{ height: '200px' }}>
          {configTable?.configTableTab4 && (
            <SheetTable dataCfg={configTable?.configTableTab4} options={configTable?.OPTIONS_TABLE_TAB4} />
          )}
        </div>
        <div className={`${styles.subTitle} ${styles.borderSubTitleB}`}>
          <div className="left">年別売上推移グラフ</div>
        </div>
        <Col xs={24} sm={24}>
          <div style={{ height: '350px' }}>
            {configTable?.dataColumnChartTab4 && <ColumnChart configChart={configTable?.dataColumnChartTab4} />}
          </div>
        </Col>
      </Col>
      <Col xs={24} sm={6}>
        <AchievementGoal />
      </Col>
    </Row>
  </>
);

export default FirstRow;
