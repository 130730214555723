import { EPath } from 'constants/routes';

export const listStepsTrainingMenu = [
  {
    step: 1,
    titleTop: 'STEP1',
    titleBottom: '方針',
    path: EPath.U7,
  },
  {
    step: 2,
    titleTop: 'STEP2',
    titleBottom: '初期投資',
    path: EPath.U7_2,
  },
  {
    step: 3,
    titleTop: 'STEP3',
    titleBottom: '年間費用',
    path: EPath.U7_3,
  },
  {
    step: 4,
    titleTop: 'STEP4',
    titleBottom: '雇用計画',
    path: EPath.U7_4,
  },
  {
    step: 5,
    titleTop: 'STEP5',
    titleBottom: '販売計画',
    path: EPath.U7_5,
  },
  {
    step: 6,
    titleTop: 'STEP6',
    titleBottom: '管理経費',
    path: EPath.U7_6,
  },
  {
    step: 7,
    titleTop: 'STEP7',
    titleBottom: '規模拡大',
    path: EPath.U7_7,
  },
  {
    step: 8,
    titleTop: 'STEP8',
    titleBottom: '資金調達',
    path: EPath.U7_8,
  },
];

export const listU8TrainingMenu = [
  {
    key: 'U8_1',
    title: '損益計算書（PL）',
    routeState: {
      tab: '1',
    },
  },
  {
    key: 'U8_2',
    title: '投資計画',
    routeState: {
      tab: '2',
    },
  },
  {
    key: 'U8_3',
    title: '資金繰計画',
    routeState: {
      tab: '3',
    },
  },
  {
    key: 'U8_4',
    title: '生産＆販売計画',
    routeState: {
      tab: '4',
    },
  },
];
