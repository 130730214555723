import React from 'react';
import { Col, Row } from 'antd';
import SheetTable from 'components/SheetTable/SheetTable';
import styles from '../style.module.scss';

const RowTwo: React.FC<{ configTable: any }> = ({ configTable }) => (
  <Row
    className={styles.row2}
    gutter={[
      { xs: 0, sm: 20 },
      { xs: 20, sm: 0 },
    ]}
  >
    <Row className={`${styles.subTitle} ${styles.borderSubTitleB}`}>
      <div className="left">年別売上推移表</div>
    </Row>
    <Col xs={24} sm={18}>
      {configTable?.configTableTab4Two && (
        <SheetTable dataCfg={configTable?.configTableTab4Two} options={configTable?.OPTIONS_TABLE_TAB4_2} />
      )}
    </Col>
  </Row>
);

export default RowTwo;
