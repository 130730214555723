import { Button, Card, Form, Input, Space, Table } from 'antd';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import { EPath } from 'constants/routes';
import { defaultValidateMessages } from 'constants/validate/message';
import { createQueryUrl } from 'libs/helpers/functions';
import { TBaseLibState } from 'modules/baseLibrary/baseLibrary.reducer';
import { TGroupsClassState } from 'modules/groupsClass/groupsClass.reducer';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { TBaseLibary } from 'types/baseLibrary';
import { TOptionsQuery } from 'types/common';
import { TGroupsClassDetail } from 'types/groupsClass';
import styles from './style.module.scss';

type Props = {
  getListBaseLib: (params: TOptionsQuery<any>) => void;
  urlQueryParams: TOptionsQuery<any>;
  baseLibState: TBaseLibState;
  groupClassState: TGroupsClassState;
  createGroup: (data: TGroupsClassDetail) => void;
  updateGroup: (data: TGroupsClassDetail) => void;
  getDetailGroup: (id: number) => void;
  groupId: string;
};

const GroupsDetails = ({
  getListBaseLib,
  urlQueryParams,
  baseLibState,
  createGroup,
  getDetailGroup,
  groupId,
  groupClassState,
  updateGroup,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { baseLibs, loading, pagination } = baseLibState;
  const { groupDetail } = groupClassState;
  const [libs, setLibs] = useState<number[]>([]);
  const columnsTableUsers = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      width: '20%',
    },
    {
      key: 'name',
      title: 'ベースライブラリ名',
      dataIndex: 'name',
      width: '70%',
    },
  ];
  useEffect(() => {
    getListBaseLib(urlQueryParams);
  }, [location]);

  useEffect(() => {
    if (groupId) getDetailGroup(Number(groupId));
  }, [groupId]);

  useEffect(() => {
    if (groupId) {
      const listBaseLibsId = groupDetail?.baseLibraries?.map((b) => b.id ?? '') ?? [];
      form.setFieldValue('name', groupDetail.name);
      setLibs(listBaseLibsId);
    }
  }, [groupDetail]);

  const dataPreviewLibs = (libs: Array<number>, baseLibs: Array<TBaseLibary>) =>
    baseLibs.filter((lib) => libs.includes(lib.id ?? 0));
  const onChangePage = (page: number, limit: number) => {
    history.push(createQueryUrl(location, { ...urlQueryParams, page, limit }));
  };

  const handleSelectLibs = (baseLib: TBaseLibary) => {
    if (libs.includes(baseLib.id ?? 0)) {
      const remainList = libs.filter((x) => x !== baseLib.id);
      setLibs(remainList);
      return;
    }
    setLibs([...libs, baseLib.id ?? 0]);
  };
  const handleSelectAllLibs = (isSelectAll: boolean, listRecord: any[], listRecordChange: any[]) => {
    const allTeacherKeys = listRecord.filter((e) => e !== undefined).map((r) => r.id ?? '');
    if (isSelectAll) {
      const newListTeacherKeys = [...(libs ?? [])];
      allTeacherKeys.forEach((e) => {
        if (!libs?.includes(e)) {
          newListTeacherKeys.push(e);
        }
      });
      setLibs(newListTeacherKeys);
    } else {
      const listTeacherKeyChange = listRecordChange.map((e) => e.id);
      const listTeacherKeysRemain = libs?.filter((r) => !listTeacherKeyChange.includes(r));
      setLibs(listTeacherKeysRemain);
    }
  };
  const handleSubmit = ({ name }: { name: string }) => {
    const dataGroup = {
      name,
      baseLibraries: libs,
    };
    if (groupId) {
      updateGroup({ ...dataGroup, id: Number(groupId) });
      return;
    }
    createGroup(dataGroup);
  };
  return (
    <div className={styles.groups}>
      <Card
        className={styles.groups__wrap}
        title={
          <div className={styles.assignmentsMenuTitle}>
            <p>{groupId ? 'グループ詳細・編集' : 'グループ登録'}</p>
          </div>
        }
        loading={false}
      >
        <div className={styles.content}>
          <div className={styles.top}>
            <Space className={styles.topButtonGroup}>
              <Button className={styles.topButton}>
                <Link to={EPath.S3_1}>グループ管理に戻る</Link>
              </Button>
            </Space>
            <Form
              autoComplete="off"
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              validateMessages={defaultValidateMessages}
              disabled={false}
            >
              <Form.Item>
                <Space direction="vertical" align="start" className={styles.assignmentItem}>
                  <p className={styles.assignmentTitle}>グループ名</p>
                  <Form.Item name="name" rules={[{ required: true, whitespace: true }]} noStyle>
                    <Input className={styles.assignmentItemWidth} placeholder="グループ名を入力してください" />
                  </Form.Item>
                </Space>
              </Form.Item>

              <div className={styles.body}>
                <p>
                  ベースライブラリの設定 <br />
                  ※このグループで利用可能とするベースライブラリを選択してください。グループ追加後に設定することもできます。
                </p>
                <Table
                  rowSelection={{
                    selectedRowKeys: libs,
                    onSelect: handleSelectLibs,
                    onSelectAll: handleSelectAllLibs,
                  }}
                  dataSource={baseLibs}
                  columns={columnsTableUsers}
                  rowKey="id"
                  pagination={false}
                  scroll={{ y: 200 }}
                  loading={loading}
                />
                <div className={styles.pagination}>
                  <PaginationCustom
                    current={pagination.page}
                    total={pagination.totalResults}
                    pageSize={pagination.limit}
                    disabled={false}
                    onChange={onChangePage}
                    showQuickJumper
                    showSizeChanger={false}
                    showLessItems={false}
                    showTotal
                  />
                </div>
                <p>※選択中のベースライブラリ一覧（上で選択したベースライブラリの一覧です）</p>
                <Table
                  dataSource={dataPreviewLibs(libs, baseLibs)}
                  columns={columnsTableUsers}
                  rowKey="id"
                  pagination={false}
                  scroll={{ y: 400 }}
                />
              </div>
              <Form.Item noStyle>
                <button disabled={false} type="submit" className={styles.confirmButton}>
                  保存する
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default GroupsDetails;
