import { Col, Row } from 'antd';
import AchievementGoal from 'components/AchievementGoal/achievementGoal';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import SheetTable from 'components/SheetTable/SheetTable';
import React from 'react';

const RowOne: React.FC<{ configTable: any }> = ({ configTable }) => (
  <Row
    gutter={[
      { xs: 0, sm: 20 },
      { xs: 20, sm: 0 },
    ]}
  >
    <ChartTitle title="５か年　PL一覧表" />
    <Col xs={24} sm={18} style={{ minHeight: '520px' }}>
      {configTable?.configTable && (
        <SheetTable dataCfg={configTable?.configTable} options={configTable.OPTIONS_TABLE} />
      )}
    </Col>
    <Col xs={24} sm={6}>
      <AchievementGoal />
    </Col>
  </Row>
);
export default RowOne;
