import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Select, Space } from 'antd';
import MainButton from 'components/MainButton/MainButton';
import EditTable from 'components/EditTable/EditTable';
import { TGroupsState } from 'modules/groups/groups.reducer';
import { TUserDetail } from 'types/groups';
import { useHistory } from 'react-router-dom';
import { createQueryUrl } from 'libs/helpers/functions';
import { TOptionsQuery } from 'types/common';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import { defaultValidateMessages } from 'constants/validate/message';
import { Location } from 'history';
import { IndexedObject } from 'constants/types';
import { TUiState } from 'modules/ui/ui.reducer';
import { TGroupsClassState } from 'modules/groupsClass/groupsClass.reducer';
import { EditableCellProps, isEditingUser, optionsValue } from './GroupsAdm.state';
import AddMembersModal from './Modal/AddMembersModal';
import DeleteMembersModal from './Modal/DeleteMembersModal';
import styles from './style.module.scss';
import './style.css';

type Props = {
  getUsers: (params: TOptionsQuery<TUserDetail>) => void;
  groupsState: TGroupsState;
  groupsClassState: TGroupsClassState;
  uiState: TUiState;
  createUser: (u: TUserDetail & { groupId: number }) => void;
  updateUser: (u: TUserDetail & { groupId: number }) => void;
  deleteUser: (data: { id: string; groupId: number }) => void;
  location: Location;
  urlQueryParams: TOptionsQuery<TUserDetail>;
};

const GroupsAdm = ({
  getUsers,
  groupsState,
  groupsClassState,
  createUser,
  updateUser,
  deleteUser,
  location,
  urlQueryParams,
  uiState,
}: Props) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState('');
  const [modalAdd, setModalAdd] = useState(false);
  const [modalRemove, setModalRemove] = useState<string>('');

  const { users, pagination } = groupsState;
  const { currentGroup } = groupsClassState;
  const { isShowLoading } = uiState;

  useEffect(() => {
    if (getUsers && currentGroup.id) {
      getUsers({ ...urlQueryParams, groupId: currentGroup.id });
    }
  }, [location, currentGroup.id]);

  const onChangePage = (page: number, limit: number) => {
    history.push(createQueryUrl(location, { ...urlQueryParams, page, limit }));
  };

  const editableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, inputType, children, ...restProps }) => {
    const inputNode =
      inputType === 'select' ? (
        <Select options={optionsValue.map((o) => ({ label: o.name, value: o.value }))} />
      ) : (
        <Input />
      );

    let rulesValidate: IndexedObject[] = [];
    if (dataIndex === 'name') {
      rulesValidate = [{ required: true, whitespace: true }];
    }
    if (dataIndex === 'email') {
      rulesValidate = [{ required: true, whitespace: true }, { type: 'email' }];
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rulesValidate}>
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleCreateUser = (values: TUserDetail) => {
    if (currentGroup.id) createUser({ ...values, groupId: currentGroup.id });
    setModalAdd(false);
  };

  const edit = (record: TUserDetail) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id ?? '');
  };

  const save = async (id: string) => {
    const row = (await form.validateFields()) as TUserDetail;
    if (currentGroup.id) {
      updateUser({ ...row, id, groupId: currentGroup.id });
      setEditingKey('');
    }
  };

  const handleDelete = () => {
    if (currentGroup.id) {
      deleteUser({ id: modalRemove, groupId: currentGroup.id });
    }
  };

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
      editable: false,
    },
    {
      key: 'email',
      title: 'メールアドレス',
      dataIndex: 'email',
      width: '20%',
      editable: true,
    },
    {
      key: 'name',
      title: '氏名',
      dataIndex: 'name',
      width: '15%',
      editable: true,
    },
    {
      key: 'role',
      title: '区分',
      dataIndex: 'role',
      width: '25%',
      editable: true,
      render: (_: any, record: TUserDetail) => {
        const value = optionsValue.find((option) => option.value === record.role);
        return value ? value.name : '';
      },
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'operation',
      render: (_: any, record: TUserDetail) => {
        const editable = isEditingUser(record, editingKey);
        return editable ? (
          <Space align="center">
            <MainButton text="編集終了" onClick={() => save(record.id ?? '')} />
          </Space>
        ) : (
          <Space align="center">
            <MainButton text="編集" onClick={() => edit(record)} />
            <MainButton subButton text="削除" onClick={() => setModalRemove(record.id ?? '')} />
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TUserDetail) => ({
        record,
        inputType: col.dataIndex === 'role' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditingUser(record, editingKey),
      }),
    };
  });

  return (
    <div className="groupsAdm">
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>ユーザー管理</p>
            </div>
          }
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Button onClick={() => setModalAdd(true)}>ユーザーを追加する</Button>
              <EditTable
                loading={isShowLoading}
                form={form}
                data={users}
                editableCell={editableCell}
                mergedColumns={mergedColumns}
                validateMessages={defaultValidateMessages}
              />
            </div>
            <div className={styles.bottom}>
              <PaginationCustom
                current={pagination.page}
                total={pagination.totalResults}
                pageSize={pagination.limit}
                disabled={isShowLoading}
                onChange={onChangePage}
              />
            </div>
          </div>
        </Card>
        <AddMembersModal open={modalAdd} openModal={setModalAdd} handleCreateUser={handleCreateUser} />
        <DeleteMembersModal open={!!modalRemove} onClose={() => setModalRemove('')} handleDelete={handleDelete} />
      </div>
    </div>
  );
};

export default GroupsAdm;
