import { ENavType } from 'constants/nav';
import React from 'react';
import { TUserDetail } from 'types/groups';

export type EditableCellProps = {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'select' | 'text';
  record: string;
  index: number;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;

export const optionsValue = [
  {
    name: '生徒',
    value: ENavType.student,
  },
  {
    name: '講師',
    value: ENavType.teacher,
  },
];

export const isEditingUser = (record: TUserDetail, editingKey: string) => record.id === editingKey;
