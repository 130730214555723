import trainingResultAction from 'modules/trainingResult/trainingResult.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { TAnswerTrainingResults } from 'types/trainingResult';
import AssignmentQuestion2 from './AssignmentQuestion2';

const AssignmentQuestion3Container = () => {
  const dispatch = useDispatch();
  const { trainingResult } = useSelector((state: AppState) => state.pages);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);

  const updateTrainingResult = (data: TAnswerTrainingResults, id: string) => {
    if (currentGroup.id)
      dispatch(trainingResultAction.updateTrainingResult({ groupId: currentGroup.id, data, id, step: 'step2' }));
  };

  const resetTrainingResult = () => {
    dispatch(trainingResultAction.resetStateTraining());
  };

  return (
    <AssignmentQuestion2
      trainingResult={trainingResult}
      updateTrainingResult={updateTrainingResult}
      resetTrainingResult={resetTrainingResult}
    />
  );
};

export default AssignmentQuestion3Container;
