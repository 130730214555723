import React, { useEffect } from 'react';
import { Button, Card, Col, Form, Row, Select, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TUiState } from 'modules/ui/ui.reducer';
import { TAssignmentsState } from 'modules/assignments/assignments.reducer';
import { TUserDetail } from 'types/groups';
import { Link } from 'react-router-dom';
import { EPath } from 'constants/routes';
import TemplateKpiTable, { TNameGoalSetting } from 'components/TemplateKpiTable/TemplateKpiTable';
import { TGoalTraining, TGoalTrainingSetting } from 'types/assignments';
import { BASE_UPLOAD_URL } from 'constants/index';
import styles from './style.module.scss';
import { getImplementTrainingDate } from '../AssignmentsAdm/AssignmentsAdm.state';

type Props = {
  assignmentsState: TAssignmentsState;
  uiState: TUiState;
};
const AssignmentsAdmDetail = ({ assignmentsState, uiState }: Props) => {
  const [form] = Form.useForm();
  const { trainingDetail } = assignmentsState;
  const { isShowLoading } = uiState;
  const columns: ColumnsType<TUserDetail> = [
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      width: '50%',
    },
    {
      title: '氏名',
      dataIndex: 'name',
      width: '50%',
    },
  ];

  useEffect(() => {
    const { goalSetting } = trainingDetail;
    if (goalSetting) {
      const defaultValue = {
        goal1: goalSetting.goal1.defaultVal[0],
        goal2: goalSetting.goal2.defaultVal[0],
        goal31: goalSetting.goal3.defaultVal[0],
        goal32: goalSetting.goal3.defaultVal[1],
        goal4: goalSetting.goal4.defaultVal[0],
        goal5: goalSetting.goal5.defaultVal[0],
      };
      form.setFieldsValue(defaultValue);
    }
  }, [trainingDetail]);

  const defaultCheckedKpi = trainingDetail.goalSetting
    ? (Object.keys(trainingDetail.goalSetting).filter(
        (i: string) =>
          trainingDetail.goalSetting &&
          !!(trainingDetail.goalSetting[i as keyof TGoalTrainingSetting] as TGoalTraining)?.checked,
      ) as TNameGoalSetting[])
    : [];

  return (
    <div className={styles.groupsAdm}>
      <div className={styles.groups}>
        <Card
          className={styles.groups__wrap}
          title={
            <div className={styles.assignmentsMenuTitle}>
              <p>研修詳細</p>
              <p>{trainingDetail.name}</p>
            </div>
          }
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <Button>
                <Link to={EPath.assignments}>研修一覧に戻る</Link>
              </Button>
            </div>
            <Row>
              <Col xs={24} sm={12}>
                <div>
                  <p className={styles.label}>研修名</p>
                  <span className={styles.value}>{trainingDetail.name}</span>
                  <p className={styles.label}>実施期間の開始日</p>
                  <span className={styles.value}>{getImplementTrainingDate(trainingDetail).startDate}</span>
                  <p className={styles.label}>実施期間の終了日</p>
                  <span className={styles.value}>{getImplementTrainingDate(trainingDetail).endDate}</span>
                  <p className={styles.label}>研修テンプレート</p>
                  <p className={styles.label}>※研修の設問や試算の係数の設定テンプレートです。</p>
                  <Select
                    disabled
                    value={trainingDetail.template?.id}
                    options={[{ label: trainingDetail.template?.name, value: trainingDetail.template?.id }]}
                  />
                </div>
              </Col>
            </Row>
            <div className={styles.sectionWrap}>
              <p className={styles.label}>業務命令書のプレビュー </p>
              <div className={styles.contentWrap}>
                <div className={styles.left}>
                  <p>{trainingDetail.summary}</p>
                </div>
                {trainingDetail.picture && (
                  <div className={styles.right}>
                    <img src={BASE_UPLOAD_URL + trainingDetail.picture} alt={trainingDetail.name} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.sectionWrap}>
              <p className={styles.label}>達成目標の設定</p>
              <Form form={form}>
                <TemplateKpiTable disabled defaultChecked={defaultCheckedKpi} />
              </Form>
            </div>
            <div className={styles.sectionWrap}>
              <p className={styles.label}>研修受講者の設定</p>
              <Table
                columns={columns}
                dataSource={trainingDetail.students}
                pagination={false}
                loading={isShowLoading}
                rowKey="id"
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AssignmentsAdmDetail;
