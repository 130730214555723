import templateAction from 'modules/template/template.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import TemplateKpi from './TemplateKpi';
import { TStep7 } from './TemplateKpi.state';

const TemplateKpiContainer = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);
  const baseLibState = useSelector((state: AppState) => state.pages.baseLibrary);

  const updateTemplate = (data: TStep7, id: string, buttonFooter: boolean, link?: string) => {
    if (currentGroup.id) {
      dispatch(templateAction.updateTemplateStep7({ groupId: currentGroup.id, data, id, buttonFooter, link }));
    }
  };

  return <TemplateKpi template={template} updateTemplate={updateTemplate} baseLibState={baseLibState} />;
};

export default TemplateKpiContainer;
