import { DEFAULT_LIMIT_PAGINATION } from 'constants/pagination';
import { templatesRepositories } from 'repositories/templates';
import { TOptionsQuery } from 'types/common';
import { TTemplateDetail } from 'types/templates';

const DEFAULT_TEMPLATES_LIMIT = DEFAULT_LIMIT_PAGINATION;
const DEFAULT_TEMPLATES_PAGE = 1;

const getTemplates = async (params: TOptionsQuery<TTemplateDetail>) => {
  const res = await templatesRepositories.getTemplates({
    condition: {
      ...params,
      limit: params.limit ?? DEFAULT_TEMPLATES_LIMIT,
      page: params.page ?? DEFAULT_TEMPLATES_PAGE,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const getAllTemplates = async (params: TTemplateDetail) => {
  const res = await templatesRepositories.getAllTemplates({
    condition: params,
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const deleteTemplate = async (data: { templateId: string; groupId: number }) => {
  const { templateId, groupId } = data;
  const res = await templatesRepositories.deleteTemplate({
    pathParameters: {
      id: templateId,
    },
    condition: {
      groupId,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

export const templatesServices = {
  getTemplates,
  getAllTemplates,
  deleteTemplate,
};
