/* eslint-disable complexity */
import { isEqual, map } from 'lodash';
import { TAnswerRadio } from 'types/questions';
import { TQuestion, TStep6, TTemplate } from '../../types/template';
import { TTrainingResult } from '../../types/trainingResult';
import { rawStrArr2EnumLikeObj } from '../utils/enumLikeObj';
import { TBasicYield, TYieldIncrementRatio, TYieldIncrementRatioSetting } from '../../types/setting';
import { IndexedObject } from '../../constants/types';

export const ETrainingStep = rawStrArr2EnumLikeObj([
  'step1',
  'step2',
  'step3',
  'step4',
  'step5',
  'step6',
  'step7',
  'step8',
]);

export type TTrainingStep = keyof typeof ETrainingStep;
export type TTargetYear = 1 | 2 | 3 | 4 | 5;

type TSettingValue = {
  value1: number;
  value2: number;
};

type TRatio = {
  rateTypeA: number;
  rateOtherType: number;
};

export class Calculator {
  private _template: TTemplate = {};

  private _m301: Record<string, number> = {};

  private _y117: Record<number, number> = {};

  private _trainingResult: TTrainingResult = {};

  private startYear = 2;

  private incrementQuestionIds: Record<TTargetYear, string> = {
    1: '',
    2: 'Q0801005',
    3: 'Q0801006',
    4: 'Q0801007',
    5: 'Q0801008',
  } as const;

  private expansionQuestionIds: Record<TTargetYear, string> = {
    1: '',
    2: 'Q0801001',
    3: 'Q0801002',
    4: 'Q0801003',
    5: 'Q0801004',
  } as const;

  private equipmentEffect = [
    'Q0202002',
    'Q0202003',
    'Q0202004',
    'Q0202005',
    'Q0202006',
    'Q0202007',
    'Q0202008',
    'Q0204001',
  ] as const;

  constructor(template: TTemplate, trainingResult: TTrainingResult) {
    this._trainingResult = trainingResult;
    this._template = template;
  }

  updateTemplate(template: TTemplate, trainingResult: TTrainingResult) {
    this._template = template;
  }

  updateTrainingResult(trainingResult: TTrainingResult) {
    this._trainingResult = trainingResult;
  }

  // eslint-disable-next-line complexity
  getSettingFromTemplate = (step: 'step4' | 'step5', questionId: string): number | TSettingValue => {
    const setting = this._template[step];
    if (setting) {
      const cSetting = setting?.yieldIncrementRatio
        ?.find((s: TYieldIncrementRatio) => s.type)
        ?.settings?.find((q: TYieldIncrementRatioSetting) => q.questionId === questionId);
      if (step === 'step4') {
        return cSetting?.value1 ? Number(cSetting?.value1) : 0;
      }
      return {
        value1: cSetting?.value1 ? Number(cSetting?.value1) : 0,
        value2: cSetting?.value2 ? Number(cSetting?.value2) : 0,
      };
    }
    return step === ETrainingStep.step5 ? 0 : { value1: 0, value2: 0 };
  };

  getBasicSettingFromTemplate = (step: 'step4' | 'step5', month: number): number => {
    const setting = this._template[step];
    if (setting) {
      const cSetting = setting?.basicYield?.find((s: TBasicYield) => s.month === month);

      return Number(cSetting?.value) ?? 0;
    }
    return 0;
  };

  getValueStringOfQuestionId = (questionId: string, step: TTrainingStep): string => {
    if (this._trainingResult[step]) {
      const value =
        this._trainingResult[step]?.answer.find((a) => isEqual(a.questionId, questionId))?.answerValue ?? '';
      return value;
    }
    return '';
  };

  getValueOfQuestionId = (questionId: string, step: TTrainingStep): number => {
    if (this._trainingResult[step]) {
      const value = this._trainingResult[step]?.answer.find((a) => isEqual(a.questionId, questionId))?.answerValue ?? 0;
      return Number(value);
    }
    return 0;
  };

  getRequiredWorkingByName = (name: string): TStep6 => {
    const setting = this._template[ETrainingStep.step6];
    if (setting) {
      const cSetting = setting.filter((e: TStep6) => isEqual(e.name.trim(), name.trim()));
      if (cSetting) return cSetting[0];
    }
    return setting[0];
  };

  private _yieldForWorkingTime: number = 0;

  getRequiredWorkingTimeYield = (targetSetting: TStep6, month: number, targetYear: TTargetYear = 1): number => {
    const targetMonth = `month${month}`;
    if (this._yieldForWorkingTime === 0) {
      this._yieldForWorkingTime = this.Y416(targetYear);
    }
    const _yield = this._yieldForWorkingTime;
    // @ts-ignore
    return (targetSetting.laborTime - targetSetting.optimalTime) * (targetSetting[targetMonth] / 100) * _yield;
  };

  getRequiredWorkingTimeAcreage = (targetSetting: TStep6, month: number): number => {
    const targetMonth = `month${month}`;
    const originalArea = this.Q0201003();
    return (
      // @ts-ignore
      (targetSetting.laborTime - targetSetting.optimalTime) * (targetSetting[targetMonth] / 100) * (originalArea / 10)
    );
  };

  getBaseRateTypeA = (month: number) => this.getBasicSettingFromTemplate(ETrainingStep.step5, month);

  /**
   * Total rate of increase in production due to investment in equipment
   */
  getTotalIncreaseByInvestment = (): TRatio => {
    let rateTypeA = 0;
    let rateOtherType = 0;
    // check all equipment
    for (let i = 0; i < this.equipmentEffect.length; i += 1) {
      // Run loops on equipment that affects yield
      const questionId = this.equipmentEffect[i];
      const increaseYieldRateByInvestment = this.increaseYieldRateByInvestment(questionId);
      rateTypeA += increaseYieldRateByInvestment.value1 * this.useEquipment(questionId);
      rateOtherType += increaseYieldRateByInvestment.value2 * this.useEquipment(questionId);
    }
    return {
      rateTypeA,
      rateOtherType,
    };
  };

  _getQuestionListByStep = (temp: IndexedObject, questionId: string): IndexedObject | undefined => {
    // Q0608001
    const _step = Number(questionId.substring(1, 3));
    const groups = temp?.filter((q: any) => q.step === _step);

    const questions: TQuestion[] = [];

    map(groups, (g) => questions.push(...g.questions));

    return questions?.find((i: any) => i.questionId === questionId);
  };

  getAnswerByValue = (value: number, questionId: string, step: 'step2' | 'step3'): IndexedObject | undefined => {
    const temp = this._template[step];
    if (temp) {
      const question = this._getQuestionListByStep(temp, questionId);
      return question?.answer?.find((a: IndexedObject) => Number(a.value) === value);
    }
    return undefined;
  };

  getLabelByQuestionId = (questionId: string, step: 'step2' | 'step3'): string | undefined => {
    if (!this._template) return undefined;

    const temp = this._template[step];

    if (temp) {
      const question = this._getQuestionListByStep(temp, questionId);

      return question?.fieldName ?? '';
    }
    return undefined;
  };

  getLabelQuestionByValue = (valueAnswer: number, questionId: string, step: 'step2' | 'step3'): string | undefined => {
    if (!this._template) return undefined;

    const temp = this._template[step];

    if (temp) {
      const question = this._getQuestionListByStep(temp, questionId);

      return question?.answer.find((a: TAnswerRadio) => a.value === String(valueAnswer))?.label ?? '';
    }
    return undefined;
  };

  getTotalPercentageIncrease = (): number => {
    const ratioByInvestment = this.getTotalIncreaseByInvestment();
    return 100 + (ratioByInvestment.rateTypeA + ratioByInvestment.rateOtherType);
  };

  Q0206001 = () => this.getValueOfQuestionId('Q0206001', ETrainingStep.step2);

  Q0206002 = () => this.getValueOfQuestionId('Q0206002', ETrainingStep.step2);

  Q0201003 = () => this.getValueOfQuestionId('Q0201003', ETrainingStep.step2); // example: 50

  Q0401001 = () => this.getValueOfQuestionId('Q0401001', ETrainingStep.step4);

  Q0402001 = () => this.getValueOfQuestionId('Q0402001', ETrainingStep.step4);

  Q0403001 = () => this.getValueOfQuestionId('Q0403001', ETrainingStep.step4);

  Q0403002 = () => this.getValueOfQuestionId('Q0403002', ETrainingStep.step4);

  Q0403003 = () => this.getValueOfQuestionId('Q0403003', ETrainingStep.step4);

  Q0402005 = () => this.getValueOfQuestionId('Q0402005', ETrainingStep.step4);

  Q0403007 = () => this.getValueOfQuestionId('Q0403007', ETrainingStep.step4);

  Q0503001 = () => this.getValueOfQuestionId('Q0503001', ETrainingStep.step5);

  Q0503002 = () => this.getValueOfQuestionId('Q0503002', ETrainingStep.step5);

  Q0504002 = () => this.getValueOfQuestionId('Q0504002', ETrainingStep.step5);

  Q0505002 = () => this.getValueOfQuestionId('Q0505002', ETrainingStep.step5);

  Q0501002 = () => this.getValueOfQuestionId('Q0501002', ETrainingStep.step5);

  Q0607001 = () => this.getValueOfQuestionId('Q0607001', ETrainingStep.step6);

  Q0607003 = () => this.getValueOfQuestionId('Q0607003', ETrainingStep.step6);

  Q0607005 = () => this.getValueOfQuestionId('Q0607005', ETrainingStep.step6);

  Q0607007 = () => this.getValueOfQuestionId('Q0607007', ETrainingStep.step6);

  Q0701001 = () => this.getValueOfQuestionId('Q0701001', ETrainingStep.step7) * 10000;

  Q0701002 = () => this.getValueOfQuestionId('Q0701002', ETrainingStep.step7);

  Q0701003 = () => this.getValueOfQuestionId('Q0701003', ETrainingStep.step7) * 10000;

  Q0701004 = () => this.getValueOfQuestionId('Q0701004', ETrainingStep.step7);

  Q0701007 = () => this.getValueOfQuestionId('Q0701007', ETrainingStep.step7) * 1000000;

  Q0701006 = () => this.getValueOfQuestionId('Q0701006', ETrainingStep.step7);

  Q0701008 = () => this.getValueOfQuestionId('Q0701008', ETrainingStep.step7) * 1000000;

  Q0701005 = () => this.getValueOfQuestionId('Q0701005', ETrainingStep.step7);

  Q0701009 = () => this.getValueOfQuestionId('Q0701009', ETrainingStep.step7) * 1000000;

  Q0701010 = () => this.getValueOfQuestionId('Q0701010', ETrainingStep.step7) * 1000000;

  Q0701011 = () => this.getValueOfQuestionId('Q0701011', ETrainingStep.step7) * 1000000;

  Q0801001 = () => this.getValueOfQuestionId('Q0801001', ETrainingStep.step8);

  Q0801002 = () => this.getValueOfQuestionId('Q0801002', ETrainingStep.step8);

  Q0801003 = () => this.getValueOfQuestionId('Q0801003', ETrainingStep.step8);

  Q0801004 = () => this.getValueOfQuestionId('Q0801004', ETrainingStep.step8);

  Q0801005 = () => this.getValueOfQuestionId('Q0801005', ETrainingStep.step8);

  Q0801006 = () => this.getValueOfQuestionId('Q0801006', ETrainingStep.step8);

  Q0801007 = () => this.getValueOfQuestionId('Q0801007', ETrainingStep.step8);

  Q0801008 = () => this.getValueOfQuestionId('Q0801008', ETrainingStep.step8);

  /* ====================
   * 土地・施設
   * ====================
   */
  /**
   * 土地費
   * @constructor
   */
  Y211 = (targetYear: TTargetYear = 1): number => {
    // get Q0201001 value
    const Q0201001 = this.getValueOfQuestionId('Q0201001', ETrainingStep.step2); // example: 90000

    // Check answer is 借入れ（有料）or not
    const answer = this.getAnswerByValue(Q0201001, 'Q0201001', ETrainingStep.step2);
    let year;
    const answerLabel = answer?.label.trim();
    if (answerLabel === '借入れ（有料）') {
      year = 5;
    } else if (answerLabel === '借入れ（無料。無償譲渡含む）') {
      year = 0;
    } else {
      year = 1;
    }
    const area = answerLabel === '借入れ（無料。無償譲渡含む）' ? 0 : this.calTotalArea(targetYear);

    return ((Q0201001 * year) / 10) * area; // out: 450000
  };

  /**
   * ハウス費
   * @constructor
   */
  Y212 = (targetYear: TTargetYear = 1): number => {
    // get Q0201005 value
    const Q0201005 = this.getValueOfQuestionId('Q0201005', ETrainingStep.step2); // example: 5000000
    return (Q0201005 / 10) * this.calTotalArea(targetYear); // out: 25000000
  };

  /**
   * 農舎費
   * @constructor
   */
  Y213 = (): number => this.getValueOfQuestionId('Q0201006', ETrainingStep.step2); // example 1000000

  /**
   * 出荷調整作業場費
   * @constructor
   */
  Y214 = (): number => this.getValueOfQuestionId('Q0201007', ETrainingStep.step2); // example 2000000

  /**
   * ハウス施設投資
   * @constructor
   */
  Y210 = (targetYear: TTargetYear = 1): number =>
    this.Y211(targetYear) + this.Y212(targetYear) + this.Y213() + this.Y214(); // example 28450000

  /* ====================
   * 機械
   * ====================
   */

  /**
   * 暖房機（重油）費用
   * @constructor
   */
  Y221 = (targetYear: TTargetYear = 1): number => {
    // get Q0202002 value
    const Q0202002 = this.getValueOfQuestionId('Q0202002', ETrainingStep.step2); // example: 960000
    return (Q0202002 / 10) * this.calTotalArea(targetYear); // out: 4800000
  };

  /**
   * カーテン
   * @constructor
   */
  Y222 = (targetYear: TTargetYear = 1): number => {
    // get Q0202003 value
    const Q0202003 = this.getValueOfQuestionId('Q0202003', ETrainingStep.step2); // example: 960000
    return (Q0202003 / 10) * this.calTotalArea(targetYear); // out: 4800000
  };

  /**
   * 循環扇
   * @constructor
   */
  Y223 = (targetYear: TTargetYear = 1): number => {
    // get Q0202004 value
    const Q0202004 = this.getValueOfQuestionId('Q0202004', ETrainingStep.step2); // example: 210,000
    return (Q0202004 / 10) * this.calTotalArea(targetYear); // out: 1050000
  };

  /**
   * かん水装置
   * @constructor
   */
  Y224 = (targetYear: TTargetYear = 1): number => {
    // get Q0202005 value
    const Q0202005 = this.getValueOfQuestionId('Q0202005', ETrainingStep.step2); // example: 320,000
    return (Q0202005 / 10) * this.calTotalArea(targetYear); // out: 1600000
  };

  /**
   * 炭酸ガス発生機
   * @constructor
   */
  Y225 = (targetYear: TTargetYear = 1): number => {
    // get Q0202006 value
    const Q0202006 = this.getValueOfQuestionId('Q0202006', ETrainingStep.step2); // example: 250,000
    return (Q0202006 / 10) * this.calTotalArea(targetYear); // out: 1250000
  };

  /**
   * 局所使用ダクトファン
   * @constructor
   */
  Y226 = (targetYear: TTargetYear = 1): number => {
    // get Q0202008 value
    const Q0202007 = this.getValueOfQuestionId('Q0202007', ETrainingStep.step2); // example: 70,000
    return (Q0202007 / 10) * this.calTotalArea(targetYear); // out: 350000
  };

  /**
   * ミストシステム
   * @constructor
   */
  Y227 = (targetYear: TTargetYear = 1): number => {
    // get Q0202008 value
    const Q0202008 = this.getValueOfQuestionId('Q0202008', ETrainingStep.step2); // example: 300,000
    return (Q0202008 / 10) * this.calTotalArea(targetYear); // out: 1500000
  };

  /**
   * 合計
   * @constructor
   */
  Y220 = (targetYear: TTargetYear = 1): number =>
    this.Y221(targetYear) +
    this.Y222(targetYear) +
    this.Y223(targetYear) +
    this.Y224(targetYear) +
    this.Y225(targetYear) +
    this.Y226(targetYear) +
    this.Y227(targetYear);

  /* ====================
   * モニタリング装置
   * ====================
   */

  /**
   * 環境モニタリング機器
   * @constructor
   */
  Y231 = (targetYear: TTargetYear = 1): number => {
    // get Q0202002 value
    const Q0203001 = this.getValueOfQuestionId('Q0203001', ETrainingStep.step2); // example: 80,000
    return (Q0203001 / 10) * this.calTotalArea(targetYear); // out: 400000
  };

  /**
   * 警報システム
   * @constructor
   */
  Y232 = (targetYear: TTargetYear = 1): number => {
    // get Q0202002 value
    const Q0203002 = this.getValueOfQuestionId('Q0203002', ETrainingStep.step2); // example: 30000
    return (Q0203002 / 10) * this.calTotalArea(targetYear); // out: 150000
  };

  /**
   * 合計
   * @constructor
   */
  Y230 = (targetYear: TTargetYear = 1): number => this.Y231(targetYear) + this.Y232(targetYear);

  /* ====================
   * 環境制御装置
   * ====================
   */

  /**
   * 環境制御盤
   * @constructor
   */
  Y240 = (targetYear: TTargetYear = 1): number => {
    // get Q0202002 value
    const Q0204001 = this.getValueOfQuestionId('Q0204001', ETrainingStep.step2); // example: 80,000
    return (Q0204001 / 10) * this.calTotalArea(targetYear); // out: 400000
  };

  /* ====================
   * 他大型機械
   * ====================
   */

  /**
   * トラクター
   * @constructor
   */
  Y251 = (): number => {
    // get Q0205001 value
    const Q0205001 = this.getValueOfQuestionId('Q0205001', ETrainingStep.step2);
    // Check answer is レンタル or not
    const answer = this.getAnswerByValue(Q0205001, 'Q0205001', ETrainingStep.step2);
    const year = answer?.label === 'レンタル' && answer?.unit === 'yen/year' ? 5 : 1;
    return Q0205001 * year;
  };

  /**
   * 管理機
   * @constructor
   */
  Y252 = (): number => {
    // get Q0205002 value
    const Q0205002 = this.getValueOfQuestionId('Q0205002', ETrainingStep.step2);
    // Check answer is レンタル or not
    const answer = this.getAnswerByValue(Q0205002, 'Q0205002', ETrainingStep.step2);
    const year = answer?.label === 'レンタル' && answer?.unit === 'yen/year' ? 5 : 1;
    return Q0205002 * year;
  };

  /**
   * 農薬散布機(動力噴霧器)
   * @constructor
   */
  Y253 = (): number => this.getValueOfQuestionId('Q0205003', ETrainingStep.step2);

  /**
   * 合計
   * @constructor
   */
  Y250 = (): number => this.Y251() + this.Y252() + this.Y253();

  /* ====================
   * 他大型機械
   * ====================
   */

  /**
   * 暖房費
   * @constructor
   */
  M1101 = (): number => (this.Q0206001() * this.Q0201003()) / 10;

  /**
   * 炭酸ガス発生費
   * @constructor
   */
  M1102 = (): number => {
    const Q0202006 = this.getValueOfQuestionId('Q0202006', ETrainingStep.step2);
    const answerQ0202006 = this.getAnswerByValue(Q0202006, 'Q0202006', ETrainingStep.step2);
    if (answerQ0202006?.label.trim() === 'いいえ') return 0;
    return (this.Q0206002() * this.Q0201003()) / 10;
  };

  /**
   * 燃料費
   * @constructor
   */
  M1100 = (): number => this.M1101() + this.M1102();

  /**
   * 機器稼働費（年間）
   * @constructor
   */
  Y1101 = (): number => this.M1101() * 7;

  /**
   * 炭酸ガス発生機稼働費
   * @constructor
   */
  Y1102 = (): number => this.M1102() * 5;

  /**
   * 合計
   * @constructor
   */
  Y1100 = (): number => this.Y1101() + this.Y1102();

  /**
   * 初期投資合計
   * Y210、Y220、Y230、Y240、Y250の合計
   * 2年目以降は規模拡大のある年だけ１年目と同等の初期投資費がかかる
   * @constructor
   */
  Y201 = (targetYear: TTargetYear = 1): number => {
    const firstYear = 1;
    const cost =
      this.Y210(firstYear) + this.Y220(firstYear) + this.Y230(firstYear) + this.Y240(firstYear) + this.Y250();
    if (targetYear === 1) {
      return cost;
    }
    if (targetYear === 2 && this.Q0801001()) {
      return cost;
    }
    if (targetYear === 3 && this.Q0801002()) {
      return cost;
    }
    if (targetYear === 4 && this.Q0801003()) {
      return cost;
    }
    if (targetYear === 5 && this.Q0801004()) {
      return cost;
    }
    return 0;
  };

  /**
   * ハウス施設投資の減価償却年数
   * @constructor
   */
  Y504 = (): number => this.getValueOfQuestionId('Q0201008', ETrainingStep.step2);

  /**
   * ハウス施設投資の減価償却費（年）
   * @constructor
   */
  Y505 = (targetYear: TTargetYear = 1): number => this.Y210(targetYear) / this.Y504();

  /**
   * 機器投資の減価償却年数
   * @constructor
   */
  Y506 = (): number => this.getValueOfQuestionId('Q0202001', ETrainingStep.step2);

  /**
   * 機器投資の減価償却費（年）
   * @constructor
   */
  Y507 = (targetYear: TTargetYear = 1): number => this.Y220(targetYear) / this.Y506();

  /**
   * モニタリング投資の減価償却年数
   * @constructor
   */
  Y508 = (): number => this.getValueOfQuestionId('Q0203003', ETrainingStep.step2);

  /**
   * モニタリング投資の減価償却費（年）
   * @constructor
   */
  Y509 = (targetYear: TTargetYear = 1): number => this.Y230(targetYear) / this.Y508();

  /**
   * 制御盤投資の減価償却年数
   * @constructor
   */
  Y510 = (): number => this.getValueOfQuestionId('Q0204002', ETrainingStep.step2);

  /**
   * 制御盤投資の減価償却費（年）
   * @constructor
   */
  Y511 = (targetYear: TTargetYear = 1): number => this.Y240(targetYear) / this.Y510();

  /**
   * 他大型機械投資の減価償却年数
   * @constructor
   */
  Y512 = (): number => this.getValueOfQuestionId('Q0205004', ETrainingStep.step2);

  /**
   *
   * @constructor
   */
  Y513 = (): number => this.Y250() / this.Y512();

  /**
   * 減価償却費
   * @constructor
   */
  Y501 = (targetYear: TTargetYear = 1): number =>
    this.Y505(targetYear) + this.Y507(targetYear) + this.Y509(targetYear) + this.Y511(targetYear) + this.Y513();

  /**
   * 減価償却費
   * @constructor
   */
  M501 = (targetYear: TTargetYear = 1): number => this.Y501(targetYear) / 12;

  /* ====================
   * 分野別投資額
   * already exists function
   * ====================
   */

  /* ====================
   * シミュレーションの結果、収量が確定しました！
   * ====================
   */

  increaseYieldRate = (questionId: string): number => {
    const value = this.getSettingFromTemplate(ETrainingStep.step4, questionId);
    return typeof value === 'number' ? value : 0;
  };

  increaseYieldRateByInvestment = (questionId: string): TSettingValue => {
    const defaultSetting = {
      value1: 0,
      value2: 0,
    };
    const value = this.getSettingFromTemplate(ETrainingStep.step5, questionId);
    return typeof value === 'object' ? value : defaultSetting;
  };

  /**
   * Check use equipment
   * @return 1 if yes else 0
   */
  useEquipment = (questionId: string): number =>
    this.getValueOfQuestionId(questionId, ETrainingStep.step2) > 0 ? 1 : 0;

  /**
   * 10aあたりの収量
   * Tinh toan san luong 10a
   * @constructor
   */
  M415 = (month: number, targetYear: TTargetYear = 1): number => {
    const monthRatio = this.getBasicSettingFromTemplate(ETrainingStep.step4, month);
    let ratio = 1;
    // check all equipment
    for (let i = 0; i < this.equipmentEffect.length; i += 1) {
      // Run loops on equipment that affects yield
      const questionId = this.equipmentEffect[i];
      const increaseYieldRateByInvestment = this.increaseYieldRate(questionId);
      ratio += (increaseYieldRateByInvestment / 100) * this.useEquipment(questionId);
    }

    let increment = 1;

    // In the first year, the expansion of the field area is not considered
    if (targetYear >= this.startYear) {
      const incrementQuestionId = this.incrementQuestionIds[targetYear];
      const newRate = this.getValueOfQuestionId(incrementQuestionId, ETrainingStep.step8);
      increment = newRate > 0 ? newRate : 1;
    }
    return monthRatio * ratio * increment;
  };

  /**
   * 10aあたりの収量
   * @constructor
   */
  Y415 = (targetYear: TTargetYear = 1): number => {
    let total = 0;
    for (let month = 1; month < 12; month += 1) {
      const monthRatio = this.M415(month, targetYear);
      total += monthRatio;
    }
    return total;
  };

  /**
   * Calculator total area by year
   * @param targetYear from 2
   */
  calTotalArea = (targetYear: TTargetYear = 1): number => {
    let totalArea = this.Q0201003();
    const originalArea = this.Q0201003();
    // In the first year, the expansion of the field area is not considered
    if (targetYear >= this.startYear) {
      for (let i = this.startYear; i <= targetYear; i += 1) {
        const k = i as TTargetYear;
        const addingAreaQuestionId = this.expansionQuestionIds[k]; // It changes by the target year. If target year is 2nd, this questionId is 'Q0801001'.
        const addingArea = this.getValueOfQuestionId(addingAreaQuestionId, ETrainingStep.step8) ? originalArea : 0;
        totalArea += addingArea;
      }
    }
    return totalArea;
  };

  /**
   * 収量
   * Tinh toan san luong 10a
   * @constructor
   */
  M416 = (month: number, targetYear: TTargetYear = 1): number => {
    const monthRatio = this.M415(month, targetYear);
    // Check has scale up from second year onwards
    const totalArea = this.calTotalArea(targetYear);
    return (monthRatio * totalArea) / 10;
  };

  /**
   * A等級の収量
   * Tinh toan san luong 10a
   * @constructor
   */
  Y416 = (targetYear: TTargetYear = 1): number => this.Y413(targetYear) + this.Y414(targetYear);

  /**
   * A等級の割合
   * @param month
   * @constructor
   */
  M411 = (month: number): number => {
    const ratioByInvestment = this.getTotalIncreaseByInvestment();
    return (this.getBaseRateTypeA(month) + ratioByInvestment.rateTypeA) / this.getTotalPercentageIncrease();
  };

  /**
   * その他等級の割合
   * @param month
   * @constructor
   */
  M412 = (month: number): number => {
    const ratioByInvestment = this.getTotalIncreaseByInvestment();
    return (100 - this.getBaseRateTypeA(month) + ratioByInvestment.rateOtherType) / this.getTotalPercentageIncrease();
  };

  /**
   *
   * @param month
   * @param targetYear
   * @constructor
   */
  M413 = (month: number, targetYear: TTargetYear = 1) => this.M411(month) * this.M416(month, targetYear);

  /**
   * その他等級の収量
   * @param month
   * @param targetYear
   * @constructor
   */
  M414 = (month: number, targetYear: TTargetYear = 1) => this.M416(month, targetYear) * this.M412(month);

  /**
   * 売上高
   * @param month
   * @param targetYear
   * @constructor
   */
  M101 = (month: number, targetYear: TTargetYear = 1) => {
    // get Q0501002 value
    const Q0501002 = this.getValueOfQuestionId('Q0501002', ETrainingStep.step5);
    return this.M413(month, targetYear) * 1000 * Q0501002 + this.M414(month, targetYear) * 1000 * Q0501002;
  };

  /**
   * 必要労働時間
   * @constructor
   */
  M900 = (targetMonth: number): number =>
    this.M901(targetMonth) +
    this.M902(targetMonth) +
    this.M903(targetMonth) +
    this.M904(targetMonth) +
    this.M905(targetMonth) +
    this.M906(targetMonth) +
    this.M907(targetMonth) +
    this.M908(targetMonth) +
    this.M909(targetMonth) +
    this.M910(targetMonth);

  /**
   * 圃場準備労働時間
   * @constructor
   */
  M901 = (targetMonth: number): number => {
    const stage = '本圃準備';
    const targetSetting = this.getRequiredWorkingByName(stage);
    return this.getRequiredWorkingTimeAcreage(targetSetting, targetMonth);
  };

  /**
   * 育苗労働時間
   * @constructor
   */
  M902 = (targetMonth: number) => {
    const stage = '育苗';
    const targetSetting = this.getRequiredWorkingByName(stage);
    return this.getRequiredWorkingTimeAcreage(targetSetting, targetMonth);
  };

  /**
   * 定植労働時間
   * @constructor
   */
  M903 = (targetMonth: number) => {
    const stage = '定植';
    const targetSetting = this.getRequiredWorkingByName(stage);
    return this.getRequiredWorkingTimeAcreage(targetSetting, targetMonth);
  };

  /**
   * 整枝・摘葉労働時間
   * @constructor
   */
  M904 = (targetMonth: number) => {
    const stage = '整枝・摘葉';
    const targetSetting = this.getRequiredWorkingByName(stage);
    return this.getRequiredWorkingTimeAcreage(targetSetting, targetMonth);
  };

  /**
   * 換気労働時間
   * @constructor
   */
  M905 = (targetMonth: number) => {
    const stage = '換気';
    const targetSetting = this.getRequiredWorkingByName(stage);
    return this.getRequiredWorkingTimeAcreage(targetSetting, targetMonth);
  };

  /**
   * かん水労働時間
   * @constructor
   */
  M906 = (targetMonth: number) => {
    const stage = '灌水';
    const targetSetting = this.getRequiredWorkingByName(stage);
    return this.getRequiredWorkingTimeAcreage(targetSetting, targetMonth);
  };

  /**
   * その他管理労働時間
   * @constructor
   */
  M907 = (targetMonth: number) => {
    const stage = 'その他管理';
    const targetSetting = this.getRequiredWorkingByName(stage);
    return this.getRequiredWorkingTimeAcreage(targetSetting, targetMonth);
  };

  /**
   * 収穫・調整労働時間
   * @constructor
   */
  M908 = (targetMonth: number) => {
    const stage = '収穫･調整';
    const targetSetting = this.getRequiredWorkingByName(stage);
    return this.getRequiredWorkingTimeYield(targetSetting, targetMonth);
  };

  /**
   * 出荷労働時間
   * @constructor
   */
  M909 = (targetMonth: number) => {
    const stage = '出荷';
    const targetSetting = this.getRequiredWorkingByName(stage);
    return this.getRequiredWorkingTimeYield(targetSetting, targetMonth);
  };

  /**
   * 後片付け労働時間
   * @constructor
   */
  M910 = (targetMonth: number) => {
    const stage = '後片づけ';
    const targetSetting = this.getRequiredWorkingByName(stage);
    return this.getRequiredWorkingTimeAcreage(targetSetting, targetMonth);
  };

  /**
   * パート人件費合計
   * @constructor
   */
  M1009 = (targetMonth: number): number => (this.Y1009() * this.M900(targetMonth)) / this.Y900();

  /**
   *
   * @constructor
   */
  M102 = (targetYear: TTargetYear = 1, targetMonth: number): number =>
    this.M501(targetYear) + this.M1009(targetMonth) + this.M1100() + this.M1200();

  /**
   * 給与（社員・家族労働者））
   * @constructor
   */
  M610 = () => {
    const Q0401003 = this.getValueOfQuestionId('Q0401003', ETrainingStep.step4);
    const Q0401001 = this.getValueOfQuestionId('Q0401001', ETrainingStep.step4);
    return Q0401003 * Q0401001;
  };

  /**
   * 給与（社長・代表）
   * @constructor
   */
  M611 = () => this.getValueOfQuestionId('Q0401002', ETrainingStep.step4);

  /**
   * 人件費（社員・家族労働者）
   * @constructor
   */
  M601 = () => this.M610() + this.M611();

  /**
   * 福利厚生（年間）
   * 人数
   */
  numPersonWelfare = (): number => this.Q0401001() + this.Q0402001() + this.Q0403001();

  /**
   * 費用合計
   * 昼食代
   * @constructor
   */
  Y812 = (): number => this.Q0607001() * this.numPersonWelfare();

  /**
   * その他制度1
   * 費用合計
   */
  totalCostOtherSys1 = (): number => this.Q0607003() * this.numPersonWelfare();

  /**
   * その他制度2
   * 費用合計
   */
  totalCostOtherSys2 = (): number => this.Q0607005() * this.numPersonWelfare();

  /**
   * その他制度3
   * 費用合計
   */
  totalCostOtherSys3 = (): number => this.Q0607007() * this.numPersonWelfare();

  /**
   * パート社外教育
   * 費用合計
   * @constructor
   */
  Y822 = () => {
    // get Q0608001 value
    const Q0608001 = this.getValueOfQuestionId('Q0608001', ETrainingStep.step6);
    return Q0608001 * this.Q0403001();
  };

  /**
   * PC
   * 費用合計
   */
  totalCostPc = () => {
    const Q0609002 = this.getValueOfQuestionId('Q0609002', ETrainingStep.step6);
    return Q0609002 * this.Q0401001();
  };

  /**
   * PC
   * 費用合計
   */
  totalCostTablet = () => {
    const Q0609003 = this.getValueOfQuestionId('Q0609003', ETrainingStep.step6);
    return Q0609003 * this.Q0401001();
  };

  /**
   * Smartphone
   * 費用合計
   */
  totalCostSmartPhone = () => {
    const Q0609004 = this.getValueOfQuestionId('Q0609004', ETrainingStep.step6);
    return Q0609004 * this.Q0401001();
  };

  /**
   * 専業者支給物
   * 費用合計
   * Example: questionId = "Q0609002"
   * @param questionId
   */
  totalCostProfessSupply = (questionId: string): number => {
    // get value from questionId
    const answer = this.getValueOfQuestionId(questionId, ETrainingStep.step6);
    return answer * this.Q0401001();
  };

  /**
   * 減価償却費（年）
   * 専業者支給物
   * パート支給物1
   * Example: questionId = "Q0609002"
   * @param questionId
   */
  depreciationExpense2 = (questionId: string): number => this.totalCostProfessSupply(questionId) / 2;

  /**
   * 管理システム
   * Example: questionId = "Q0609026"
   * @param questionId
   */
  depreciationExpense4 = (questionId: string): number => {
    // get value from questionId
    const answer = this.getValueOfQuestionId(questionId, ETrainingStep.step6);
    return answer / 4;
  };

  /**
   * 減価償却費（年）
   * 軽トラック
   * @constructor
   */
  Y838 = (): number => {
    // get Q0609001 value
    const Q0609001 = this.getValueOfQuestionId('Q0609001', ETrainingStep.step6);
    return Q0609001 / 6;
  };

  /**
   * 減価償却費（年）
   * PC
   * @constructor
   */
  Y832 = (): number => this.totalCostProfessSupply('Q0609002') / 4;

  /**
   * 減価償却費（年）
   * タブレット
   * @constructor
   */
  Y833 = (): number => this.totalCostProfessSupply('Q0609003') / 4;

  /**
   * 売上
   * 合計
   * @constructor
   */
  Y101 = (targetYear: TTargetYear = 1): number =>
    (this.Y413(targetYear) + this.Y414(targetYear)) * this.Q0501002() * 1000;

  /**
   *
   * @constructor
   */
  Y1201 = (): number => {
    const Q0402008 = this.getValueOfQuestionId('Q0402008', ETrainingStep.step4);
    return Q0402008 * this.Q0402001();
  };

  /**
   *
   * @constructor
   */
  Y1202 = (): number => this.Q0402005() * 12 * this.Q0402001();

  /**
   *
   * @constructor
   */
  Y1203 = (): number => {
    const Q0402006 = this.getValueOfQuestionId('Q0402006', ETrainingStep.step4);
    const Q0402007 = this.getValueOfQuestionId('Q0402007', ETrainingStep.step4);
    if (Q0402006 !== 1) return 0;
    return this.Q0402005() * Q0402007 * this.Q0402001();
  };

  /**
   * 売上原価
   * 栽培技術コンサル人件費
   * @constructor
   */
  Y1200 = (): number => this.Y1201() + this.Y1202() + this.Y1203();

  /**
   * 売上原価
   * 栽培技術コンサル人件費（月）
   * @constructor
   */
  M1200 = (): number => this.Y1200() / 12;

  /**
   * パートの雇用人数
   * パートの雇用人数
   * @constructor
   */
  Y1001 = (): number => this.Q0403001();

  /**
   * ひと月あたりの平均稼働日数
   * パートの平均稼働日数（月）
   * @constructor
   */
  Y1002 = (): number => this.Q0403002();

  /**
   * １日あたりの平均稼働時間
   * パートの平均稼働時間（日）
   * @constructor
   *
   */
  Y1003 = (): number => this.Q0403003();

  Y1004 = () => this.Q0403007();

  /**
   * １月あたりのパート作業時間合計
   * @constructor
   */
  Y1005 = (): number => this.Y1001() * this.Y1002() * this.Y1003();

  /**
   * 年間パート作業時間合計
   * @constructor
   */
  Y1006 = () => this.Y1004() * this.Y1005();

  /**
   *
   * @constructor
   */
  Y1007 = (): number => this.getValueOfQuestionId('Q0403004', ETrainingStep.step4);

  /**
   * １人あたりのパート人件費
   * @constructor
   */
  Y1008 = (): number => this.Y1007() * this.Y1002() * this.Y1003();

  /**
   * パート人件費合計
   * @constructor
   */
  Y1009 = (): number => this.Y1006() * this.Y1007();

  /**
   * 必要労働時間
   * Required working hours by years
   * @constructor
   */
  Y900 = () => {
    let total = 0;
    for (let month = 1; month <= 12; month += 1) {
      const monthRatio = this.M900(month);
      total += monthRatio;
    }
    return total;
  };

  /**
   * 売上原価
   * @constructor
   */
  Y102 = (targetYear: TTargetYear = 1): number => this.Y501(targetYear) + this.Y1009() + this.Y1200() + this.Y1100();

  /**
   *
   */
  ratio2Revenue = (targetYear: TTargetYear = 1) => this.Y102(targetYear) + this.Y101(targetYear) * 100;

  /**
   * 売上総利益
   * @constructor
   */
  Y103 = (targetYear: TTargetYear = 1): number => this.Y101(targetYear) - this.Y102(targetYear);

  /**
   * 粗利率
   * @constructor
   */
  Y104 = (targetYear: TTargetYear = 1) => (this.Y103(targetYear) / this.Y101(targetYear)) * 100;

  /**
   * A等級の販促規模
   * @constructor
   */
  Y705 = () => this.getValueOfQuestionId('Q0502002', ETrainingStep.step5);

  /**
   * A等級の販促活動費
   * @constructor
   */
  Y703 = () => {
    const Q0502001 = this.getValueOfQuestionId('Q0502001', ETrainingStep.step5);
    return Q0502001 * this.Y705();
  };

  /**
   * その他等級の販促規模
   * @constructor
   */
  Y706 = () => this.getValueOfQuestionId('Q0502002', ETrainingStep.step5);

  /**
   * その他等級の販促活動費
   * @constructor
   */
  Y704 = () => {
    const Q0502001 = this.getValueOfQuestionId('Q0502001', ETrainingStep.step5);
    return Q0502001 * this.Y706();
  };

  /**
   * 販促活動費
   * @constructor
   */
  Y702 = () => this.Y703() + this.Y704();

  /**
   * 人件費（社員・家族労働者）
   * @constructor
   */
  Y601 = () => this.M601() * 12;

  /**
   * 給与（社長・代表）
   * @constructor
   */
  Y611 = () => this.M611() * 12;

  /**
   * 一般管理費
   * 本社人件費
   */
  headOfficeLaborCosts = () => this.Y601() * this.Y611();

  /**
   * その他制度費用（社員・家族労働者対象）
   * @constructor
   */
  Y813 = () => this.Q0607003() + this.Q0607005() + this.Q0607007() * this.Q0401001();

  /**
   * その他制度費用（全員対象）
   * @constructor
   */
  Y814 = () =>
    this.Q0607003() + this.Q0607005() + this.Q0607007() * (this.Q0401001() + this.Q0402001() + this.Q0403001());

  /**
   * スマートフォン減価償却費（社員・家族労働者対象）
   * @constructor
   */
  Y831 = () => {
    const Q0609004 = this.getValueOfQuestionId('Q0609004', ETrainingStep.step6);
    return Q0609004 * (this.Q0401001() / 4);
  };

  /**
   * 元金返済額_1年目
   * @constructor
   */
  Y1301 = () => this.Q0701007() / this.Q0701005();

  /**
   * 借入残高_期初_1年目
   * @constructor
   */
  Y1303 = () => this.Q0701007();

  /**
   * 借入残高_期末_1年目
   * @constructor
   */
  Y1304 = () => this.Y1303() - this.Y1301();

  /**
   * 借入残高_期初_2年目
   * @constructor
   */
  Y1313 = () => this.Y1304() + this.Q0701008();

  /**
   * 元金返済額_2年目
   * @constructor
   */
  Y1311 = () => (this.Q0701007() + this.Q0701008()) / this.Q0701005();

  /**
   * 借入残高_期末_2年目
   * @constructor
   */
  Y1314 = () => this.Y1313() - this.Y1311();

  /**
   * 利息返済額_1年目
   * @constructor
   */
  Y1302 = () => {
    const Q0701006 = this.getValueOfQuestionId('Q0701006', ETrainingStep.step7);
    return (((this.Y1303() + this.Y1304()) / 2) * Q0701006) / 100;
  };

  /**
   * 返済額_1年目
   * @constructor
   */
  Y1300 = () => this.Y1301() + this.Y1302();

  Y1310 = () => this.Y1311() + this.Y1312();

  /**
   * 利息返済額_2年目
   * @constructor
   */
  Y1312 = () => ((this.Y1313() + this.Y1314()) / 2) * (this.Q0701006() / 100);

  /**
   * 元金返済額_3年目
   * @constructor
   */
  Y1321 = () => (this.Q0701007() + this.Q0701008() + this.Q0701009()) / this.Q0701005();

  /**
   * 利息返済額_3年目
   * @constructor
   */
  Y1322 = () => ((this.Y1323() + this.Y1324()) / 2) * (this.Q0701006() / 100);

  /**
   * 借入残高_期初_3年目
   * @constructor
   */
  Y1323 = () => this.Y1314() + this.Q0701009();

  /**
   * 借入残高_期末_3年目
   * @constructor
   */
  Y1324 = () => this.Y1323() - this.Y1321();

  /**
   * 返済額_3年目
   * @constructor
   */
  Y1320 = () => this.Y1321() + this.Y1322();

  /**
   * 元金返済額_4年目
   * @constructor
   */
  Y1331 = () => (this.Q0701007() + this.Q0701008() + this.Q0701009() + this.Q0701010()) / this.Q0701005();

  /**
   * 借入残高_期初_4年目
   * @constructor
   */
  Y1333 = () => this.Y1324() + this.Q0701010();

  /**
   * 借入残高_期末_4年目
   * @constructor
   */
  Y1334 = () => this.Y1333() - this.Y1331();

  /**
   * 利息返済額_4年目
   * @constructor
   */
  Y1332 = () => ((this.Y1333() + this.Y1334()) / 2) * (this.Q0701006() / 100);

  /**
   * 返済額_4年目
   * @constructor
   */
  Y1330 = () => this.Y1331() + this.Y1332();

  /**
   * 元金返済額_5年目
   * @constructor
   */
  Y1341 = () =>
    (this.Q0701007() + this.Q0701008() + this.Q0701009() + this.Q0701010() + this.Q0701011()) / this.Q0701005();

  /**
   * 利息返済額_5年目
   * @constructor
   */
  Y1342 = () => ((this.Y1343() + this.Y1344()) / 2) * (this.Q0701006() / 100);

  /**
   * 借入残高_期初_5年目
   * @constructor
   */
  Y1343 = () => this.Y1334() + this.Q0701011();

  /**
   * 借入残高_期末_5年目
   * @constructor
   */
  Y1344 = () => this.Y1343() - this.Y1341();

  /**
   * 返済額_5年目
   * @constructor
   */
  Y1340 = () => this.Y1341() + this.Y1342();

  /**
   * A等級の割合
   * @param endYear
   * @constructor
   */
  Y411 = (endYear: TTargetYear): number => this.Y413(endYear) / this.Y416(endYear);

  /**
   * その他等級の割合
   * @param endYear
   * @constructor
   */
  Y412 = (endYear: TTargetYear): number => this.Y414(endYear) / this.Y416(endYear);

  /**
   * A等級の収量
   * @constructor
   */
  Y413 = (targetYear: TTargetYear = 1): number => {
    let total = 0;
    for (let month = 1; month <= 12; month += 1) {
      const monthRatio = this.M413(month, targetYear);
      total += monthRatio;
    }
    return total;
  };

  /**
   * その他等級の収量
   * @constructor
   */
  Y414 = (targetYear: TTargetYear = 1): number => {
    let total = 0;
    for (let month = 1; month < 12; month += 1) {
      const monthRatio = this.M414(month, targetYear);
      total += monthRatio;
    }
    return total;
  };

  /**
   * A等級の梱包材料費
   * @constructor
   */
  Y709 = (targetYear: TTargetYear = 1) => {
    const Q0503001 = this.getValueOfQuestionId('Q0503001', ETrainingStep.step5);
    if (Q0503001 === 1) return this.Q0503002() * this.Y413(targetYear);
    return 0;
  };

  /**
   * その他等級の梱包材料費
   * @constructor
   */
  Y710 = (targetYear: TTargetYear = 1) => {
    const Q0503001 = this.getValueOfQuestionId('Q0503001', ETrainingStep.step5);
    if (Q0503001 === 1) return this.Q0503002() * this.Y414(targetYear);
    return 0;
  };

  /**
   * 梱包材料費
   * @constructor
   */
  Y708 = (targetYear: TTargetYear = 1) => this.Y709(targetYear) + this.Y710(targetYear);

  /**
   * A等級の物流運搬費
   * @constructor
   */
  Y713 = (targetYear: TTargetYear = 1) => this.Q0504002() * this.Y413(targetYear);

  /**
   * A等級の物流運搬費
   * @constructor
   */
  Y714 = (targetYear: TTargetYear = 1) => this.Q0504002() * this.Y414(targetYear);

  /**
   * 物流運搬費
   * @constructor
   */
  Y712 = (targetYear: TTargetYear = 1) => this.Y713(targetYear) + this.Y714(targetYear);

  /**
   * A等級のその他販売経費
   * @constructor
   */

  Y716 = (targetYear: TTargetYear = 1): number => {
    const Q0505001 = this.getValueOfQuestionId('Q0505001', ETrainingStep.step5);
    if (Q0505001 === 1) return (this.Y413(targetYear) * this.Q0501002() * 1000 * this.Q0505002()) / 100;
    return 0;
  };

  /**
   * A等級のその他販売経費
   * @constructor
   */
  Y717 = (targetYear: TTargetYear = 1) => {
    const Q0505001 = this.getValueOfQuestionId('Q0505001', ETrainingStep.step5);
    if (Q0505001 === 1) return (this.Y414(targetYear) * this.Q0501002() * 1000 * this.Q0505002()) / 100;
    return 0;
  };

  /**
   * その他販売経費
   * @constructor
   */
  Y715 = (targetYear: TTargetYear = 1) => this.Y716(targetYear) + this.Y717(targetYear);

  /**
   * 販売費
   * @constructor
   */
  Y700 = (targetYear: TTargetYear = 1) =>
    this.Y702() + this.Y708(targetYear) + this.Y712(targetYear) + this.Y715(targetYear);

  /**
   * 売上に対する販売費の割合
   * @constructor
   */
  Y701 = (targetYear: TTargetYear = 1) => (this.Y700(targetYear) / this.Y101(targetYear)) * 100;

  /**
   * 福利厚生費
   * @constructor
   */
  Y810 = () => this.Y812() + this.Y813() + this.Y814();

  /**
   * 教育費
   * @constructor
   */
  Y820 = () => this.Y822();

  /**
   * その他購入物減価償却費（社員・家族労働者対象）
   * @constructor
   */
  Y834 = () => {
    const Q0609008 = this.getValueOfQuestionId('Q0609008', ETrainingStep.step6);
    const Q0609010 = this.getValueOfQuestionId('Q0609010', ETrainingStep.step6);
    const Q0609012 = this.getValueOfQuestionId('Q0609012', ETrainingStep.step6);
    const Q0609014 = this.getValueOfQuestionId('Q0609014', ETrainingStep.step6);
    return ((Q0609008 + Q0609010 + Q0609012 + Q0609014) * this.Q0401001()) / 2;
  };

  /**
   * その他購入物減価償却費（パート対象）
   * @constructor
   */
  Y835 = () => {
    const Q0609016 = this.getValueOfQuestionId('Q0609016', ETrainingStep.step6);
    const Q0609018 = this.getValueOfQuestionId('Q0609018', ETrainingStep.step6);
    const Q0609020 = this.getValueOfQuestionId('Q0609020', ETrainingStep.step6);
    const Q0609022 = this.getValueOfQuestionId('Q0609022', ETrainingStep.step6);
    const Q0609024 = this.getValueOfQuestionId('Q0609024', ETrainingStep.step6);
    return ((Q0609016 + Q0609018 + Q0609020 + Q0609022 + Q0609024) * this.Q0403001()) / 2;
  };

  /**
   * 管理用システム減価償却費
   * @constructor
   */
  Y836 = () => {
    const Q0609026 = this.getValueOfQuestionId('Q0609026', ETrainingStep.step6);
    const Q0609028 = this.getValueOfQuestionId('Q0609028', ETrainingStep.step6);
    const Q0609030 = this.getValueOfQuestionId('Q0609030', ETrainingStep.step6);
    const Q0609032 = this.getValueOfQuestionId('Q0609032', ETrainingStep.step6);
    const Q0609034 = this.getValueOfQuestionId('Q0609034', ETrainingStep.step6);
    return (Q0609026 + Q0609028 + Q0609030 + Q0609032 + Q0609034) / 4;
  };

  /**
   * その他経費
   * @constructor
   */
  Y837 = () => {
    const Q0609036 = this.getValueOfQuestionId('Q0609036', ETrainingStep.step6);
    const Q0609038 = this.getValueOfQuestionId('Q0609038', ETrainingStep.step6);
    const Q0609040 = this.getValueOfQuestionId('Q0609040', ETrainingStep.step6);
    const Q0609042 = this.getValueOfQuestionId('Q0609042', ETrainingStep.step6);
    const Q0609044 = this.getValueOfQuestionId('Q0609044', ETrainingStep.step6);
    return Q0609036 + Q0609038 + Q0609040 + Q0609042 + Q0609044;
  };

  /**
   * その他
   * @constructor
   */
  Y830 = () =>
    this.Y831() + this.Y832() + this.Y833() + this.Y834() + this.Y835() + this.Y836() + this.Y837() + this.Y838();

  /**
   * 一般管理費
   * @constructor
   */
  Y800 = () => this.Y810() + this.Y820() + this.Y830();

  /**
   * 各等級の販売価格
   * @constructor
   */
  Y401 = () => this.getValueOfQuestionId('Q0501002', ETrainingStep.step5);

  /**
   * 売上に対する販促活動費の割合
   * @constructor
   */
  Y707 = (targetYear: TTargetYear = 1) => (this.Y702() / this.Y101(targetYear)) * 100;

  /**
   * 売上に対する梱包材料費の割合
   * @constructor
   */
  Y711 = (targetYear: TTargetYear = 1) => (this.Y708(targetYear) / this.Y101(targetYear)) * 100;

  /**
   * 売上に対するその他販売経費の割合
   * @constructor
   */
  Y718 = (targetYear: TTargetYear = 1) => (this.Y715(targetYear) / this.Y101(targetYear)) * 100;

  /**
   * 給与（社員・家族労働者）
   * @constructor
   */
  Y610 = (): number => this.M610() * 12;

  M700 = (targetYear: TTargetYear = 1): number => this.M702() + this.M708(targetYear) + this.M712(targetYear);

  M702 = (): number => this.M703() + this.M704();

  M703 = (): number => this.Y703() / 12;

  M704 = (): number => this.Y704() / 12;

  M706 = (): number => 0;

  M708 = (targetYear: TTargetYear = 1): number => this.M709(targetYear) + this.M710(targetYear);

  M709 = (targetYear: TTargetYear = 1): number => this.Y713(targetYear) / 12;

  M710 = (targetYear: TTargetYear = 1): number => this.Y710(targetYear) / 12;

  M712 = (targetYear: TTargetYear = 1): number => this.M713(targetYear) + this.M714(targetYear);

  M713 = (targetYear: TTargetYear = 1): number => this.Y713(targetYear) / 12;

  M714 = (targetYear: TTargetYear = 1): number => this.Y714(targetYear) / 12;

  M800 = (): number => this.Y800() / 12;

  M105 = (targetYear: TTargetYear = 1): number => this.M700(targetYear) + this.M601() + this.M800();

  Y105 = (targetYear: TTargetYear = 1): number => this.M105(targetYear) * 12;

  Y106 = (targetYear: TTargetYear = 1): number => this.Y103(targetYear) - this.Y105(targetYear);

  Y107 = (targetYear: TTargetYear = 1): number => (this.Y106(targetYear) / this.Y101(targetYear)) * 100;

  /**
   * Y1302 1st year
   * Y1312 2nd year
   * Y1322 3rd year
   * Y1332 4th year
   * Y1342 5th year
   * @constructor
   */
  Y108 = (targetYear: TTargetYear = 1): number => {
    switch (targetYear) {
      case 2:
        return this.Y1312();
      case 3:
        return this.Y1322();
      case 4:
        return this.Y1332();
      case 5:
        return this.Y1342();
      case 1:
      default:
        return this.Y1302();
    }
  };

  Y109 = (targetYear: TTargetYear = 1): number => this.Y106(targetYear) - this.Y108(targetYear);

  Y110 = (targetYear: TTargetYear = 1): number => (this.Y109(targetYear) / this.Y101(targetYear)) * 100;

  Y111 = (targetYear: TTargetYear = 1): number => this.Y502(targetYear);

  Y502 = (targetYear: TTargetYear = 1): number => {
    switch (targetYear) {
      case 1:
      case 2:
      case 3:
        return this.getValueOfQuestionId('Q0701004', ETrainingStep.step7);
      default:
        return 0;
    }
  };

  Y112 = (targetYear: TTargetYear = 1): number => 0;

  Y113 = (targetYear: TTargetYear = 1): number => this.Y109(targetYear) + this.Y111(targetYear) - this.Y112();

  Y114 = (targetYear: TTargetYear = 1): number => Math.min(0, this.Y113(targetYear)) * 0.3;

  Y115 = (targetYear: TTargetYear = 1): number => this.Y113(targetYear) - this.Y114(targetYear);

  Y116 = (targetYear: TTargetYear = 1): number => (this.Y115(targetYear) / this.Y101(targetYear)) * 100;

  Y117 = (targetYear: TTargetYear = 1): number => this._y117[targetYear];

  M301 = (month: number, targetYear: number) => this._m301[`${month}-${targetYear}`];

  calY117All = () => {
    for (let year = 1; year <= 5; year += 1) {
      let currentValue;
      if (year === 1) {
        currentValue = this.Y115(year as TTargetYear);
      } else {
        currentValue = this._y117[year - 1] + this.Y115(year as TTargetYear);
      }
      this._y117 = { ...this._y117, [year]: currentValue };
    }
  };

  M3 = () => {
    for (let year = 1; year <= 5; year += 1) {
      for (let month = 1; month <= 12; month += 1) {
        const res =
          this.M302(month, year as TTargetYear) +
          this.M303(month, year as TTargetYear) -
          this.M304(year as TTargetYear, month) +
          this.M305(month, year as TTargetYear) +
          this.M306(month, year as TTargetYear) -
          this.M307(month, year as TTargetYear);
        const currentMonth = `${month}-${year}`;
        this._m301 = { ...this._m301, [currentMonth]: res };
      }
    }
    return this._m301;
  };

  M302 = (month: number, targetYear: TTargetYear = 1): number => {
    if (month === 1 && targetYear === 1) {
      return 0;
    }
    if (month === 1) {
      return this.M301(12, (targetYear - 1) as TTargetYear);
    }
    const previousMonth = month - 1;
    return this.M301(previousMonth, targetYear);
  };

  M307 = (month: number, targetYear: TTargetYear = 1): number => {
    if (month === 12 && targetYear === 1) {
      return this.Y1300();
    }
    if (month === 12 && targetYear === 2) {
      return this.Y1310();
    }
    if (month === 12 && targetYear === 3) {
      return this.Y1320();
    }
    if (month === 12 && targetYear === 4) {
      return this.Y1330();
    }
    if (month === 12 && targetYear === 5) {
      return this.Y1340();
    }
    return 0;
  };

  M303 = (month: number, targetYear: TTargetYear = 1): number => this.M101(month, targetYear);

  M304 = (targetYear: TTargetYear = 1, targetMonth: number): number => {
    if (targetMonth === 1 && targetYear === 1) {
      return (
        this.Y210(targetYear) +
        this.Y220(targetYear) +
        this.Y230(targetYear) +
        this.M1009(targetMonth) +
        this.M105(targetYear) +
        this.M1200()
      );
    }
    if (targetMonth === 1 && targetYear === 2) {
      return (
        (this.Y210(1) + this.Y220(1) + this.Y230(1)) * this.Q0801001() +
        this.M1100() +
        this.M1009(targetMonth) +
        this.M105(targetYear) +
        this.M1200()
      );
    }
    if (targetMonth === 1 && targetYear === 3) {
      return (
        (this.Y210(1) + this.Y220(1) + this.Y230(1)) * this.Q0801002() +
        this.M1100() +
        this.M1009(targetMonth) +
        this.M105(targetYear) +
        this.M1200()
      );
    }
    if (targetMonth === 1 && targetYear === 4) {
      return (
        (this.Y210(1) + this.Y220(1) + this.Y230(1)) * this.Q0801003() +
        this.M1100() +
        this.M1009(targetMonth) +
        this.M105(targetYear) +
        this.M1200()
      );
    }
    if (targetMonth === 1 && targetYear === 5) {
      return (
        (this.Y210(1) + this.Y220(1) + this.Y230(1)) * this.Q0801004() +
        this.M1100() +
        this.M1009(targetMonth) +
        this.M105(targetYear) +
        this.M1200()
      );
    }

    return this.M1009(targetMonth) + this.M1100() + this.M105(targetYear) + this.M1200();
  };

  M305 = (month: number, targetYear: TTargetYear = 1): number => {
    if (month === 1 && targetYear === 1) {
      return this.Q0701007() + this.Q0701003() + this.Q0701004();
    }
    if (month === 1 && targetYear === 2) {
      return this.Q0701008() + this.Q0701004();
    }
    if (month === 1 && targetYear === 3) {
      return this.Q0701009() + this.Q0701004();
    }
    if (month === 1 && targetYear === 4) {
      return this.Q0701010();
    }
    if (month === 1 && targetYear === 5) {
      return this.Q0701011();
    }
    return 0;
  };

  M306 = (month: number, targetYear: TTargetYear = 1): number => {
    if (month === 1 && targetYear === 1) return this.Q0701001();
    return 0;
  };

  Y261 = (targetYear: TTargetYear = 1): number | string => {
    if (targetYear === 1) return this.Q0701001();
    return '-';
  };

  Y262 = (targetYear: TTargetYear = 1): number => {
    switch (targetYear) {
      case 1:
        return this.Q0701004();
      case 2:
        return this.Q0701004();
      case 3:
        return this.Q0701004();
      default:
        return 0;
    }
  };

  Y263 = (targetYear: TTargetYear = 1): number => {
    switch (targetYear) {
      case 2:
        return this.Q0701008();
      case 3:
        return this.Q0701009();
      case 4:
        return this.Q0701010();
      case 5:
        return this.Q0701011();
      case 1:
      default:
        return this.Q0701007();
    }
  };

  Y264 = (targetYear: TTargetYear = 1): number => 0;

  Y265 = (targetYear: TTargetYear = 1): number => {
    switch (targetYear) {
      case 2:
        return this.Y1310();
      case 3:
        return this.Y1320();
      case 4:
        return this.Y1330();
      case 5:
        return this.Y1340();
      case 1:
      default:
        return this.Y1300();
    }
  };

  Y266 = (targetYear: TTargetYear = 1): number | string => {
    switch (targetYear) {
      case 1:
        return this.Q0701003();
      default:
        return '-';
    }
  };

  totalSellingExpenses = (targetYear: TTargetYear = 1): number => this.Y700(targetYear);

  annualSales = (targetYear: TTargetYear = 1): number =>
    (this.Y413(targetYear) + this.Y414(targetYear)) * this.Y401() * 1000;
}
