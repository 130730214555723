import { TOptionsQuery } from 'types/common';
import { TTemplateDetail } from 'types/templates';
import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory();

export default {
  getTemplates: ac<TOptionsQuery<TTemplateDetail>>('getTemplates'),
  getAllTemplates: ac<TTemplateDetail & { groupId: number }>('getAllTemplates'),
  deleteTemplate: ac<{ templateId: string; groupId: number }>('deleteTemplate'),
};
