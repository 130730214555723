import { TOptionsQuery } from 'types/common';
import { TUserDetail } from 'types/groups';
import actionCreatorFactory from 'typescript-fsa';

const ac = actionCreatorFactory();

export default {
  getUsers: ac<TOptionsQuery<TUserDetail>>('getUsers'),
  updateUser: ac<TUserDetail & { groupId: number }>('updateUser'),
  createUser: ac<TUserDetail & { groupId: number }>('createUser'),
  deleteUser: ac<{ id: string; groupId: number }>('deleteUser'),
};
