import assignmentsAction from 'modules/assignments/assignments.action';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'store';
import AssignmentsAdmDetail from './AssignmentsAdmDetail';

const AssignmentsAdmDetailContainer = () => {
  const dispatch = useDispatch();
  const { training } = useParams<{ training: string }>();
  const assignmentsState = useSelector((state: AppState) => state.pages.assignments);
  const uiState = useSelector((state: AppState) => state.pages.ui);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);

  const getTraining = (trainingId: string) => {
    if (currentGroup.id) dispatch(assignmentsAction.getTraining({ id: trainingId, groupId: currentGroup.id }));
  };

  useEffect(() => {
    if (training && currentGroup.id) {
      getTraining(training);
    }
  }, [training, currentGroup]);

  return <AssignmentsAdmDetail assignmentsState={assignmentsState} uiState={uiState} />;
};

export default AssignmentsAdmDetailContainer;
