import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const getUsers: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'users',
};

const updateUser: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'users/:id',
};

const createUser: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'users',
};

const deleteUser: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'users/:id',
};

export const groupsRepositories = {
  getUsers: apiClient.getFn(getUsers),
  createUser: apiClient.postFn(createUser),
  updateUser: apiClient.patchFn(updateUser),
  deleteUser: apiClient.deleteFn(deleteUser),
};
