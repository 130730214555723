import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map, mergeMap } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { AxiosResponse } from 'libs/axios';
import { uiModule } from 'modules/ui/ui.reducer';
import { messageModule } from 'modules/message/message.reducer';
import { trainingResultServices } from 'services/trainingResult';
import { TTrainingResult } from 'types/trainingResult';
import { routers } from 'constants/router';
import { push } from 'connected-react-router';
import actions from './trainingResult.action';
import { trainingResultModule } from './trainingResult.reducer';

export const createTrainingResultEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.createTrainingResult),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(trainingResultServices.createTrainingResult(payload.data)),
        next: (t: TTrainingResult) => [
          push(routers.U5.path.replace(':trainingId', t.training?.id ?? '')),
          trainingResultModule.actions.saveTraining(t),
        ],
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateTrainingResultEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateTrainingResult),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(
          trainingResultServices.editTrainingResult(payload.groupId, payload.data, payload.id, payload.step ?? ''),
        ),
        next: (v: TTrainingResult) => [trainingResultModule.actions.saveTraining(v)],
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getTrainingResultByTrainingEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getTrainingResultByTraining),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(trainingResultServices.getTrainingResultByTraining(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (t: TTrainingResult) => trainingResultModule.actions.saveTraining(t),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const getTrainingResultByTrainingAndStudent: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (
  action$,
) =>
  action$.pipe(
    ofAction(actions.getTrainingResultByTrainingAndStudent),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(
          trainingResultServices.getTrainingResultByTrainingAndStudent(
            payload.trainingId,
            payload.studentId,
            payload.groupId,
          ),
        ),
        complete: uiModule.actions.hideLoading(),
        next: (t: TTrainingResult) => trainingResultModule.actions.saveTraining(t),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const resetStateTraining: Epic<AnyAction, WrapAction<any>> = (action$) =>
  action$.pipe(
    ofAction(actions.resetStateTraining),
    mergeMap(() => [trainingResultModule.actions.clearStateUpdate()]),
  );
