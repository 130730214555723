import React from 'react';
import '@antv/s2-react/dist/style.min.css';
import RowTwo from './RowTwo';
import RowThree from './RowThree';
import RowFour from './RowFour';
import RowOne from './RowOne';

const Tab4: React.FC<{ configTable: any }> = ({ configTable }) => (
  <>
    <RowOne configTable={configTable} />
    <RowTwo configTable={configTable} />
    <RowThree configTable={configTable} />
    <RowFour configTable={configTable} />
  </>
);

export default Tab4;
