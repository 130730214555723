import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { groupsServices } from 'services/groups';
import { TUserDetail } from 'types/groups';
import { AxiosResponse, TResponsePagination } from 'libs/axios';
import { uiModule } from 'modules/ui/ui.reducer';
import { messageModule } from 'modules/message/message.reducer';
import actions from './groups.action';
import { groupsModule } from './groups.reducer';

export const getUsersEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getUsers),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(groupsServices.getUsers(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: TResponsePagination<TUserDetail>) => [
          groupsModule.actions.saveUsers(u),
          groupsModule.actions.setParams(payload),
        ],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const createUserEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$, state$) =>
  action$.pipe(
    ofAction(actions.createUser),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFns: from(groupsServices.createUser(payload)),
        next: () => {
          const { params } = state$.value.pages.groups;
          return [actions.getUsers(params), messageModule.actions.setSuccess('')];
        },
        previous: uiModule.actions.showLoading(),
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const updateUserEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.updateUser),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(groupsServices.updateUser(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: TUserDetail) => [groupsModule.actions.saveUser(u), messageModule.actions.setSuccess('')],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );

export const deleteUserEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.deleteUser),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(groupsServices.deleteUser(payload)),
        complete: uiModule.actions.hideLoading(),
        next: (u: TUserDetail) => [groupsModule.actions.deleteUser(u), messageModule.actions.setSuccess('')],
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
