import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import MainButton from 'components/MainButton/MainButton';
import { TAddTrainingBody, TTrainingDetail } from 'types/assignments';
import { TOptionsQuery, TPagination } from 'types/common';
import { getDateFormat } from 'libs/helpers/date';
import { DEFAULT_FORMAT_DATE } from 'constants/date';
import { EPath } from 'constants/routes';
import PaginationCustom from 'components/PaginationCustom/PaginationCustom';
import { useHistory } from 'react-router-dom';
import styles from './style.module.scss';

const Task: React.FC<{
  title: string;
  trainings: TTrainingDetail[];
  pagination: TPagination;
  loading: boolean;
  getTrainingsWorking: (params: TOptionsQuery<TAddTrainingBody>) => void;
  getTrainingsFinish: (params: TOptionsQuery<TAddTrainingBody>) => void;
  finish: boolean;
  createTrainingsResult: (trainingId: string) => void;
  userId: string | undefined;
}> = ({
  title,
  trainings,
  pagination,
  loading,
  getTrainingsWorking,
  getTrainingsFinish,
  finish,
  createTrainingsResult,
  userId,
}) => {
  const history = useHistory();
  const createTraining = (id?: string) => {
    if (id) {
      createTrainingsResult(id);
    }
  };

  const clickOpen = (record: TTrainingDetail) => {
    if (record.seen) {
      const seen = record.seen.filter((seen) => seen === userId);
      if (!seen.length) {
        createTraining(record.id);
      } else {
        history.push(EPath.U4_Menu.replace(':trainingId', record.id ?? ''));
      }
    }
  };

  const columns: ColumnsType<TTrainingDetail> = [
    {
      title: 'タイトル',
      dataIndex: 'name',
      width: '45%',
    },
    {
      title: '更新日',
      width: '35%',
      render: (_: any, record: TTrainingDetail) => getDateFormat(record.updatedAt, DEFAULT_FORMAT_DATE),
    },
    {
      render: (_: any, record: TTrainingDetail) => (
        <div>
          <MainButton text="課題を開く" onClick={() => clickOpen(record)} />
        </div>
      ),
      width: '20%',
      align: 'center',
    },
  ];

  const onChangePage = (page: number, limit: number) =>
    finish ? getTrainingsFinish({ page, limit }) : getTrainingsWorking({ page, limit });

  return (
    <div className={styles.taskWarp}>
      <p className={styles.taskTitle}>{title}</p>
      <div className={styles.taskTable}>
        <Table bordered columns={columns} dataSource={trainings} pagination={false} loading={loading} rowKey="id" />
      </div>
      <div className={styles.pagination}>
        <PaginationCustom
          current={pagination.page}
          total={pagination.totalResults}
          pageSize={pagination.limit}
          disabled={loading}
          onChange={onChangePage}
          showQuickJumper={false}
          showSizeChanger={false}
          showLessItems={false}
          showTotal={false}
          hideOnSinglePage
        />
      </div>
    </div>
  );
};

export default Task;
