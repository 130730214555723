import React, { ReactNode } from 'react';
import { Modal, Space } from 'antd';
import MainButton from 'components/MainButton/MainButton';

const ModalDelete: React.FC<{
  open: boolean;
  onClose: () => void;
  handleDelete: () => void;
  title: string;
  subTitle: string | ReactNode;
}> = ({ open, onClose, handleDelete, title, subTitle }) => (
  <Modal
    title={title}
    centered
    open={open}
    onCancel={onClose}
    footer={[
      <Space align="center" key="actions">
        <MainButton subButton text="いいえ" onClick={onClose} />
        <MainButton
          text="はい"
          onClick={() => {
            handleDelete();
            onClose();
          }}
        />
      </Space>,
    ]}
  >
    <p>{subTitle}</p>
  </Modal>
);

export default ModalDelete;
