import React from 'react';
import { MinusCircleFilled, PlusCircleFilled, SwapOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import { TQuestionRadio } from 'types/questions';
import styles from './style.module.scss';

const FormDragDrop: React.FC<{ control: Control<TQuestionRadio, any>; register: UseFormRegister<TQuestionRadio> }> = ({
  control,
  register,
}) => {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'answer',
  });

  const handleDrag = (result: DropResult) => {
    const { source, destination } = result;
    if (destination) {
      move(source.index, destination.index);
    }
  };

  return (
    <>
      <p className={styles.item}>項目</p>
      <DragDropContext onDragEnd={handleDrag}>
        <div>
          <Droppable droppableId="radio-items">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className={styles.li}>
                  <Space style={{ display: 'flex', marginBottom: 8, marginTop: 8 }} align="baseline">
                    <div className={styles.label}>
                      <div className={styles.label__left}>
                        <input value="ラベル" disabled />
                      </div>
                      <div className={styles.label__right}>
                        <input value="値" disabled />
                      </div>
                    </div>
                  </Space>
                </div>
                {fields.map((item, index) => (
                  <Draggable key={`radio[${item.id}]`} draggableId={`item-${item.id}`} index={index}>
                    {(provided2) => (
                      <div className={styles.li} key={item.id} ref={provided2.innerRef} {...provided2.draggableProps}>
                        <Space style={{ display: 'flex', marginBottom: 8, marginTop: 8 }} align="baseline">
                          <SwapOutlined {...provided2.dragHandleProps} />
                          <div className={styles.formItem}>
                            <div className={styles.formItem__left}>
                              <input {...register(`answer.${index}.label`)} />
                            </div>
                            <div className={styles.formItem__right}>
                              <input {...register(`answer.${index}.value`)} />
                            </div>
                          </div>
                          <MinusCircleFilled onClick={() => remove(index)} className={styles.iconRemove} />
                        </Space>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <Button type="dashed" onClick={() => append({ label: '', value: '', unit: 'ー' })} className={styles.buttonAdd}>
        <PlusCircleFilled className={styles.iconAdd} />
      </Button>
    </>
  );
};

export default FormDragDrop;
