import React from 'react';
import '@antv/s2-react/dist/style.min.css';
import { RadioChangeEvent } from 'antd';
import { TTargetYear } from 'libs/calculators';
import RowOne from './RowOne';
import RowTwo from './RowTwo';
import RowThree from './RowThree';
import RowFour from './RowFour';

const Tab1: React.FC<{
  configTable: any;
  handleProfitAndLossEachYear?: (e: RadioChangeEvent) => void;
  handleStatementExpenseEachYear?: (e: RadioChangeEvent) => void;
  profitAndLossEachYear: TTargetYear;
  statementExpenseEachYear: TTargetYear;
}> = ({
  configTable,
  handleProfitAndLossEachYear,
  handleStatementExpenseEachYear,
  profitAndLossEachYear,
  statementExpenseEachYear,
}) => (
  <>
    <RowOne configTable={configTable} />
    <RowTwo configTable={configTable} />
    <RowThree
      configTable={configTable}
      onChange={handleProfitAndLossEachYear}
      profitAndLossEachYear={profitAndLossEachYear}
    />
    <RowFour
      configTable={configTable}
      onChange={handleStatementExpenseEachYear}
      statementExpenseEachYear={statementExpenseEachYear}
    />
  </>
);

export default Tab1;
