import React from 'react';
import { Row, Card, Form, Input } from 'antd';
import { defaultValidateMessages } from 'constants/validate/message';
import MainButton from '../../components/MainButton/MainButton';
import styles from './style.module.scss';

const ForgotPassword: React.FC<{ forgotPassword: (email: string) => void }> = ({ forgotPassword }) => {
  const handelSubmit = (values: { email: string }) => {
    forgotPassword(values.email);
  };

  return (
    <Row justify="center" align="middle" style={{ height: '100%' }}>
      <Card type="inner" title="パスワード変更URL送信" className={styles.cardWrap}>
        <p className={styles.text}>
          パスワード再設定ページのURLを記載したメールを送信します。ログインIDとして利用中のメールアドレスを入力してください。
        </p>
        <div className={styles.formWarp}>
          <Form layout="vertical" onFinish={handelSubmit} validateMessages={defaultValidateMessages}>
            <Form.Item label="メールアドレス" name="email" style={{ marginBottom: '16px', marginTop: '25px' }}>
              <Input placeholder="Enter your email" name="email" />
            </Form.Item>
            <Form.Item>
              <MainButton htmlType="submit" text="メール送信" />
            </Form.Item>
          </Form>
        </div>
      </Card>
    </Row>
  );
};

export default ForgotPassword;
