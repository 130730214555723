import { TSelectOptions } from 'types/common';

export const selectOptions: TSelectOptions = [
  {
    name: 'キュウリ',
    value: '1',
  },
];

export const templateState = {
  name: '',
  picture: '',
  description: '',
  template: '',
  isCompleted: false,
  baseLibrary: 0,
};

export type TTemplate = typeof templateState;
