import React from 'react';
import { Col, Row } from 'antd';
import SheetTable from 'components/SheetTable/SheetTable';
import styles from '../style.module.scss';

const Row4: React.FC<{ configTable: any }> = ({ configTable }) => (
  <Row
    gutter={[
      { xs: 0, sm: 20 },
      { xs: 20, sm: 0 },
    ]}
  >
    <Row className={`${styles.subTitle} ${styles.borderSubTitleB}`}>
      <div className="left">年別収量推移表</div>
    </Row>
    <Col xs={24} sm={18} style={{ height: '250px' }}>
      {configTable?.configTableTab4Three && (
        <SheetTable dataCfg={configTable?.configTableTab4Three} options={configTable?.OPTIONS_TABLE_TAB4_2} />
      )}
    </Col>
  </Row>
);

export default Row4;
