import React from 'react';
import { AppState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import questionsAction from 'modules/questions/questions.action';
import templateAction from 'modules/template/template.action';
import AdmAddQuestion1 from './AdmAddQuestion1';
import { TStep2 } from './AdmAddQuestion1.state';

const AdmAddQuestion1Container = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const baseLibState = useSelector((state: AppState) => state.pages.baseLibrary);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);

  const getQuestions = (groupId: number) => dispatch(questionsAction.getQuestions(groupId));

  const updateTemplate = (data: TStep2, id: string, buttonFooter: boolean, link?: string) => {
    if (currentGroup.id) {
      dispatch(templateAction.updateTemplateStep2({ groupId: currentGroup.id, data, id, buttonFooter, link }));
    }
  };

  return (
    <AdmAddQuestion1
      template={template}
      getQuestions={getQuestions}
      updateTemplate={updateTemplate}
      currentGroup={currentGroup}
      baseLibState={baseLibState}
    />
  );
};

export default AdmAddQuestion1Container;
