import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const forgotPassword: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'auth/forgot-password',
};

const resetPassword: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'auth/reset-password',
};

export const forgotPasswordRepositories = {
  forgotPassword: apiClient.postFn(forgotPassword),
  resetPassword: apiClient.postFn(resetPassword),
};
