import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const getTrainings: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'trainings',
};

const getTraining: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'trainings/:id',
};

const updateTraining: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'trainings/:id',
};

const createTraining: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'trainings',
};

const deleteTraining: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'trainings/:id',
};

export const assignmentsRepositories = {
  getTrainings: apiClient.getFn(getTrainings),
  getTraining: apiClient.getFn(getTraining),
  createTraining: apiClient.postFn(createTraining),
  updateTraining: apiClient.patchFn(updateTraining),
  deleteTraining: apiClient.deleteFn(deleteTraining),
};
