import forgotPasswordAction from 'modules/forgotPassword/forgotPassword.action';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ResetPassword from './ResetPassword';

const ResetPasswordContainer = () => {
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();

  const resetPassword = useCallback(
    (newPassword: string) => {
      dispatch(forgotPasswordAction.resetPassword({ token, newPassword }));
    },
    [token],
  );

  return <ResetPassword resetPassword={resetPassword} />;
};

export default ResetPasswordContainer;
