import React, { useEffect } from 'react';
import Task from 'components/Task/Task';
import { TOptionsQuery } from 'types/common';
import { TAddTrainingBody } from 'types/assignments';
import { TAssignmentsState } from 'modules/assignments/assignments.reducer';
import { TGroupsClassDetail } from 'types/groupsClass';
import { TUiState } from 'modules/ui/ui.reducer';
import styles from './style.module.scss';

type Props = {
  getTrainingsWorking: (params: TOptionsQuery<TAddTrainingBody>) => void;
  getTrainingsFinish: (params: TOptionsQuery<TAddTrainingBody>) => void;
  assignmentsState: TAssignmentsState;
  uiState: TUiState;
  createTrainingsResult: (trainingId: string) => void;
  userId: string | undefined;
  currentGroup: TGroupsClassDetail;
};
const Home = ({
  assignmentsState,
  uiState,
  getTrainingsFinish,
  getTrainingsWorking,
  createTrainingsResult,
  userId,
  currentGroup,
}: Props) => {
  const { trainingsFinish, paginationFinish, trainingsWorking, paginationWorking } = assignmentsState;
  const { isShowLoading } = uiState;

  useEffect(() => {
    if (getTrainingsWorking && currentGroup.id) {
      getTrainingsWorking({ groupId: currentGroup.id });
    }
    if (getTrainingsFinish && currentGroup.id) {
      getTrainingsFinish({ groupId: currentGroup.id });
    }
  }, [currentGroup.id]);

  return (
    <div className={styles.topWrap}>
      <Task
        createTrainingsResult={createTrainingsResult}
        title="課題作業中"
        trainings={trainingsWorking}
        pagination={paginationWorking}
        loading={isShowLoading}
        getTrainingsWorking={getTrainingsWorking}
        getTrainingsFinish={getTrainingsFinish}
        userId={userId}
        finish={false}
      />
      <Task
        createTrainingsResult={createTrainingsResult}
        title="課題提出中"
        trainings={trainingsFinish}
        pagination={paginationFinish}
        loading={isShowLoading}
        getTrainingsWorking={getTrainingsWorking}
        getTrainingsFinish={getTrainingsFinish}
        userId={userId}
        finish
      />
    </div>
  );
};

export default Home;
