import { combineEpics } from 'redux-observable';
import { showLeftMenu } from './ui/ui.epic';
import { userLogin, userLogout, userChangePassword, getAuth } from './user/user.epic';
import { fetchRepositories } from './github/github.epic';
import { createUserEpic, deleteUserEpic, getUsersEpic, updateUserEpic } from './groups/groups.epic';
import {
  createTrainingEpic,
  deleteTrainingEpic,
  getTrainingEpic,
  getTrainingsEpic,
  getTrainingsFinishEpic,
  getTrainingsWorkingEpic,
  updateTrainingEpic,
} from './assignments/assignments.epic';
import {
  clearTemplate,
  createTemplateEpic,
  getTemplateEpic,
  updateTemplateStep1,
  updateTemplateStep2,
  updateTemplateStep3,
  updateTemplateStep4,
  updateTemplateStep5,
  updateTemplateStep6,
  updateTemplateStep7,
} from './template/template.epic';
import { getQuestionsEpic } from './questions/questions.epic';
import { clearState, uploadImage } from './upload/upload.epic';
import {
  createTrainingResultEpic,
  getTrainingResultByTrainingAndStudent,
  getTrainingResultByTrainingEpic,
  resetStateTraining,
  updateTrainingResultEpic,
} from './trainingResult/trainingResult.epic';
import { deleteTemplateEpic, getAllTemplatesEpic, getTemplatesEpic } from './templates/templates.epic';
import { forgotPassword, resetPassword } from './forgotPassword/forgotPassword.epic';
import {
  createBaseLibraryEpic,
  deleteBaseLibraryEpic,
  downloadBaseLibraryEpic,
  getBaseLibraryDetailEpic,
  getBaseLibraryListEpic,
  getBaseLibrarySampleEpic,
  updateBaseLibraryEpic,
  uploadBaseLibraryFileEpic,
} from './baseLibrary/baseLibrary.epic';
import {
  createGroupsClassEpic,
  deleteGroupsClassEpic,
  getDetailGroupClassEpic,
  getGroupsClassEpic,
  getGroupsClassModalEpic,
  updateGroupsClassEpic,
} from './groupsClass/groupsClass.epic';

const combineUserEpics = [userLogout, userLogin, userChangePassword, getAuth];

const combineGroupEpics = [getUsersEpic, createUserEpic, updateUserEpic, deleteUserEpic];

const combineAssignmentsEpics = [
  getTrainingsEpic,
  getTrainingEpic,
  createTrainingEpic,
  updateTrainingEpic,
  deleteTrainingEpic,
  getTrainingsWorkingEpic,
  getTrainingsFinishEpic,
];

const combineTemplatesEpics = [getTemplatesEpic, deleteTemplateEpic, getAllTemplatesEpic];

const combineTemplateEpics = [
  getTemplateEpic,
  createTemplateEpic,
  updateTemplateStep1,
  updateTemplateStep2,
  updateTemplateStep3,
  updateTemplateStep4,
  updateTemplateStep5,
  updateTemplateStep6,
  updateTemplateStep7,
  clearTemplate,
];

const combineUploadEpics = [uploadImage, clearState];

const combineQuestionsEpics = [getQuestionsEpic];

const combineTrainingResultEpics = [
  createTrainingResultEpic,
  updateTrainingResultEpic,
  getTrainingResultByTrainingEpic,
  resetStateTraining,
  getTrainingResultByTrainingAndStudent,
];

const combineBaseLibraryEpics = [
  getBaseLibraryListEpic,
  uploadBaseLibraryFileEpic,
  createBaseLibraryEpic,
  deleteBaseLibraryEpic,
  getBaseLibraryDetailEpic,
  updateBaseLibraryEpic,
  downloadBaseLibraryEpic,
  getBaseLibrarySampleEpic,
];

const combineGroupsClassEpics = [
  getGroupsClassEpic,
  getGroupsClassModalEpic,
  deleteGroupsClassEpic,
  createGroupsClassEpic,
  getDetailGroupClassEpic,
  updateGroupsClassEpic,
];
const combineForgotPasswordEpics = [forgotPassword, resetPassword];

export default combineEpics(
  fetchRepositories,
  showLeftMenu,
  ...combineAssignmentsEpics,
  ...combineUserEpics,
  ...combineGroupEpics,
  ...combineTemplateEpics,
  ...combineQuestionsEpics,
  ...combineUploadEpics,
  ...combineTrainingResultEpics,
  ...combineTemplatesEpics,
  ...combineForgotPasswordEpics,
  ...combineBaseLibraryEpics,
  ...combineGroupsClassEpics,
);
