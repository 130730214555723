import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map } from 'rxjs/operators';
import { asyncActionWithCallback, WrapAction } from 'libs/reduxObservableUtils';
import { from } from 'rxjs';
import { AxiosResponse } from 'libs/axios';
import { uiModule } from 'modules/ui/ui.reducer';
import { messageModule } from 'modules/message/message.reducer';
import { questionsServices } from 'services/questions';
import { TQuestions } from 'types/questions';
import { templateModule } from '../template/template.reducer';
import actions from './questions.action';

export const getQuestionsEpic: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getQuestions),
    map(({ payload }) =>
      asyncActionWithCallback({
        previous: uiModule.actions.showLoading(),
        asyncFns: from(questionsServices.getQuestions(payload)),
        next: (v: TQuestions[]) => [templateModule.actions.updateQuestions(v)],
        complete: uiModule.actions.hideLoading(),
        error: (err: AxiosResponse) => messageModule.actions.setError(err.data.message),
      }),
    ),
  );
