import { IndexedObject } from 'constants/types';
import { TQuestions } from 'types/questions';
import { TAnswerTrainingResult } from 'types/trainingResult';

export const converDataAfterSave = (data: TQuestions[], values: IndexedObject): TAnswerTrainingResult[] => {
  const newData = [...data];
  const answer: TAnswerTrainingResult[] = [];

  newData.forEach((questions) =>
    questions.questions.forEach((question) => {
      const value = values[question.fieldCd];

      const arr = value ? value.toString().split('|') : [];
      const answerValue = arr.length > 1 ? arr[1] : arr[0];
      const answerIndex = arr.length > 1 ? arr[0] : undefined;
      answer.push({
        questionId: question.questionId,
        fieldCd: question.fieldCd,
        answerValue,
        answerIndex,
      });
    }),
  );
  return answer;
};

export const convertData = (data: TAnswerTrainingResult[]): IndexedObject => {
  const newData = [...data];
  const answer: IndexedObject = {};
  newData.forEach((questions) =>
    Object.assign(answer, {
      [questions.fieldCd]: questions.answerIndex
        ? `${questions.answerIndex}|${questions.answerValue}`
        : questions.answerValue,
    }),
  );
  return answer;
};
