import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './style.module.scss';

const CommonCustomInput: React.FC<{
  label: string;
  register: UseFormRegisterReturn<string>;
  disabled: boolean;
}> = ({ label, register, disabled }) => (
  <div className={styles.item}>
    <label htmlFor={label}>
      {label} <input {...register} disabled={disabled} />
    </label>
  </div>
);

export default CommonCustomInput;
