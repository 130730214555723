import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Select, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { logger } from 'libs/logger';
import { ENavType } from 'constants/nav';
import classnames from 'classnames';
import { TTemplateState } from 'modules/template/template.reducer';
import { routers } from 'constants/router';
import EditTemplate from 'libs/hook/EditTemplate';
import ButtonMoveA3 from 'components/ButtonMoveA3';
import { MESSAGE_VALIDATE_TEMPLATE_BASE, MESSAGE_VALIDATE_TEMPLATE_NAME } from 'constants/notifications/message';
import { TGroupsClassWithBaseLibDetail } from 'types/groupsClass';
import { defaultValidateMessages } from 'constants/validate/message';
import { templateState, TTemplate } from './TemplateRfp.state';
import ModalUpload from './Modal/ModalUpload';
import styles from './style.module.scss';

const TemplateRfp: React.FC<{
  creteTemplate: (data: TTemplate & { baseLibrary: number }, link?: string) => void;
  template: TTemplateState;
  updateTemplate: (data: TTemplate & { baseLibrary: number }, id: string, buttonFooter: boolean, link?: string) => void;
  updateImage: (data: FormData) => void;
  clearStateUpload: () => void;
  groupId: number;
  getDetailGr: () => void;
  getBaseLibraryDetail: (baseLibrary: number) => void;
  groupDetail: TGroupsClassWithBaseLibDetail;
}> = ({
  creteTemplate,
  getDetailGr,
  getBaseLibraryDetail,
  template,
  groupId,
  updateTemplate,
  updateImage,
  clearStateUpload,
  groupDetail,
}) => {
  const [state, setState] = useState<TTemplate & { baseLibrary: number }>(templateState);
  const [isShowModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [formSub] = Form.useForm();
  const history = useHistory();

  EditTemplate();

  useEffect(() => {
    if (template.id) {
      const templateData = {
        name: template.name,
        picture: template.picture,
        description: template.description,
        template: template.template,
        isCompleted: template.isCompleted,
        baseLibrary: template.baseLibrary ?? 0,
      };
      setState({
        ...state,
        ...templateData,
      });

      form.setFieldsValue(templateData);
      formSub.setFieldsValue(templateData);
    }
  }, [template]);
  useEffect(() => {
    getDetailGr();
  }, [groupId]);

  const onFinish = (values: any) => {
    logger.log('Click ok', values);
  };
  const onChangeBaseLibrary = (value: number) => {
    logger.log('onChangeBaseLibrary', value);
    getBaseLibraryDetail(value);
  };

  const onClickNextStep = async (buttonFooter: boolean = false, link?: string) => {
    const value = await form.validateFields();
    const desc = await formSub.validateFields();
    const data = {
      ...state,
      ...desc,
      name: value.name,
      template: value.template,
      picture: template.picture,
    };

    logger.log('baseLibrary diff', value.baseLibrary !== template.baseLibrary, value.baseLibrary, template.baseLibrary);

    if (!template.id) {
      creteTemplate({ ...data, baseLibrary: value.baseLibrary }, link);
    } else {
      updateTemplate(
        { ...data, baseLibrary: value.baseLibrary ?? template.baseLibrary },
        template.id,
        buttonFooter,
        link,
      );
    }
  };

  const onClickPreStep = () => {
    history.push(routers.A3.path);
  };

  const clickChangeRoute = (link: string) => {
    onClickNextStep(false, link);
  };

  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <Row>
          <Col span={12}>
            <Row>
              <p>研修テンプレート登録</p>
              <p className={styles.title}>{template.name}</p>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <ButtonMoveA3 />
            </Row>
          </Col>
        </Row>
        <NavStep type={ENavType.teacher} onClick={clickChangeRoute} />
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div className={styles.item}>
              <div className={styles.item__top}>研修テンプレート名称入力、ベースライブラリ選択</div>
              <div className={styles.item__bottom}>
                <Form
                  form={form}
                  autoComplete="off"
                  layout="vertical"
                  initialValues={{ name: template.name, template: template.template }}
                  onFinish={onFinish}
                >
                  <span>研修テンプレート名</span>
                  <Form.Item name="name" rules={[{ required: true, message: MESSAGE_VALIDATE_TEMPLATE_NAME }]}>
                    <Input placeholder="example" />
                  </Form.Item>
                  <span>研修テンプレートのベースとなる設定を選択します</span>
                  <Form.Item name="baseLibrary" rules={[{ required: true, message: MESSAGE_VALIDATE_TEMPLATE_BASE }]}>
                    <Select
                      placeholder="Please select"
                      options={groupDetail.baseLibraries?.map((b) => ({ value: b.id ?? 0, label: b.name ?? '' }))}
                      onChange={onChangeBaseLibrary}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div className={classnames([styles.item, styles.item2])}>
              <div className={styles.item__top}>業務命令書の設定</div>
              <div className={styles.item__bottom}>
                <Form
                  form={formSub}
                  autoComplete="off"
                  layout="vertical"
                  initialValues={{ description: template.description }}
                >
                  <span>研修の冒頭説明の文章を設定します。2000文字まで設定できます。</span>
                  <Form.Item
                    name="description"
                    rules={[{ required: true, message: defaultValidateMessages.whitespace }]}
                  >
                    <Input.TextArea rows={4} placeholder="text" />
                  </Form.Item>
                  <p>写真の設定</p>
                  <span>冒頭説明で画像を載せたい場合は、画像のアップロードをしてください。</span>
                  <br />
                  <span>（JPEG/GIF/PNG形式。添付可能なファイルサイズは最大で5MBです。）</span>
                </Form>
                <Button onClick={() => setShowModal(true)}>ファイルを選択</Button>
                <span className={styles.imgName}>{template.picture ? template.picture : '選択されていません。'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalUpload
        open={isShowModal}
        setModal={setShowModal}
        updateImage={updateImage}
        clearStateUpload={clearStateUpload}
      />
      <ButtonFooter handelClickNext={onClickNextStep} handelClickPre={onClickPreStep} />
    </>
  );
};

export default TemplateRfp;
