import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Table } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import NavStep from 'components/NavStep/NavStep';
import { ENavType } from 'constants/nav';
import { TWorkingTime } from 'types/workingTime';
import ButtonMoveA3 from 'components/ButtonMoveA3';
import { TTemplateState } from 'modules/template/template.reducer';
import { TBaseLibState } from 'modules/baseLibrary/baseLibrary.reducer';
import EditTemplate from 'libs/hook/EditTemplate';
import { dataWorkingTime } from 'mock/workingTime';
import { ColumnTypes, defaultColumns, TStep6 } from './WorkingTime.state';
import EditableCellProps from '../../../components/EditTableColRow/EditableCellProps';
import EditableRow from '../../../components/EditTableColRow/EditableRow';
import styles from './style.module.scss';

const WorkingTime: React.FC<{
  updateTemplate: (data: TStep6, id: string, buttonFooter: boolean, link?: string) => void;
  template: TTemplateState;
  baseLibState: TBaseLibState;
}> = ({ updateTemplate, template, baseLibState }) => {
  const [workingTime, setWorkingTime] = useState<TWorkingTime[] | []>([]);

  EditTemplate();

  useEffect(() => {
    if (template.step6 && template.step6.length > 0) {
      setWorkingTime(template.step6);
    } else {
      setWorkingTime(dataWorkingTime);
    }
  }, [template, baseLibState]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCellProps,
    },
  };

  const handleSave = (row: TWorkingTime) => {
    const newData = [...workingTime];
    const index = newData.findIndex((item) => row.name === item.name);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      totalMonth:
        row.month1 +
        row.month2 +
        row.month3 +
        row.month4 +
        row.month5 +
        row.month6 +
        row.month7 +
        row.month8 +
        row.month9 +
        row.month10 +
        row.month11 +
        row.month12,
    });

    setWorkingTime(newData);
  };

  const columns: ColumnTypes = defaultColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    const newCol = {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: true,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map((e: any) => {
        if (!e.editable) {
          return e;
        }
        return {
          ...e,
          onCell: (record: any) => ({
            record,
            editable: true,
            dataIndex: e.dataIndex,
            title: e.title,
            handleSave,
          }),
        };
      });
    }
    return newCol;
  });

  const onFinish = (buttonFooter: boolean = false, link?: string) => {
    const data: TStep6 = {
      step6: workingTime,
    };
    updateTemplate(data, template.id, buttonFooter, link);
  };

  const checkTotal = useMemo(
    (): boolean => !workingTime.every((workingTime) => workingTime.totalMonth === 100),
    [workingTime],
  );

  const totalWorkTime = (unit: string, isOptimal: boolean = false) => {
    const data = [...workingTime];
    let total = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (unit === data[i].unit) {
        total = (total * 1000 + data[i].laborTime * 1000) / 1000;
        if (isOptimal) {
          // Calculating time savings from investment
          total -= data[i].optimalTime;
        }
      }
    }
    return total;
  };

  const onClickChangeRouter = (link: string) => {
    const buttonFooter = false;
    onFinish(buttonFooter, link);
  };

  return (
    <>
      <div className={styles.assignmentsQuestionWrap}>
        <Row>
          <Col span={12}>
            <Row>
              <p>研修テンプレート登録</p>
              <p className={styles.title}>{template.name}</p>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <ButtonMoveA3 />
            </Row>
          </Col>
        </Row>
        <NavStep type={ENavType.teacher} onClick={onClickChangeRouter} />
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div className={styles.contentWrap__left}>
              <div className={styles.contentTitle}>労働時間試算設定</div>
              <div className={styles.form}>
                <div className={styles.form__top}>
                  <div className={styles.left}>
                    <ul>
                      <li>①年間の必要労働量の設定を行います。</li>
                      <li>②設備投資時の年間の削減量の設定を行います。</li>
                      <li>③対象月の必要労働量の比率の設定を行います。</li>
                    </ul>
                  </div>
                  <div className={styles.right}>
                    <ul>
                      <li>
                        年間の必要労働時間合計
                        <span>
                          {totalWorkTime('h/10a')} h/10a + {totalWorkTime('h/t')} h/t
                        </span>
                      </li>
                      <li>
                        設備投資時の年間必要労働時間合計
                        <span>
                          {totalWorkTime('h/10a', true)} h/10a + {totalWorkTime('h/t', true)} h/t
                        </span>
                      </li>
                    </ul>
                    {checkTotal && <p className={styles.textRed}>比率合計が100%となっていない項目があります</p>}
                  </div>
                </div>
                <div className={styles.form__bottom}>
                  <Table
                    columns={columns}
                    dataSource={workingTime}
                    scroll={{ y: 350 }}
                    pagination={false}
                    rowClassName={() => 'editable-row'}
                    bordered
                    components={components}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtonFooter handelClickNext={onFinish} handelClickPre={onFinish} />
    </>
  );
};

export default WorkingTime;
