import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const createTemplate: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'templates',
};

const updateTemplateStep1: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'templates/:id',
};

const getTemplate: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'templates/:id',
};
export const templateRepositories = {
  createTemplate: apiClient.postFn(createTemplate),
  updateTemplateStep1: apiClient.patchFn(updateTemplateStep1),
  getTemplate: apiClient.getFn(getTemplate),
};
