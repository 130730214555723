/* eslint-disable complexity */
import React from 'react';
import './style.css';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import { getNumberOnly } from 'libs/helpers/functions';
import SheetTable from '../../../components/SheetTable/SheetTable';
import styles from './style.module.scss';
import { THEME_TABLE } from './AssignmentCalculationSettingsYields.state';

const AssignmentCalculationYieldsForm: React.FC<{ configTable: any }> = ({ configTable }) => (
  <div>
    <div>
      <p>シミュレーションの結果栽培に必要な労働時間が確定しました！</p>
      {configTable?.configTable1 && (
        <SheetTable dataCfg={configTable?.configTable1} options={configTable?.optionTable1} themeCfg={THEME_TABLE} />
      )}
      {getNumberOnly(configTable?.dataTable1[0].value1) > getNumberOnly(configTable?.dataTable1[1].value1) ? (
        <p className={styles.descTable}>
          栽培作業者（パート等）の労働時間が不足しています。雇用計画を見直してください。
        </p>
      ) : (
        ''
      )}
    </div>
    {/* row 2 */}
    <div>
      <p className={styles.titleTable}>栽培に必要な月別工数（時間）</p>
      <p>工数（時間）</p>
      {configTable?.dataChart && <ColumnChart configChart={configTable?.dataChart} />}
    </div>
    {/* row 3 */}
    <div>
      <p className={styles.titleTable}>栽培に必要な月別工数（時間）</p>
      {configTable?.configTable2 && (
        <SheetTable dataCfg={configTable?.configTable2} options={configTable?.optionTable2} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 4 */}
    <div>
      <p>人件費（年間）</p>
      {configTable?.configTable3 && (
        <SheetTable dataCfg={configTable?.configTable3} options={configTable?.optionTable3} themeCfg={THEME_TABLE} />
      )}
    </div>
  </div>
);

export default AssignmentCalculationYieldsForm;
