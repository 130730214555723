/* eslint-disable complexity */
import React from 'react';
import MainButton from 'components/MainButton/MainButton';
import styles from './style.module.scss';

const ButtonFooter: React.FC<{
  active?: boolean;
  handelClickPre?: () => void;
  handelClickNext?: (buttonFooter?: boolean) => void;
  handelClickFinish?: (buttonFooter?: boolean) => void;
}> = ({ handelClickPre, handelClickNext, handelClickFinish, active }) => {
  const onClickPre = () => {
    if (handelClickPre) {
      handelClickPre();
    }
  };

  const onClickNext = () => {
    if (handelClickNext) {
      handelClickNext(true);
    }
  };

  const onClickFinish = () => {
    if (handelClickFinish) {
      handelClickFinish(true);
    }
  };

  return (
    <div className={styles.buttonFooter}>
      {handelClickPre && (
        <div className={styles.buttonWrap}>
          <MainButton subButton text="戻る" onClick={onClickPre} />
        </div>
      )}
      {handelClickNext && (
        <div className={styles.buttonWrap}>
          <MainButton text="進む" disable={active} onClick={onClickNext} />
        </div>
      )}
      {handelClickFinish && (
        <div className={styles.buttonFinish}>
          <MainButton text="完了して一覧に戻る" onClick={onClickFinish} />
        </div>
      )}
    </div>
  );
};

export default ButtonFooter;
