import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { paginationDefault } from 'constants/pagination';
import { TResponsePagination } from 'libs/axios';
import { saveGroupToLS } from 'libs/helpers/functions';
import { TOptionsQuery, TPagination } from 'types/common';
import { TUserDetail } from 'types/groups';
import { TGroupsClassDetail, TGroupsClassWithBaseLibDetail } from 'types/groupsClass';

type TShowModal = 'groups' | 'delete' | '';
export type TGroupsClassState = {
  loading: boolean;
  error: boolean;
  showModal: TShowModal;
  message: string;
  groupDetail: TGroupsClassWithBaseLibDetail;
  groups: Array<TGroupsClassDetail>;
  groupsModal: Array<TGroupsClassDetail>;
  pagination: TPagination;
  currentGroup: TGroupsClassDetail;
  pagingModal: Partial<TPagination>;
};

const initialState: TGroupsClassState = {
  loading: false,
  error: false,
  showModal: '',
  message: '',
  groups: [],
  groupsModal: [],
  groupDetail: {},
  currentGroup: {},
  pagination: paginationDefault(),
  pagingModal: paginationDefault(),
};

const _module = createSlice({
  name: 'groupsClass',
  initialState,
  reducers: {
    loading(state: TGroupsClassState) {
      return {
        ...state,
        loading: true,
      };
    },
    stopLoading(state) {
      return {
        ...state,
        loading: false,
      };
    },
    setModal: (state: TGroupsClassState, action: PayloadAction<TShowModal>) => ({
      ...state,
      showModal: action.payload,
    }),
    setParams(state: TGroupsClassState, action: PayloadAction<TOptionsQuery<TUserDetail>>) {
      return {
        ...state,
        params: action.payload,
      };
    },
    createSuccess(state, action: PayloadAction<TShowModal>) {
      return {
        ...state,
        showModalNoti: action.payload,
      };
    },
    clearSuccess(state) {
      return {
        ...state,
        showModalNoti: '',
      };
    },
    getListGroups: (state: TGroupsClassState, action: PayloadAction<TResponsePagination<TGroupsClassDetail>>) => {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        groups: results,
        pagination,
      };
    },
    removeGroups: (state: TGroupsClassState, action: PayloadAction<number>) => {
      const newListGroups = state.groups.filter((g: TGroupsClassDetail) => g.id !== action.payload);
      if (state.currentGroup.id === action.payload) {
        saveGroupToLS(newListGroups[0]);
        return {
          ...state,
          groups: newListGroups,
          currentGroup: newListGroups[0],
        };
      }
      return {
        ...state,
        groups: newListGroups,
      };
    },
    saveListGroups: (state: TGroupsClassState, action: PayloadAction<TGroupsClassDetail>) => ({
      ...state,
      groups: [...state.groups, action.payload],
    }),
    saveListGroupsModal: (state: TGroupsClassState, action: PayloadAction<TResponsePagination<TGroupsClassDetail>>) => {
      const { results, ...pagination } = action.payload;
      return {
        ...state,
        groupsModal: results,
        pagingModal: pagination,
      };
    },
    updateListGroups: (state: TGroupsClassState, action: PayloadAction<TGroupsClassDetail>) => {
      const updateListGroups = state.groups.map((g: TGroupsClassDetail) =>
        g.id === action.payload.id ? action.payload : g,
      );
      return {
        ...state,
        groups: updateListGroups,
      };
    },
    saveDetailGroups: (state: TGroupsClassState, action: PayloadAction<TGroupsClassWithBaseLibDetail>) => ({
      ...state,
      groupDetail: action.payload,
    }),
    saveCurrentGroup: (state: TGroupsClassState, action: PayloadAction<TGroupsClassDetail>) => ({
      ...state,
      currentGroup: action.payload,
    }),
    savePagingModal: (state: TGroupsClassState, action: PayloadAction<Partial<TPagination>>) => ({
      ...state,
      pagingModal: action.payload,
    }),
    clearState: (state: TGroupsClassState, action) => initialState,
  },
});

export const groupsClassModule = _module;
