import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import AssignmentsRfp from './AssignmentsRfp';

const AssignmentsRfpContainer = () => {
  const { trainingResult } = useSelector((state: AppState) => state.pages);

  return <AssignmentsRfp trainingResult={trainingResult} />;
};

export default AssignmentsRfpContainer;
