import trainingResultAction from 'modules/trainingResult/trainingResult.action';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from 'store';
import AssignmentsMenu from './AssignmentsMenu';

const AssignmentsMenuContainer = () => {
  const dispatch = useDispatch();
  const { trainingId } = useParams<{ trainingId: string }>();
  const trainingResultState = useSelector((state: AppState) => state.pages.trainingResult);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);
  const uiState = useSelector((state: AppState) => state.pages.ui);

  const getTrainingResultByTraining = (trainingId: string) => {
    if (currentGroup.id)
      dispatch(trainingResultAction.getTrainingResultByTraining({ trainingId, groupId: currentGroup.id }));
  };

  useEffect(() => {
    if (trainingId) {
      getTrainingResultByTraining(trainingId);
    }
  }, [trainingId, currentGroup.id]);
  return <AssignmentsMenu trainingResultState={trainingResultState} uiState={uiState} />;
};

export default AssignmentsMenuContainer;
