import { IndexedObject } from 'constants/types';

export const TITLE_WORK: IndexedObject = {
  U7: '方針',
  U7_2: '初期投資',
  U7_3: '年間費用',
  U7_4: '雇用計画',
  U7_5: '販売計画',
  U7_6: '管理経費',
  U7_7: '規模拡大',
  U7_8: '資金調達',
};
