/* eslint-disable complexity */
import React from 'react';
import SheetTable from '../../../components/SheetTable/SheetTable';
import { THEME_TABLE, OPTIONS_TABLE, OPTIONS_TABLE_ROW_3 } from './AssignmentCalculatorGrade.state';

const CalculatorGradeForm: React.FC<{ configTable: any }> = ({ configTable }) => (
  <div>
    <div>
      <p>売上（年間）</p>
      {configTable?.configTable1 && (
        <SheetTable dataCfg={configTable?.configTable1} options={OPTIONS_TABLE} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 2 */}
    <div>
      <p>販促活動費（年間）</p>
      {configTable?.configTable2 && (
        <SheetTable dataCfg={configTable?.configTable2} options={OPTIONS_TABLE_ROW_3} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 3 */}
    <div>
      <p>梱包材料費（年間）</p>
      {configTable?.configTable3 && (
        <SheetTable dataCfg={configTable?.configTable3} options={OPTIONS_TABLE_ROW_3} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 4 */}
    <div>
      <p>物流・輸送費（年間）</p>
      {configTable?.configTable4 && (
        <SheetTable dataCfg={configTable?.configTable4} options={OPTIONS_TABLE_ROW_3} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 5 */}
    <div>
      <p>その他経費（年間）</p>
      {configTable?.configTable5 && (
        <SheetTable dataCfg={configTable?.configTable5} options={OPTIONS_TABLE_ROW_3} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 6 */}
    <div>
      {/* <p>機器稼働費（年間）</p> */}
      {configTable?.configTable6 && (
        <SheetTable dataCfg={configTable?.configTable6} options={OPTIONS_TABLE} themeCfg={THEME_TABLE} />
      )}
    </div>
  </div>
);

export default CalculatorGradeForm;
