import { TNavSteps } from 'types/common';

export enum ENavType {
  teacher = 'teacher',
  student = 'student',
}

export const navAdminEdit: TNavSteps = [
  { name: 'STEP1', label: '業務命令書', link: '/adm/templates/:traningId/edit/rfp' },
  { name: 'STEP2', label: '設問設定①', link: '/adm/templates/:traningId/edit/questions/1' },
  { name: 'STEP3', label: '設問設定②', link: '/adm/templates/:traningId/edit/questions/2' },
  { name: 'STEP4', label: '収量試算設定', link: '/adm/templates/:traningId/edit/calculation_settings/yields' },
  { name: 'STEP5', label: '等級試算設定', link: '/adm/templates/:traningId/edit/calculation_settings/grade' },
  {
    name: 'STEP6',
    label: '労働時間試算設定',
    link: '/adm/templates/:traningId/edit/calculation_settings/working_time',
  },
  { name: 'STEP7', label: '達成目標設定', link: '/adm/templates/:traningId/edit/kpi' },
];

export const navAdmin: TNavSteps = [
  { name: 'STEP1', label: '業務命令書', link: '/adm/templates/add/rfp' },
  { name: 'STEP2', label: '設問設定①', link: '/adm/templates/add/questions/1' },
  { name: 'STEP3', label: '設問設定②', link: '/adm/templates/add/questions/2' },
  { name: 'STEP4', label: '収量試算設定', link: '/adm/templates/add/calculation_settings/yields' },
  { name: 'STEP5', label: '等級試算設定', link: '/adm/templates/add/calculation_settings/grade' },
  { name: 'STEP6', label: '労働時間試算設定', link: '/adm/templates/add/calculation_settings/working_time' },
  { name: 'STEP7', label: '達成目標設定', link: '/adm/templates/add/kpi' },
];

export const navClient: TNavSteps = [
  { name: 'STEP1', label: '方針', link: '/assignments/:trainingId/questions/1' },
  { name: 'STEP2', label: '初期投資', link: '/assignments/:trainingId/questions/2' },
  { name: 'STEP3', label: '年間費用', link: '/assignments/:trainingId/questions/3' },
  { name: 'STEP4', label: '雇用計画', link: '/assignments/:trainingId/questions/4' },
  { name: 'STEP5', label: '販売計画', link: '/assignments/:trainingId/questions/5' },
  { name: 'STEP6', label: '管理経費', link: '/assignments/:trainingId/questions/6' },
  { name: 'STEP7', label: '規模拡大', link: '/assignments/:trainingId/questions/7' },
  { name: 'STEP8', label: '資金調達', link: '/assignments/:trainingId/questions/8' },
];
