import React from 'react';
import styles from './style.module.scss';

const ChartTitle: React.FC<{
  title: string;
  subTitle?: string;
}> = ({ title, subTitle }) => (
  <div className={styles.chartTitleWrap}>
    {title && <div className={styles.title}>{title}</div>}
    <div className={styles.line} />
    {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
  </div>
);

export default ChartTitle;
