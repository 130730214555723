import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import templateAction from 'modules/template/template.action';
import WorkingTime from './WorkingTime';
import { TStep6 } from './WorkingTime.state';

const WorkingTimeContainer = () => {
  const dispatch = useDispatch();
  const template = useSelector((state: AppState) => state.pages.template);
  const { currentGroup } = useSelector((state: AppState) => state.pages.groupClass);
  const baseLibState = useSelector((state: AppState) => state.pages.baseLibrary);

  const updateTemplate = (data: TStep6, id: string, buttonFooter: boolean, link?: string) => {
    if (currentGroup.id) {
      dispatch(templateAction.updateTemplateStep6({ groupId: currentGroup.id, data, id, buttonFooter, link }));
    }
  };

  return <WorkingTime updateTemplate={updateTemplate} template={template} baseLibState={baseLibState} />;
};

export default WorkingTimeContainer;
