import { EPath } from 'constants/routes';
import { parseSearchParams, replacePathParams } from 'libs/helpers/functions';
import groupsClassAction from 'modules/groupsClass/groupsClass.action';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AppState } from 'store';
import { TOptionsQuery } from 'types/common';
import { TGroupsClassDetail } from 'types/groupsClass';
import GroupsManage from './GroupsManage';

const GroupsManageContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const urlQueryParams: TOptionsQuery<TGroupsClassDetail> = parseSearchParams(location.search);
  const groupsClassState = useSelector((state: AppState) => state.pages.groupClass);

  const deleteGroupsClass = (id: number) => {
    dispatch(groupsClassAction.deleteGroupsClass(id));
  };

  const goGroupsDetailPage = (groupId: number) =>
    history.push(replacePathParams(EPath.S3_2, { id: groupId.toString() }));
  return (
    <GroupsManage
      groupsClassState={groupsClassState}
      deleteGroupsClass={deleteGroupsClass}
      goGroupsDetailPage={goGroupsDetailPage}
      urlQueryParams={urlQueryParams}
    />
  );
};

export default GroupsManageContainer;
