import { getRefreshToken } from 'config/server';
import { TAuth, TAuthWithToken } from 'modules/user/user.reducer';
import { authRepositories } from 'repositories/auth';
import { TChangePasswordBody, TUser } from 'types/auth';

const login = async (data: TUser): Promise<TAuthWithToken> => {
  const res = await authRepositories.postLogin({
    body: data,
  });

  if (res instanceof Error) {
    throw res;
  }
  if (!res.result) {
    return {
      isAuthenticated: false,
      currentUser: {},
      token: '',
      refreshToken: '',
    };
  }

  return {
    isAuthenticated: true,
    currentUser: res.data.user,
    token: res.data.tokens.access.token,
    refreshToken: res.data.tokens.refresh.token,
  };
};

const logout = async (): Promise<any> => {
  const refreshToken = getRefreshToken();
  return authRepositories.postLogout({
    body: { refreshToken },
  });
};

const refreshTokens = async (refreshToken: string): Promise<any> =>
  authRepositories.refreshTokens({
    body: { refreshToken },
  });

const changePassword = async (data: TChangePasswordBody): Promise<any> =>
  authRepositories.changePassword({
    body: data,
  });

const getAuth = async (refreshToken: string): Promise<TAuth> => {
  const res = await authRepositories.getAuth({
    body: { refreshToken },
  });

  if (res instanceof Error) {
    throw res;
  }
  if (!res.result) {
    return {
      isAuthenticated: false,
      currentUser: {},
    };
  }

  return {
    isAuthenticated: true,
    currentUser: res.data,
  };
};

export const authServices = {
  login,
  logout,
  changePassword,
  refreshTokens,
  getAuth,
};
